import * as amplitude from '@amplitude/analytics-browser';
import { type ValidPropertyType } from '@amplitude/analytics-types';
import { mainLogger } from '@nord-beaver/core/utils/logger';
import { merge } from '@nord-beaver/core/utils/utils';

export type AmplitudeEventData = Record<string, ValidPropertyType>;
export type EventData = Record<string, unknown> | void;

const logger = mainLogger.getLogger('Analytics', '#2c92ff').getLogger('Amplitude');

export class AmplitudeService {
    protected readonly commonProps: AmplitudeEventData;

    constructor() {
        this.commonProps = {};
    }

    init({
        apiKey,
        id,
    }: {
        apiKey: string;
        id: string;
    }) {
        amplitude.init(apiKey, {
            autocapture: true,
            userId: id,
            sessionId: Date.now(),
            serverZone: 'EU',
        });

        logger.log('Initialized');
    }

    updateUserProperties(action: (user: amplitude.Identify) => void) {
        const user = new amplitude.Identify();

        action(user);

        amplitude.identify(user);

        logger.log('User properties updated');
    }

    setUserId(userId: string) {
        amplitude.setUserId(userId);

        logger.log(`User ID: ${userId}`);
    }

    setSessionId(sessionId: number) {
        amplitude.setSessionId(sessionId);

        logger.log(`Session ID: ${sessionId}`);
    }

    sendEvent(eventName: string, data: EventData) {
        const eventData = merge({}, this.commonProps, data);

        amplitude.track(eventName, eventData);

        logger.getLogger(eventName).log(eventData);
    }
}