import { mainLogger } from '@nord-beaver/core/utils/logger';
import { boot } from 'game/boot';

try {
    window.Telegram.WebApp.ready();
    window.Telegram.WebApp.expand();
    // // https://core.telegram.org/bots/webapps#initializing-mini-apps
    // (window.Telegram.WebApp as unknown as { requestFullscreen: () => void }).requestFullscreen();
} catch (error) {
    mainLogger.error('Telegram WebApp not found');
}

boot();