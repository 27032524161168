import { type Entity } from '@nord-beaver/core/ecs';
import { isDefined } from '@nord-beaver/core/utils/utils';
import { api } from 'game/api/api';
import { type Currency } from 'game/types/entityDescs/currency';
import { type MatchComponentDesc } from 'game/types/entityDescs/match';

export class MatchComponent {
    matchId: string;
    name: string;
    refLink: string;
    operationUid: string;
    bet: {
        type: Currency;
        value: number;
    } | undefined;

    isMatchCancelled = false;

    state: api.PlayerMatchState = api.PlayerMatchState.DICE;
    coins = 0;

    matchTimeMs?: number;
    turnTimeMs?: {
        current: number;
        max: number;
    };

    moveTileIndexes?: number[];
    buyTileAvailability = false;
    rewards: {
        name: string;
        avatar: string;
        currency: {
            type: Currency;
            value: number;
        };
        isPlayer: boolean;
    }[] = [];

    get matchInProcess() {
        return !this.isMatchCancelled && isDefined(this.matchTimeMs) && this.rewards.length === 0;
    }

    constructor(
        readonly desc: MatchComponentDesc,
        readonly mapEntity?: Entity,
        data?: {
            matchId?: string;
            name?: string;
            refLink?: string;
            bet?: {
                type: Currency;
                value: number;
            } | undefined;
            operationUid?: string;
        },
    ) {
        this.matchId = data?.matchId ?? '';
        this.name = data?.name ?? '';
        this.refLink = data?.refLink ?? '';
        this.bet = data?.bet;
        this.operationUid = data?.operationUid ?? '';
    }
}