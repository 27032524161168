<script setup lang="ts">
import { EventCategory } from 'game/services/analytics/amplitudeEventService';
import Container from 'game/ui/common/Container.vue';
import ScrollPan from 'game/ui/commonInstances/ScrollPan.vue';
import NavigationMenu from 'game/ui/components/lobby/NavigationMenu.vue';
import ResourcesCounter from 'game/ui/components/lobby/ResourcesCounter.vue';
import { useAmplitudeEventService } from 'game/ui/composables/amplitudeEventService';
import { useEventService } from 'game/ui/composables/eventService';
import { useModel } from 'game/ui/composables/model';
import { useWindowManager } from 'game/ui/composables/windowManager';
import { type ContainerIds } from 'game/ui/config/containers';
import { navigationMenuButtons } from 'game/ui/constants/nav';
import { useWindowsState } from 'game/ui/store/windowsState';
import { ref, watch } from 'vue';

defineProps<{
    containerId: ContainerIds;
    hideHeader?: boolean;
    hideNavigationMenu?: boolean;
}>();

const eventService = useEventService();
const amplitudeEventService = useAmplitudeEventService();
const windowManager = useWindowManager();
const windowState = useWindowsState();
const isMenuVisible = useModel(['lobby', 'isMenuVisible']);

const pageScrollPan = ref<InstanceType<typeof ScrollPan> | null>(null);
const selectedTab = useModel(['lobby', 'selectedTab']);

const onFriendsClick = () => {
    windowManager.setState('friends');

    amplitudeEventService.sendEvent('Menu click Friends', {
        category: EventCategory.Menu,
    });

    scrollToTop();
};
const onClansClick = () => {
    windowManager.setState('clans');

    amplitudeEventService.sendEvent('Menu click Clans', {
        category: EventCategory.Menu,
    });

    scrollToTop();
};

const onHomeClick = () => {
    windowManager.setState('vault');

    amplitudeEventService.sendEvent('Menu click Home', {
        category: EventCategory.Menu,
    });

    scrollToTop();
};

const onTasksClick = () => {
    windowManager.setState('tasks');

    amplitudeEventService.sendEvent('Menu click Tasks', {
        category: EventCategory.Menu,
    });

    scrollToTop();
};

const onPlayClick = () => {
    eventService.lobby.dispatch('startMatchmaking');

    amplitudeEventService.sendEvent('Menu click Play', {
        category: EventCategory.Menu,
    });

    scrollToTop();
};

const scrollToTop = () => {
    pageScrollPan.value?.setScroll(0);
};

const updateSelectedTab = (stateKey: keyof typeof windowManager.view.states | null) => {
    switch (stateKey) {
        case 'friends':
            selectedTab.value = 'friends';
            break;
        case 'clans':
            selectedTab.value = 'clans';
            break;
        case 'vault':
            selectedTab.value = 'home';
            break;
        case 'tasks':
            selectedTab.value = 'tasks';
            break;
        default:
            selectedTab.value = undefined;
            break;
    }
};


watch(
    () => windowState.currentState,
    stateKey => {
        updateSelectedTab(stateKey);
    },
    { immediate: true },
);

defineExpose({
    scrollToTop,
});
</script>

<template>
    <Container class="default-layout" :container-id="containerId">
        <ScrollPan ref="pageScrollPan" class="default-layout__content">
            <ResourcesCounter v-if="!hideHeader" class="default-layout__resources-counter" />

            <div class="default-layout__body">
                <slot />
            </div>
        </ScrollPan>

        <NavigationMenu
            v-if="isMenuVisible && !hideNavigationMenu"
            class="default-layout__navigation-menu"
            :buttons="navigationMenuButtons"
            :selected-button-type="selectedTab"
            @vault="onHomeClick"
            @friends="onFriendsClick"
            @clans="onClansClick"
            @play="onPlayClick"
            @tasks="onTasksClick"
        />
    </Container>
</template>

<style scoped lang="scss">
.default-layout {
    position: absolute;
    top: 0;
    left: 50%;

    display: grid;

    grid-template-rows: 1fr auto;
    grid-template-columns: 1fr;

    width: 100%;
    max-width: size(1290px);
    height: 100%;

    transform: translateX(-50%);

    &__content {
        position: relative;
        z-index: 2;

        display: flex;

        flex-direction: column;

        grid-row: 1;
        grid-column: 1;

        width: 100%;
        height: 100%;
        padding: 0 size(90px);
    }

    &__resources-counter {
        padding-top: size(24px);
        padding-bottom: size(24px);
    }

    &__navigation-menu {
        z-index: 10;

        grid-row: 2;
        grid-column: 1;

        width: 100%;
    }

    &__body {
        position: relative;
    }
}
</style>
