import { type NodeList, SystemService } from '@nord-beaver/core/ecs';
import { type EntityService } from '@nord-beaver/core/ecs/entityService';
import { mainLogger } from '@nord-beaver/core/utils/logger';
import { clamp } from '@nord-beaver/core/utils/utils';
import { api } from 'game/api/api';
import { VaultComponent } from 'game/ecs/components/meta/vaultComponent';
import { VaultNode } from 'game/ecs/nodes/meta/vaultNode';
import { MetaNode } from 'game/ecs/nodes/metaNode';
import { EventCategory, type AmplitudeEventService } from 'game/services/analytics/amplitudeEventService';
import { type EventService } from 'game/services/eventService';
import { type NakamaService } from 'game/services/nakamaService';
import { EnitityComponents } from 'game/types/entityComponents';
import { type VaultComponentDesc } from 'game/types/entityDescs/vault';
import { ApiRpc } from 'game/types/nakama/rpcData';
import { windowManager } from 'game/ui/services/windowManager';
import { type DependencyContainer } from 'game/utils/dependencyContainer';
import { getEntityComponentDesc } from 'game/utils/entityDesc';

const logger = mainLogger.getLogger('Meta').getLogger('VaultSystem');

export class VaultSystem extends SystemService {
    private vaultNodeList?: NodeList<VaultNode>;

    constructor(
        _dependencyContainer: DependencyContainer,
        private readonly eventService: EventService,
        private readonly nakamaService: NakamaService,
        private readonly entityService: EntityService,
        private readonly amplitudeEventService: AmplitudeEventService,
    ) {
        super();
    }

    init() {
        this.setupNodeList({
            node: MetaNode,
            add: this.addMeta,
            remove: this.removeMeta,
        });
        this.vaultNodeList = this.setupNodeList({
            node: VaultNode,
            update: this.updateVault,
        });

        this.eventService.lobby.on('claimVaultReward', this.claimReward, this);
    }

    override destroy() {
        this.eventService.lobby.offAll('claimVaultReward', this);
    }

    private addMeta(node: MetaNode) {
        const { meta, entity } = node;

        if (!meta.vaultData) {
            logger.error('Vault data not found', { entity });

            return;
        }

        const desc = getEntityComponentDesc<VaultComponentDesc>(this.entityService, '/vault', EnitityComponents.Vault);
        if (!desc) {
            logger.error('Vault component desc not found', { entity });

            return;
        }

        const vault = new VaultComponent(desc);
        entity.add(vault);

        this.updateVaultMeta(node);

        this.eventService.metaData.on('response', keys => {
            if (keys.includes('vaultData')) {
                vault.isFetching = false;

                this.updateVaultMeta(node);
            }
        }, entity);
        this.eventService.windowManager.on('changeState', state => {
            if (state.stateKey !== 'vault') {
                return;
            }

            vault.isFetching = true;

            this.eventService.metaData.dispatch('request', 'vaultData');
        }, entity);

        logger.info('Vault added', { entity });
    }

    private removeMeta(node: MetaNode) {
        const { entity } = node;

        this.eventService.metaData.offAll('response', entity);
    }

    private updateVaultMeta(node: MetaNode) {
        const { meta, entity } = node;
        if (!meta.vaultData) {
            logger.error('Vault data not found');

            return;
        }

        const vault = entity.get(VaultComponent);
        if (!vault) {
            logger.error('Vault component not found');

            return;
        }

        logger.log('Update vault meta', { vaultData: structuredClone(meta.vaultData) });

        if (!meta.vaultData.available) {
            const timerElapsedTimeMS = Number(meta.vaultData.timeData?.elapsedTimeS ?? 0) * 1000;
            const timerLeftTimeMS = Number(meta.vaultData.timeData?.nextRewardS ?? 0) * 1000;

            vault.leftTimeMS = timerLeftTimeMS;
            vault.elapsedTimeMS = timerElapsedTimeMS;
        } else {
            const claimTimeLeftMS = Number(meta.vaultData.timeData?.claimLeftTimeS ?? vault.desc.collectTimeS) * 1000;
            const collectTimeMs = vault.desc.collectTimeS * 1000;

            vault.leftTimeMS = claimTimeLeftMS;
            vault.elapsedTimeMS = Math.max(0, collectTimeMs - claimTimeLeftMS);
        }

        vault.isAvailable = meta.vaultData.available;

        this.updateState(vault);
    }

    private updateVault = (node: VaultNode, dt: number) => {
        const { vault } = node;

        if (!windowManager.isVisible('Vault')) {
            return;
        }

        if (vault.isFetching) {
            vault.fetchTimeMS += dt;

            if (vault.fetchTimeMS >= vault.fetchTimeoutMS) {
                vault.fetchTimeMS = 0;

                vault.isFetching = false;
            }

            return;
        }

        this.updateState(vault, dt);
    };

    private claimReward = async () => {
        if (!this.vaultNodeList) {
            logger.error('Vault node list not found');

            return;
        }

        const { vault } = this.vaultNodeList.get(0) ?? {};
        if (!vault) {
            logger.error('Vault not found');

            return;
        }

        vault.isFetching = true;

        const { result, timeData } = await this.nakamaService.callRpc({
            rpcType: ApiRpc.collectVault,
            payload: {},
        });

        vault.isFetching = false;

        switch (result) {
            case api.CollectVaultResult.VAULT_FAIL_NOT_AVAILABLE_YET:
            case api.CollectVaultResult.VAULT_SUCCESS: {
                logger.log('Vault collected', { result: api.CollectVaultResult[result] });

                if (result === api.CollectVaultResult.VAULT_SUCCESS) {
                    this.eventService.metaData.dispatch('request', 'clientAccounts');

                    this.amplitudeEventService.sendEvent('Claim Storage', {
                        category: EventCategory.Start,
                    });
                }

                vault.isAvailable = false;

                const timerElapsedTimeMS = Number(timeData?.elapsedTimeS ?? 0) * 1000;
                const timerLeftTimeMS = Number(timeData?.nextRewardS ?? 0) * 1000;

                vault.leftTimeMS = timerLeftTimeMS;
                vault.elapsedTimeMS = timerElapsedTimeMS;
                break;
            }
            default: {
                logger.error('Failed to collect vault', { result: api.CollectVaultResult[result] });

                vault.isAvailable = true;

                const claimTimeLeftMS = Number(timeData?.claimLeftTimeS ?? vault.desc.collectTimeS) * 1000;
                const collectTimeMs = vault.desc.collectTimeS * 1000;

                vault.leftTimeMS = claimTimeLeftMS;
                vault.elapsedTimeMS = Math.max(0, collectTimeMs - claimTimeLeftMS);
                break;
            }
        }

        this.updateState(vault);
    };

    private updateState(vault: VaultComponent, dt = 0) {
        const leftTimeMS = vault.leftTimeMS - dt;

        vault.leftTimeMS = Math.max(0, leftTimeMS);
        vault.elapsedTimeMS += dt + Math.min(0, leftTimeMS);

        if (leftTimeMS <= 0) {
            vault.isAvailable = !vault.isAvailable;

            if (!vault.isAvailable) {
                this.eventService.metaData.dispatch('request', 'vaultData');
            }

            if (vault.isAvailable) {
                vault.elapsedTimeMS = 0;
                vault.leftTimeMS = vault.desc.collectTimeS * 1000;
            } else {
                vault.elapsedTimeMS = 0;
                vault.leftTimeMS = vault.desc.fillDurationS * 1000;
            }
        }

        if (!vault.isAvailable) {
            this.updateFillProgress(vault, vault.elapsedTimeMS, vault.leftTimeMS);
        } else {
            this.updateCollectProgress(vault);
        }
    }

    private updateFillProgress(vault: VaultComponent, elapsedTimeMS: number, leftTimeMS: number) {
        const actualFillDurationMS = elapsedTimeMS + leftTimeMS;
        const fillDurationMS = vault.desc.fillDurationS * 1000;

        vault.progress = clamp(
            0,
            1,
            Math.abs(actualFillDurationMS - fillDurationMS) < 0.01
                ? elapsedTimeMS / fillDurationMS
                : leftTimeMS >= fillDurationMS
                    ? 0
                    : leftTimeMS / fillDurationMS,
        );
    }

    private updateCollectProgress(vault: VaultComponent) {
        vault.progress = 1;
    }
}