/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const api = $root.api = (() => {

    /**
     * Namespace api.
     * @exports api
     * @namespace
     */
    const api = {};

    /**
     * OpCodeRequest enum.
     * @name api.OpCodeRequest
     * @enum {number}
     * @property {number} REQUEST_UNSPECIFIED=0 REQUEST_UNSPECIFIED value
     * @property {number} PLAYER_DICE=1 PLAYER_DICE value
     * @property {number} MOVE_ON_TILE=2 MOVE_ON_TILE value
     * @property {number} BUY_TILE=3 BUY_TILE value
     * @property {number} MATCH_START=4 MATCH_START value
     * @property {number} LEAVE_MATCH=5 LEAVE_MATCH value
     * @property {number} TILE_SELL=6 TILE_SELL value
     * @property {number} APPLY_DICE_BONUS=7 APPLY_DICE_BONUS value
     */
    api.OpCodeRequest = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "REQUEST_UNSPECIFIED"] = 0;
        values[valuesById[1] = "PLAYER_DICE"] = 1;
        values[valuesById[2] = "MOVE_ON_TILE"] = 2;
        values[valuesById[3] = "BUY_TILE"] = 3;
        values[valuesById[4] = "MATCH_START"] = 4;
        values[valuesById[5] = "LEAVE_MATCH"] = 5;
        values[valuesById[6] = "TILE_SELL"] = 6;
        values[valuesById[7] = "APPLY_DICE_BONUS"] = 7;
        return values;
    })();

    /**
     * OpCodeResponse enum.
     * @name api.OpCodeResponse
     * @enum {number}
     * @property {number} RESPONSE_UNSPECIFIED=0 RESPONSE_UNSPECIFIED value
     * @property {number} PLAYER_DICE_RESPONCE=1 PLAYER_DICE_RESPONCE value
     * @property {number} ADD_REPLICANT=2 ADD_REPLICANT value
     * @property {number} PLAYER_MATCH_STATE_RESPONCE=3 PLAYER_MATCH_STATE_RESPONCE value
     * @property {number} MOVE_ON_TILE_RESPONCE=4 MOVE_ON_TILE_RESPONCE value
     * @property {number} BUY_TILE_RESPONCE=5 BUY_TILE_RESPONCE value
     * @property {number} COINS_UPDATE=6 COINS_UPDATE value
     * @property {number} TILE_OWNER_UPDATE=7 TILE_OWNER_UPDATE value
     * @property {number} PLAYER_POSITION_UPDATE=8 PLAYER_POSITION_UPDATE value
     * @property {number} PLAYER_LEFT_GAME=9 PLAYER_LEFT_GAME value
     * @property {number} MATCH_OVER_RESPONCE=10 MATCH_OVER_RESPONCE value
     * @property {number} MATCH_TIME_RESPONCE=11 MATCH_TIME_RESPONCE value
     * @property {number} MATCH_START_RESPONCE=12 MATCH_START_RESPONCE value
     * @property {number} ADD_PLAYER=13 ADD_PLAYER value
     * @property {number} EVENT_RESPONCE=14 EVENT_RESPONCE value
     * @property {number} BUY_TILE_AVAILABILITY_RESPONCE=15 BUY_TILE_AVAILABILITY_RESPONCE value
     * @property {number} SELL_TILE_RESPONCE=16 SELL_TILE_RESPONCE value
     * @property {number} LEAVE_MATCH_RESPONCE=17 LEAVE_MATCH_RESPONCE value
     */
    api.OpCodeResponse = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "RESPONSE_UNSPECIFIED"] = 0;
        values[valuesById[1] = "PLAYER_DICE_RESPONCE"] = 1;
        values[valuesById[2] = "ADD_REPLICANT"] = 2;
        values[valuesById[3] = "PLAYER_MATCH_STATE_RESPONCE"] = 3;
        values[valuesById[4] = "MOVE_ON_TILE_RESPONCE"] = 4;
        values[valuesById[5] = "BUY_TILE_RESPONCE"] = 5;
        values[valuesById[6] = "COINS_UPDATE"] = 6;
        values[valuesById[7] = "TILE_OWNER_UPDATE"] = 7;
        values[valuesById[8] = "PLAYER_POSITION_UPDATE"] = 8;
        values[valuesById[9] = "PLAYER_LEFT_GAME"] = 9;
        values[valuesById[10] = "MATCH_OVER_RESPONCE"] = 10;
        values[valuesById[11] = "MATCH_TIME_RESPONCE"] = 11;
        values[valuesById[12] = "MATCH_START_RESPONCE"] = 12;
        values[valuesById[13] = "ADD_PLAYER"] = 13;
        values[valuesById[14] = "EVENT_RESPONCE"] = 14;
        values[valuesById[15] = "BUY_TILE_AVAILABILITY_RESPONCE"] = 15;
        values[valuesById[16] = "SELL_TILE_RESPONCE"] = 16;
        values[valuesById[17] = "LEAVE_MATCH_RESPONCE"] = 17;
        return values;
    })();

    /**
     * NotificationCode enum.
     * @name api.NotificationCode
     * @enum {number}
     * @property {number} NOTIFICATION_UNSPECIFIED=0 NOTIFICATION_UNSPECIFIED value
     * @property {number} LOG=1 LOG value
     * @property {number} DAILY_REWARD=2 DAILY_REWARD value
     */
    api.NotificationCode = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "NOTIFICATION_UNSPECIFIED"] = 0;
        values[valuesById[1] = "LOG"] = 1;
        values[valuesById[2] = "DAILY_REWARD"] = 2;
        return values;
    })();

    /**
     * PlayerMatchState enum.
     * @name api.PlayerMatchState
     * @enum {number}
     * @property {number} STATE_UNSPECIFIED=0 STATE_UNSPECIFIED value
     * @property {number} DICE=1 DICE value
     * @property {number} MOVE=2 MOVE value
     * @property {number} BANKRUPTY=3 BANKRUPTY value
     * @property {number} SPECTATOR=4 SPECTATOR value
     * @property {number} WAIT=5 WAIT value
     */
    api.PlayerMatchState = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "STATE_UNSPECIFIED"] = 0;
        values[valuesById[1] = "DICE"] = 1;
        values[valuesById[2] = "MOVE"] = 2;
        values[valuesById[3] = "BANKRUPTY"] = 3;
        values[valuesById[4] = "SPECTATOR"] = 4;
        values[valuesById[5] = "WAIT"] = 5;
        return values;
    })();

    /**
     * PlayerDiceResult enum.
     * @name api.PlayerDiceResult
     * @enum {number}
     * @property {number} DICE_UNSPECIFIED=0 DICE_UNSPECIFIED value
     * @property {number} DICE_SUCCESS=1 DICE_SUCCESS value
     * @property {number} DICE_FAIL_BAD_DATA=2 DICE_FAIL_BAD_DATA value
     * @property {number} DICE_FAIL_NOT_ACTION_YET=3 DICE_FAIL_NOT_ACTION_YET value
     */
    api.PlayerDiceResult = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "DICE_UNSPECIFIED"] = 0;
        values[valuesById[1] = "DICE_SUCCESS"] = 1;
        values[valuesById[2] = "DICE_FAIL_BAD_DATA"] = 2;
        values[valuesById[3] = "DICE_FAIL_NOT_ACTION_YET"] = 3;
        return values;
    })();

    /**
     * MoveOnTileResult enum.
     * @name api.MoveOnTileResult
     * @enum {number}
     * @property {number} MOVE_UNSPECIFIED=0 MOVE_UNSPECIFIED value
     * @property {number} MOVE_SUCCESS=1 MOVE_SUCCESS value
     * @property {number} MOVE_FAIL_BAD_DATA=2 MOVE_FAIL_BAD_DATA value
     * @property {number} MOVE_FAIL_UNAVAILABLE_TILE_TYPE=3 MOVE_FAIL_UNAVAILABLE_TILE_TYPE value
     * @property {number} MOVE_FAIL_CURRENT_TILE_POSITION=4 MOVE_FAIL_CURRENT_TILE_POSITION value
     * @property {number} MOVE_FAIL_INCORRECT_MATCH_STATE=5 MOVE_FAIL_INCORRECT_MATCH_STATE value
     */
    api.MoveOnTileResult = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "MOVE_UNSPECIFIED"] = 0;
        values[valuesById[1] = "MOVE_SUCCESS"] = 1;
        values[valuesById[2] = "MOVE_FAIL_BAD_DATA"] = 2;
        values[valuesById[3] = "MOVE_FAIL_UNAVAILABLE_TILE_TYPE"] = 3;
        values[valuesById[4] = "MOVE_FAIL_CURRENT_TILE_POSITION"] = 4;
        values[valuesById[5] = "MOVE_FAIL_INCORRECT_MATCH_STATE"] = 5;
        return values;
    })();

    /**
     * BuyTileResult enum.
     * @name api.BuyTileResult
     * @enum {number}
     * @property {number} BUY_UNSPECIFIED=0 BUY_UNSPECIFIED value
     * @property {number} BUY_SUCCESS=1 BUY_SUCCESS value
     * @property {number} BUY_FAIL_BAD_DATA=2 BUY_FAIL_BAD_DATA value
     * @property {number} BUY_FAIL_UNAVAILABLE_TILE_TYPE=3 BUY_FAIL_UNAVAILABLE_TILE_TYPE value
     * @property {number} BUY_FAIL_NOT_ENOUGH_COINS=4 BUY_FAIL_NOT_ENOUGH_COINS value
     * @property {number} BUY_FAIL_INCORRECT_MATCH_STATE=5 BUY_FAIL_INCORRECT_MATCH_STATE value
     * @property {number} BUY_FAIL_MISMATCH_TILE_INDEX=6 BUY_FAIL_MISMATCH_TILE_INDEX value
     * @property {number} BUY_FAIL_INCORRECT_OWNER=7 BUY_FAIL_INCORRECT_OWNER value
     * @property {number} BUY_FAIL_BUY_TURN_LIMIT=8 BUY_FAIL_BUY_TURN_LIMIT value
     */
    api.BuyTileResult = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "BUY_UNSPECIFIED"] = 0;
        values[valuesById[1] = "BUY_SUCCESS"] = 1;
        values[valuesById[2] = "BUY_FAIL_BAD_DATA"] = 2;
        values[valuesById[3] = "BUY_FAIL_UNAVAILABLE_TILE_TYPE"] = 3;
        values[valuesById[4] = "BUY_FAIL_NOT_ENOUGH_COINS"] = 4;
        values[valuesById[5] = "BUY_FAIL_INCORRECT_MATCH_STATE"] = 5;
        values[valuesById[6] = "BUY_FAIL_MISMATCH_TILE_INDEX"] = 6;
        values[valuesById[7] = "BUY_FAIL_INCORRECT_OWNER"] = 7;
        values[valuesById[8] = "BUY_FAIL_BUY_TURN_LIMIT"] = 8;
        return values;
    })();

    /**
     * SellTileResult enum.
     * @name api.SellTileResult
     * @enum {number}
     * @property {number} SELL_UNSPECIFIED=0 SELL_UNSPECIFIED value
     * @property {number} SELL_SUCCESS=1 SELL_SUCCESS value
     * @property {number} SELL_FAIL_BAD_DATA=2 SELL_FAIL_BAD_DATA value
     * @property {number} SELL_FAIL_INCORRECT_MATCH_STATE=3 SELL_FAIL_INCORRECT_MATCH_STATE value
     * @property {number} SELL_FAIL_INCORRECT_OWNER=4 SELL_FAIL_INCORRECT_OWNER value
     */
    api.SellTileResult = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "SELL_UNSPECIFIED"] = 0;
        values[valuesById[1] = "SELL_SUCCESS"] = 1;
        values[valuesById[2] = "SELL_FAIL_BAD_DATA"] = 2;
        values[valuesById[3] = "SELL_FAIL_INCORRECT_MATCH_STATE"] = 3;
        values[valuesById[4] = "SELL_FAIL_INCORRECT_OWNER"] = 4;
        return values;
    })();

    /**
     * CollectTaskRewardResult enum.
     * @name api.CollectTaskRewardResult
     * @enum {number}
     * @property {number} TASK_REWARD_UNSPECIFIED=0 TASK_REWARD_UNSPECIFIED value
     * @property {number} TASK_REWARD_SUCCESS=1 TASK_REWARD_SUCCESS value
     * @property {number} TASK_REWARD_NOT_DONE=2 TASK_REWARD_NOT_DONE value
     * @property {number} TASK_REWARD_ALREADY_COLLECTED=3 TASK_REWARD_ALREADY_COLLECTED value
     */
    api.CollectTaskRewardResult = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "TASK_REWARD_UNSPECIFIED"] = 0;
        values[valuesById[1] = "TASK_REWARD_SUCCESS"] = 1;
        values[valuesById[2] = "TASK_REWARD_NOT_DONE"] = 2;
        values[valuesById[3] = "TASK_REWARD_ALREADY_COLLECTED"] = 3;
        return values;
    })();

    /**
     * CompleteTaskResult enum.
     * @name api.CompleteTaskResult
     * @enum {number}
     * @property {number} TASK_COMPLETE_UNSPECIFIED=0 TASK_COMPLETE_UNSPECIFIED value
     * @property {number} TASK_COMPLETE_SUCCESS=1 TASK_COMPLETE_SUCCESS value
     * @property {number} TASK_COMPLETE_FAIL=2 TASK_COMPLETE_FAIL value
     * @property {number} TASK_COMPLETE_ALREADY_COMPLETED=3 TASK_COMPLETE_ALREADY_COMPLETED value
     */
    api.CompleteTaskResult = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "TASK_COMPLETE_UNSPECIFIED"] = 0;
        values[valuesById[1] = "TASK_COMPLETE_SUCCESS"] = 1;
        values[valuesById[2] = "TASK_COMPLETE_FAIL"] = 2;
        values[valuesById[3] = "TASK_COMPLETE_ALREADY_COMPLETED"] = 3;
        return values;
    })();

    /**
     * GetDailyRewardResult enum.
     * @name api.GetDailyRewardResult
     * @enum {number}
     * @property {number} DAILY_REWARD_COMPLETE_UNSPECIFIED=0 DAILY_REWARD_COMPLETE_UNSPECIFIED value
     * @property {number} DAILY_REWARD_SUCCESS=1 DAILY_REWARD_SUCCESS value
     * @property {number} DAILY_REWARD_FAIL_NOT_AVAILABLE_YET=2 DAILY_REWARD_FAIL_NOT_AVAILABLE_YET value
     */
    api.GetDailyRewardResult = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "DAILY_REWARD_COMPLETE_UNSPECIFIED"] = 0;
        values[valuesById[1] = "DAILY_REWARD_SUCCESS"] = 1;
        values[valuesById[2] = "DAILY_REWARD_FAIL_NOT_AVAILABLE_YET"] = 2;
        return values;
    })();

    /**
     * CollectVaultResult enum.
     * @name api.CollectVaultResult
     * @enum {number}
     * @property {number} VAULT_UNSPECIFIED=0 VAULT_UNSPECIFIED value
     * @property {number} VAULT_SUCCESS=1 VAULT_SUCCESS value
     * @property {number} VAULT_FAIL_NOT_AVAILABLE_YET=2 VAULT_FAIL_NOT_AVAILABLE_YET value
     */
    api.CollectVaultResult = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "VAULT_UNSPECIFIED"] = 0;
        values[valuesById[1] = "VAULT_SUCCESS"] = 1;
        values[valuesById[2] = "VAULT_FAIL_NOT_AVAILABLE_YET"] = 2;
        return values;
    })();

    /**
     * CoinsUpdateReason enum.
     * @name api.CoinsUpdateReason
     * @enum {number}
     * @property {number} COINS_UPDATE_REASON_UNSPECIFIED=0 COINS_UPDATE_REASON_UNSPECIFIED value
     * @property {number} INCOME_PENALTY=1 INCOME_PENALTY value
     * @property {number} OUTCOME_PENALTY=2 OUTCOME_PENALTY value
     * @property {number} COLLECT_TILE_COINS=3 COLLECT_TILE_COINS value
     * @property {number} PURCHASE_TILE=4 PURCHASE_TILE value
     * @property {number} SELL_TILE=5 SELL_TILE value
     * @property {number} BANKRUPTY_PROCESS=6 BANKRUPTY_PROCESS value
     * @property {number} CHANCE_TILE=7 CHANCE_TILE value
     * @property {number} DICE_BONUS=8 DICE_BONUS value
     * @property {number} START_MATCH_COINS=9 START_MATCH_COINS value
     * @property {number} SHAPE_BREAK_BONUS=10 SHAPE_BREAK_BONUS value
     */
    api.CoinsUpdateReason = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "COINS_UPDATE_REASON_UNSPECIFIED"] = 0;
        values[valuesById[1] = "INCOME_PENALTY"] = 1;
        values[valuesById[2] = "OUTCOME_PENALTY"] = 2;
        values[valuesById[3] = "COLLECT_TILE_COINS"] = 3;
        values[valuesById[4] = "PURCHASE_TILE"] = 4;
        values[valuesById[5] = "SELL_TILE"] = 5;
        values[valuesById[6] = "BANKRUPTY_PROCESS"] = 6;
        values[valuesById[7] = "CHANCE_TILE"] = 7;
        values[valuesById[8] = "DICE_BONUS"] = 8;
        values[valuesById[9] = "START_MATCH_COINS"] = 9;
        values[valuesById[10] = "SHAPE_BREAK_BONUS"] = 10;
        return values;
    })();

    /**
     * PlayerLeaveReason enum.
     * @name api.PlayerLeaveReason
     * @enum {number}
     * @property {number} PLAYER_LEAVE_REASON_UNSPECIFIED=0 PLAYER_LEAVE_REASON_UNSPECIFIED value
     * @property {number} DISCONNECTION=1 DISCONNECTION value
     * @property {number} LOSS=2 LOSS value
     */
    api.PlayerLeaveReason = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "PLAYER_LEAVE_REASON_UNSPECIFIED"] = 0;
        values[valuesById[1] = "DISCONNECTION"] = 1;
        values[valuesById[2] = "LOSS"] = 2;
        return values;
    })();

    api.MatchDataResponse = (function() {

        /**
         * Properties of a MatchDataResponse.
         * @memberof api
         * @interface IMatchDataResponse
         * @property {string|null} [matchId] MatchDataResponse matchId
         * @property {string|null} [tilemapEntityId] MatchDataResponse tilemapEntityId
         */

        /**
         * Constructs a new MatchDataResponse.
         * @memberof api
         * @classdesc Represents a MatchDataResponse.
         * @implements IMatchDataResponse
         * @constructor
         * @param {api.IMatchDataResponse=} [properties] Properties to set
         */
        function MatchDataResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MatchDataResponse matchId.
         * @member {string} matchId
         * @memberof api.MatchDataResponse
         * @instance
         */
        MatchDataResponse.prototype.matchId = "";

        /**
         * MatchDataResponse tilemapEntityId.
         * @member {string} tilemapEntityId
         * @memberof api.MatchDataResponse
         * @instance
         */
        MatchDataResponse.prototype.tilemapEntityId = "";

        /**
         * Creates a new MatchDataResponse instance using the specified properties.
         * @function create
         * @memberof api.MatchDataResponse
         * @static
         * @param {api.IMatchDataResponse=} [properties] Properties to set
         * @returns {api.MatchDataResponse} MatchDataResponse instance
         */
        MatchDataResponse.create = function create(properties) {
            return new MatchDataResponse(properties);
        };

        /**
         * Encodes the specified MatchDataResponse message. Does not implicitly {@link api.MatchDataResponse.verify|verify} messages.
         * @function encode
         * @memberof api.MatchDataResponse
         * @static
         * @param {api.MatchDataResponse} message MatchDataResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MatchDataResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.matchId != null && Object.hasOwnProperty.call(message, "matchId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.matchId);
            if (message.tilemapEntityId != null && Object.hasOwnProperty.call(message, "tilemapEntityId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.tilemapEntityId);
            return writer;
        };

        /**
         * Encodes the specified MatchDataResponse message, length delimited. Does not implicitly {@link api.MatchDataResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.MatchDataResponse
         * @static
         * @param {api.MatchDataResponse} message MatchDataResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MatchDataResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MatchDataResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.MatchDataResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.MatchDataResponse} MatchDataResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MatchDataResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.MatchDataResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.matchId = reader.string();
                        break;
                    }
                case 2: {
                        message.tilemapEntityId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MatchDataResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.MatchDataResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.MatchDataResponse} MatchDataResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MatchDataResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MatchDataResponse message.
         * @function verify
         * @memberof api.MatchDataResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MatchDataResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.matchId != null && message.hasOwnProperty("matchId"))
                if (!$util.isString(message.matchId))
                    return "matchId: string expected";
            if (message.tilemapEntityId != null && message.hasOwnProperty("tilemapEntityId"))
                if (!$util.isString(message.tilemapEntityId))
                    return "tilemapEntityId: string expected";
            return null;
        };

        /**
         * Creates a MatchDataResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.MatchDataResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.MatchDataResponse} MatchDataResponse
         */
        MatchDataResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.MatchDataResponse)
                return object;
            let message = new $root.api.MatchDataResponse();
            if (object.matchId != null)
                message.matchId = String(object.matchId);
            if (object.tilemapEntityId != null)
                message.tilemapEntityId = String(object.tilemapEntityId);
            return message;
        };

        /**
         * Creates a plain object from a MatchDataResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.MatchDataResponse
         * @static
         * @param {api.MatchDataResponse} message MatchDataResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MatchDataResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.matchId = "";
                object.tilemapEntityId = "";
            }
            if (message.matchId != null && message.hasOwnProperty("matchId"))
                object.matchId = message.matchId;
            if (message.tilemapEntityId != null && message.hasOwnProperty("tilemapEntityId"))
                object.tilemapEntityId = message.tilemapEntityId;
            return object;
        };

        /**
         * Converts this MatchDataResponse to JSON.
         * @function toJSON
         * @memberof api.MatchDataResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MatchDataResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MatchDataResponse
         * @function getTypeUrl
         * @memberof api.MatchDataResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MatchDataResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.MatchDataResponse";
        };

        return MatchDataResponse;
    })();

    api.RpcCreateMatchRequest = (function() {

        /**
         * Properties of a RpcCreateMatchRequest.
         * @memberof api
         * @interface IRpcCreateMatchRequest
         * @property {string|null} [matchName] RpcCreateMatchRequest matchName
         * @property {string|null} [operationUid] RpcCreateMatchRequest operationUid
         */

        /**
         * Constructs a new RpcCreateMatchRequest.
         * @memberof api
         * @classdesc Represents a RpcCreateMatchRequest.
         * @implements IRpcCreateMatchRequest
         * @constructor
         * @param {api.IRpcCreateMatchRequest=} [properties] Properties to set
         */
        function RpcCreateMatchRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcCreateMatchRequest matchName.
         * @member {string} matchName
         * @memberof api.RpcCreateMatchRequest
         * @instance
         */
        RpcCreateMatchRequest.prototype.matchName = "";

        /**
         * RpcCreateMatchRequest operationUid.
         * @member {string} operationUid
         * @memberof api.RpcCreateMatchRequest
         * @instance
         */
        RpcCreateMatchRequest.prototype.operationUid = "";

        /**
         * Creates a new RpcCreateMatchRequest instance using the specified properties.
         * @function create
         * @memberof api.RpcCreateMatchRequest
         * @static
         * @param {api.IRpcCreateMatchRequest=} [properties] Properties to set
         * @returns {api.RpcCreateMatchRequest} RpcCreateMatchRequest instance
         */
        RpcCreateMatchRequest.create = function create(properties) {
            return new RpcCreateMatchRequest(properties);
        };

        /**
         * Encodes the specified RpcCreateMatchRequest message. Does not implicitly {@link api.RpcCreateMatchRequest.verify|verify} messages.
         * @function encode
         * @memberof api.RpcCreateMatchRequest
         * @static
         * @param {api.RpcCreateMatchRequest} message RpcCreateMatchRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcCreateMatchRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.matchName != null && Object.hasOwnProperty.call(message, "matchName"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.matchName);
            if (message.operationUid != null && Object.hasOwnProperty.call(message, "operationUid"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.operationUid);
            return writer;
        };

        /**
         * Encodes the specified RpcCreateMatchRequest message, length delimited. Does not implicitly {@link api.RpcCreateMatchRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcCreateMatchRequest
         * @static
         * @param {api.RpcCreateMatchRequest} message RpcCreateMatchRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcCreateMatchRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcCreateMatchRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcCreateMatchRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcCreateMatchRequest} RpcCreateMatchRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcCreateMatchRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcCreateMatchRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.matchName = reader.string();
                        break;
                    }
                case 2: {
                        message.operationUid = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcCreateMatchRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcCreateMatchRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcCreateMatchRequest} RpcCreateMatchRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcCreateMatchRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcCreateMatchRequest message.
         * @function verify
         * @memberof api.RpcCreateMatchRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcCreateMatchRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.matchName != null && message.hasOwnProperty("matchName"))
                if (!$util.isString(message.matchName))
                    return "matchName: string expected";
            if (message.operationUid != null && message.hasOwnProperty("operationUid"))
                if (!$util.isString(message.operationUid))
                    return "operationUid: string expected";
            return null;
        };

        /**
         * Creates a RpcCreateMatchRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcCreateMatchRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcCreateMatchRequest} RpcCreateMatchRequest
         */
        RpcCreateMatchRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcCreateMatchRequest)
                return object;
            let message = new $root.api.RpcCreateMatchRequest();
            if (object.matchName != null)
                message.matchName = String(object.matchName);
            if (object.operationUid != null)
                message.operationUid = String(object.operationUid);
            return message;
        };

        /**
         * Creates a plain object from a RpcCreateMatchRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcCreateMatchRequest
         * @static
         * @param {api.RpcCreateMatchRequest} message RpcCreateMatchRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcCreateMatchRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.matchName = "";
                object.operationUid = "";
            }
            if (message.matchName != null && message.hasOwnProperty("matchName"))
                object.matchName = message.matchName;
            if (message.operationUid != null && message.hasOwnProperty("operationUid"))
                object.operationUid = message.operationUid;
            return object;
        };

        /**
         * Converts this RpcCreateMatchRequest to JSON.
         * @function toJSON
         * @memberof api.RpcCreateMatchRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcCreateMatchRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcCreateMatchRequest
         * @function getTypeUrl
         * @memberof api.RpcCreateMatchRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcCreateMatchRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcCreateMatchRequest";
        };

        return RpcCreateMatchRequest;
    })();

    api.RpcCreateMatchResponse = (function() {

        /**
         * Properties of a RpcCreateMatchResponse.
         * @memberof api
         * @interface IRpcCreateMatchResponse
         * @property {api.MatchDataResponse|null} [match] RpcCreateMatchResponse match
         */

        /**
         * Constructs a new RpcCreateMatchResponse.
         * @memberof api
         * @classdesc Represents a RpcCreateMatchResponse.
         * @implements IRpcCreateMatchResponse
         * @constructor
         * @param {api.IRpcCreateMatchResponse=} [properties] Properties to set
         */
        function RpcCreateMatchResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcCreateMatchResponse match.
         * @member {api.MatchDataResponse|null|undefined} match
         * @memberof api.RpcCreateMatchResponse
         * @instance
         */
        RpcCreateMatchResponse.prototype.match = null;

        /**
         * Creates a new RpcCreateMatchResponse instance using the specified properties.
         * @function create
         * @memberof api.RpcCreateMatchResponse
         * @static
         * @param {api.IRpcCreateMatchResponse=} [properties] Properties to set
         * @returns {api.RpcCreateMatchResponse} RpcCreateMatchResponse instance
         */
        RpcCreateMatchResponse.create = function create(properties) {
            return new RpcCreateMatchResponse(properties);
        };

        /**
         * Encodes the specified RpcCreateMatchResponse message. Does not implicitly {@link api.RpcCreateMatchResponse.verify|verify} messages.
         * @function encode
         * @memberof api.RpcCreateMatchResponse
         * @static
         * @param {api.RpcCreateMatchResponse} message RpcCreateMatchResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcCreateMatchResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.match != null && Object.hasOwnProperty.call(message, "match"))
                $root.api.MatchDataResponse.encode(message.match, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified RpcCreateMatchResponse message, length delimited. Does not implicitly {@link api.RpcCreateMatchResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcCreateMatchResponse
         * @static
         * @param {api.RpcCreateMatchResponse} message RpcCreateMatchResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcCreateMatchResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcCreateMatchResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcCreateMatchResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcCreateMatchResponse} RpcCreateMatchResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcCreateMatchResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcCreateMatchResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.match = $root.api.MatchDataResponse.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcCreateMatchResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcCreateMatchResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcCreateMatchResponse} RpcCreateMatchResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcCreateMatchResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcCreateMatchResponse message.
         * @function verify
         * @memberof api.RpcCreateMatchResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcCreateMatchResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.match != null && message.hasOwnProperty("match")) {
                let error = $root.api.MatchDataResponse.verify(message.match);
                if (error)
                    return "match." + error;
            }
            return null;
        };

        /**
         * Creates a RpcCreateMatchResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcCreateMatchResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcCreateMatchResponse} RpcCreateMatchResponse
         */
        RpcCreateMatchResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcCreateMatchResponse)
                return object;
            let message = new $root.api.RpcCreateMatchResponse();
            if (object.match != null) {
                if (typeof object.match !== "object")
                    throw TypeError(".api.RpcCreateMatchResponse.match: object expected");
                message.match = $root.api.MatchDataResponse.fromObject(object.match);
            }
            return message;
        };

        /**
         * Creates a plain object from a RpcCreateMatchResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcCreateMatchResponse
         * @static
         * @param {api.RpcCreateMatchResponse} message RpcCreateMatchResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcCreateMatchResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.match = null;
            if (message.match != null && message.hasOwnProperty("match"))
                object.match = $root.api.MatchDataResponse.toObject(message.match, options);
            return object;
        };

        /**
         * Converts this RpcCreateMatchResponse to JSON.
         * @function toJSON
         * @memberof api.RpcCreateMatchResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcCreateMatchResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcCreateMatchResponse
         * @function getTypeUrl
         * @memberof api.RpcCreateMatchResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcCreateMatchResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcCreateMatchResponse";
        };

        return RpcCreateMatchResponse;
    })();

    api.RpcUserStatusResponse = (function() {

        /**
         * Properties of a RpcUserStatusResponse.
         * @memberof api
         * @interface IRpcUserStatusResponse
         * @property {api.DailyRewardData|null} [dailyRewardData] RpcUserStatusResponse dailyRewardData
         * @property {api.VaultData|null} [vaultData] RpcUserStatusResponse vaultData
         */

        /**
         * Constructs a new RpcUserStatusResponse.
         * @memberof api
         * @classdesc Represents a RpcUserStatusResponse.
         * @implements IRpcUserStatusResponse
         * @constructor
         * @param {api.IRpcUserStatusResponse=} [properties] Properties to set
         */
        function RpcUserStatusResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcUserStatusResponse dailyRewardData.
         * @member {api.DailyRewardData|null|undefined} dailyRewardData
         * @memberof api.RpcUserStatusResponse
         * @instance
         */
        RpcUserStatusResponse.prototype.dailyRewardData = null;

        /**
         * RpcUserStatusResponse vaultData.
         * @member {api.VaultData|null|undefined} vaultData
         * @memberof api.RpcUserStatusResponse
         * @instance
         */
        RpcUserStatusResponse.prototype.vaultData = null;

        /**
         * Creates a new RpcUserStatusResponse instance using the specified properties.
         * @function create
         * @memberof api.RpcUserStatusResponse
         * @static
         * @param {api.IRpcUserStatusResponse=} [properties] Properties to set
         * @returns {api.RpcUserStatusResponse} RpcUserStatusResponse instance
         */
        RpcUserStatusResponse.create = function create(properties) {
            return new RpcUserStatusResponse(properties);
        };

        /**
         * Encodes the specified RpcUserStatusResponse message. Does not implicitly {@link api.RpcUserStatusResponse.verify|verify} messages.
         * @function encode
         * @memberof api.RpcUserStatusResponse
         * @static
         * @param {api.RpcUserStatusResponse} message RpcUserStatusResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcUserStatusResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.dailyRewardData != null && Object.hasOwnProperty.call(message, "dailyRewardData"))
                $root.api.DailyRewardData.encode(message.dailyRewardData, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.vaultData != null && Object.hasOwnProperty.call(message, "vaultData"))
                $root.api.VaultData.encode(message.vaultData, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified RpcUserStatusResponse message, length delimited. Does not implicitly {@link api.RpcUserStatusResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcUserStatusResponse
         * @static
         * @param {api.RpcUserStatusResponse} message RpcUserStatusResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcUserStatusResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcUserStatusResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcUserStatusResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcUserStatusResponse} RpcUserStatusResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcUserStatusResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcUserStatusResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.dailyRewardData = $root.api.DailyRewardData.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.vaultData = $root.api.VaultData.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcUserStatusResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcUserStatusResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcUserStatusResponse} RpcUserStatusResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcUserStatusResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcUserStatusResponse message.
         * @function verify
         * @memberof api.RpcUserStatusResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcUserStatusResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.dailyRewardData != null && message.hasOwnProperty("dailyRewardData")) {
                let error = $root.api.DailyRewardData.verify(message.dailyRewardData);
                if (error)
                    return "dailyRewardData." + error;
            }
            if (message.vaultData != null && message.hasOwnProperty("vaultData")) {
                let error = $root.api.VaultData.verify(message.vaultData);
                if (error)
                    return "vaultData." + error;
            }
            return null;
        };

        /**
         * Creates a RpcUserStatusResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcUserStatusResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcUserStatusResponse} RpcUserStatusResponse
         */
        RpcUserStatusResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcUserStatusResponse)
                return object;
            let message = new $root.api.RpcUserStatusResponse();
            if (object.dailyRewardData != null) {
                if (typeof object.dailyRewardData !== "object")
                    throw TypeError(".api.RpcUserStatusResponse.dailyRewardData: object expected");
                message.dailyRewardData = $root.api.DailyRewardData.fromObject(object.dailyRewardData);
            }
            if (object.vaultData != null) {
                if (typeof object.vaultData !== "object")
                    throw TypeError(".api.RpcUserStatusResponse.vaultData: object expected");
                message.vaultData = $root.api.VaultData.fromObject(object.vaultData);
            }
            return message;
        };

        /**
         * Creates a plain object from a RpcUserStatusResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcUserStatusResponse
         * @static
         * @param {api.RpcUserStatusResponse} message RpcUserStatusResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcUserStatusResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.dailyRewardData = null;
                object.vaultData = null;
            }
            if (message.dailyRewardData != null && message.hasOwnProperty("dailyRewardData"))
                object.dailyRewardData = $root.api.DailyRewardData.toObject(message.dailyRewardData, options);
            if (message.vaultData != null && message.hasOwnProperty("vaultData"))
                object.vaultData = $root.api.VaultData.toObject(message.vaultData, options);
            return object;
        };

        /**
         * Converts this RpcUserStatusResponse to JSON.
         * @function toJSON
         * @memberof api.RpcUserStatusResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcUserStatusResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcUserStatusResponse
         * @function getTypeUrl
         * @memberof api.RpcUserStatusResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcUserStatusResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcUserStatusResponse";
        };

        return RpcUserStatusResponse;
    })();

    api.DailyRewardData = (function() {

        /**
         * Properties of a DailyRewardData.
         * @memberof api
         * @interface IDailyRewardData
         * @property {number|null} [rewardDay] DailyRewardData rewardDay
         * @property {boolean|null} [available] DailyRewardData available
         * @property {number|Long|null} [nextRewardUnix] DailyRewardData nextRewardUnix
         */

        /**
         * Constructs a new DailyRewardData.
         * @memberof api
         * @classdesc Represents a DailyRewardData.
         * @implements IDailyRewardData
         * @constructor
         * @param {api.IDailyRewardData=} [properties] Properties to set
         */
        function DailyRewardData(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DailyRewardData rewardDay.
         * @member {number} rewardDay
         * @memberof api.DailyRewardData
         * @instance
         */
        DailyRewardData.prototype.rewardDay = 0;

        /**
         * DailyRewardData available.
         * @member {boolean} available
         * @memberof api.DailyRewardData
         * @instance
         */
        DailyRewardData.prototype.available = false;

        /**
         * DailyRewardData nextRewardUnix.
         * @member {number|Long|null|undefined} nextRewardUnix
         * @memberof api.DailyRewardData
         * @instance
         */
        DailyRewardData.prototype.nextRewardUnix = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(DailyRewardData.prototype, "_nextRewardUnix", {
            get: $util.oneOfGetter($oneOfFields = ["nextRewardUnix"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new DailyRewardData instance using the specified properties.
         * @function create
         * @memberof api.DailyRewardData
         * @static
         * @param {api.IDailyRewardData=} [properties] Properties to set
         * @returns {api.DailyRewardData} DailyRewardData instance
         */
        DailyRewardData.create = function create(properties) {
            return new DailyRewardData(properties);
        };

        /**
         * Encodes the specified DailyRewardData message. Does not implicitly {@link api.DailyRewardData.verify|verify} messages.
         * @function encode
         * @memberof api.DailyRewardData
         * @static
         * @param {api.DailyRewardData} message DailyRewardData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DailyRewardData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rewardDay != null && Object.hasOwnProperty.call(message, "rewardDay"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.rewardDay);
            if (message.available != null && Object.hasOwnProperty.call(message, "available"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.available);
            if (message.nextRewardUnix != null && Object.hasOwnProperty.call(message, "nextRewardUnix"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.nextRewardUnix);
            return writer;
        };

        /**
         * Encodes the specified DailyRewardData message, length delimited. Does not implicitly {@link api.DailyRewardData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.DailyRewardData
         * @static
         * @param {api.DailyRewardData} message DailyRewardData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DailyRewardData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DailyRewardData message from the specified reader or buffer.
         * @function decode
         * @memberof api.DailyRewardData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.DailyRewardData} DailyRewardData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DailyRewardData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.DailyRewardData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.rewardDay = reader.int32();
                        break;
                    }
                case 2: {
                        message.available = reader.bool();
                        break;
                    }
                case 3: {
                        message.nextRewardUnix = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DailyRewardData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.DailyRewardData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.DailyRewardData} DailyRewardData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DailyRewardData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DailyRewardData message.
         * @function verify
         * @memberof api.DailyRewardData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DailyRewardData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.rewardDay != null && message.hasOwnProperty("rewardDay"))
                if (!$util.isInteger(message.rewardDay))
                    return "rewardDay: integer expected";
            if (message.available != null && message.hasOwnProperty("available"))
                if (typeof message.available !== "boolean")
                    return "available: boolean expected";
            if (message.nextRewardUnix != null && message.hasOwnProperty("nextRewardUnix")) {
                properties._nextRewardUnix = 1;
                if (!$util.isInteger(message.nextRewardUnix) && !(message.nextRewardUnix && $util.isInteger(message.nextRewardUnix.low) && $util.isInteger(message.nextRewardUnix.high)))
                    return "nextRewardUnix: integer|Long expected";
            }
            return null;
        };

        /**
         * Creates a DailyRewardData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.DailyRewardData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.DailyRewardData} DailyRewardData
         */
        DailyRewardData.fromObject = function fromObject(object) {
            if (object instanceof $root.api.DailyRewardData)
                return object;
            let message = new $root.api.DailyRewardData();
            if (object.rewardDay != null)
                message.rewardDay = object.rewardDay | 0;
            if (object.available != null)
                message.available = Boolean(object.available);
            if (object.nextRewardUnix != null)
                if ($util.Long)
                    (message.nextRewardUnix = $util.Long.fromValue(object.nextRewardUnix)).unsigned = false;
                else if (typeof object.nextRewardUnix === "string")
                    message.nextRewardUnix = parseInt(object.nextRewardUnix, 10);
                else if (typeof object.nextRewardUnix === "number")
                    message.nextRewardUnix = object.nextRewardUnix;
                else if (typeof object.nextRewardUnix === "object")
                    message.nextRewardUnix = new $util.LongBits(object.nextRewardUnix.low >>> 0, object.nextRewardUnix.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a DailyRewardData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.DailyRewardData
         * @static
         * @param {api.DailyRewardData} message DailyRewardData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DailyRewardData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.rewardDay = 0;
                object.available = false;
            }
            if (message.rewardDay != null && message.hasOwnProperty("rewardDay"))
                object.rewardDay = message.rewardDay;
            if (message.available != null && message.hasOwnProperty("available"))
                object.available = message.available;
            if (message.nextRewardUnix != null && message.hasOwnProperty("nextRewardUnix")) {
                if (typeof message.nextRewardUnix === "number")
                    object.nextRewardUnix = options.longs === String ? String(message.nextRewardUnix) : message.nextRewardUnix;
                else
                    object.nextRewardUnix = options.longs === String ? $util.Long.prototype.toString.call(message.nextRewardUnix) : options.longs === Number ? new $util.LongBits(message.nextRewardUnix.low >>> 0, message.nextRewardUnix.high >>> 0).toNumber() : message.nextRewardUnix;
                if (options.oneofs)
                    object._nextRewardUnix = "nextRewardUnix";
            }
            return object;
        };

        /**
         * Converts this DailyRewardData to JSON.
         * @function toJSON
         * @memberof api.DailyRewardData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DailyRewardData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DailyRewardData
         * @function getTypeUrl
         * @memberof api.DailyRewardData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DailyRewardData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.DailyRewardData";
        };

        return DailyRewardData;
    })();

    api.VaultData = (function() {

        /**
         * Properties of a VaultData.
         * @memberof api
         * @interface IVaultData
         * @property {boolean|null} [available] VaultData available
         * @property {api.VaultTimeData|null} [timeData] VaultData timeData
         */

        /**
         * Constructs a new VaultData.
         * @memberof api
         * @classdesc Represents a VaultData.
         * @implements IVaultData
         * @constructor
         * @param {api.IVaultData=} [properties] Properties to set
         */
        function VaultData(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * VaultData available.
         * @member {boolean} available
         * @memberof api.VaultData
         * @instance
         */
        VaultData.prototype.available = false;

        /**
         * VaultData timeData.
         * @member {api.VaultTimeData|null|undefined} timeData
         * @memberof api.VaultData
         * @instance
         */
        VaultData.prototype.timeData = null;

        /**
         * Creates a new VaultData instance using the specified properties.
         * @function create
         * @memberof api.VaultData
         * @static
         * @param {api.IVaultData=} [properties] Properties to set
         * @returns {api.VaultData} VaultData instance
         */
        VaultData.create = function create(properties) {
            return new VaultData(properties);
        };

        /**
         * Encodes the specified VaultData message. Does not implicitly {@link api.VaultData.verify|verify} messages.
         * @function encode
         * @memberof api.VaultData
         * @static
         * @param {api.VaultData} message VaultData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VaultData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.available != null && Object.hasOwnProperty.call(message, "available"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.available);
            if (message.timeData != null && Object.hasOwnProperty.call(message, "timeData"))
                $root.api.VaultTimeData.encode(message.timeData, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified VaultData message, length delimited. Does not implicitly {@link api.VaultData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.VaultData
         * @static
         * @param {api.VaultData} message VaultData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VaultData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a VaultData message from the specified reader or buffer.
         * @function decode
         * @memberof api.VaultData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.VaultData} VaultData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VaultData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.VaultData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.available = reader.bool();
                        break;
                    }
                case 2: {
                        message.timeData = $root.api.VaultTimeData.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a VaultData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.VaultData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.VaultData} VaultData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VaultData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a VaultData message.
         * @function verify
         * @memberof api.VaultData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        VaultData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.available != null && message.hasOwnProperty("available"))
                if (typeof message.available !== "boolean")
                    return "available: boolean expected";
            if (message.timeData != null && message.hasOwnProperty("timeData")) {
                let error = $root.api.VaultTimeData.verify(message.timeData);
                if (error)
                    return "timeData." + error;
            }
            return null;
        };

        /**
         * Creates a VaultData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.VaultData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.VaultData} VaultData
         */
        VaultData.fromObject = function fromObject(object) {
            if (object instanceof $root.api.VaultData)
                return object;
            let message = new $root.api.VaultData();
            if (object.available != null)
                message.available = Boolean(object.available);
            if (object.timeData != null) {
                if (typeof object.timeData !== "object")
                    throw TypeError(".api.VaultData.timeData: object expected");
                message.timeData = $root.api.VaultTimeData.fromObject(object.timeData);
            }
            return message;
        };

        /**
         * Creates a plain object from a VaultData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.VaultData
         * @static
         * @param {api.VaultData} message VaultData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        VaultData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.available = false;
                object.timeData = null;
            }
            if (message.available != null && message.hasOwnProperty("available"))
                object.available = message.available;
            if (message.timeData != null && message.hasOwnProperty("timeData"))
                object.timeData = $root.api.VaultTimeData.toObject(message.timeData, options);
            return object;
        };

        /**
         * Converts this VaultData to JSON.
         * @function toJSON
         * @memberof api.VaultData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        VaultData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for VaultData
         * @function getTypeUrl
         * @memberof api.VaultData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        VaultData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.VaultData";
        };

        return VaultData;
    })();

    api.VaultTimeData = (function() {

        /**
         * Properties of a VaultTimeData.
         * @memberof api
         * @interface IVaultTimeData
         * @property {number|Long|null} [nextRewardS] VaultTimeData nextRewardS
         * @property {number|Long|null} [claimLeftTimeS] VaultTimeData claimLeftTimeS
         * @property {number|Long|null} [elapsedTimeS] VaultTimeData elapsedTimeS
         */

        /**
         * Constructs a new VaultTimeData.
         * @memberof api
         * @classdesc Represents a VaultTimeData.
         * @implements IVaultTimeData
         * @constructor
         * @param {api.IVaultTimeData=} [properties] Properties to set
         */
        function VaultTimeData(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * VaultTimeData nextRewardS.
         * @member {number|Long|null|undefined} nextRewardS
         * @memberof api.VaultTimeData
         * @instance
         */
        VaultTimeData.prototype.nextRewardS = null;

        /**
         * VaultTimeData claimLeftTimeS.
         * @member {number|Long|null|undefined} claimLeftTimeS
         * @memberof api.VaultTimeData
         * @instance
         */
        VaultTimeData.prototype.claimLeftTimeS = null;

        /**
         * VaultTimeData elapsedTimeS.
         * @member {number|Long|null|undefined} elapsedTimeS
         * @memberof api.VaultTimeData
         * @instance
         */
        VaultTimeData.prototype.elapsedTimeS = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(VaultTimeData.prototype, "_nextRewardS", {
            get: $util.oneOfGetter($oneOfFields = ["nextRewardS"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(VaultTimeData.prototype, "_claimLeftTimeS", {
            get: $util.oneOfGetter($oneOfFields = ["claimLeftTimeS"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(VaultTimeData.prototype, "_elapsedTimeS", {
            get: $util.oneOfGetter($oneOfFields = ["elapsedTimeS"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new VaultTimeData instance using the specified properties.
         * @function create
         * @memberof api.VaultTimeData
         * @static
         * @param {api.IVaultTimeData=} [properties] Properties to set
         * @returns {api.VaultTimeData} VaultTimeData instance
         */
        VaultTimeData.create = function create(properties) {
            return new VaultTimeData(properties);
        };

        /**
         * Encodes the specified VaultTimeData message. Does not implicitly {@link api.VaultTimeData.verify|verify} messages.
         * @function encode
         * @memberof api.VaultTimeData
         * @static
         * @param {api.VaultTimeData} message VaultTimeData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VaultTimeData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.nextRewardS != null && Object.hasOwnProperty.call(message, "nextRewardS"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.nextRewardS);
            if (message.claimLeftTimeS != null && Object.hasOwnProperty.call(message, "claimLeftTimeS"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.claimLeftTimeS);
            if (message.elapsedTimeS != null && Object.hasOwnProperty.call(message, "elapsedTimeS"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.elapsedTimeS);
            return writer;
        };

        /**
         * Encodes the specified VaultTimeData message, length delimited. Does not implicitly {@link api.VaultTimeData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.VaultTimeData
         * @static
         * @param {api.VaultTimeData} message VaultTimeData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VaultTimeData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a VaultTimeData message from the specified reader or buffer.
         * @function decode
         * @memberof api.VaultTimeData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.VaultTimeData} VaultTimeData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VaultTimeData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.VaultTimeData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.nextRewardS = reader.int64();
                        break;
                    }
                case 2: {
                        message.claimLeftTimeS = reader.int64();
                        break;
                    }
                case 3: {
                        message.elapsedTimeS = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a VaultTimeData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.VaultTimeData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.VaultTimeData} VaultTimeData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VaultTimeData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a VaultTimeData message.
         * @function verify
         * @memberof api.VaultTimeData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        VaultTimeData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.nextRewardS != null && message.hasOwnProperty("nextRewardS")) {
                properties._nextRewardS = 1;
                if (!$util.isInteger(message.nextRewardS) && !(message.nextRewardS && $util.isInteger(message.nextRewardS.low) && $util.isInteger(message.nextRewardS.high)))
                    return "nextRewardS: integer|Long expected";
            }
            if (message.claimLeftTimeS != null && message.hasOwnProperty("claimLeftTimeS")) {
                properties._claimLeftTimeS = 1;
                if (!$util.isInteger(message.claimLeftTimeS) && !(message.claimLeftTimeS && $util.isInteger(message.claimLeftTimeS.low) && $util.isInteger(message.claimLeftTimeS.high)))
                    return "claimLeftTimeS: integer|Long expected";
            }
            if (message.elapsedTimeS != null && message.hasOwnProperty("elapsedTimeS")) {
                properties._elapsedTimeS = 1;
                if (!$util.isInteger(message.elapsedTimeS) && !(message.elapsedTimeS && $util.isInteger(message.elapsedTimeS.low) && $util.isInteger(message.elapsedTimeS.high)))
                    return "elapsedTimeS: integer|Long expected";
            }
            return null;
        };

        /**
         * Creates a VaultTimeData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.VaultTimeData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.VaultTimeData} VaultTimeData
         */
        VaultTimeData.fromObject = function fromObject(object) {
            if (object instanceof $root.api.VaultTimeData)
                return object;
            let message = new $root.api.VaultTimeData();
            if (object.nextRewardS != null)
                if ($util.Long)
                    (message.nextRewardS = $util.Long.fromValue(object.nextRewardS)).unsigned = false;
                else if (typeof object.nextRewardS === "string")
                    message.nextRewardS = parseInt(object.nextRewardS, 10);
                else if (typeof object.nextRewardS === "number")
                    message.nextRewardS = object.nextRewardS;
                else if (typeof object.nextRewardS === "object")
                    message.nextRewardS = new $util.LongBits(object.nextRewardS.low >>> 0, object.nextRewardS.high >>> 0).toNumber();
            if (object.claimLeftTimeS != null)
                if ($util.Long)
                    (message.claimLeftTimeS = $util.Long.fromValue(object.claimLeftTimeS)).unsigned = false;
                else if (typeof object.claimLeftTimeS === "string")
                    message.claimLeftTimeS = parseInt(object.claimLeftTimeS, 10);
                else if (typeof object.claimLeftTimeS === "number")
                    message.claimLeftTimeS = object.claimLeftTimeS;
                else if (typeof object.claimLeftTimeS === "object")
                    message.claimLeftTimeS = new $util.LongBits(object.claimLeftTimeS.low >>> 0, object.claimLeftTimeS.high >>> 0).toNumber();
            if (object.elapsedTimeS != null)
                if ($util.Long)
                    (message.elapsedTimeS = $util.Long.fromValue(object.elapsedTimeS)).unsigned = false;
                else if (typeof object.elapsedTimeS === "string")
                    message.elapsedTimeS = parseInt(object.elapsedTimeS, 10);
                else if (typeof object.elapsedTimeS === "number")
                    message.elapsedTimeS = object.elapsedTimeS;
                else if (typeof object.elapsedTimeS === "object")
                    message.elapsedTimeS = new $util.LongBits(object.elapsedTimeS.low >>> 0, object.elapsedTimeS.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a VaultTimeData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.VaultTimeData
         * @static
         * @param {api.VaultTimeData} message VaultTimeData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        VaultTimeData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.nextRewardS != null && message.hasOwnProperty("nextRewardS")) {
                if (typeof message.nextRewardS === "number")
                    object.nextRewardS = options.longs === String ? String(message.nextRewardS) : message.nextRewardS;
                else
                    object.nextRewardS = options.longs === String ? $util.Long.prototype.toString.call(message.nextRewardS) : options.longs === Number ? new $util.LongBits(message.nextRewardS.low >>> 0, message.nextRewardS.high >>> 0).toNumber() : message.nextRewardS;
                if (options.oneofs)
                    object._nextRewardS = "nextRewardS";
            }
            if (message.claimLeftTimeS != null && message.hasOwnProperty("claimLeftTimeS")) {
                if (typeof message.claimLeftTimeS === "number")
                    object.claimLeftTimeS = options.longs === String ? String(message.claimLeftTimeS) : message.claimLeftTimeS;
                else
                    object.claimLeftTimeS = options.longs === String ? $util.Long.prototype.toString.call(message.claimLeftTimeS) : options.longs === Number ? new $util.LongBits(message.claimLeftTimeS.low >>> 0, message.claimLeftTimeS.high >>> 0).toNumber() : message.claimLeftTimeS;
                if (options.oneofs)
                    object._claimLeftTimeS = "claimLeftTimeS";
            }
            if (message.elapsedTimeS != null && message.hasOwnProperty("elapsedTimeS")) {
                if (typeof message.elapsedTimeS === "number")
                    object.elapsedTimeS = options.longs === String ? String(message.elapsedTimeS) : message.elapsedTimeS;
                else
                    object.elapsedTimeS = options.longs === String ? $util.Long.prototype.toString.call(message.elapsedTimeS) : options.longs === Number ? new $util.LongBits(message.elapsedTimeS.low >>> 0, message.elapsedTimeS.high >>> 0).toNumber() : message.elapsedTimeS;
                if (options.oneofs)
                    object._elapsedTimeS = "elapsedTimeS";
            }
            return object;
        };

        /**
         * Converts this VaultTimeData to JSON.
         * @function toJSON
         * @memberof api.VaultTimeData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        VaultTimeData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for VaultTimeData
         * @function getTypeUrl
         * @memberof api.VaultTimeData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        VaultTimeData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.VaultTimeData";
        };

        return VaultTimeData;
    })();

    api.RpcCollectVaultResponce = (function() {

        /**
         * Properties of a RpcCollectVaultResponce.
         * @memberof api
         * @interface IRpcCollectVaultResponce
         * @property {api.CollectVaultResult|null} [result] RpcCollectVaultResponce result
         * @property {api.VaultTimeData|null} [timeData] RpcCollectVaultResponce timeData
         */

        /**
         * Constructs a new RpcCollectVaultResponce.
         * @memberof api
         * @classdesc Represents a RpcCollectVaultResponce.
         * @implements IRpcCollectVaultResponce
         * @constructor
         * @param {api.IRpcCollectVaultResponce=} [properties] Properties to set
         */
        function RpcCollectVaultResponce(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcCollectVaultResponce result.
         * @member {api.CollectVaultResult} result
         * @memberof api.RpcCollectVaultResponce
         * @instance
         */
        RpcCollectVaultResponce.prototype.result = 0;

        /**
         * RpcCollectVaultResponce timeData.
         * @member {api.VaultTimeData|null|undefined} timeData
         * @memberof api.RpcCollectVaultResponce
         * @instance
         */
        RpcCollectVaultResponce.prototype.timeData = null;

        /**
         * Creates a new RpcCollectVaultResponce instance using the specified properties.
         * @function create
         * @memberof api.RpcCollectVaultResponce
         * @static
         * @param {api.IRpcCollectVaultResponce=} [properties] Properties to set
         * @returns {api.RpcCollectVaultResponce} RpcCollectVaultResponce instance
         */
        RpcCollectVaultResponce.create = function create(properties) {
            return new RpcCollectVaultResponce(properties);
        };

        /**
         * Encodes the specified RpcCollectVaultResponce message. Does not implicitly {@link api.RpcCollectVaultResponce.verify|verify} messages.
         * @function encode
         * @memberof api.RpcCollectVaultResponce
         * @static
         * @param {api.RpcCollectVaultResponce} message RpcCollectVaultResponce message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcCollectVaultResponce.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
            if (message.timeData != null && Object.hasOwnProperty.call(message, "timeData"))
                $root.api.VaultTimeData.encode(message.timeData, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified RpcCollectVaultResponce message, length delimited. Does not implicitly {@link api.RpcCollectVaultResponce.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcCollectVaultResponce
         * @static
         * @param {api.RpcCollectVaultResponce} message RpcCollectVaultResponce message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcCollectVaultResponce.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcCollectVaultResponce message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcCollectVaultResponce
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcCollectVaultResponce} RpcCollectVaultResponce
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcCollectVaultResponce.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcCollectVaultResponce();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.result = reader.int32();
                        break;
                    }
                case 2: {
                        message.timeData = $root.api.VaultTimeData.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcCollectVaultResponce message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcCollectVaultResponce
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcCollectVaultResponce} RpcCollectVaultResponce
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcCollectVaultResponce.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcCollectVaultResponce message.
         * @function verify
         * @memberof api.RpcCollectVaultResponce
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcCollectVaultResponce.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.result != null && message.hasOwnProperty("result"))
                switch (message.result) {
                default:
                    return "result: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.timeData != null && message.hasOwnProperty("timeData")) {
                let error = $root.api.VaultTimeData.verify(message.timeData);
                if (error)
                    return "timeData." + error;
            }
            return null;
        };

        /**
         * Creates a RpcCollectVaultResponce message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcCollectVaultResponce
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcCollectVaultResponce} RpcCollectVaultResponce
         */
        RpcCollectVaultResponce.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcCollectVaultResponce)
                return object;
            let message = new $root.api.RpcCollectVaultResponce();
            switch (object.result) {
            default:
                if (typeof object.result === "number") {
                    message.result = object.result;
                    break;
                }
                break;
            case "VAULT_UNSPECIFIED":
            case 0:
                message.result = 0;
                break;
            case "VAULT_SUCCESS":
            case 1:
                message.result = 1;
                break;
            case "VAULT_FAIL_NOT_AVAILABLE_YET":
            case 2:
                message.result = 2;
                break;
            }
            if (object.timeData != null) {
                if (typeof object.timeData !== "object")
                    throw TypeError(".api.RpcCollectVaultResponce.timeData: object expected");
                message.timeData = $root.api.VaultTimeData.fromObject(object.timeData);
            }
            return message;
        };

        /**
         * Creates a plain object from a RpcCollectVaultResponce message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcCollectVaultResponce
         * @static
         * @param {api.RpcCollectVaultResponce} message RpcCollectVaultResponce
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcCollectVaultResponce.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.result = options.enums === String ? "VAULT_UNSPECIFIED" : 0;
                object.timeData = null;
            }
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = options.enums === String ? $root.api.CollectVaultResult[message.result] === undefined ? message.result : $root.api.CollectVaultResult[message.result] : message.result;
            if (message.timeData != null && message.hasOwnProperty("timeData"))
                object.timeData = $root.api.VaultTimeData.toObject(message.timeData, options);
            return object;
        };

        /**
         * Converts this RpcCollectVaultResponce to JSON.
         * @function toJSON
         * @memberof api.RpcCollectVaultResponce
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcCollectVaultResponce.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcCollectVaultResponce
         * @function getTypeUrl
         * @memberof api.RpcCollectVaultResponce
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcCollectVaultResponce.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcCollectVaultResponce";
        };

        return RpcCollectVaultResponce;
    })();

    api.RpcGetDailyRewardResponce = (function() {

        /**
         * Properties of a RpcGetDailyRewardResponce.
         * @memberof api
         * @interface IRpcGetDailyRewardResponce
         * @property {api.GetDailyRewardResult|null} [result] RpcGetDailyRewardResponce result
         * @property {number|null} [rewardDay] RpcGetDailyRewardResponce rewardDay
         * @property {number|Long|null} [nextRewardS] RpcGetDailyRewardResponce nextRewardS
         */

        /**
         * Constructs a new RpcGetDailyRewardResponce.
         * @memberof api
         * @classdesc Represents a RpcGetDailyRewardResponce.
         * @implements IRpcGetDailyRewardResponce
         * @constructor
         * @param {api.IRpcGetDailyRewardResponce=} [properties] Properties to set
         */
        function RpcGetDailyRewardResponce(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcGetDailyRewardResponce result.
         * @member {api.GetDailyRewardResult} result
         * @memberof api.RpcGetDailyRewardResponce
         * @instance
         */
        RpcGetDailyRewardResponce.prototype.result = 0;

        /**
         * RpcGetDailyRewardResponce rewardDay.
         * @member {number|null|undefined} rewardDay
         * @memberof api.RpcGetDailyRewardResponce
         * @instance
         */
        RpcGetDailyRewardResponce.prototype.rewardDay = null;

        /**
         * RpcGetDailyRewardResponce nextRewardS.
         * @member {number|Long} nextRewardS
         * @memberof api.RpcGetDailyRewardResponce
         * @instance
         */
        RpcGetDailyRewardResponce.prototype.nextRewardS = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(RpcGetDailyRewardResponce.prototype, "_rewardDay", {
            get: $util.oneOfGetter($oneOfFields = ["rewardDay"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new RpcGetDailyRewardResponce instance using the specified properties.
         * @function create
         * @memberof api.RpcGetDailyRewardResponce
         * @static
         * @param {api.IRpcGetDailyRewardResponce=} [properties] Properties to set
         * @returns {api.RpcGetDailyRewardResponce} RpcGetDailyRewardResponce instance
         */
        RpcGetDailyRewardResponce.create = function create(properties) {
            return new RpcGetDailyRewardResponce(properties);
        };

        /**
         * Encodes the specified RpcGetDailyRewardResponce message. Does not implicitly {@link api.RpcGetDailyRewardResponce.verify|verify} messages.
         * @function encode
         * @memberof api.RpcGetDailyRewardResponce
         * @static
         * @param {api.RpcGetDailyRewardResponce} message RpcGetDailyRewardResponce message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGetDailyRewardResponce.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
            if (message.rewardDay != null && Object.hasOwnProperty.call(message, "rewardDay"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.rewardDay);
            if (message.nextRewardS != null && Object.hasOwnProperty.call(message, "nextRewardS"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.nextRewardS);
            return writer;
        };

        /**
         * Encodes the specified RpcGetDailyRewardResponce message, length delimited. Does not implicitly {@link api.RpcGetDailyRewardResponce.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcGetDailyRewardResponce
         * @static
         * @param {api.RpcGetDailyRewardResponce} message RpcGetDailyRewardResponce message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGetDailyRewardResponce.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcGetDailyRewardResponce message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcGetDailyRewardResponce
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcGetDailyRewardResponce} RpcGetDailyRewardResponce
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGetDailyRewardResponce.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcGetDailyRewardResponce();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.result = reader.int32();
                        break;
                    }
                case 2: {
                        message.rewardDay = reader.int32();
                        break;
                    }
                case 3: {
                        message.nextRewardS = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcGetDailyRewardResponce message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcGetDailyRewardResponce
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcGetDailyRewardResponce} RpcGetDailyRewardResponce
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGetDailyRewardResponce.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcGetDailyRewardResponce message.
         * @function verify
         * @memberof api.RpcGetDailyRewardResponce
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcGetDailyRewardResponce.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.result != null && message.hasOwnProperty("result"))
                switch (message.result) {
                default:
                    return "result: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.rewardDay != null && message.hasOwnProperty("rewardDay")) {
                properties._rewardDay = 1;
                if (!$util.isInteger(message.rewardDay))
                    return "rewardDay: integer expected";
            }
            if (message.nextRewardS != null && message.hasOwnProperty("nextRewardS"))
                if (!$util.isInteger(message.nextRewardS) && !(message.nextRewardS && $util.isInteger(message.nextRewardS.low) && $util.isInteger(message.nextRewardS.high)))
                    return "nextRewardS: integer|Long expected";
            return null;
        };

        /**
         * Creates a RpcGetDailyRewardResponce message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcGetDailyRewardResponce
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcGetDailyRewardResponce} RpcGetDailyRewardResponce
         */
        RpcGetDailyRewardResponce.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcGetDailyRewardResponce)
                return object;
            let message = new $root.api.RpcGetDailyRewardResponce();
            switch (object.result) {
            default:
                if (typeof object.result === "number") {
                    message.result = object.result;
                    break;
                }
                break;
            case "DAILY_REWARD_COMPLETE_UNSPECIFIED":
            case 0:
                message.result = 0;
                break;
            case "DAILY_REWARD_SUCCESS":
            case 1:
                message.result = 1;
                break;
            case "DAILY_REWARD_FAIL_NOT_AVAILABLE_YET":
            case 2:
                message.result = 2;
                break;
            }
            if (object.rewardDay != null)
                message.rewardDay = object.rewardDay | 0;
            if (object.nextRewardS != null)
                if ($util.Long)
                    (message.nextRewardS = $util.Long.fromValue(object.nextRewardS)).unsigned = false;
                else if (typeof object.nextRewardS === "string")
                    message.nextRewardS = parseInt(object.nextRewardS, 10);
                else if (typeof object.nextRewardS === "number")
                    message.nextRewardS = object.nextRewardS;
                else if (typeof object.nextRewardS === "object")
                    message.nextRewardS = new $util.LongBits(object.nextRewardS.low >>> 0, object.nextRewardS.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a RpcGetDailyRewardResponce message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcGetDailyRewardResponce
         * @static
         * @param {api.RpcGetDailyRewardResponce} message RpcGetDailyRewardResponce
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcGetDailyRewardResponce.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.result = options.enums === String ? "DAILY_REWARD_COMPLETE_UNSPECIFIED" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.nextRewardS = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.nextRewardS = options.longs === String ? "0" : 0;
            }
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = options.enums === String ? $root.api.GetDailyRewardResult[message.result] === undefined ? message.result : $root.api.GetDailyRewardResult[message.result] : message.result;
            if (message.rewardDay != null && message.hasOwnProperty("rewardDay")) {
                object.rewardDay = message.rewardDay;
                if (options.oneofs)
                    object._rewardDay = "rewardDay";
            }
            if (message.nextRewardS != null && message.hasOwnProperty("nextRewardS"))
                if (typeof message.nextRewardS === "number")
                    object.nextRewardS = options.longs === String ? String(message.nextRewardS) : message.nextRewardS;
                else
                    object.nextRewardS = options.longs === String ? $util.Long.prototype.toString.call(message.nextRewardS) : options.longs === Number ? new $util.LongBits(message.nextRewardS.low >>> 0, message.nextRewardS.high >>> 0).toNumber() : message.nextRewardS;
            return object;
        };

        /**
         * Converts this RpcGetDailyRewardResponce to JSON.
         * @function toJSON
         * @memberof api.RpcGetDailyRewardResponce
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcGetDailyRewardResponce.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcGetDailyRewardResponce
         * @function getTypeUrl
         * @memberof api.RpcGetDailyRewardResponce
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcGetDailyRewardResponce.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcGetDailyRewardResponce";
        };

        return RpcGetDailyRewardResponce;
    })();

    api.RpcFindMatchResponse = (function() {

        /**
         * Properties of a RpcFindMatchResponse.
         * @memberof api
         * @interface IRpcFindMatchResponse
         * @property {Array.<api.MatchDataResponse>|null} [matches] RpcFindMatchResponse matches
         */

        /**
         * Constructs a new RpcFindMatchResponse.
         * @memberof api
         * @classdesc Represents a RpcFindMatchResponse.
         * @implements IRpcFindMatchResponse
         * @constructor
         * @param {api.IRpcFindMatchResponse=} [properties] Properties to set
         */
        function RpcFindMatchResponse(properties) {
            this.matches = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcFindMatchResponse matches.
         * @member {Array.<api.MatchDataResponse>} matches
         * @memberof api.RpcFindMatchResponse
         * @instance
         */
        RpcFindMatchResponse.prototype.matches = $util.emptyArray;

        /**
         * Creates a new RpcFindMatchResponse instance using the specified properties.
         * @function create
         * @memberof api.RpcFindMatchResponse
         * @static
         * @param {api.IRpcFindMatchResponse=} [properties] Properties to set
         * @returns {api.RpcFindMatchResponse} RpcFindMatchResponse instance
         */
        RpcFindMatchResponse.create = function create(properties) {
            return new RpcFindMatchResponse(properties);
        };

        /**
         * Encodes the specified RpcFindMatchResponse message. Does not implicitly {@link api.RpcFindMatchResponse.verify|verify} messages.
         * @function encode
         * @memberof api.RpcFindMatchResponse
         * @static
         * @param {api.RpcFindMatchResponse} message RpcFindMatchResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcFindMatchResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.matches != null && message.matches.length)
                for (let i = 0; i < message.matches.length; ++i)
                    $root.api.MatchDataResponse.encode(message.matches[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified RpcFindMatchResponse message, length delimited. Does not implicitly {@link api.RpcFindMatchResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcFindMatchResponse
         * @static
         * @param {api.RpcFindMatchResponse} message RpcFindMatchResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcFindMatchResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcFindMatchResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcFindMatchResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcFindMatchResponse} RpcFindMatchResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcFindMatchResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcFindMatchResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.matches && message.matches.length))
                            message.matches = [];
                        message.matches.push($root.api.MatchDataResponse.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcFindMatchResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcFindMatchResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcFindMatchResponse} RpcFindMatchResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcFindMatchResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcFindMatchResponse message.
         * @function verify
         * @memberof api.RpcFindMatchResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcFindMatchResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.matches != null && message.hasOwnProperty("matches")) {
                if (!Array.isArray(message.matches))
                    return "matches: array expected";
                for (let i = 0; i < message.matches.length; ++i) {
                    let error = $root.api.MatchDataResponse.verify(message.matches[i]);
                    if (error)
                        return "matches." + error;
                }
            }
            return null;
        };

        /**
         * Creates a RpcFindMatchResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcFindMatchResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcFindMatchResponse} RpcFindMatchResponse
         */
        RpcFindMatchResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcFindMatchResponse)
                return object;
            let message = new $root.api.RpcFindMatchResponse();
            if (object.matches) {
                if (!Array.isArray(object.matches))
                    throw TypeError(".api.RpcFindMatchResponse.matches: array expected");
                message.matches = [];
                for (let i = 0; i < object.matches.length; ++i) {
                    if (typeof object.matches[i] !== "object")
                        throw TypeError(".api.RpcFindMatchResponse.matches: object expected");
                    message.matches[i] = $root.api.MatchDataResponse.fromObject(object.matches[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a RpcFindMatchResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcFindMatchResponse
         * @static
         * @param {api.RpcFindMatchResponse} message RpcFindMatchResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcFindMatchResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.matches = [];
            if (message.matches && message.matches.length) {
                object.matches = [];
                for (let j = 0; j < message.matches.length; ++j)
                    object.matches[j] = $root.api.MatchDataResponse.toObject(message.matches[j], options);
            }
            return object;
        };

        /**
         * Converts this RpcFindMatchResponse to JSON.
         * @function toJSON
         * @memberof api.RpcFindMatchResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcFindMatchResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcFindMatchResponse
         * @function getTypeUrl
         * @memberof api.RpcFindMatchResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcFindMatchResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcFindMatchResponse";
        };

        return RpcFindMatchResponse;
    })();

    api.TaskData = (function() {

        /**
         * Properties of a TaskData.
         * @memberof api
         * @interface ITaskData
         * @property {string|null} [key] TaskData key
         * @property {string|null} [title] TaskData title
         * @property {number|null} [reward] TaskData reward
         * @property {boolean|null} [done] TaskData done
         * @property {boolean|null} [collected] TaskData collected
         */

        /**
         * Constructs a new TaskData.
         * @memberof api
         * @classdesc Represents a TaskData.
         * @implements ITaskData
         * @constructor
         * @param {api.ITaskData=} [properties] Properties to set
         */
        function TaskData(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TaskData key.
         * @member {string} key
         * @memberof api.TaskData
         * @instance
         */
        TaskData.prototype.key = "";

        /**
         * TaskData title.
         * @member {string} title
         * @memberof api.TaskData
         * @instance
         */
        TaskData.prototype.title = "";

        /**
         * TaskData reward.
         * @member {number} reward
         * @memberof api.TaskData
         * @instance
         */
        TaskData.prototype.reward = 0;

        /**
         * TaskData done.
         * @member {boolean} done
         * @memberof api.TaskData
         * @instance
         */
        TaskData.prototype.done = false;

        /**
         * TaskData collected.
         * @member {boolean} collected
         * @memberof api.TaskData
         * @instance
         */
        TaskData.prototype.collected = false;

        /**
         * Creates a new TaskData instance using the specified properties.
         * @function create
         * @memberof api.TaskData
         * @static
         * @param {api.ITaskData=} [properties] Properties to set
         * @returns {api.TaskData} TaskData instance
         */
        TaskData.create = function create(properties) {
            return new TaskData(properties);
        };

        /**
         * Encodes the specified TaskData message. Does not implicitly {@link api.TaskData.verify|verify} messages.
         * @function encode
         * @memberof api.TaskData
         * @static
         * @param {api.TaskData} message TaskData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TaskData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.key);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
            if (message.reward != null && Object.hasOwnProperty.call(message, "reward"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.reward);
            if (message.done != null && Object.hasOwnProperty.call(message, "done"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.done);
            if (message.collected != null && Object.hasOwnProperty.call(message, "collected"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.collected);
            return writer;
        };

        /**
         * Encodes the specified TaskData message, length delimited. Does not implicitly {@link api.TaskData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.TaskData
         * @static
         * @param {api.TaskData} message TaskData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TaskData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TaskData message from the specified reader or buffer.
         * @function decode
         * @memberof api.TaskData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.TaskData} TaskData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TaskData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.TaskData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.key = reader.string();
                        break;
                    }
                case 2: {
                        message.title = reader.string();
                        break;
                    }
                case 3: {
                        message.reward = reader.int32();
                        break;
                    }
                case 4: {
                        message.done = reader.bool();
                        break;
                    }
                case 5: {
                        message.collected = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TaskData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.TaskData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.TaskData} TaskData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TaskData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TaskData message.
         * @function verify
         * @memberof api.TaskData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TaskData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.key != null && message.hasOwnProperty("key"))
                if (!$util.isString(message.key))
                    return "key: string expected";
            if (message.title != null && message.hasOwnProperty("title"))
                if (!$util.isString(message.title))
                    return "title: string expected";
            if (message.reward != null && message.hasOwnProperty("reward"))
                if (!$util.isInteger(message.reward))
                    return "reward: integer expected";
            if (message.done != null && message.hasOwnProperty("done"))
                if (typeof message.done !== "boolean")
                    return "done: boolean expected";
            if (message.collected != null && message.hasOwnProperty("collected"))
                if (typeof message.collected !== "boolean")
                    return "collected: boolean expected";
            return null;
        };

        /**
         * Creates a TaskData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.TaskData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.TaskData} TaskData
         */
        TaskData.fromObject = function fromObject(object) {
            if (object instanceof $root.api.TaskData)
                return object;
            let message = new $root.api.TaskData();
            if (object.key != null)
                message.key = String(object.key);
            if (object.title != null)
                message.title = String(object.title);
            if (object.reward != null)
                message.reward = object.reward | 0;
            if (object.done != null)
                message.done = Boolean(object.done);
            if (object.collected != null)
                message.collected = Boolean(object.collected);
            return message;
        };

        /**
         * Creates a plain object from a TaskData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.TaskData
         * @static
         * @param {api.TaskData} message TaskData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TaskData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.key = "";
                object.title = "";
                object.reward = 0;
                object.done = false;
                object.collected = false;
            }
            if (message.key != null && message.hasOwnProperty("key"))
                object.key = message.key;
            if (message.title != null && message.hasOwnProperty("title"))
                object.title = message.title;
            if (message.reward != null && message.hasOwnProperty("reward"))
                object.reward = message.reward;
            if (message.done != null && message.hasOwnProperty("done"))
                object.done = message.done;
            if (message.collected != null && message.hasOwnProperty("collected"))
                object.collected = message.collected;
            return object;
        };

        /**
         * Converts this TaskData to JSON.
         * @function toJSON
         * @memberof api.TaskData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TaskData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TaskData
         * @function getTypeUrl
         * @memberof api.TaskData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TaskData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.TaskData";
        };

        return TaskData;
    })();

    api.RpcGetTasksResponse = (function() {

        /**
         * Properties of a RpcGetTasksResponse.
         * @memberof api
         * @interface IRpcGetTasksResponse
         * @property {Array.<api.TaskData>|null} [tasks] RpcGetTasksResponse tasks
         */

        /**
         * Constructs a new RpcGetTasksResponse.
         * @memberof api
         * @classdesc Represents a RpcGetTasksResponse.
         * @implements IRpcGetTasksResponse
         * @constructor
         * @param {api.IRpcGetTasksResponse=} [properties] Properties to set
         */
        function RpcGetTasksResponse(properties) {
            this.tasks = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcGetTasksResponse tasks.
         * @member {Array.<api.TaskData>} tasks
         * @memberof api.RpcGetTasksResponse
         * @instance
         */
        RpcGetTasksResponse.prototype.tasks = $util.emptyArray;

        /**
         * Creates a new RpcGetTasksResponse instance using the specified properties.
         * @function create
         * @memberof api.RpcGetTasksResponse
         * @static
         * @param {api.IRpcGetTasksResponse=} [properties] Properties to set
         * @returns {api.RpcGetTasksResponse} RpcGetTasksResponse instance
         */
        RpcGetTasksResponse.create = function create(properties) {
            return new RpcGetTasksResponse(properties);
        };

        /**
         * Encodes the specified RpcGetTasksResponse message. Does not implicitly {@link api.RpcGetTasksResponse.verify|verify} messages.
         * @function encode
         * @memberof api.RpcGetTasksResponse
         * @static
         * @param {api.RpcGetTasksResponse} message RpcGetTasksResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGetTasksResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.tasks != null && message.tasks.length)
                for (let i = 0; i < message.tasks.length; ++i)
                    $root.api.TaskData.encode(message.tasks[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified RpcGetTasksResponse message, length delimited. Does not implicitly {@link api.RpcGetTasksResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcGetTasksResponse
         * @static
         * @param {api.RpcGetTasksResponse} message RpcGetTasksResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGetTasksResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcGetTasksResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcGetTasksResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcGetTasksResponse} RpcGetTasksResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGetTasksResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcGetTasksResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.tasks && message.tasks.length))
                            message.tasks = [];
                        message.tasks.push($root.api.TaskData.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcGetTasksResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcGetTasksResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcGetTasksResponse} RpcGetTasksResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGetTasksResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcGetTasksResponse message.
         * @function verify
         * @memberof api.RpcGetTasksResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcGetTasksResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.tasks != null && message.hasOwnProperty("tasks")) {
                if (!Array.isArray(message.tasks))
                    return "tasks: array expected";
                for (let i = 0; i < message.tasks.length; ++i) {
                    let error = $root.api.TaskData.verify(message.tasks[i]);
                    if (error)
                        return "tasks." + error;
                }
            }
            return null;
        };

        /**
         * Creates a RpcGetTasksResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcGetTasksResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcGetTasksResponse} RpcGetTasksResponse
         */
        RpcGetTasksResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcGetTasksResponse)
                return object;
            let message = new $root.api.RpcGetTasksResponse();
            if (object.tasks) {
                if (!Array.isArray(object.tasks))
                    throw TypeError(".api.RpcGetTasksResponse.tasks: array expected");
                message.tasks = [];
                for (let i = 0; i < object.tasks.length; ++i) {
                    if (typeof object.tasks[i] !== "object")
                        throw TypeError(".api.RpcGetTasksResponse.tasks: object expected");
                    message.tasks[i] = $root.api.TaskData.fromObject(object.tasks[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a RpcGetTasksResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcGetTasksResponse
         * @static
         * @param {api.RpcGetTasksResponse} message RpcGetTasksResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcGetTasksResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.tasks = [];
            if (message.tasks && message.tasks.length) {
                object.tasks = [];
                for (let j = 0; j < message.tasks.length; ++j)
                    object.tasks[j] = $root.api.TaskData.toObject(message.tasks[j], options);
            }
            return object;
        };

        /**
         * Converts this RpcGetTasksResponse to JSON.
         * @function toJSON
         * @memberof api.RpcGetTasksResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcGetTasksResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcGetTasksResponse
         * @function getTypeUrl
         * @memberof api.RpcGetTasksResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcGetTasksResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcGetTasksResponse";
        };

        return RpcGetTasksResponse;
    })();

    api.RpcGetTaskRewardRequest = (function() {

        /**
         * Properties of a RpcGetTaskRewardRequest.
         * @memberof api
         * @interface IRpcGetTaskRewardRequest
         * @property {string|null} [key] RpcGetTaskRewardRequest key
         */

        /**
         * Constructs a new RpcGetTaskRewardRequest.
         * @memberof api
         * @classdesc Represents a RpcGetTaskRewardRequest.
         * @implements IRpcGetTaskRewardRequest
         * @constructor
         * @param {api.IRpcGetTaskRewardRequest=} [properties] Properties to set
         */
        function RpcGetTaskRewardRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcGetTaskRewardRequest key.
         * @member {string} key
         * @memberof api.RpcGetTaskRewardRequest
         * @instance
         */
        RpcGetTaskRewardRequest.prototype.key = "";

        /**
         * Creates a new RpcGetTaskRewardRequest instance using the specified properties.
         * @function create
         * @memberof api.RpcGetTaskRewardRequest
         * @static
         * @param {api.IRpcGetTaskRewardRequest=} [properties] Properties to set
         * @returns {api.RpcGetTaskRewardRequest} RpcGetTaskRewardRequest instance
         */
        RpcGetTaskRewardRequest.create = function create(properties) {
            return new RpcGetTaskRewardRequest(properties);
        };

        /**
         * Encodes the specified RpcGetTaskRewardRequest message. Does not implicitly {@link api.RpcGetTaskRewardRequest.verify|verify} messages.
         * @function encode
         * @memberof api.RpcGetTaskRewardRequest
         * @static
         * @param {api.RpcGetTaskRewardRequest} message RpcGetTaskRewardRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGetTaskRewardRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.key);
            return writer;
        };

        /**
         * Encodes the specified RpcGetTaskRewardRequest message, length delimited. Does not implicitly {@link api.RpcGetTaskRewardRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcGetTaskRewardRequest
         * @static
         * @param {api.RpcGetTaskRewardRequest} message RpcGetTaskRewardRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGetTaskRewardRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcGetTaskRewardRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcGetTaskRewardRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcGetTaskRewardRequest} RpcGetTaskRewardRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGetTaskRewardRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcGetTaskRewardRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.key = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcGetTaskRewardRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcGetTaskRewardRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcGetTaskRewardRequest} RpcGetTaskRewardRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGetTaskRewardRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcGetTaskRewardRequest message.
         * @function verify
         * @memberof api.RpcGetTaskRewardRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcGetTaskRewardRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.key != null && message.hasOwnProperty("key"))
                if (!$util.isString(message.key))
                    return "key: string expected";
            return null;
        };

        /**
         * Creates a RpcGetTaskRewardRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcGetTaskRewardRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcGetTaskRewardRequest} RpcGetTaskRewardRequest
         */
        RpcGetTaskRewardRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcGetTaskRewardRequest)
                return object;
            let message = new $root.api.RpcGetTaskRewardRequest();
            if (object.key != null)
                message.key = String(object.key);
            return message;
        };

        /**
         * Creates a plain object from a RpcGetTaskRewardRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcGetTaskRewardRequest
         * @static
         * @param {api.RpcGetTaskRewardRequest} message RpcGetTaskRewardRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcGetTaskRewardRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.key = "";
            if (message.key != null && message.hasOwnProperty("key"))
                object.key = message.key;
            return object;
        };

        /**
         * Converts this RpcGetTaskRewardRequest to JSON.
         * @function toJSON
         * @memberof api.RpcGetTaskRewardRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcGetTaskRewardRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcGetTaskRewardRequest
         * @function getTypeUrl
         * @memberof api.RpcGetTaskRewardRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcGetTaskRewardRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcGetTaskRewardRequest";
        };

        return RpcGetTaskRewardRequest;
    })();

    api.RpcCompleteTaskRequestDebug = (function() {

        /**
         * Properties of a RpcCompleteTaskRequestDebug.
         * @memberof api
         * @interface IRpcCompleteTaskRequestDebug
         * @property {string|null} [key] RpcCompleteTaskRequestDebug key
         */

        /**
         * Constructs a new RpcCompleteTaskRequestDebug.
         * @memberof api
         * @classdesc Represents a RpcCompleteTaskRequestDebug.
         * @implements IRpcCompleteTaskRequestDebug
         * @constructor
         * @param {api.IRpcCompleteTaskRequestDebug=} [properties] Properties to set
         */
        function RpcCompleteTaskRequestDebug(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcCompleteTaskRequestDebug key.
         * @member {string} key
         * @memberof api.RpcCompleteTaskRequestDebug
         * @instance
         */
        RpcCompleteTaskRequestDebug.prototype.key = "";

        /**
         * Creates a new RpcCompleteTaskRequestDebug instance using the specified properties.
         * @function create
         * @memberof api.RpcCompleteTaskRequestDebug
         * @static
         * @param {api.IRpcCompleteTaskRequestDebug=} [properties] Properties to set
         * @returns {api.RpcCompleteTaskRequestDebug} RpcCompleteTaskRequestDebug instance
         */
        RpcCompleteTaskRequestDebug.create = function create(properties) {
            return new RpcCompleteTaskRequestDebug(properties);
        };

        /**
         * Encodes the specified RpcCompleteTaskRequestDebug message. Does not implicitly {@link api.RpcCompleteTaskRequestDebug.verify|verify} messages.
         * @function encode
         * @memberof api.RpcCompleteTaskRequestDebug
         * @static
         * @param {api.RpcCompleteTaskRequestDebug} message RpcCompleteTaskRequestDebug message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcCompleteTaskRequestDebug.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.key);
            return writer;
        };

        /**
         * Encodes the specified RpcCompleteTaskRequestDebug message, length delimited. Does not implicitly {@link api.RpcCompleteTaskRequestDebug.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcCompleteTaskRequestDebug
         * @static
         * @param {api.RpcCompleteTaskRequestDebug} message RpcCompleteTaskRequestDebug message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcCompleteTaskRequestDebug.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcCompleteTaskRequestDebug message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcCompleteTaskRequestDebug
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcCompleteTaskRequestDebug} RpcCompleteTaskRequestDebug
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcCompleteTaskRequestDebug.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcCompleteTaskRequestDebug();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.key = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcCompleteTaskRequestDebug message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcCompleteTaskRequestDebug
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcCompleteTaskRequestDebug} RpcCompleteTaskRequestDebug
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcCompleteTaskRequestDebug.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcCompleteTaskRequestDebug message.
         * @function verify
         * @memberof api.RpcCompleteTaskRequestDebug
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcCompleteTaskRequestDebug.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.key != null && message.hasOwnProperty("key"))
                if (!$util.isString(message.key))
                    return "key: string expected";
            return null;
        };

        /**
         * Creates a RpcCompleteTaskRequestDebug message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcCompleteTaskRequestDebug
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcCompleteTaskRequestDebug} RpcCompleteTaskRequestDebug
         */
        RpcCompleteTaskRequestDebug.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcCompleteTaskRequestDebug)
                return object;
            let message = new $root.api.RpcCompleteTaskRequestDebug();
            if (object.key != null)
                message.key = String(object.key);
            return message;
        };

        /**
         * Creates a plain object from a RpcCompleteTaskRequestDebug message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcCompleteTaskRequestDebug
         * @static
         * @param {api.RpcCompleteTaskRequestDebug} message RpcCompleteTaskRequestDebug
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcCompleteTaskRequestDebug.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.key = "";
            if (message.key != null && message.hasOwnProperty("key"))
                object.key = message.key;
            return object;
        };

        /**
         * Converts this RpcCompleteTaskRequestDebug to JSON.
         * @function toJSON
         * @memberof api.RpcCompleteTaskRequestDebug
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcCompleteTaskRequestDebug.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcCompleteTaskRequestDebug
         * @function getTypeUrl
         * @memberof api.RpcCompleteTaskRequestDebug
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcCompleteTaskRequestDebug.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcCompleteTaskRequestDebug";
        };

        return RpcCompleteTaskRequestDebug;
    })();

    api.RpcCollectTaskRewardResponce = (function() {

        /**
         * Properties of a RpcCollectTaskRewardResponce.
         * @memberof api
         * @interface IRpcCollectTaskRewardResponce
         * @property {api.CollectTaskRewardResult|null} [result] RpcCollectTaskRewardResponce result
         * @property {string|null} [key] RpcCollectTaskRewardResponce key
         */

        /**
         * Constructs a new RpcCollectTaskRewardResponce.
         * @memberof api
         * @classdesc Represents a RpcCollectTaskRewardResponce.
         * @implements IRpcCollectTaskRewardResponce
         * @constructor
         * @param {api.IRpcCollectTaskRewardResponce=} [properties] Properties to set
         */
        function RpcCollectTaskRewardResponce(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcCollectTaskRewardResponce result.
         * @member {api.CollectTaskRewardResult} result
         * @memberof api.RpcCollectTaskRewardResponce
         * @instance
         */
        RpcCollectTaskRewardResponce.prototype.result = 0;

        /**
         * RpcCollectTaskRewardResponce key.
         * @member {string} key
         * @memberof api.RpcCollectTaskRewardResponce
         * @instance
         */
        RpcCollectTaskRewardResponce.prototype.key = "";

        /**
         * Creates a new RpcCollectTaskRewardResponce instance using the specified properties.
         * @function create
         * @memberof api.RpcCollectTaskRewardResponce
         * @static
         * @param {api.IRpcCollectTaskRewardResponce=} [properties] Properties to set
         * @returns {api.RpcCollectTaskRewardResponce} RpcCollectTaskRewardResponce instance
         */
        RpcCollectTaskRewardResponce.create = function create(properties) {
            return new RpcCollectTaskRewardResponce(properties);
        };

        /**
         * Encodes the specified RpcCollectTaskRewardResponce message. Does not implicitly {@link api.RpcCollectTaskRewardResponce.verify|verify} messages.
         * @function encode
         * @memberof api.RpcCollectTaskRewardResponce
         * @static
         * @param {api.RpcCollectTaskRewardResponce} message RpcCollectTaskRewardResponce message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcCollectTaskRewardResponce.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.key);
            return writer;
        };

        /**
         * Encodes the specified RpcCollectTaskRewardResponce message, length delimited. Does not implicitly {@link api.RpcCollectTaskRewardResponce.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcCollectTaskRewardResponce
         * @static
         * @param {api.RpcCollectTaskRewardResponce} message RpcCollectTaskRewardResponce message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcCollectTaskRewardResponce.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcCollectTaskRewardResponce message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcCollectTaskRewardResponce
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcCollectTaskRewardResponce} RpcCollectTaskRewardResponce
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcCollectTaskRewardResponce.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcCollectTaskRewardResponce();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.result = reader.int32();
                        break;
                    }
                case 2: {
                        message.key = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcCollectTaskRewardResponce message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcCollectTaskRewardResponce
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcCollectTaskRewardResponce} RpcCollectTaskRewardResponce
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcCollectTaskRewardResponce.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcCollectTaskRewardResponce message.
         * @function verify
         * @memberof api.RpcCollectTaskRewardResponce
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcCollectTaskRewardResponce.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.result != null && message.hasOwnProperty("result"))
                switch (message.result) {
                default:
                    return "result: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            if (message.key != null && message.hasOwnProperty("key"))
                if (!$util.isString(message.key))
                    return "key: string expected";
            return null;
        };

        /**
         * Creates a RpcCollectTaskRewardResponce message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcCollectTaskRewardResponce
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcCollectTaskRewardResponce} RpcCollectTaskRewardResponce
         */
        RpcCollectTaskRewardResponce.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcCollectTaskRewardResponce)
                return object;
            let message = new $root.api.RpcCollectTaskRewardResponce();
            switch (object.result) {
            default:
                if (typeof object.result === "number") {
                    message.result = object.result;
                    break;
                }
                break;
            case "TASK_REWARD_UNSPECIFIED":
            case 0:
                message.result = 0;
                break;
            case "TASK_REWARD_SUCCESS":
            case 1:
                message.result = 1;
                break;
            case "TASK_REWARD_NOT_DONE":
            case 2:
                message.result = 2;
                break;
            case "TASK_REWARD_ALREADY_COLLECTED":
            case 3:
                message.result = 3;
                break;
            }
            if (object.key != null)
                message.key = String(object.key);
            return message;
        };

        /**
         * Creates a plain object from a RpcCollectTaskRewardResponce message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcCollectTaskRewardResponce
         * @static
         * @param {api.RpcCollectTaskRewardResponce} message RpcCollectTaskRewardResponce
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcCollectTaskRewardResponce.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.result = options.enums === String ? "TASK_REWARD_UNSPECIFIED" : 0;
                object.key = "";
            }
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = options.enums === String ? $root.api.CollectTaskRewardResult[message.result] === undefined ? message.result : $root.api.CollectTaskRewardResult[message.result] : message.result;
            if (message.key != null && message.hasOwnProperty("key"))
                object.key = message.key;
            return object;
        };

        /**
         * Converts this RpcCollectTaskRewardResponce to JSON.
         * @function toJSON
         * @memberof api.RpcCollectTaskRewardResponce
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcCollectTaskRewardResponce.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcCollectTaskRewardResponce
         * @function getTypeUrl
         * @memberof api.RpcCollectTaskRewardResponce
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcCollectTaskRewardResponce.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcCollectTaskRewardResponce";
        };

        return RpcCollectTaskRewardResponce;
    })();

    api.RpcCompleteTaskResponce = (function() {

        /**
         * Properties of a RpcCompleteTaskResponce.
         * @memberof api
         * @interface IRpcCompleteTaskResponce
         * @property {api.CompleteTaskResult|null} [result] RpcCompleteTaskResponce result
         * @property {string|null} [key] RpcCompleteTaskResponce key
         */

        /**
         * Constructs a new RpcCompleteTaskResponce.
         * @memberof api
         * @classdesc Represents a RpcCompleteTaskResponce.
         * @implements IRpcCompleteTaskResponce
         * @constructor
         * @param {api.IRpcCompleteTaskResponce=} [properties] Properties to set
         */
        function RpcCompleteTaskResponce(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcCompleteTaskResponce result.
         * @member {api.CompleteTaskResult} result
         * @memberof api.RpcCompleteTaskResponce
         * @instance
         */
        RpcCompleteTaskResponce.prototype.result = 0;

        /**
         * RpcCompleteTaskResponce key.
         * @member {string} key
         * @memberof api.RpcCompleteTaskResponce
         * @instance
         */
        RpcCompleteTaskResponce.prototype.key = "";

        /**
         * Creates a new RpcCompleteTaskResponce instance using the specified properties.
         * @function create
         * @memberof api.RpcCompleteTaskResponce
         * @static
         * @param {api.IRpcCompleteTaskResponce=} [properties] Properties to set
         * @returns {api.RpcCompleteTaskResponce} RpcCompleteTaskResponce instance
         */
        RpcCompleteTaskResponce.create = function create(properties) {
            return new RpcCompleteTaskResponce(properties);
        };

        /**
         * Encodes the specified RpcCompleteTaskResponce message. Does not implicitly {@link api.RpcCompleteTaskResponce.verify|verify} messages.
         * @function encode
         * @memberof api.RpcCompleteTaskResponce
         * @static
         * @param {api.RpcCompleteTaskResponce} message RpcCompleteTaskResponce message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcCompleteTaskResponce.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.key);
            return writer;
        };

        /**
         * Encodes the specified RpcCompleteTaskResponce message, length delimited. Does not implicitly {@link api.RpcCompleteTaskResponce.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcCompleteTaskResponce
         * @static
         * @param {api.RpcCompleteTaskResponce} message RpcCompleteTaskResponce message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcCompleteTaskResponce.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcCompleteTaskResponce message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcCompleteTaskResponce
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcCompleteTaskResponce} RpcCompleteTaskResponce
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcCompleteTaskResponce.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcCompleteTaskResponce();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.result = reader.int32();
                        break;
                    }
                case 2: {
                        message.key = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcCompleteTaskResponce message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcCompleteTaskResponce
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcCompleteTaskResponce} RpcCompleteTaskResponce
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcCompleteTaskResponce.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcCompleteTaskResponce message.
         * @function verify
         * @memberof api.RpcCompleteTaskResponce
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcCompleteTaskResponce.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.result != null && message.hasOwnProperty("result"))
                switch (message.result) {
                default:
                    return "result: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            if (message.key != null && message.hasOwnProperty("key"))
                if (!$util.isString(message.key))
                    return "key: string expected";
            return null;
        };

        /**
         * Creates a RpcCompleteTaskResponce message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcCompleteTaskResponce
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcCompleteTaskResponce} RpcCompleteTaskResponce
         */
        RpcCompleteTaskResponce.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcCompleteTaskResponce)
                return object;
            let message = new $root.api.RpcCompleteTaskResponce();
            switch (object.result) {
            default:
                if (typeof object.result === "number") {
                    message.result = object.result;
                    break;
                }
                break;
            case "TASK_COMPLETE_UNSPECIFIED":
            case 0:
                message.result = 0;
                break;
            case "TASK_COMPLETE_SUCCESS":
            case 1:
                message.result = 1;
                break;
            case "TASK_COMPLETE_FAIL":
            case 2:
                message.result = 2;
                break;
            case "TASK_COMPLETE_ALREADY_COMPLETED":
            case 3:
                message.result = 3;
                break;
            }
            if (object.key != null)
                message.key = String(object.key);
            return message;
        };

        /**
         * Creates a plain object from a RpcCompleteTaskResponce message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcCompleteTaskResponce
         * @static
         * @param {api.RpcCompleteTaskResponce} message RpcCompleteTaskResponce
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcCompleteTaskResponce.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.result = options.enums === String ? "TASK_COMPLETE_UNSPECIFIED" : 0;
                object.key = "";
            }
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = options.enums === String ? $root.api.CompleteTaskResult[message.result] === undefined ? message.result : $root.api.CompleteTaskResult[message.result] : message.result;
            if (message.key != null && message.hasOwnProperty("key"))
                object.key = message.key;
            return object;
        };

        /**
         * Converts this RpcCompleteTaskResponce to JSON.
         * @function toJSON
         * @memberof api.RpcCompleteTaskResponce
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcCompleteTaskResponce.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcCompleteTaskResponce
         * @function getTypeUrl
         * @memberof api.RpcCompleteTaskResponce
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcCompleteTaskResponce.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcCompleteTaskResponce";
        };

        return RpcCompleteTaskResponce;
    })();

    api.RpcCompareHashesResponce = (function() {

        /**
         * Properties of a RpcCompareHashesResponce.
         * @memberof api
         * @interface IRpcCompareHashesResponce
         * @property {string|null} [api] RpcCompareHashesResponce api
         * @property {string|null} [entities] RpcCompareHashesResponce entities
         */

        /**
         * Constructs a new RpcCompareHashesResponce.
         * @memberof api
         * @classdesc Represents a RpcCompareHashesResponce.
         * @implements IRpcCompareHashesResponce
         * @constructor
         * @param {api.IRpcCompareHashesResponce=} [properties] Properties to set
         */
        function RpcCompareHashesResponce(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcCompareHashesResponce api.
         * @member {string} api
         * @memberof api.RpcCompareHashesResponce
         * @instance
         */
        RpcCompareHashesResponce.prototype.api = "";

        /**
         * RpcCompareHashesResponce entities.
         * @member {string} entities
         * @memberof api.RpcCompareHashesResponce
         * @instance
         */
        RpcCompareHashesResponce.prototype.entities = "";

        /**
         * Creates a new RpcCompareHashesResponce instance using the specified properties.
         * @function create
         * @memberof api.RpcCompareHashesResponce
         * @static
         * @param {api.IRpcCompareHashesResponce=} [properties] Properties to set
         * @returns {api.RpcCompareHashesResponce} RpcCompareHashesResponce instance
         */
        RpcCompareHashesResponce.create = function create(properties) {
            return new RpcCompareHashesResponce(properties);
        };

        /**
         * Encodes the specified RpcCompareHashesResponce message. Does not implicitly {@link api.RpcCompareHashesResponce.verify|verify} messages.
         * @function encode
         * @memberof api.RpcCompareHashesResponce
         * @static
         * @param {api.RpcCompareHashesResponce} message RpcCompareHashesResponce message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcCompareHashesResponce.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.api != null && Object.hasOwnProperty.call(message, "api"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.api);
            if (message.entities != null && Object.hasOwnProperty.call(message, "entities"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.entities);
            return writer;
        };

        /**
         * Encodes the specified RpcCompareHashesResponce message, length delimited. Does not implicitly {@link api.RpcCompareHashesResponce.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcCompareHashesResponce
         * @static
         * @param {api.RpcCompareHashesResponce} message RpcCompareHashesResponce message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcCompareHashesResponce.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcCompareHashesResponce message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcCompareHashesResponce
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcCompareHashesResponce} RpcCompareHashesResponce
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcCompareHashesResponce.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcCompareHashesResponce();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.api = reader.string();
                        break;
                    }
                case 2: {
                        message.entities = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcCompareHashesResponce message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcCompareHashesResponce
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcCompareHashesResponce} RpcCompareHashesResponce
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcCompareHashesResponce.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcCompareHashesResponce message.
         * @function verify
         * @memberof api.RpcCompareHashesResponce
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcCompareHashesResponce.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.api != null && message.hasOwnProperty("api"))
                if (!$util.isString(message.api))
                    return "api: string expected";
            if (message.entities != null && message.hasOwnProperty("entities"))
                if (!$util.isString(message.entities))
                    return "entities: string expected";
            return null;
        };

        /**
         * Creates a RpcCompareHashesResponce message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcCompareHashesResponce
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcCompareHashesResponce} RpcCompareHashesResponce
         */
        RpcCompareHashesResponce.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcCompareHashesResponce)
                return object;
            let message = new $root.api.RpcCompareHashesResponce();
            if (object.api != null)
                message.api = String(object.api);
            if (object.entities != null)
                message.entities = String(object.entities);
            return message;
        };

        /**
         * Creates a plain object from a RpcCompareHashesResponce message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcCompareHashesResponce
         * @static
         * @param {api.RpcCompareHashesResponce} message RpcCompareHashesResponce
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcCompareHashesResponce.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.api = "";
                object.entities = "";
            }
            if (message.api != null && message.hasOwnProperty("api"))
                object.api = message.api;
            if (message.entities != null && message.hasOwnProperty("entities"))
                object.entities = message.entities;
            return object;
        };

        /**
         * Converts this RpcCompareHashesResponce to JSON.
         * @function toJSON
         * @memberof api.RpcCompareHashesResponce
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcCompareHashesResponce.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcCompareHashesResponce
         * @function getTypeUrl
         * @memberof api.RpcCompareHashesResponce
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcCompareHashesResponce.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcCompareHashesResponce";
        };

        return RpcCompareHashesResponce;
    })();

    api.Error = (function() {

        /**
         * Properties of an Error.
         * @memberof api
         * @interface IError
         * @property {Object.<string,string>|null} [error] Error error
         */

        /**
         * Constructs a new Error.
         * @memberof api
         * @classdesc Represents an Error.
         * @implements IError
         * @constructor
         * @param {api.IError=} [properties] Properties to set
         */
        function Error(properties) {
            this.error = {};
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Error error.
         * @member {Object.<string,string>} error
         * @memberof api.Error
         * @instance
         */
        Error.prototype.error = $util.emptyObject;

        /**
         * Creates a new Error instance using the specified properties.
         * @function create
         * @memberof api.Error
         * @static
         * @param {api.IError=} [properties] Properties to set
         * @returns {api.Error} Error instance
         */
        Error.create = function create(properties) {
            return new Error(properties);
        };

        /**
         * Encodes the specified Error message. Does not implicitly {@link api.Error.verify|verify} messages.
         * @function encode
         * @memberof api.Error
         * @static
         * @param {api.Error} message Error message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Error.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                for (let keys = Object.keys(message.error), i = 0; i < keys.length; ++i)
                    writer.uint32(/* id 1, wireType 2 =*/10).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.error[keys[i]]).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Error message, length delimited. Does not implicitly {@link api.Error.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.Error
         * @static
         * @param {api.Error} message Error message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Error.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an Error message from the specified reader or buffer.
         * @function decode
         * @memberof api.Error
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.Error} Error
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Error.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.Error(), key, value;
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (message.error === $util.emptyObject)
                            message.error = {};
                        let end2 = reader.uint32() + reader.pos;
                        key = "";
                        value = "";
                        while (reader.pos < end2) {
                            let tag2 = reader.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                key = reader.string();
                                break;
                            case 2:
                                value = reader.string();
                                break;
                            default:
                                reader.skipType(tag2 & 7);
                                break;
                            }
                        }
                        message.error[key] = value;
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an Error message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.Error
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.Error} Error
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Error.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an Error message.
         * @function verify
         * @memberof api.Error
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Error.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.error != null && message.hasOwnProperty("error")) {
                if (!$util.isObject(message.error))
                    return "error: object expected";
                let key = Object.keys(message.error);
                for (let i = 0; i < key.length; ++i)
                    if (!$util.isString(message.error[key[i]]))
                        return "error: string{k:string} expected";
            }
            return null;
        };

        /**
         * Creates an Error message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.Error
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.Error} Error
         */
        Error.fromObject = function fromObject(object) {
            if (object instanceof $root.api.Error)
                return object;
            let message = new $root.api.Error();
            if (object.error) {
                if (typeof object.error !== "object")
                    throw TypeError(".api.Error.error: object expected");
                message.error = {};
                for (let keys = Object.keys(object.error), i = 0; i < keys.length; ++i)
                    message.error[keys[i]] = String(object.error[keys[i]]);
            }
            return message;
        };

        /**
         * Creates a plain object from an Error message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.Error
         * @static
         * @param {api.Error} message Error
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Error.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.objects || options.defaults)
                object.error = {};
            let keys2;
            if (message.error && (keys2 = Object.keys(message.error)).length) {
                object.error = {};
                for (let j = 0; j < keys2.length; ++j)
                    object.error[keys2[j]] = message.error[keys2[j]];
            }
            return object;
        };

        /**
         * Converts this Error to JSON.
         * @function toJSON
         * @memberof api.Error
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Error.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Error
         * @function getTypeUrl
         * @memberof api.Error
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Error.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.Error";
        };

        return Error;
    })();

    api.ClientAccount = (function() {

        /**
         * Properties of a ClientAccount.
         * @memberof api
         * @interface IClientAccount
         * @property {string|null} [balance] ClientAccount balance
         * @property {string|null} [currency] ClientAccount currency
         * @property {number|null} [decimals] ClientAccount decimals
         */

        /**
         * Constructs a new ClientAccount.
         * @memberof api
         * @classdesc Represents a ClientAccount.
         * @implements IClientAccount
         * @constructor
         * @param {api.IClientAccount=} [properties] Properties to set
         */
        function ClientAccount(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ClientAccount balance.
         * @member {string} balance
         * @memberof api.ClientAccount
         * @instance
         */
        ClientAccount.prototype.balance = "";

        /**
         * ClientAccount currency.
         * @member {string} currency
         * @memberof api.ClientAccount
         * @instance
         */
        ClientAccount.prototype.currency = "";

        /**
         * ClientAccount decimals.
         * @member {number} decimals
         * @memberof api.ClientAccount
         * @instance
         */
        ClientAccount.prototype.decimals = 0;

        /**
         * Creates a new ClientAccount instance using the specified properties.
         * @function create
         * @memberof api.ClientAccount
         * @static
         * @param {api.IClientAccount=} [properties] Properties to set
         * @returns {api.ClientAccount} ClientAccount instance
         */
        ClientAccount.create = function create(properties) {
            return new ClientAccount(properties);
        };

        /**
         * Encodes the specified ClientAccount message. Does not implicitly {@link api.ClientAccount.verify|verify} messages.
         * @function encode
         * @memberof api.ClientAccount
         * @static
         * @param {api.ClientAccount} message ClientAccount message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ClientAccount.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.balance != null && Object.hasOwnProperty.call(message, "balance"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.balance);
            if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.currency);
            if (message.decimals != null && Object.hasOwnProperty.call(message, "decimals"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.decimals);
            return writer;
        };

        /**
         * Encodes the specified ClientAccount message, length delimited. Does not implicitly {@link api.ClientAccount.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.ClientAccount
         * @static
         * @param {api.ClientAccount} message ClientAccount message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ClientAccount.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ClientAccount message from the specified reader or buffer.
         * @function decode
         * @memberof api.ClientAccount
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.ClientAccount} ClientAccount
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ClientAccount.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.ClientAccount();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.balance = reader.string();
                        break;
                    }
                case 2: {
                        message.currency = reader.string();
                        break;
                    }
                case 3: {
                        message.decimals = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ClientAccount message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.ClientAccount
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.ClientAccount} ClientAccount
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ClientAccount.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ClientAccount message.
         * @function verify
         * @memberof api.ClientAccount
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ClientAccount.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.balance != null && message.hasOwnProperty("balance"))
                if (!$util.isString(message.balance))
                    return "balance: string expected";
            if (message.currency != null && message.hasOwnProperty("currency"))
                if (!$util.isString(message.currency))
                    return "currency: string expected";
            if (message.decimals != null && message.hasOwnProperty("decimals"))
                if (!$util.isInteger(message.decimals))
                    return "decimals: integer expected";
            return null;
        };

        /**
         * Creates a ClientAccount message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.ClientAccount
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.ClientAccount} ClientAccount
         */
        ClientAccount.fromObject = function fromObject(object) {
            if (object instanceof $root.api.ClientAccount)
                return object;
            let message = new $root.api.ClientAccount();
            if (object.balance != null)
                message.balance = String(object.balance);
            if (object.currency != null)
                message.currency = String(object.currency);
            if (object.decimals != null)
                message.decimals = object.decimals | 0;
            return message;
        };

        /**
         * Creates a plain object from a ClientAccount message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.ClientAccount
         * @static
         * @param {api.ClientAccount} message ClientAccount
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ClientAccount.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.balance = "";
                object.currency = "";
                object.decimals = 0;
            }
            if (message.balance != null && message.hasOwnProperty("balance"))
                object.balance = message.balance;
            if (message.currency != null && message.hasOwnProperty("currency"))
                object.currency = message.currency;
            if (message.decimals != null && message.hasOwnProperty("decimals"))
                object.decimals = message.decimals;
            return object;
        };

        /**
         * Converts this ClientAccount to JSON.
         * @function toJSON
         * @memberof api.ClientAccount
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ClientAccount.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ClientAccount
         * @function getTypeUrl
         * @memberof api.ClientAccount
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ClientAccount.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.ClientAccount";
        };

        return ClientAccount;
    })();

    api.ClientRewardAccount = (function() {

        /**
         * Properties of a ClientRewardAccount.
         * @memberof api
         * @interface IClientRewardAccount
         * @property {number|Long|null} [balance] ClientRewardAccount balance
         * @property {string|null} [currency] ClientRewardAccount currency
         * @property {number|null} [decimals] ClientRewardAccount decimals
         */

        /**
         * Constructs a new ClientRewardAccount.
         * @memberof api
         * @classdesc Represents a ClientRewardAccount.
         * @implements IClientRewardAccount
         * @constructor
         * @param {api.IClientRewardAccount=} [properties] Properties to set
         */
        function ClientRewardAccount(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ClientRewardAccount balance.
         * @member {number|Long} balance
         * @memberof api.ClientRewardAccount
         * @instance
         */
        ClientRewardAccount.prototype.balance = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ClientRewardAccount currency.
         * @member {string} currency
         * @memberof api.ClientRewardAccount
         * @instance
         */
        ClientRewardAccount.prototype.currency = "";

        /**
         * ClientRewardAccount decimals.
         * @member {number} decimals
         * @memberof api.ClientRewardAccount
         * @instance
         */
        ClientRewardAccount.prototype.decimals = 0;

        /**
         * Creates a new ClientRewardAccount instance using the specified properties.
         * @function create
         * @memberof api.ClientRewardAccount
         * @static
         * @param {api.IClientRewardAccount=} [properties] Properties to set
         * @returns {api.ClientRewardAccount} ClientRewardAccount instance
         */
        ClientRewardAccount.create = function create(properties) {
            return new ClientRewardAccount(properties);
        };

        /**
         * Encodes the specified ClientRewardAccount message. Does not implicitly {@link api.ClientRewardAccount.verify|verify} messages.
         * @function encode
         * @memberof api.ClientRewardAccount
         * @static
         * @param {api.ClientRewardAccount} message ClientRewardAccount message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ClientRewardAccount.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.balance != null && Object.hasOwnProperty.call(message, "balance"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.balance);
            if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.currency);
            if (message.decimals != null && Object.hasOwnProperty.call(message, "decimals"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.decimals);
            return writer;
        };

        /**
         * Encodes the specified ClientRewardAccount message, length delimited. Does not implicitly {@link api.ClientRewardAccount.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.ClientRewardAccount
         * @static
         * @param {api.ClientRewardAccount} message ClientRewardAccount message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ClientRewardAccount.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ClientRewardAccount message from the specified reader or buffer.
         * @function decode
         * @memberof api.ClientRewardAccount
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.ClientRewardAccount} ClientRewardAccount
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ClientRewardAccount.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.ClientRewardAccount();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.balance = reader.int64();
                        break;
                    }
                case 2: {
                        message.currency = reader.string();
                        break;
                    }
                case 3: {
                        message.decimals = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ClientRewardAccount message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.ClientRewardAccount
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.ClientRewardAccount} ClientRewardAccount
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ClientRewardAccount.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ClientRewardAccount message.
         * @function verify
         * @memberof api.ClientRewardAccount
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ClientRewardAccount.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.balance != null && message.hasOwnProperty("balance"))
                if (!$util.isInteger(message.balance) && !(message.balance && $util.isInteger(message.balance.low) && $util.isInteger(message.balance.high)))
                    return "balance: integer|Long expected";
            if (message.currency != null && message.hasOwnProperty("currency"))
                if (!$util.isString(message.currency))
                    return "currency: string expected";
            if (message.decimals != null && message.hasOwnProperty("decimals"))
                if (!$util.isInteger(message.decimals))
                    return "decimals: integer expected";
            return null;
        };

        /**
         * Creates a ClientRewardAccount message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.ClientRewardAccount
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.ClientRewardAccount} ClientRewardAccount
         */
        ClientRewardAccount.fromObject = function fromObject(object) {
            if (object instanceof $root.api.ClientRewardAccount)
                return object;
            let message = new $root.api.ClientRewardAccount();
            if (object.balance != null)
                if ($util.Long)
                    (message.balance = $util.Long.fromValue(object.balance)).unsigned = false;
                else if (typeof object.balance === "string")
                    message.balance = parseInt(object.balance, 10);
                else if (typeof object.balance === "number")
                    message.balance = object.balance;
                else if (typeof object.balance === "object")
                    message.balance = new $util.LongBits(object.balance.low >>> 0, object.balance.high >>> 0).toNumber();
            if (object.currency != null)
                message.currency = String(object.currency);
            if (object.decimals != null)
                message.decimals = object.decimals | 0;
            return message;
        };

        /**
         * Creates a plain object from a ClientRewardAccount message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.ClientRewardAccount
         * @static
         * @param {api.ClientRewardAccount} message ClientRewardAccount
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ClientRewardAccount.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.balance = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.balance = options.longs === String ? "0" : 0;
                object.currency = "";
                object.decimals = 0;
            }
            if (message.balance != null && message.hasOwnProperty("balance"))
                if (typeof message.balance === "number")
                    object.balance = options.longs === String ? String(message.balance) : message.balance;
                else
                    object.balance = options.longs === String ? $util.Long.prototype.toString.call(message.balance) : options.longs === Number ? new $util.LongBits(message.balance.low >>> 0, message.balance.high >>> 0).toNumber() : message.balance;
            if (message.currency != null && message.hasOwnProperty("currency"))
                object.currency = message.currency;
            if (message.decimals != null && message.hasOwnProperty("decimals"))
                object.decimals = message.decimals;
            return object;
        };

        /**
         * Converts this ClientRewardAccount to JSON.
         * @function toJSON
         * @memberof api.ClientRewardAccount
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ClientRewardAccount.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ClientRewardAccount
         * @function getTypeUrl
         * @memberof api.ClientRewardAccount
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ClientRewardAccount.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.ClientRewardAccount";
        };

        return ClientRewardAccount;
    })();

    api.RpcGetAccountsResponse = (function() {

        /**
         * Properties of a RpcGetAccountsResponse.
         * @memberof api
         * @interface IRpcGetAccountsResponse
         * @property {number|null} [code] RpcGetAccountsResponse code
         * @property {api.RpcGetAccountsResponseData|null} [data] RpcGetAccountsResponse data
         * @property {string|null} [message] RpcGetAccountsResponse message
         * @property {api.Error|null} [error] RpcGetAccountsResponse error
         */

        /**
         * Constructs a new RpcGetAccountsResponse.
         * @memberof api
         * @classdesc Represents a RpcGetAccountsResponse.
         * @implements IRpcGetAccountsResponse
         * @constructor
         * @param {api.IRpcGetAccountsResponse=} [properties] Properties to set
         */
        function RpcGetAccountsResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcGetAccountsResponse code.
         * @member {number} code
         * @memberof api.RpcGetAccountsResponse
         * @instance
         */
        RpcGetAccountsResponse.prototype.code = 0;

        /**
         * RpcGetAccountsResponse data.
         * @member {api.RpcGetAccountsResponseData|null|undefined} data
         * @memberof api.RpcGetAccountsResponse
         * @instance
         */
        RpcGetAccountsResponse.prototype.data = null;

        /**
         * RpcGetAccountsResponse message.
         * @member {string} message
         * @memberof api.RpcGetAccountsResponse
         * @instance
         */
        RpcGetAccountsResponse.prototype.message = "";

        /**
         * RpcGetAccountsResponse error.
         * @member {api.Error|null|undefined} error
         * @memberof api.RpcGetAccountsResponse
         * @instance
         */
        RpcGetAccountsResponse.prototype.error = null;

        /**
         * Creates a new RpcGetAccountsResponse instance using the specified properties.
         * @function create
         * @memberof api.RpcGetAccountsResponse
         * @static
         * @param {api.IRpcGetAccountsResponse=} [properties] Properties to set
         * @returns {api.RpcGetAccountsResponse} RpcGetAccountsResponse instance
         */
        RpcGetAccountsResponse.create = function create(properties) {
            return new RpcGetAccountsResponse(properties);
        };

        /**
         * Encodes the specified RpcGetAccountsResponse message. Does not implicitly {@link api.RpcGetAccountsResponse.verify|verify} messages.
         * @function encode
         * @memberof api.RpcGetAccountsResponse
         * @static
         * @param {api.RpcGetAccountsResponse} message RpcGetAccountsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGetAccountsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.code);
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                $root.api.RpcGetAccountsResponseData.encode(message.data, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.message);
            if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                $root.api.Error.encode(message.error, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified RpcGetAccountsResponse message, length delimited. Does not implicitly {@link api.RpcGetAccountsResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcGetAccountsResponse
         * @static
         * @param {api.RpcGetAccountsResponse} message RpcGetAccountsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGetAccountsResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcGetAccountsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcGetAccountsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcGetAccountsResponse} RpcGetAccountsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGetAccountsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcGetAccountsResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.code = reader.int32();
                        break;
                    }
                case 2: {
                        message.data = $root.api.RpcGetAccountsResponseData.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.message = reader.string();
                        break;
                    }
                case 4: {
                        message.error = $root.api.Error.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcGetAccountsResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcGetAccountsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcGetAccountsResponse} RpcGetAccountsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGetAccountsResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcGetAccountsResponse message.
         * @function verify
         * @memberof api.RpcGetAccountsResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcGetAccountsResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.code != null && message.hasOwnProperty("code"))
                if (!$util.isInteger(message.code))
                    return "code: integer expected";
            if (message.data != null && message.hasOwnProperty("data")) {
                let error = $root.api.RpcGetAccountsResponseData.verify(message.data);
                if (error)
                    return "data." + error;
            }
            if (message.message != null && message.hasOwnProperty("message"))
                if (!$util.isString(message.message))
                    return "message: string expected";
            if (message.error != null && message.hasOwnProperty("error")) {
                let error = $root.api.Error.verify(message.error);
                if (error)
                    return "error." + error;
            }
            return null;
        };

        /**
         * Creates a RpcGetAccountsResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcGetAccountsResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcGetAccountsResponse} RpcGetAccountsResponse
         */
        RpcGetAccountsResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcGetAccountsResponse)
                return object;
            let message = new $root.api.RpcGetAccountsResponse();
            if (object.code != null)
                message.code = object.code | 0;
            if (object.data != null) {
                if (typeof object.data !== "object")
                    throw TypeError(".api.RpcGetAccountsResponse.data: object expected");
                message.data = $root.api.RpcGetAccountsResponseData.fromObject(object.data);
            }
            if (object.message != null)
                message.message = String(object.message);
            if (object.error != null) {
                if (typeof object.error !== "object")
                    throw TypeError(".api.RpcGetAccountsResponse.error: object expected");
                message.error = $root.api.Error.fromObject(object.error);
            }
            return message;
        };

        /**
         * Creates a plain object from a RpcGetAccountsResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcGetAccountsResponse
         * @static
         * @param {api.RpcGetAccountsResponse} message RpcGetAccountsResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcGetAccountsResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.code = 0;
                object.data = null;
                object.message = "";
                object.error = null;
            }
            if (message.code != null && message.hasOwnProperty("code"))
                object.code = message.code;
            if (message.data != null && message.hasOwnProperty("data"))
                object.data = $root.api.RpcGetAccountsResponseData.toObject(message.data, options);
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = message.message;
            if (message.error != null && message.hasOwnProperty("error"))
                object.error = $root.api.Error.toObject(message.error, options);
            return object;
        };

        /**
         * Converts this RpcGetAccountsResponse to JSON.
         * @function toJSON
         * @memberof api.RpcGetAccountsResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcGetAccountsResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcGetAccountsResponse
         * @function getTypeUrl
         * @memberof api.RpcGetAccountsResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcGetAccountsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcGetAccountsResponse";
        };

        return RpcGetAccountsResponse;
    })();

    api.RpcGetAccountsResponseData = (function() {

        /**
         * Properties of a RpcGetAccountsResponseData.
         * @memberof api
         * @interface IRpcGetAccountsResponseData
         * @property {Array.<api.ClientAccount>|null} [client_accounts] RpcGetAccountsResponseData client_accounts
         * @property {Array.<api.ClientRewardAccount>|null} [client_reward_accounts] RpcGetAccountsResponseData client_reward_accounts
         */

        /**
         * Constructs a new RpcGetAccountsResponseData.
         * @memberof api
         * @classdesc Represents a RpcGetAccountsResponseData.
         * @implements IRpcGetAccountsResponseData
         * @constructor
         * @param {api.IRpcGetAccountsResponseData=} [properties] Properties to set
         */
        function RpcGetAccountsResponseData(properties) {
            this.client_accounts = [];
            this.client_reward_accounts = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcGetAccountsResponseData client_accounts.
         * @member {Array.<api.ClientAccount>} client_accounts
         * @memberof api.RpcGetAccountsResponseData
         * @instance
         */
        RpcGetAccountsResponseData.prototype.client_accounts = $util.emptyArray;

        /**
         * RpcGetAccountsResponseData client_reward_accounts.
         * @member {Array.<api.ClientRewardAccount>} client_reward_accounts
         * @memberof api.RpcGetAccountsResponseData
         * @instance
         */
        RpcGetAccountsResponseData.prototype.client_reward_accounts = $util.emptyArray;

        /**
         * Creates a new RpcGetAccountsResponseData instance using the specified properties.
         * @function create
         * @memberof api.RpcGetAccountsResponseData
         * @static
         * @param {api.IRpcGetAccountsResponseData=} [properties] Properties to set
         * @returns {api.RpcGetAccountsResponseData} RpcGetAccountsResponseData instance
         */
        RpcGetAccountsResponseData.create = function create(properties) {
            return new RpcGetAccountsResponseData(properties);
        };

        /**
         * Encodes the specified RpcGetAccountsResponseData message. Does not implicitly {@link api.RpcGetAccountsResponseData.verify|verify} messages.
         * @function encode
         * @memberof api.RpcGetAccountsResponseData
         * @static
         * @param {api.RpcGetAccountsResponseData} message RpcGetAccountsResponseData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGetAccountsResponseData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.client_accounts != null && message.client_accounts.length)
                for (let i = 0; i < message.client_accounts.length; ++i)
                    $root.api.ClientAccount.encode(message.client_accounts[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.client_reward_accounts != null && message.client_reward_accounts.length)
                for (let i = 0; i < message.client_reward_accounts.length; ++i)
                    $root.api.ClientRewardAccount.encode(message.client_reward_accounts[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified RpcGetAccountsResponseData message, length delimited. Does not implicitly {@link api.RpcGetAccountsResponseData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcGetAccountsResponseData
         * @static
         * @param {api.RpcGetAccountsResponseData} message RpcGetAccountsResponseData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGetAccountsResponseData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcGetAccountsResponseData message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcGetAccountsResponseData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcGetAccountsResponseData} RpcGetAccountsResponseData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGetAccountsResponseData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcGetAccountsResponseData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.client_accounts && message.client_accounts.length))
                            message.client_accounts = [];
                        message.client_accounts.push($root.api.ClientAccount.decode(reader, reader.uint32()));
                        break;
                    }
                case 2: {
                        if (!(message.client_reward_accounts && message.client_reward_accounts.length))
                            message.client_reward_accounts = [];
                        message.client_reward_accounts.push($root.api.ClientRewardAccount.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcGetAccountsResponseData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcGetAccountsResponseData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcGetAccountsResponseData} RpcGetAccountsResponseData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGetAccountsResponseData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcGetAccountsResponseData message.
         * @function verify
         * @memberof api.RpcGetAccountsResponseData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcGetAccountsResponseData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.client_accounts != null && message.hasOwnProperty("client_accounts")) {
                if (!Array.isArray(message.client_accounts))
                    return "client_accounts: array expected";
                for (let i = 0; i < message.client_accounts.length; ++i) {
                    let error = $root.api.ClientAccount.verify(message.client_accounts[i]);
                    if (error)
                        return "client_accounts." + error;
                }
            }
            if (message.client_reward_accounts != null && message.hasOwnProperty("client_reward_accounts")) {
                if (!Array.isArray(message.client_reward_accounts))
                    return "client_reward_accounts: array expected";
                for (let i = 0; i < message.client_reward_accounts.length; ++i) {
                    let error = $root.api.ClientRewardAccount.verify(message.client_reward_accounts[i]);
                    if (error)
                        return "client_reward_accounts." + error;
                }
            }
            return null;
        };

        /**
         * Creates a RpcGetAccountsResponseData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcGetAccountsResponseData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcGetAccountsResponseData} RpcGetAccountsResponseData
         */
        RpcGetAccountsResponseData.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcGetAccountsResponseData)
                return object;
            let message = new $root.api.RpcGetAccountsResponseData();
            if (object.client_accounts) {
                if (!Array.isArray(object.client_accounts))
                    throw TypeError(".api.RpcGetAccountsResponseData.client_accounts: array expected");
                message.client_accounts = [];
                for (let i = 0; i < object.client_accounts.length; ++i) {
                    if (typeof object.client_accounts[i] !== "object")
                        throw TypeError(".api.RpcGetAccountsResponseData.client_accounts: object expected");
                    message.client_accounts[i] = $root.api.ClientAccount.fromObject(object.client_accounts[i]);
                }
            }
            if (object.client_reward_accounts) {
                if (!Array.isArray(object.client_reward_accounts))
                    throw TypeError(".api.RpcGetAccountsResponseData.client_reward_accounts: array expected");
                message.client_reward_accounts = [];
                for (let i = 0; i < object.client_reward_accounts.length; ++i) {
                    if (typeof object.client_reward_accounts[i] !== "object")
                        throw TypeError(".api.RpcGetAccountsResponseData.client_reward_accounts: object expected");
                    message.client_reward_accounts[i] = $root.api.ClientRewardAccount.fromObject(object.client_reward_accounts[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a RpcGetAccountsResponseData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcGetAccountsResponseData
         * @static
         * @param {api.RpcGetAccountsResponseData} message RpcGetAccountsResponseData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcGetAccountsResponseData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.client_accounts = [];
                object.client_reward_accounts = [];
            }
            if (message.client_accounts && message.client_accounts.length) {
                object.client_accounts = [];
                for (let j = 0; j < message.client_accounts.length; ++j)
                    object.client_accounts[j] = $root.api.ClientAccount.toObject(message.client_accounts[j], options);
            }
            if (message.client_reward_accounts && message.client_reward_accounts.length) {
                object.client_reward_accounts = [];
                for (let j = 0; j < message.client_reward_accounts.length; ++j)
                    object.client_reward_accounts[j] = $root.api.ClientRewardAccount.toObject(message.client_reward_accounts[j], options);
            }
            return object;
        };

        /**
         * Converts this RpcGetAccountsResponseData to JSON.
         * @function toJSON
         * @memberof api.RpcGetAccountsResponseData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcGetAccountsResponseData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcGetAccountsResponseData
         * @function getTypeUrl
         * @memberof api.RpcGetAccountsResponseData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcGetAccountsResponseData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcGetAccountsResponseData";
        };

        return RpcGetAccountsResponseData;
    })();

    api.Group = (function() {

        /**
         * Properties of a Group.
         * @memberof api
         * @interface IGroup
         * @property {string|null} [admin_client_uid] Group admin_client_uid
         * @property {string|null} [avatar] Group avatar
         * @property {number|null} [clients_count] Group clients_count
         * @property {string|null} [created_at] Group created_at
         * @property {number|null} [id] Group id
         * @property {string|null} [name] Group name
         * @property {string|null} [ref_link] Group ref_link
         * @property {string|null} [tg] Group tg
         * @property {string|null} [uid] Group uid
         */

        /**
         * Constructs a new Group.
         * @memberof api
         * @classdesc Represents a Group.
         * @implements IGroup
         * @constructor
         * @param {api.IGroup=} [properties] Properties to set
         */
        function Group(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Group admin_client_uid.
         * @member {string} admin_client_uid
         * @memberof api.Group
         * @instance
         */
        Group.prototype.admin_client_uid = "";

        /**
         * Group avatar.
         * @member {string} avatar
         * @memberof api.Group
         * @instance
         */
        Group.prototype.avatar = "";

        /**
         * Group clients_count.
         * @member {number} clients_count
         * @memberof api.Group
         * @instance
         */
        Group.prototype.clients_count = 0;

        /**
         * Group created_at.
         * @member {string} created_at
         * @memberof api.Group
         * @instance
         */
        Group.prototype.created_at = "";

        /**
         * Group id.
         * @member {number} id
         * @memberof api.Group
         * @instance
         */
        Group.prototype.id = 0;

        /**
         * Group name.
         * @member {string} name
         * @memberof api.Group
         * @instance
         */
        Group.prototype.name = "";

        /**
         * Group ref_link.
         * @member {string} ref_link
         * @memberof api.Group
         * @instance
         */
        Group.prototype.ref_link = "";

        /**
         * Group tg.
         * @member {string} tg
         * @memberof api.Group
         * @instance
         */
        Group.prototype.tg = "";

        /**
         * Group uid.
         * @member {string} uid
         * @memberof api.Group
         * @instance
         */
        Group.prototype.uid = "";

        /**
         * Creates a new Group instance using the specified properties.
         * @function create
         * @memberof api.Group
         * @static
         * @param {api.IGroup=} [properties] Properties to set
         * @returns {api.Group} Group instance
         */
        Group.create = function create(properties) {
            return new Group(properties);
        };

        /**
         * Encodes the specified Group message. Does not implicitly {@link api.Group.verify|verify} messages.
         * @function encode
         * @memberof api.Group
         * @static
         * @param {api.Group} message Group message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Group.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.admin_client_uid != null && Object.hasOwnProperty.call(message, "admin_client_uid"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.admin_client_uid);
            if (message.avatar != null && Object.hasOwnProperty.call(message, "avatar"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.avatar);
            if (message.clients_count != null && Object.hasOwnProperty.call(message, "clients_count"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.clients_count);
            if (message.created_at != null && Object.hasOwnProperty.call(message, "created_at"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.created_at);
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.name);
            if (message.ref_link != null && Object.hasOwnProperty.call(message, "ref_link"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.ref_link);
            if (message.tg != null && Object.hasOwnProperty.call(message, "tg"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.tg);
            if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.uid);
            return writer;
        };

        /**
         * Encodes the specified Group message, length delimited. Does not implicitly {@link api.Group.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.Group
         * @static
         * @param {api.Group} message Group message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Group.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Group message from the specified reader or buffer.
         * @function decode
         * @memberof api.Group
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.Group} Group
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Group.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.Group();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.admin_client_uid = reader.string();
                        break;
                    }
                case 2: {
                        message.avatar = reader.string();
                        break;
                    }
                case 3: {
                        message.clients_count = reader.int32();
                        break;
                    }
                case 4: {
                        message.created_at = reader.string();
                        break;
                    }
                case 5: {
                        message.id = reader.int32();
                        break;
                    }
                case 6: {
                        message.name = reader.string();
                        break;
                    }
                case 7: {
                        message.ref_link = reader.string();
                        break;
                    }
                case 8: {
                        message.tg = reader.string();
                        break;
                    }
                case 9: {
                        message.uid = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Group message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.Group
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.Group} Group
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Group.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Group message.
         * @function verify
         * @memberof api.Group
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Group.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.admin_client_uid != null && message.hasOwnProperty("admin_client_uid"))
                if (!$util.isString(message.admin_client_uid))
                    return "admin_client_uid: string expected";
            if (message.avatar != null && message.hasOwnProperty("avatar"))
                if (!$util.isString(message.avatar))
                    return "avatar: string expected";
            if (message.clients_count != null && message.hasOwnProperty("clients_count"))
                if (!$util.isInteger(message.clients_count))
                    return "clients_count: integer expected";
            if (message.created_at != null && message.hasOwnProperty("created_at"))
                if (!$util.isString(message.created_at))
                    return "created_at: string expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isInteger(message.id))
                    return "id: integer expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.ref_link != null && message.hasOwnProperty("ref_link"))
                if (!$util.isString(message.ref_link))
                    return "ref_link: string expected";
            if (message.tg != null && message.hasOwnProperty("tg"))
                if (!$util.isString(message.tg))
                    return "tg: string expected";
            if (message.uid != null && message.hasOwnProperty("uid"))
                if (!$util.isString(message.uid))
                    return "uid: string expected";
            return null;
        };

        /**
         * Creates a Group message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.Group
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.Group} Group
         */
        Group.fromObject = function fromObject(object) {
            if (object instanceof $root.api.Group)
                return object;
            let message = new $root.api.Group();
            if (object.admin_client_uid != null)
                message.admin_client_uid = String(object.admin_client_uid);
            if (object.avatar != null)
                message.avatar = String(object.avatar);
            if (object.clients_count != null)
                message.clients_count = object.clients_count | 0;
            if (object.created_at != null)
                message.created_at = String(object.created_at);
            if (object.id != null)
                message.id = object.id | 0;
            if (object.name != null)
                message.name = String(object.name);
            if (object.ref_link != null)
                message.ref_link = String(object.ref_link);
            if (object.tg != null)
                message.tg = String(object.tg);
            if (object.uid != null)
                message.uid = String(object.uid);
            return message;
        };

        /**
         * Creates a plain object from a Group message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.Group
         * @static
         * @param {api.Group} message Group
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Group.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.admin_client_uid = "";
                object.avatar = "";
                object.clients_count = 0;
                object.created_at = "";
                object.id = 0;
                object.name = "";
                object.ref_link = "";
                object.tg = "";
                object.uid = "";
            }
            if (message.admin_client_uid != null && message.hasOwnProperty("admin_client_uid"))
                object.admin_client_uid = message.admin_client_uid;
            if (message.avatar != null && message.hasOwnProperty("avatar"))
                object.avatar = message.avatar;
            if (message.clients_count != null && message.hasOwnProperty("clients_count"))
                object.clients_count = message.clients_count;
            if (message.created_at != null && message.hasOwnProperty("created_at"))
                object.created_at = message.created_at;
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.ref_link != null && message.hasOwnProperty("ref_link"))
                object.ref_link = message.ref_link;
            if (message.tg != null && message.hasOwnProperty("tg"))
                object.tg = message.tg;
            if (message.uid != null && message.hasOwnProperty("uid"))
                object.uid = message.uid;
            return object;
        };

        /**
         * Converts this Group to JSON.
         * @function toJSON
         * @memberof api.Group
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Group.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Group
         * @function getTypeUrl
         * @memberof api.Group
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Group.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.Group";
        };

        return Group;
    })();

    api.Client = (function() {

        /**
         * Properties of a Client.
         * @memberof api
         * @interface IClient
         * @property {string|null} [avatar] Client avatar
         * @property {string|null} [created_at] Client created_at
         * @property {string|null} [firstname] Client firstname
         * @property {api.Group|null} [group] Client group
         * @property {number|null} [id] Client id
         * @property {string|null} [lastname] Client lastname
         * @property {number|null} [ref_line1_count] Client ref_line1_count
         * @property {number|null} [ref_line2_count] Client ref_line2_count
         * @property {number|null} [ref_line3_count] Client ref_line3_count
         * @property {string|null} [ref_link] Client ref_link
         * @property {string|null} [uid] Client uid
         * @property {string|null} [username] Client username
         */

        /**
         * Constructs a new Client.
         * @memberof api
         * @classdesc Represents a Client.
         * @implements IClient
         * @constructor
         * @param {api.IClient=} [properties] Properties to set
         */
        function Client(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Client avatar.
         * @member {string} avatar
         * @memberof api.Client
         * @instance
         */
        Client.prototype.avatar = "";

        /**
         * Client created_at.
         * @member {string} created_at
         * @memberof api.Client
         * @instance
         */
        Client.prototype.created_at = "";

        /**
         * Client firstname.
         * @member {string} firstname
         * @memberof api.Client
         * @instance
         */
        Client.prototype.firstname = "";

        /**
         * Client group.
         * @member {api.Group|null|undefined} group
         * @memberof api.Client
         * @instance
         */
        Client.prototype.group = null;

        /**
         * Client id.
         * @member {number} id
         * @memberof api.Client
         * @instance
         */
        Client.prototype.id = 0;

        /**
         * Client lastname.
         * @member {string} lastname
         * @memberof api.Client
         * @instance
         */
        Client.prototype.lastname = "";

        /**
         * Client ref_line1_count.
         * @member {number} ref_line1_count
         * @memberof api.Client
         * @instance
         */
        Client.prototype.ref_line1_count = 0;

        /**
         * Client ref_line2_count.
         * @member {number} ref_line2_count
         * @memberof api.Client
         * @instance
         */
        Client.prototype.ref_line2_count = 0;

        /**
         * Client ref_line3_count.
         * @member {number} ref_line3_count
         * @memberof api.Client
         * @instance
         */
        Client.prototype.ref_line3_count = 0;

        /**
         * Client ref_link.
         * @member {string} ref_link
         * @memberof api.Client
         * @instance
         */
        Client.prototype.ref_link = "";

        /**
         * Client uid.
         * @member {string} uid
         * @memberof api.Client
         * @instance
         */
        Client.prototype.uid = "";

        /**
         * Client username.
         * @member {string} username
         * @memberof api.Client
         * @instance
         */
        Client.prototype.username = "";

        /**
         * Creates a new Client instance using the specified properties.
         * @function create
         * @memberof api.Client
         * @static
         * @param {api.IClient=} [properties] Properties to set
         * @returns {api.Client} Client instance
         */
        Client.create = function create(properties) {
            return new Client(properties);
        };

        /**
         * Encodes the specified Client message. Does not implicitly {@link api.Client.verify|verify} messages.
         * @function encode
         * @memberof api.Client
         * @static
         * @param {api.Client} message Client message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Client.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.avatar != null && Object.hasOwnProperty.call(message, "avatar"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.avatar);
            if (message.created_at != null && Object.hasOwnProperty.call(message, "created_at"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.created_at);
            if (message.firstname != null && Object.hasOwnProperty.call(message, "firstname"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.firstname);
            if (message.group != null && Object.hasOwnProperty.call(message, "group"))
                $root.api.Group.encode(message.group, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.id);
            if (message.lastname != null && Object.hasOwnProperty.call(message, "lastname"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.lastname);
            if (message.ref_line1_count != null && Object.hasOwnProperty.call(message, "ref_line1_count"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.ref_line1_count);
            if (message.ref_line2_count != null && Object.hasOwnProperty.call(message, "ref_line2_count"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.ref_line2_count);
            if (message.ref_line3_count != null && Object.hasOwnProperty.call(message, "ref_line3_count"))
                writer.uint32(/* id 9, wireType 0 =*/72).int32(message.ref_line3_count);
            if (message.ref_link != null && Object.hasOwnProperty.call(message, "ref_link"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.ref_link);
            if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.uid);
            if (message.username != null && Object.hasOwnProperty.call(message, "username"))
                writer.uint32(/* id 12, wireType 2 =*/98).string(message.username);
            return writer;
        };

        /**
         * Encodes the specified Client message, length delimited. Does not implicitly {@link api.Client.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.Client
         * @static
         * @param {api.Client} message Client message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Client.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Client message from the specified reader or buffer.
         * @function decode
         * @memberof api.Client
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.Client} Client
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Client.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.Client();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.avatar = reader.string();
                        break;
                    }
                case 2: {
                        message.created_at = reader.string();
                        break;
                    }
                case 3: {
                        message.firstname = reader.string();
                        break;
                    }
                case 4: {
                        message.group = $root.api.Group.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.id = reader.int32();
                        break;
                    }
                case 6: {
                        message.lastname = reader.string();
                        break;
                    }
                case 7: {
                        message.ref_line1_count = reader.int32();
                        break;
                    }
                case 8: {
                        message.ref_line2_count = reader.int32();
                        break;
                    }
                case 9: {
                        message.ref_line3_count = reader.int32();
                        break;
                    }
                case 10: {
                        message.ref_link = reader.string();
                        break;
                    }
                case 11: {
                        message.uid = reader.string();
                        break;
                    }
                case 12: {
                        message.username = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Client message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.Client
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.Client} Client
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Client.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Client message.
         * @function verify
         * @memberof api.Client
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Client.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.avatar != null && message.hasOwnProperty("avatar"))
                if (!$util.isString(message.avatar))
                    return "avatar: string expected";
            if (message.created_at != null && message.hasOwnProperty("created_at"))
                if (!$util.isString(message.created_at))
                    return "created_at: string expected";
            if (message.firstname != null && message.hasOwnProperty("firstname"))
                if (!$util.isString(message.firstname))
                    return "firstname: string expected";
            if (message.group != null && message.hasOwnProperty("group")) {
                let error = $root.api.Group.verify(message.group);
                if (error)
                    return "group." + error;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isInteger(message.id))
                    return "id: integer expected";
            if (message.lastname != null && message.hasOwnProperty("lastname"))
                if (!$util.isString(message.lastname))
                    return "lastname: string expected";
            if (message.ref_line1_count != null && message.hasOwnProperty("ref_line1_count"))
                if (!$util.isInteger(message.ref_line1_count))
                    return "ref_line1_count: integer expected";
            if (message.ref_line2_count != null && message.hasOwnProperty("ref_line2_count"))
                if (!$util.isInteger(message.ref_line2_count))
                    return "ref_line2_count: integer expected";
            if (message.ref_line3_count != null && message.hasOwnProperty("ref_line3_count"))
                if (!$util.isInteger(message.ref_line3_count))
                    return "ref_line3_count: integer expected";
            if (message.ref_link != null && message.hasOwnProperty("ref_link"))
                if (!$util.isString(message.ref_link))
                    return "ref_link: string expected";
            if (message.uid != null && message.hasOwnProperty("uid"))
                if (!$util.isString(message.uid))
                    return "uid: string expected";
            if (message.username != null && message.hasOwnProperty("username"))
                if (!$util.isString(message.username))
                    return "username: string expected";
            return null;
        };

        /**
         * Creates a Client message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.Client
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.Client} Client
         */
        Client.fromObject = function fromObject(object) {
            if (object instanceof $root.api.Client)
                return object;
            let message = new $root.api.Client();
            if (object.avatar != null)
                message.avatar = String(object.avatar);
            if (object.created_at != null)
                message.created_at = String(object.created_at);
            if (object.firstname != null)
                message.firstname = String(object.firstname);
            if (object.group != null) {
                if (typeof object.group !== "object")
                    throw TypeError(".api.Client.group: object expected");
                message.group = $root.api.Group.fromObject(object.group);
            }
            if (object.id != null)
                message.id = object.id | 0;
            if (object.lastname != null)
                message.lastname = String(object.lastname);
            if (object.ref_line1_count != null)
                message.ref_line1_count = object.ref_line1_count | 0;
            if (object.ref_line2_count != null)
                message.ref_line2_count = object.ref_line2_count | 0;
            if (object.ref_line3_count != null)
                message.ref_line3_count = object.ref_line3_count | 0;
            if (object.ref_link != null)
                message.ref_link = String(object.ref_link);
            if (object.uid != null)
                message.uid = String(object.uid);
            if (object.username != null)
                message.username = String(object.username);
            return message;
        };

        /**
         * Creates a plain object from a Client message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.Client
         * @static
         * @param {api.Client} message Client
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Client.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.avatar = "";
                object.created_at = "";
                object.firstname = "";
                object.group = null;
                object.id = 0;
                object.lastname = "";
                object.ref_line1_count = 0;
                object.ref_line2_count = 0;
                object.ref_line3_count = 0;
                object.ref_link = "";
                object.uid = "";
                object.username = "";
            }
            if (message.avatar != null && message.hasOwnProperty("avatar"))
                object.avatar = message.avatar;
            if (message.created_at != null && message.hasOwnProperty("created_at"))
                object.created_at = message.created_at;
            if (message.firstname != null && message.hasOwnProperty("firstname"))
                object.firstname = message.firstname;
            if (message.group != null && message.hasOwnProperty("group"))
                object.group = $root.api.Group.toObject(message.group, options);
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.lastname != null && message.hasOwnProperty("lastname"))
                object.lastname = message.lastname;
            if (message.ref_line1_count != null && message.hasOwnProperty("ref_line1_count"))
                object.ref_line1_count = message.ref_line1_count;
            if (message.ref_line2_count != null && message.hasOwnProperty("ref_line2_count"))
                object.ref_line2_count = message.ref_line2_count;
            if (message.ref_line3_count != null && message.hasOwnProperty("ref_line3_count"))
                object.ref_line3_count = message.ref_line3_count;
            if (message.ref_link != null && message.hasOwnProperty("ref_link"))
                object.ref_link = message.ref_link;
            if (message.uid != null && message.hasOwnProperty("uid"))
                object.uid = message.uid;
            if (message.username != null && message.hasOwnProperty("username"))
                object.username = message.username;
            return object;
        };

        /**
         * Converts this Client to JSON.
         * @function toJSON
         * @memberof api.Client
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Client.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Client
         * @function getTypeUrl
         * @memberof api.Client
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Client.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.Client";
        };

        return Client;
    })();

    api.RpcRpcGetClientResponse = (function() {

        /**
         * Properties of a RpcRpcGetClientResponse.
         * @memberof api
         * @interface IRpcRpcGetClientResponse
         * @property {number|null} [code] RpcRpcGetClientResponse code
         * @property {api.RpcRpcGetClientResponseData|null} [data] RpcRpcGetClientResponse data
         * @property {api.Error|null} [error] RpcRpcGetClientResponse error
         * @property {string|null} [message] RpcRpcGetClientResponse message
         */

        /**
         * Constructs a new RpcRpcGetClientResponse.
         * @memberof api
         * @classdesc Represents a RpcRpcGetClientResponse.
         * @implements IRpcRpcGetClientResponse
         * @constructor
         * @param {api.IRpcRpcGetClientResponse=} [properties] Properties to set
         */
        function RpcRpcGetClientResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcRpcGetClientResponse code.
         * @member {number} code
         * @memberof api.RpcRpcGetClientResponse
         * @instance
         */
        RpcRpcGetClientResponse.prototype.code = 0;

        /**
         * RpcRpcGetClientResponse data.
         * @member {api.RpcRpcGetClientResponseData|null|undefined} data
         * @memberof api.RpcRpcGetClientResponse
         * @instance
         */
        RpcRpcGetClientResponse.prototype.data = null;

        /**
         * RpcRpcGetClientResponse error.
         * @member {api.Error|null|undefined} error
         * @memberof api.RpcRpcGetClientResponse
         * @instance
         */
        RpcRpcGetClientResponse.prototype.error = null;

        /**
         * RpcRpcGetClientResponse message.
         * @member {string} message
         * @memberof api.RpcRpcGetClientResponse
         * @instance
         */
        RpcRpcGetClientResponse.prototype.message = "";

        /**
         * Creates a new RpcRpcGetClientResponse instance using the specified properties.
         * @function create
         * @memberof api.RpcRpcGetClientResponse
         * @static
         * @param {api.IRpcRpcGetClientResponse=} [properties] Properties to set
         * @returns {api.RpcRpcGetClientResponse} RpcRpcGetClientResponse instance
         */
        RpcRpcGetClientResponse.create = function create(properties) {
            return new RpcRpcGetClientResponse(properties);
        };

        /**
         * Encodes the specified RpcRpcGetClientResponse message. Does not implicitly {@link api.RpcRpcGetClientResponse.verify|verify} messages.
         * @function encode
         * @memberof api.RpcRpcGetClientResponse
         * @static
         * @param {api.RpcRpcGetClientResponse} message RpcRpcGetClientResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcRpcGetClientResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.code);
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                $root.api.RpcRpcGetClientResponseData.encode(message.data, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                $root.api.Error.encode(message.error, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.message);
            return writer;
        };

        /**
         * Encodes the specified RpcRpcGetClientResponse message, length delimited. Does not implicitly {@link api.RpcRpcGetClientResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcRpcGetClientResponse
         * @static
         * @param {api.RpcRpcGetClientResponse} message RpcRpcGetClientResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcRpcGetClientResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcRpcGetClientResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcRpcGetClientResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcRpcGetClientResponse} RpcRpcGetClientResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcRpcGetClientResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcRpcGetClientResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.code = reader.int32();
                        break;
                    }
                case 2: {
                        message.data = $root.api.RpcRpcGetClientResponseData.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.error = $root.api.Error.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.message = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcRpcGetClientResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcRpcGetClientResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcRpcGetClientResponse} RpcRpcGetClientResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcRpcGetClientResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcRpcGetClientResponse message.
         * @function verify
         * @memberof api.RpcRpcGetClientResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcRpcGetClientResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.code != null && message.hasOwnProperty("code"))
                if (!$util.isInteger(message.code))
                    return "code: integer expected";
            if (message.data != null && message.hasOwnProperty("data")) {
                let error = $root.api.RpcRpcGetClientResponseData.verify(message.data);
                if (error)
                    return "data." + error;
            }
            if (message.error != null && message.hasOwnProperty("error")) {
                let error = $root.api.Error.verify(message.error);
                if (error)
                    return "error." + error;
            }
            if (message.message != null && message.hasOwnProperty("message"))
                if (!$util.isString(message.message))
                    return "message: string expected";
            return null;
        };

        /**
         * Creates a RpcRpcGetClientResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcRpcGetClientResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcRpcGetClientResponse} RpcRpcGetClientResponse
         */
        RpcRpcGetClientResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcRpcGetClientResponse)
                return object;
            let message = new $root.api.RpcRpcGetClientResponse();
            if (object.code != null)
                message.code = object.code | 0;
            if (object.data != null) {
                if (typeof object.data !== "object")
                    throw TypeError(".api.RpcRpcGetClientResponse.data: object expected");
                message.data = $root.api.RpcRpcGetClientResponseData.fromObject(object.data);
            }
            if (object.error != null) {
                if (typeof object.error !== "object")
                    throw TypeError(".api.RpcRpcGetClientResponse.error: object expected");
                message.error = $root.api.Error.fromObject(object.error);
            }
            if (object.message != null)
                message.message = String(object.message);
            return message;
        };

        /**
         * Creates a plain object from a RpcRpcGetClientResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcRpcGetClientResponse
         * @static
         * @param {api.RpcRpcGetClientResponse} message RpcRpcGetClientResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcRpcGetClientResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.code = 0;
                object.data = null;
                object.error = null;
                object.message = "";
            }
            if (message.code != null && message.hasOwnProperty("code"))
                object.code = message.code;
            if (message.data != null && message.hasOwnProperty("data"))
                object.data = $root.api.RpcRpcGetClientResponseData.toObject(message.data, options);
            if (message.error != null && message.hasOwnProperty("error"))
                object.error = $root.api.Error.toObject(message.error, options);
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = message.message;
            return object;
        };

        /**
         * Converts this RpcRpcGetClientResponse to JSON.
         * @function toJSON
         * @memberof api.RpcRpcGetClientResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcRpcGetClientResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcRpcGetClientResponse
         * @function getTypeUrl
         * @memberof api.RpcRpcGetClientResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcRpcGetClientResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcRpcGetClientResponse";
        };

        return RpcRpcGetClientResponse;
    })();

    api.RpcRpcGetClientResponseData = (function() {

        /**
         * Properties of a RpcRpcGetClientResponseData.
         * @memberof api
         * @interface IRpcRpcGetClientResponseData
         * @property {api.Client|null} [client] RpcRpcGetClientResponseData client
         */

        /**
         * Constructs a new RpcRpcGetClientResponseData.
         * @memberof api
         * @classdesc Represents a RpcRpcGetClientResponseData.
         * @implements IRpcRpcGetClientResponseData
         * @constructor
         * @param {api.IRpcRpcGetClientResponseData=} [properties] Properties to set
         */
        function RpcRpcGetClientResponseData(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcRpcGetClientResponseData client.
         * @member {api.Client|null|undefined} client
         * @memberof api.RpcRpcGetClientResponseData
         * @instance
         */
        RpcRpcGetClientResponseData.prototype.client = null;

        /**
         * Creates a new RpcRpcGetClientResponseData instance using the specified properties.
         * @function create
         * @memberof api.RpcRpcGetClientResponseData
         * @static
         * @param {api.IRpcRpcGetClientResponseData=} [properties] Properties to set
         * @returns {api.RpcRpcGetClientResponseData} RpcRpcGetClientResponseData instance
         */
        RpcRpcGetClientResponseData.create = function create(properties) {
            return new RpcRpcGetClientResponseData(properties);
        };

        /**
         * Encodes the specified RpcRpcGetClientResponseData message. Does not implicitly {@link api.RpcRpcGetClientResponseData.verify|verify} messages.
         * @function encode
         * @memberof api.RpcRpcGetClientResponseData
         * @static
         * @param {api.RpcRpcGetClientResponseData} message RpcRpcGetClientResponseData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcRpcGetClientResponseData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.client != null && Object.hasOwnProperty.call(message, "client"))
                $root.api.Client.encode(message.client, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified RpcRpcGetClientResponseData message, length delimited. Does not implicitly {@link api.RpcRpcGetClientResponseData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcRpcGetClientResponseData
         * @static
         * @param {api.RpcRpcGetClientResponseData} message RpcRpcGetClientResponseData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcRpcGetClientResponseData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcRpcGetClientResponseData message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcRpcGetClientResponseData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcRpcGetClientResponseData} RpcRpcGetClientResponseData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcRpcGetClientResponseData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcRpcGetClientResponseData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.client = $root.api.Client.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcRpcGetClientResponseData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcRpcGetClientResponseData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcRpcGetClientResponseData} RpcRpcGetClientResponseData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcRpcGetClientResponseData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcRpcGetClientResponseData message.
         * @function verify
         * @memberof api.RpcRpcGetClientResponseData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcRpcGetClientResponseData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.client != null && message.hasOwnProperty("client")) {
                let error = $root.api.Client.verify(message.client);
                if (error)
                    return "client." + error;
            }
            return null;
        };

        /**
         * Creates a RpcRpcGetClientResponseData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcRpcGetClientResponseData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcRpcGetClientResponseData} RpcRpcGetClientResponseData
         */
        RpcRpcGetClientResponseData.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcRpcGetClientResponseData)
                return object;
            let message = new $root.api.RpcRpcGetClientResponseData();
            if (object.client != null) {
                if (typeof object.client !== "object")
                    throw TypeError(".api.RpcRpcGetClientResponseData.client: object expected");
                message.client = $root.api.Client.fromObject(object.client);
            }
            return message;
        };

        /**
         * Creates a plain object from a RpcRpcGetClientResponseData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcRpcGetClientResponseData
         * @static
         * @param {api.RpcRpcGetClientResponseData} message RpcRpcGetClientResponseData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcRpcGetClientResponseData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.client = null;
            if (message.client != null && message.hasOwnProperty("client"))
                object.client = $root.api.Client.toObject(message.client, options);
            return object;
        };

        /**
         * Converts this RpcRpcGetClientResponseData to JSON.
         * @function toJSON
         * @memberof api.RpcRpcGetClientResponseData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcRpcGetClientResponseData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcRpcGetClientResponseData
         * @function getTypeUrl
         * @memberof api.RpcRpcGetClientResponseData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcRpcGetClientResponseData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcRpcGetClientResponseData";
        };

        return RpcRpcGetClientResponseData;
    })();

    api.RpcGetCurrenciesRatesResponseData = (function() {

        /**
         * Properties of a RpcGetCurrenciesRatesResponseData.
         * @memberof api
         * @interface IRpcGetCurrenciesRatesResponseData
         * @property {Object.<string,string>|null} [rates] RpcGetCurrenciesRatesResponseData rates
         */

        /**
         * Constructs a new RpcGetCurrenciesRatesResponseData.
         * @memberof api
         * @classdesc Represents a RpcGetCurrenciesRatesResponseData.
         * @implements IRpcGetCurrenciesRatesResponseData
         * @constructor
         * @param {api.IRpcGetCurrenciesRatesResponseData=} [properties] Properties to set
         */
        function RpcGetCurrenciesRatesResponseData(properties) {
            this.rates = {};
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcGetCurrenciesRatesResponseData rates.
         * @member {Object.<string,string>} rates
         * @memberof api.RpcGetCurrenciesRatesResponseData
         * @instance
         */
        RpcGetCurrenciesRatesResponseData.prototype.rates = $util.emptyObject;

        /**
         * Creates a new RpcGetCurrenciesRatesResponseData instance using the specified properties.
         * @function create
         * @memberof api.RpcGetCurrenciesRatesResponseData
         * @static
         * @param {api.IRpcGetCurrenciesRatesResponseData=} [properties] Properties to set
         * @returns {api.RpcGetCurrenciesRatesResponseData} RpcGetCurrenciesRatesResponseData instance
         */
        RpcGetCurrenciesRatesResponseData.create = function create(properties) {
            return new RpcGetCurrenciesRatesResponseData(properties);
        };

        /**
         * Encodes the specified RpcGetCurrenciesRatesResponseData message. Does not implicitly {@link api.RpcGetCurrenciesRatesResponseData.verify|verify} messages.
         * @function encode
         * @memberof api.RpcGetCurrenciesRatesResponseData
         * @static
         * @param {api.RpcGetCurrenciesRatesResponseData} message RpcGetCurrenciesRatesResponseData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGetCurrenciesRatesResponseData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rates != null && Object.hasOwnProperty.call(message, "rates"))
                for (let keys = Object.keys(message.rates), i = 0; i < keys.length; ++i)
                    writer.uint32(/* id 1, wireType 2 =*/10).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.rates[keys[i]]).ldelim();
            return writer;
        };

        /**
         * Encodes the specified RpcGetCurrenciesRatesResponseData message, length delimited. Does not implicitly {@link api.RpcGetCurrenciesRatesResponseData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcGetCurrenciesRatesResponseData
         * @static
         * @param {api.RpcGetCurrenciesRatesResponseData} message RpcGetCurrenciesRatesResponseData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGetCurrenciesRatesResponseData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcGetCurrenciesRatesResponseData message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcGetCurrenciesRatesResponseData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcGetCurrenciesRatesResponseData} RpcGetCurrenciesRatesResponseData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGetCurrenciesRatesResponseData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcGetCurrenciesRatesResponseData(), key, value;
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (message.rates === $util.emptyObject)
                            message.rates = {};
                        let end2 = reader.uint32() + reader.pos;
                        key = "";
                        value = "";
                        while (reader.pos < end2) {
                            let tag2 = reader.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                key = reader.string();
                                break;
                            case 2:
                                value = reader.string();
                                break;
                            default:
                                reader.skipType(tag2 & 7);
                                break;
                            }
                        }
                        message.rates[key] = value;
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcGetCurrenciesRatesResponseData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcGetCurrenciesRatesResponseData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcGetCurrenciesRatesResponseData} RpcGetCurrenciesRatesResponseData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGetCurrenciesRatesResponseData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcGetCurrenciesRatesResponseData message.
         * @function verify
         * @memberof api.RpcGetCurrenciesRatesResponseData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcGetCurrenciesRatesResponseData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.rates != null && message.hasOwnProperty("rates")) {
                if (!$util.isObject(message.rates))
                    return "rates: object expected";
                let key = Object.keys(message.rates);
                for (let i = 0; i < key.length; ++i)
                    if (!$util.isString(message.rates[key[i]]))
                        return "rates: string{k:string} expected";
            }
            return null;
        };

        /**
         * Creates a RpcGetCurrenciesRatesResponseData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcGetCurrenciesRatesResponseData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcGetCurrenciesRatesResponseData} RpcGetCurrenciesRatesResponseData
         */
        RpcGetCurrenciesRatesResponseData.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcGetCurrenciesRatesResponseData)
                return object;
            let message = new $root.api.RpcGetCurrenciesRatesResponseData();
            if (object.rates) {
                if (typeof object.rates !== "object")
                    throw TypeError(".api.RpcGetCurrenciesRatesResponseData.rates: object expected");
                message.rates = {};
                for (let keys = Object.keys(object.rates), i = 0; i < keys.length; ++i)
                    message.rates[keys[i]] = String(object.rates[keys[i]]);
            }
            return message;
        };

        /**
         * Creates a plain object from a RpcGetCurrenciesRatesResponseData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcGetCurrenciesRatesResponseData
         * @static
         * @param {api.RpcGetCurrenciesRatesResponseData} message RpcGetCurrenciesRatesResponseData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcGetCurrenciesRatesResponseData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.objects || options.defaults)
                object.rates = {};
            let keys2;
            if (message.rates && (keys2 = Object.keys(message.rates)).length) {
                object.rates = {};
                for (let j = 0; j < keys2.length; ++j)
                    object.rates[keys2[j]] = message.rates[keys2[j]];
            }
            return object;
        };

        /**
         * Converts this RpcGetCurrenciesRatesResponseData to JSON.
         * @function toJSON
         * @memberof api.RpcGetCurrenciesRatesResponseData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcGetCurrenciesRatesResponseData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcGetCurrenciesRatesResponseData
         * @function getTypeUrl
         * @memberof api.RpcGetCurrenciesRatesResponseData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcGetCurrenciesRatesResponseData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcGetCurrenciesRatesResponseData";
        };

        return RpcGetCurrenciesRatesResponseData;
    })();

    api.RpcGetCurrenciesRatesResponse = (function() {

        /**
         * Properties of a RpcGetCurrenciesRatesResponse.
         * @memberof api
         * @interface IRpcGetCurrenciesRatesResponse
         * @property {number|null} [code] RpcGetCurrenciesRatesResponse code
         * @property {api.RpcGetCurrenciesRatesResponseData|null} [data] RpcGetCurrenciesRatesResponse data
         * @property {api.Error|null} [error] RpcGetCurrenciesRatesResponse error
         * @property {string|null} [message] RpcGetCurrenciesRatesResponse message
         */

        /**
         * Constructs a new RpcGetCurrenciesRatesResponse.
         * @memberof api
         * @classdesc Represents a RpcGetCurrenciesRatesResponse.
         * @implements IRpcGetCurrenciesRatesResponse
         * @constructor
         * @param {api.IRpcGetCurrenciesRatesResponse=} [properties] Properties to set
         */
        function RpcGetCurrenciesRatesResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcGetCurrenciesRatesResponse code.
         * @member {number} code
         * @memberof api.RpcGetCurrenciesRatesResponse
         * @instance
         */
        RpcGetCurrenciesRatesResponse.prototype.code = 0;

        /**
         * RpcGetCurrenciesRatesResponse data.
         * @member {api.RpcGetCurrenciesRatesResponseData|null|undefined} data
         * @memberof api.RpcGetCurrenciesRatesResponse
         * @instance
         */
        RpcGetCurrenciesRatesResponse.prototype.data = null;

        /**
         * RpcGetCurrenciesRatesResponse error.
         * @member {api.Error|null|undefined} error
         * @memberof api.RpcGetCurrenciesRatesResponse
         * @instance
         */
        RpcGetCurrenciesRatesResponse.prototype.error = null;

        /**
         * RpcGetCurrenciesRatesResponse message.
         * @member {string} message
         * @memberof api.RpcGetCurrenciesRatesResponse
         * @instance
         */
        RpcGetCurrenciesRatesResponse.prototype.message = "";

        /**
         * Creates a new RpcGetCurrenciesRatesResponse instance using the specified properties.
         * @function create
         * @memberof api.RpcGetCurrenciesRatesResponse
         * @static
         * @param {api.IRpcGetCurrenciesRatesResponse=} [properties] Properties to set
         * @returns {api.RpcGetCurrenciesRatesResponse} RpcGetCurrenciesRatesResponse instance
         */
        RpcGetCurrenciesRatesResponse.create = function create(properties) {
            return new RpcGetCurrenciesRatesResponse(properties);
        };

        /**
         * Encodes the specified RpcGetCurrenciesRatesResponse message. Does not implicitly {@link api.RpcGetCurrenciesRatesResponse.verify|verify} messages.
         * @function encode
         * @memberof api.RpcGetCurrenciesRatesResponse
         * @static
         * @param {api.RpcGetCurrenciesRatesResponse} message RpcGetCurrenciesRatesResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGetCurrenciesRatesResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.code);
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                $root.api.RpcGetCurrenciesRatesResponseData.encode(message.data, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                $root.api.Error.encode(message.error, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.message);
            return writer;
        };

        /**
         * Encodes the specified RpcGetCurrenciesRatesResponse message, length delimited. Does not implicitly {@link api.RpcGetCurrenciesRatesResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcGetCurrenciesRatesResponse
         * @static
         * @param {api.RpcGetCurrenciesRatesResponse} message RpcGetCurrenciesRatesResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGetCurrenciesRatesResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcGetCurrenciesRatesResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcGetCurrenciesRatesResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcGetCurrenciesRatesResponse} RpcGetCurrenciesRatesResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGetCurrenciesRatesResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcGetCurrenciesRatesResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.code = reader.int32();
                        break;
                    }
                case 2: {
                        message.data = $root.api.RpcGetCurrenciesRatesResponseData.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.error = $root.api.Error.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.message = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcGetCurrenciesRatesResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcGetCurrenciesRatesResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcGetCurrenciesRatesResponse} RpcGetCurrenciesRatesResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGetCurrenciesRatesResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcGetCurrenciesRatesResponse message.
         * @function verify
         * @memberof api.RpcGetCurrenciesRatesResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcGetCurrenciesRatesResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.code != null && message.hasOwnProperty("code"))
                if (!$util.isInteger(message.code))
                    return "code: integer expected";
            if (message.data != null && message.hasOwnProperty("data")) {
                let error = $root.api.RpcGetCurrenciesRatesResponseData.verify(message.data);
                if (error)
                    return "data." + error;
            }
            if (message.error != null && message.hasOwnProperty("error")) {
                let error = $root.api.Error.verify(message.error);
                if (error)
                    return "error." + error;
            }
            if (message.message != null && message.hasOwnProperty("message"))
                if (!$util.isString(message.message))
                    return "message: string expected";
            return null;
        };

        /**
         * Creates a RpcGetCurrenciesRatesResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcGetCurrenciesRatesResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcGetCurrenciesRatesResponse} RpcGetCurrenciesRatesResponse
         */
        RpcGetCurrenciesRatesResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcGetCurrenciesRatesResponse)
                return object;
            let message = new $root.api.RpcGetCurrenciesRatesResponse();
            if (object.code != null)
                message.code = object.code | 0;
            if (object.data != null) {
                if (typeof object.data !== "object")
                    throw TypeError(".api.RpcGetCurrenciesRatesResponse.data: object expected");
                message.data = $root.api.RpcGetCurrenciesRatesResponseData.fromObject(object.data);
            }
            if (object.error != null) {
                if (typeof object.error !== "object")
                    throw TypeError(".api.RpcGetCurrenciesRatesResponse.error: object expected");
                message.error = $root.api.Error.fromObject(object.error);
            }
            if (object.message != null)
                message.message = String(object.message);
            return message;
        };

        /**
         * Creates a plain object from a RpcGetCurrenciesRatesResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcGetCurrenciesRatesResponse
         * @static
         * @param {api.RpcGetCurrenciesRatesResponse} message RpcGetCurrenciesRatesResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcGetCurrenciesRatesResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.code = 0;
                object.data = null;
                object.error = null;
                object.message = "";
            }
            if (message.code != null && message.hasOwnProperty("code"))
                object.code = message.code;
            if (message.data != null && message.hasOwnProperty("data"))
                object.data = $root.api.RpcGetCurrenciesRatesResponseData.toObject(message.data, options);
            if (message.error != null && message.hasOwnProperty("error"))
                object.error = $root.api.Error.toObject(message.error, options);
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = message.message;
            return object;
        };

        /**
         * Converts this RpcGetCurrenciesRatesResponse to JSON.
         * @function toJSON
         * @memberof api.RpcGetCurrenciesRatesResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcGetCurrenciesRatesResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcGetCurrenciesRatesResponse
         * @function getTypeUrl
         * @memberof api.RpcGetCurrenciesRatesResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcGetCurrenciesRatesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcGetCurrenciesRatesResponse";
        };

        return RpcGetCurrenciesRatesResponse;
    })();

    api.RpcListCurrency = (function() {

        /**
         * Properties of a RpcListCurrency.
         * @memberof api
         * @interface IRpcListCurrency
         * @property {string|null} [ticker] RpcListCurrency ticker
         * @property {number|null} [decimals] RpcListCurrency decimals
         */

        /**
         * Constructs a new RpcListCurrency.
         * @memberof api
         * @classdesc Represents a RpcListCurrency.
         * @implements IRpcListCurrency
         * @constructor
         * @param {api.IRpcListCurrency=} [properties] Properties to set
         */
        function RpcListCurrency(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcListCurrency ticker.
         * @member {string} ticker
         * @memberof api.RpcListCurrency
         * @instance
         */
        RpcListCurrency.prototype.ticker = "";

        /**
         * RpcListCurrency decimals.
         * @member {number} decimals
         * @memberof api.RpcListCurrency
         * @instance
         */
        RpcListCurrency.prototype.decimals = 0;

        /**
         * Creates a new RpcListCurrency instance using the specified properties.
         * @function create
         * @memberof api.RpcListCurrency
         * @static
         * @param {api.IRpcListCurrency=} [properties] Properties to set
         * @returns {api.RpcListCurrency} RpcListCurrency instance
         */
        RpcListCurrency.create = function create(properties) {
            return new RpcListCurrency(properties);
        };

        /**
         * Encodes the specified RpcListCurrency message. Does not implicitly {@link api.RpcListCurrency.verify|verify} messages.
         * @function encode
         * @memberof api.RpcListCurrency
         * @static
         * @param {api.RpcListCurrency} message RpcListCurrency message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcListCurrency.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.ticker != null && Object.hasOwnProperty.call(message, "ticker"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.ticker);
            if (message.decimals != null && Object.hasOwnProperty.call(message, "decimals"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.decimals);
            return writer;
        };

        /**
         * Encodes the specified RpcListCurrency message, length delimited. Does not implicitly {@link api.RpcListCurrency.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcListCurrency
         * @static
         * @param {api.RpcListCurrency} message RpcListCurrency message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcListCurrency.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcListCurrency message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcListCurrency
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcListCurrency} RpcListCurrency
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcListCurrency.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcListCurrency();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.ticker = reader.string();
                        break;
                    }
                case 2: {
                        message.decimals = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcListCurrency message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcListCurrency
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcListCurrency} RpcListCurrency
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcListCurrency.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcListCurrency message.
         * @function verify
         * @memberof api.RpcListCurrency
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcListCurrency.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.ticker != null && message.hasOwnProperty("ticker"))
                if (!$util.isString(message.ticker))
                    return "ticker: string expected";
            if (message.decimals != null && message.hasOwnProperty("decimals"))
                if (!$util.isInteger(message.decimals))
                    return "decimals: integer expected";
            return null;
        };

        /**
         * Creates a RpcListCurrency message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcListCurrency
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcListCurrency} RpcListCurrency
         */
        RpcListCurrency.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcListCurrency)
                return object;
            let message = new $root.api.RpcListCurrency();
            if (object.ticker != null)
                message.ticker = String(object.ticker);
            if (object.decimals != null)
                message.decimals = object.decimals | 0;
            return message;
        };

        /**
         * Creates a plain object from a RpcListCurrency message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcListCurrency
         * @static
         * @param {api.RpcListCurrency} message RpcListCurrency
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcListCurrency.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.ticker = "";
                object.decimals = 0;
            }
            if (message.ticker != null && message.hasOwnProperty("ticker"))
                object.ticker = message.ticker;
            if (message.decimals != null && message.hasOwnProperty("decimals"))
                object.decimals = message.decimals;
            return object;
        };

        /**
         * Converts this RpcListCurrency to JSON.
         * @function toJSON
         * @memberof api.RpcListCurrency
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcListCurrency.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcListCurrency
         * @function getTypeUrl
         * @memberof api.RpcListCurrency
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcListCurrency.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcListCurrency";
        };

        return RpcListCurrency;
    })();

    api.RpcListCurrenciesList = (function() {

        /**
         * Properties of a RpcListCurrenciesList.
         * @memberof api
         * @interface IRpcListCurrenciesList
         * @property {Array.<api.RpcListCurrency>|null} [currencies] RpcListCurrenciesList currencies
         */

        /**
         * Constructs a new RpcListCurrenciesList.
         * @memberof api
         * @classdesc Represents a RpcListCurrenciesList.
         * @implements IRpcListCurrenciesList
         * @constructor
         * @param {api.IRpcListCurrenciesList=} [properties] Properties to set
         */
        function RpcListCurrenciesList(properties) {
            this.currencies = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcListCurrenciesList currencies.
         * @member {Array.<api.RpcListCurrency>} currencies
         * @memberof api.RpcListCurrenciesList
         * @instance
         */
        RpcListCurrenciesList.prototype.currencies = $util.emptyArray;

        /**
         * Creates a new RpcListCurrenciesList instance using the specified properties.
         * @function create
         * @memberof api.RpcListCurrenciesList
         * @static
         * @param {api.IRpcListCurrenciesList=} [properties] Properties to set
         * @returns {api.RpcListCurrenciesList} RpcListCurrenciesList instance
         */
        RpcListCurrenciesList.create = function create(properties) {
            return new RpcListCurrenciesList(properties);
        };

        /**
         * Encodes the specified RpcListCurrenciesList message. Does not implicitly {@link api.RpcListCurrenciesList.verify|verify} messages.
         * @function encode
         * @memberof api.RpcListCurrenciesList
         * @static
         * @param {api.RpcListCurrenciesList} message RpcListCurrenciesList message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcListCurrenciesList.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.currencies != null && message.currencies.length)
                for (let i = 0; i < message.currencies.length; ++i)
                    $root.api.RpcListCurrency.encode(message.currencies[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified RpcListCurrenciesList message, length delimited. Does not implicitly {@link api.RpcListCurrenciesList.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcListCurrenciesList
         * @static
         * @param {api.RpcListCurrenciesList} message RpcListCurrenciesList message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcListCurrenciesList.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcListCurrenciesList message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcListCurrenciesList
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcListCurrenciesList} RpcListCurrenciesList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcListCurrenciesList.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcListCurrenciesList();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.currencies && message.currencies.length))
                            message.currencies = [];
                        message.currencies.push($root.api.RpcListCurrency.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcListCurrenciesList message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcListCurrenciesList
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcListCurrenciesList} RpcListCurrenciesList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcListCurrenciesList.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcListCurrenciesList message.
         * @function verify
         * @memberof api.RpcListCurrenciesList
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcListCurrenciesList.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.currencies != null && message.hasOwnProperty("currencies")) {
                if (!Array.isArray(message.currencies))
                    return "currencies: array expected";
                for (let i = 0; i < message.currencies.length; ++i) {
                    let error = $root.api.RpcListCurrency.verify(message.currencies[i]);
                    if (error)
                        return "currencies." + error;
                }
            }
            return null;
        };

        /**
         * Creates a RpcListCurrenciesList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcListCurrenciesList
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcListCurrenciesList} RpcListCurrenciesList
         */
        RpcListCurrenciesList.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcListCurrenciesList)
                return object;
            let message = new $root.api.RpcListCurrenciesList();
            if (object.currencies) {
                if (!Array.isArray(object.currencies))
                    throw TypeError(".api.RpcListCurrenciesList.currencies: array expected");
                message.currencies = [];
                for (let i = 0; i < object.currencies.length; ++i) {
                    if (typeof object.currencies[i] !== "object")
                        throw TypeError(".api.RpcListCurrenciesList.currencies: object expected");
                    message.currencies[i] = $root.api.RpcListCurrency.fromObject(object.currencies[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a RpcListCurrenciesList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcListCurrenciesList
         * @static
         * @param {api.RpcListCurrenciesList} message RpcListCurrenciesList
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcListCurrenciesList.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.currencies = [];
            if (message.currencies && message.currencies.length) {
                object.currencies = [];
                for (let j = 0; j < message.currencies.length; ++j)
                    object.currencies[j] = $root.api.RpcListCurrency.toObject(message.currencies[j], options);
            }
            return object;
        };

        /**
         * Converts this RpcListCurrenciesList to JSON.
         * @function toJSON
         * @memberof api.RpcListCurrenciesList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcListCurrenciesList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcListCurrenciesList
         * @function getTypeUrl
         * @memberof api.RpcListCurrenciesList
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcListCurrenciesList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcListCurrenciesList";
        };

        return RpcListCurrenciesList;
    })();

    api.RpcListCurrenciesResponse = (function() {

        /**
         * Properties of a RpcListCurrenciesResponse.
         * @memberof api
         * @interface IRpcListCurrenciesResponse
         * @property {number|null} [code] RpcListCurrenciesResponse code
         * @property {api.RpcListCurrenciesList|null} [data] RpcListCurrenciesResponse data
         * @property {Object.<string,string>|null} [error] RpcListCurrenciesResponse error
         * @property {string|null} [message] RpcListCurrenciesResponse message
         */

        /**
         * Constructs a new RpcListCurrenciesResponse.
         * @memberof api
         * @classdesc Represents a RpcListCurrenciesResponse.
         * @implements IRpcListCurrenciesResponse
         * @constructor
         * @param {api.IRpcListCurrenciesResponse=} [properties] Properties to set
         */
        function RpcListCurrenciesResponse(properties) {
            this.error = {};
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcListCurrenciesResponse code.
         * @member {number} code
         * @memberof api.RpcListCurrenciesResponse
         * @instance
         */
        RpcListCurrenciesResponse.prototype.code = 0;

        /**
         * RpcListCurrenciesResponse data.
         * @member {api.RpcListCurrenciesList|null|undefined} data
         * @memberof api.RpcListCurrenciesResponse
         * @instance
         */
        RpcListCurrenciesResponse.prototype.data = null;

        /**
         * RpcListCurrenciesResponse error.
         * @member {Object.<string,string>} error
         * @memberof api.RpcListCurrenciesResponse
         * @instance
         */
        RpcListCurrenciesResponse.prototype.error = $util.emptyObject;

        /**
         * RpcListCurrenciesResponse message.
         * @member {string} message
         * @memberof api.RpcListCurrenciesResponse
         * @instance
         */
        RpcListCurrenciesResponse.prototype.message = "";

        /**
         * Creates a new RpcListCurrenciesResponse instance using the specified properties.
         * @function create
         * @memberof api.RpcListCurrenciesResponse
         * @static
         * @param {api.IRpcListCurrenciesResponse=} [properties] Properties to set
         * @returns {api.RpcListCurrenciesResponse} RpcListCurrenciesResponse instance
         */
        RpcListCurrenciesResponse.create = function create(properties) {
            return new RpcListCurrenciesResponse(properties);
        };

        /**
         * Encodes the specified RpcListCurrenciesResponse message. Does not implicitly {@link api.RpcListCurrenciesResponse.verify|verify} messages.
         * @function encode
         * @memberof api.RpcListCurrenciesResponse
         * @static
         * @param {api.RpcListCurrenciesResponse} message RpcListCurrenciesResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcListCurrenciesResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.code);
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                $root.api.RpcListCurrenciesList.encode(message.data, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                for (let keys = Object.keys(message.error), i = 0; i < keys.length; ++i)
                    writer.uint32(/* id 3, wireType 2 =*/26).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.error[keys[i]]).ldelim();
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.message);
            return writer;
        };

        /**
         * Encodes the specified RpcListCurrenciesResponse message, length delimited. Does not implicitly {@link api.RpcListCurrenciesResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcListCurrenciesResponse
         * @static
         * @param {api.RpcListCurrenciesResponse} message RpcListCurrenciesResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcListCurrenciesResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcListCurrenciesResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcListCurrenciesResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcListCurrenciesResponse} RpcListCurrenciesResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcListCurrenciesResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcListCurrenciesResponse(), key, value;
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.code = reader.int32();
                        break;
                    }
                case 2: {
                        message.data = $root.api.RpcListCurrenciesList.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        if (message.error === $util.emptyObject)
                            message.error = {};
                        let end2 = reader.uint32() + reader.pos;
                        key = "";
                        value = "";
                        while (reader.pos < end2) {
                            let tag2 = reader.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                key = reader.string();
                                break;
                            case 2:
                                value = reader.string();
                                break;
                            default:
                                reader.skipType(tag2 & 7);
                                break;
                            }
                        }
                        message.error[key] = value;
                        break;
                    }
                case 4: {
                        message.message = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcListCurrenciesResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcListCurrenciesResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcListCurrenciesResponse} RpcListCurrenciesResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcListCurrenciesResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcListCurrenciesResponse message.
         * @function verify
         * @memberof api.RpcListCurrenciesResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcListCurrenciesResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.code != null && message.hasOwnProperty("code"))
                if (!$util.isInteger(message.code))
                    return "code: integer expected";
            if (message.data != null && message.hasOwnProperty("data")) {
                let error = $root.api.RpcListCurrenciesList.verify(message.data);
                if (error)
                    return "data." + error;
            }
            if (message.error != null && message.hasOwnProperty("error")) {
                if (!$util.isObject(message.error))
                    return "error: object expected";
                let key = Object.keys(message.error);
                for (let i = 0; i < key.length; ++i)
                    if (!$util.isString(message.error[key[i]]))
                        return "error: string{k:string} expected";
            }
            if (message.message != null && message.hasOwnProperty("message"))
                if (!$util.isString(message.message))
                    return "message: string expected";
            return null;
        };

        /**
         * Creates a RpcListCurrenciesResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcListCurrenciesResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcListCurrenciesResponse} RpcListCurrenciesResponse
         */
        RpcListCurrenciesResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcListCurrenciesResponse)
                return object;
            let message = new $root.api.RpcListCurrenciesResponse();
            if (object.code != null)
                message.code = object.code | 0;
            if (object.data != null) {
                if (typeof object.data !== "object")
                    throw TypeError(".api.RpcListCurrenciesResponse.data: object expected");
                message.data = $root.api.RpcListCurrenciesList.fromObject(object.data);
            }
            if (object.error) {
                if (typeof object.error !== "object")
                    throw TypeError(".api.RpcListCurrenciesResponse.error: object expected");
                message.error = {};
                for (let keys = Object.keys(object.error), i = 0; i < keys.length; ++i)
                    message.error[keys[i]] = String(object.error[keys[i]]);
            }
            if (object.message != null)
                message.message = String(object.message);
            return message;
        };

        /**
         * Creates a plain object from a RpcListCurrenciesResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcListCurrenciesResponse
         * @static
         * @param {api.RpcListCurrenciesResponse} message RpcListCurrenciesResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcListCurrenciesResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.objects || options.defaults)
                object.error = {};
            if (options.defaults) {
                object.code = 0;
                object.data = null;
                object.message = "";
            }
            if (message.code != null && message.hasOwnProperty("code"))
                object.code = message.code;
            if (message.data != null && message.hasOwnProperty("data"))
                object.data = $root.api.RpcListCurrenciesList.toObject(message.data, options);
            let keys2;
            if (message.error && (keys2 = Object.keys(message.error)).length) {
                object.error = {};
                for (let j = 0; j < keys2.length; ++j)
                    object.error[keys2[j]] = message.error[keys2[j]];
            }
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = message.message;
            return object;
        };

        /**
         * Converts this RpcListCurrenciesResponse to JSON.
         * @function toJSON
         * @memberof api.RpcListCurrenciesResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcListCurrenciesResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcListCurrenciesResponse
         * @function getTypeUrl
         * @memberof api.RpcListCurrenciesResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcListCurrenciesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcListCurrenciesResponse";
        };

        return RpcListCurrenciesResponse;
    })();

    api.RpcGetInviteesRequest = (function() {

        /**
         * Properties of a RpcGetInviteesRequest.
         * @memberof api
         * @interface IRpcGetInviteesRequest
         * @property {string|null} [query] RpcGetInviteesRequest query
         */

        /**
         * Constructs a new RpcGetInviteesRequest.
         * @memberof api
         * @classdesc Represents a RpcGetInviteesRequest.
         * @implements IRpcGetInviteesRequest
         * @constructor
         * @param {api.IRpcGetInviteesRequest=} [properties] Properties to set
         */
        function RpcGetInviteesRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcGetInviteesRequest query.
         * @member {string} query
         * @memberof api.RpcGetInviteesRequest
         * @instance
         */
        RpcGetInviteesRequest.prototype.query = "";

        /**
         * Creates a new RpcGetInviteesRequest instance using the specified properties.
         * @function create
         * @memberof api.RpcGetInviteesRequest
         * @static
         * @param {api.IRpcGetInviteesRequest=} [properties] Properties to set
         * @returns {api.RpcGetInviteesRequest} RpcGetInviteesRequest instance
         */
        RpcGetInviteesRequest.create = function create(properties) {
            return new RpcGetInviteesRequest(properties);
        };

        /**
         * Encodes the specified RpcGetInviteesRequest message. Does not implicitly {@link api.RpcGetInviteesRequest.verify|verify} messages.
         * @function encode
         * @memberof api.RpcGetInviteesRequest
         * @static
         * @param {api.RpcGetInviteesRequest} message RpcGetInviteesRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGetInviteesRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.query != null && Object.hasOwnProperty.call(message, "query"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.query);
            return writer;
        };

        /**
         * Encodes the specified RpcGetInviteesRequest message, length delimited. Does not implicitly {@link api.RpcGetInviteesRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcGetInviteesRequest
         * @static
         * @param {api.RpcGetInviteesRequest} message RpcGetInviteesRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGetInviteesRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcGetInviteesRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcGetInviteesRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcGetInviteesRequest} RpcGetInviteesRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGetInviteesRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcGetInviteesRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.query = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcGetInviteesRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcGetInviteesRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcGetInviteesRequest} RpcGetInviteesRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGetInviteesRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcGetInviteesRequest message.
         * @function verify
         * @memberof api.RpcGetInviteesRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcGetInviteesRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.query != null && message.hasOwnProperty("query"))
                if (!$util.isString(message.query))
                    return "query: string expected";
            return null;
        };

        /**
         * Creates a RpcGetInviteesRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcGetInviteesRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcGetInviteesRequest} RpcGetInviteesRequest
         */
        RpcGetInviteesRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcGetInviteesRequest)
                return object;
            let message = new $root.api.RpcGetInviteesRequest();
            if (object.query != null)
                message.query = String(object.query);
            return message;
        };

        /**
         * Creates a plain object from a RpcGetInviteesRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcGetInviteesRequest
         * @static
         * @param {api.RpcGetInviteesRequest} message RpcGetInviteesRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcGetInviteesRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.query = "";
            if (message.query != null && message.hasOwnProperty("query"))
                object.query = message.query;
            return object;
        };

        /**
         * Converts this RpcGetInviteesRequest to JSON.
         * @function toJSON
         * @memberof api.RpcGetInviteesRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcGetInviteesRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcGetInviteesRequest
         * @function getTypeUrl
         * @memberof api.RpcGetInviteesRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcGetInviteesRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcGetInviteesRequest";
        };

        return RpcGetInviteesRequest;
    })();

    api.RpcGetInviteesResponseClientState = (function() {

        /**
         * Properties of a RpcGetInviteesResponseClientState.
         * @memberof api
         * @interface IRpcGetInviteesResponseClientState
         * @property {number|null} [id] RpcGetInviteesResponseClientState id
         * @property {number|null} [loss_operations_count] RpcGetInviteesResponseClientState loss_operations_count
         * @property {number|null} [points] RpcGetInviteesResponseClientState points
         * @property {number|null} [profit_operations_count] RpcGetInviteesResponseClientState profit_operations_count
         */

        /**
         * Constructs a new RpcGetInviteesResponseClientState.
         * @memberof api
         * @classdesc Represents a RpcGetInviteesResponseClientState.
         * @implements IRpcGetInviteesResponseClientState
         * @constructor
         * @param {api.IRpcGetInviteesResponseClientState=} [properties] Properties to set
         */
        function RpcGetInviteesResponseClientState(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcGetInviteesResponseClientState id.
         * @member {number} id
         * @memberof api.RpcGetInviteesResponseClientState
         * @instance
         */
        RpcGetInviteesResponseClientState.prototype.id = 0;

        /**
         * RpcGetInviteesResponseClientState loss_operations_count.
         * @member {number} loss_operations_count
         * @memberof api.RpcGetInviteesResponseClientState
         * @instance
         */
        RpcGetInviteesResponseClientState.prototype.loss_operations_count = 0;

        /**
         * RpcGetInviteesResponseClientState points.
         * @member {number} points
         * @memberof api.RpcGetInviteesResponseClientState
         * @instance
         */
        RpcGetInviteesResponseClientState.prototype.points = 0;

        /**
         * RpcGetInviteesResponseClientState profit_operations_count.
         * @member {number} profit_operations_count
         * @memberof api.RpcGetInviteesResponseClientState
         * @instance
         */
        RpcGetInviteesResponseClientState.prototype.profit_operations_count = 0;

        /**
         * Creates a new RpcGetInviteesResponseClientState instance using the specified properties.
         * @function create
         * @memberof api.RpcGetInviteesResponseClientState
         * @static
         * @param {api.IRpcGetInviteesResponseClientState=} [properties] Properties to set
         * @returns {api.RpcGetInviteesResponseClientState} RpcGetInviteesResponseClientState instance
         */
        RpcGetInviteesResponseClientState.create = function create(properties) {
            return new RpcGetInviteesResponseClientState(properties);
        };

        /**
         * Encodes the specified RpcGetInviteesResponseClientState message. Does not implicitly {@link api.RpcGetInviteesResponseClientState.verify|verify} messages.
         * @function encode
         * @memberof api.RpcGetInviteesResponseClientState
         * @static
         * @param {api.RpcGetInviteesResponseClientState} message RpcGetInviteesResponseClientState message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGetInviteesResponseClientState.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
            if (message.loss_operations_count != null && Object.hasOwnProperty.call(message, "loss_operations_count"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.loss_operations_count);
            if (message.points != null && Object.hasOwnProperty.call(message, "points"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.points);
            if (message.profit_operations_count != null && Object.hasOwnProperty.call(message, "profit_operations_count"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.profit_operations_count);
            return writer;
        };

        /**
         * Encodes the specified RpcGetInviteesResponseClientState message, length delimited. Does not implicitly {@link api.RpcGetInviteesResponseClientState.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcGetInviteesResponseClientState
         * @static
         * @param {api.RpcGetInviteesResponseClientState} message RpcGetInviteesResponseClientState message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGetInviteesResponseClientState.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcGetInviteesResponseClientState message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcGetInviteesResponseClientState
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcGetInviteesResponseClientState} RpcGetInviteesResponseClientState
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGetInviteesResponseClientState.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcGetInviteesResponseClientState();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.int32();
                        break;
                    }
                case 2: {
                        message.loss_operations_count = reader.int32();
                        break;
                    }
                case 3: {
                        message.points = reader.int32();
                        break;
                    }
                case 4: {
                        message.profit_operations_count = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcGetInviteesResponseClientState message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcGetInviteesResponseClientState
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcGetInviteesResponseClientState} RpcGetInviteesResponseClientState
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGetInviteesResponseClientState.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcGetInviteesResponseClientState message.
         * @function verify
         * @memberof api.RpcGetInviteesResponseClientState
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcGetInviteesResponseClientState.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isInteger(message.id))
                    return "id: integer expected";
            if (message.loss_operations_count != null && message.hasOwnProperty("loss_operations_count"))
                if (!$util.isInteger(message.loss_operations_count))
                    return "loss_operations_count: integer expected";
            if (message.points != null && message.hasOwnProperty("points"))
                if (!$util.isInteger(message.points))
                    return "points: integer expected";
            if (message.profit_operations_count != null && message.hasOwnProperty("profit_operations_count"))
                if (!$util.isInteger(message.profit_operations_count))
                    return "profit_operations_count: integer expected";
            return null;
        };

        /**
         * Creates a RpcGetInviteesResponseClientState message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcGetInviteesResponseClientState
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcGetInviteesResponseClientState} RpcGetInviteesResponseClientState
         */
        RpcGetInviteesResponseClientState.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcGetInviteesResponseClientState)
                return object;
            let message = new $root.api.RpcGetInviteesResponseClientState();
            if (object.id != null)
                message.id = object.id | 0;
            if (object.loss_operations_count != null)
                message.loss_operations_count = object.loss_operations_count | 0;
            if (object.points != null)
                message.points = object.points | 0;
            if (object.profit_operations_count != null)
                message.profit_operations_count = object.profit_operations_count | 0;
            return message;
        };

        /**
         * Creates a plain object from a RpcGetInviteesResponseClientState message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcGetInviteesResponseClientState
         * @static
         * @param {api.RpcGetInviteesResponseClientState} message RpcGetInviteesResponseClientState
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcGetInviteesResponseClientState.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.id = 0;
                object.loss_operations_count = 0;
                object.points = 0;
                object.profit_operations_count = 0;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.loss_operations_count != null && message.hasOwnProperty("loss_operations_count"))
                object.loss_operations_count = message.loss_operations_count;
            if (message.points != null && message.hasOwnProperty("points"))
                object.points = message.points;
            if (message.profit_operations_count != null && message.hasOwnProperty("profit_operations_count"))
                object.profit_operations_count = message.profit_operations_count;
            return object;
        };

        /**
         * Converts this RpcGetInviteesResponseClientState to JSON.
         * @function toJSON
         * @memberof api.RpcGetInviteesResponseClientState
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcGetInviteesResponseClientState.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcGetInviteesResponseClientState
         * @function getTypeUrl
         * @memberof api.RpcGetInviteesResponseClientState
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcGetInviteesResponseClientState.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcGetInviteesResponseClientState";
        };

        return RpcGetInviteesResponseClientState;
    })();

    api.RpcGetInviteesResponseInvitee = (function() {

        /**
         * Properties of a RpcGetInviteesResponseInvitee.
         * @memberof api
         * @interface IRpcGetInviteesResponseInvitee
         * @property {string|null} [avatar] RpcGetInviteesResponseInvitee avatar
         * @property {api.RpcGetInviteesResponseClientState|null} [client_state] RpcGetInviteesResponseInvitee client_state
         * @property {string|null} [created_at] RpcGetInviteesResponseInvitee created_at
         * @property {string|null} [firstname] RpcGetInviteesResponseInvitee firstname
         * @property {api.RpcGroupCreateResponseDataGroup|null} [group] RpcGetInviteesResponseInvitee group
         * @property {number|null} [id] RpcGetInviteesResponseInvitee id
         * @property {string|null} [lastname] RpcGetInviteesResponseInvitee lastname
         * @property {number|null} [ref_line1_count] RpcGetInviteesResponseInvitee ref_line1_count
         * @property {number|null} [ref_line2_count] RpcGetInviteesResponseInvitee ref_line2_count
         * @property {number|null} [ref_line3_count] RpcGetInviteesResponseInvitee ref_line3_count
         * @property {string|null} [ref_link] RpcGetInviteesResponseInvitee ref_link
         * @property {string|null} [uid] RpcGetInviteesResponseInvitee uid
         * @property {string|null} [username] RpcGetInviteesResponseInvitee username
         */

        /**
         * Constructs a new RpcGetInviteesResponseInvitee.
         * @memberof api
         * @classdesc Represents a RpcGetInviteesResponseInvitee.
         * @implements IRpcGetInviteesResponseInvitee
         * @constructor
         * @param {api.IRpcGetInviteesResponseInvitee=} [properties] Properties to set
         */
        function RpcGetInviteesResponseInvitee(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcGetInviteesResponseInvitee avatar.
         * @member {string} avatar
         * @memberof api.RpcGetInviteesResponseInvitee
         * @instance
         */
        RpcGetInviteesResponseInvitee.prototype.avatar = "";

        /**
         * RpcGetInviteesResponseInvitee client_state.
         * @member {api.RpcGetInviteesResponseClientState|null|undefined} client_state
         * @memberof api.RpcGetInviteesResponseInvitee
         * @instance
         */
        RpcGetInviteesResponseInvitee.prototype.client_state = null;

        /**
         * RpcGetInviteesResponseInvitee created_at.
         * @member {string} created_at
         * @memberof api.RpcGetInviteesResponseInvitee
         * @instance
         */
        RpcGetInviteesResponseInvitee.prototype.created_at = "";

        /**
         * RpcGetInviteesResponseInvitee firstname.
         * @member {string} firstname
         * @memberof api.RpcGetInviteesResponseInvitee
         * @instance
         */
        RpcGetInviteesResponseInvitee.prototype.firstname = "";

        /**
         * RpcGetInviteesResponseInvitee group.
         * @member {api.RpcGroupCreateResponseDataGroup|null|undefined} group
         * @memberof api.RpcGetInviteesResponseInvitee
         * @instance
         */
        RpcGetInviteesResponseInvitee.prototype.group = null;

        /**
         * RpcGetInviteesResponseInvitee id.
         * @member {number} id
         * @memberof api.RpcGetInviteesResponseInvitee
         * @instance
         */
        RpcGetInviteesResponseInvitee.prototype.id = 0;

        /**
         * RpcGetInviteesResponseInvitee lastname.
         * @member {string} lastname
         * @memberof api.RpcGetInviteesResponseInvitee
         * @instance
         */
        RpcGetInviteesResponseInvitee.prototype.lastname = "";

        /**
         * RpcGetInviteesResponseInvitee ref_line1_count.
         * @member {number} ref_line1_count
         * @memberof api.RpcGetInviteesResponseInvitee
         * @instance
         */
        RpcGetInviteesResponseInvitee.prototype.ref_line1_count = 0;

        /**
         * RpcGetInviteesResponseInvitee ref_line2_count.
         * @member {number} ref_line2_count
         * @memberof api.RpcGetInviteesResponseInvitee
         * @instance
         */
        RpcGetInviteesResponseInvitee.prototype.ref_line2_count = 0;

        /**
         * RpcGetInviteesResponseInvitee ref_line3_count.
         * @member {number} ref_line3_count
         * @memberof api.RpcGetInviteesResponseInvitee
         * @instance
         */
        RpcGetInviteesResponseInvitee.prototype.ref_line3_count = 0;

        /**
         * RpcGetInviteesResponseInvitee ref_link.
         * @member {string} ref_link
         * @memberof api.RpcGetInviteesResponseInvitee
         * @instance
         */
        RpcGetInviteesResponseInvitee.prototype.ref_link = "";

        /**
         * RpcGetInviteesResponseInvitee uid.
         * @member {string} uid
         * @memberof api.RpcGetInviteesResponseInvitee
         * @instance
         */
        RpcGetInviteesResponseInvitee.prototype.uid = "";

        /**
         * RpcGetInviteesResponseInvitee username.
         * @member {string} username
         * @memberof api.RpcGetInviteesResponseInvitee
         * @instance
         */
        RpcGetInviteesResponseInvitee.prototype.username = "";

        /**
         * Creates a new RpcGetInviteesResponseInvitee instance using the specified properties.
         * @function create
         * @memberof api.RpcGetInviteesResponseInvitee
         * @static
         * @param {api.IRpcGetInviteesResponseInvitee=} [properties] Properties to set
         * @returns {api.RpcGetInviteesResponseInvitee} RpcGetInviteesResponseInvitee instance
         */
        RpcGetInviteesResponseInvitee.create = function create(properties) {
            return new RpcGetInviteesResponseInvitee(properties);
        };

        /**
         * Encodes the specified RpcGetInviteesResponseInvitee message. Does not implicitly {@link api.RpcGetInviteesResponseInvitee.verify|verify} messages.
         * @function encode
         * @memberof api.RpcGetInviteesResponseInvitee
         * @static
         * @param {api.RpcGetInviteesResponseInvitee} message RpcGetInviteesResponseInvitee message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGetInviteesResponseInvitee.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.avatar != null && Object.hasOwnProperty.call(message, "avatar"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.avatar);
            if (message.client_state != null && Object.hasOwnProperty.call(message, "client_state"))
                $root.api.RpcGetInviteesResponseClientState.encode(message.client_state, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.created_at != null && Object.hasOwnProperty.call(message, "created_at"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.created_at);
            if (message.firstname != null && Object.hasOwnProperty.call(message, "firstname"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.firstname);
            if (message.group != null && Object.hasOwnProperty.call(message, "group"))
                $root.api.RpcGroupCreateResponseDataGroup.encode(message.group, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.id);
            if (message.lastname != null && Object.hasOwnProperty.call(message, "lastname"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.lastname);
            if (message.ref_line1_count != null && Object.hasOwnProperty.call(message, "ref_line1_count"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.ref_line1_count);
            if (message.ref_line2_count != null && Object.hasOwnProperty.call(message, "ref_line2_count"))
                writer.uint32(/* id 9, wireType 0 =*/72).int32(message.ref_line2_count);
            if (message.ref_line3_count != null && Object.hasOwnProperty.call(message, "ref_line3_count"))
                writer.uint32(/* id 10, wireType 0 =*/80).int32(message.ref_line3_count);
            if (message.ref_link != null && Object.hasOwnProperty.call(message, "ref_link"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.ref_link);
            if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
                writer.uint32(/* id 12, wireType 2 =*/98).string(message.uid);
            if (message.username != null && Object.hasOwnProperty.call(message, "username"))
                writer.uint32(/* id 13, wireType 2 =*/106).string(message.username);
            return writer;
        };

        /**
         * Encodes the specified RpcGetInviteesResponseInvitee message, length delimited. Does not implicitly {@link api.RpcGetInviteesResponseInvitee.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcGetInviteesResponseInvitee
         * @static
         * @param {api.RpcGetInviteesResponseInvitee} message RpcGetInviteesResponseInvitee message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGetInviteesResponseInvitee.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcGetInviteesResponseInvitee message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcGetInviteesResponseInvitee
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcGetInviteesResponseInvitee} RpcGetInviteesResponseInvitee
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGetInviteesResponseInvitee.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcGetInviteesResponseInvitee();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.avatar = reader.string();
                        break;
                    }
                case 2: {
                        message.client_state = $root.api.RpcGetInviteesResponseClientState.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.created_at = reader.string();
                        break;
                    }
                case 4: {
                        message.firstname = reader.string();
                        break;
                    }
                case 5: {
                        message.group = $root.api.RpcGroupCreateResponseDataGroup.decode(reader, reader.uint32());
                        break;
                    }
                case 6: {
                        message.id = reader.int32();
                        break;
                    }
                case 7: {
                        message.lastname = reader.string();
                        break;
                    }
                case 8: {
                        message.ref_line1_count = reader.int32();
                        break;
                    }
                case 9: {
                        message.ref_line2_count = reader.int32();
                        break;
                    }
                case 10: {
                        message.ref_line3_count = reader.int32();
                        break;
                    }
                case 11: {
                        message.ref_link = reader.string();
                        break;
                    }
                case 12: {
                        message.uid = reader.string();
                        break;
                    }
                case 13: {
                        message.username = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcGetInviteesResponseInvitee message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcGetInviteesResponseInvitee
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcGetInviteesResponseInvitee} RpcGetInviteesResponseInvitee
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGetInviteesResponseInvitee.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcGetInviteesResponseInvitee message.
         * @function verify
         * @memberof api.RpcGetInviteesResponseInvitee
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcGetInviteesResponseInvitee.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.avatar != null && message.hasOwnProperty("avatar"))
                if (!$util.isString(message.avatar))
                    return "avatar: string expected";
            if (message.client_state != null && message.hasOwnProperty("client_state")) {
                let error = $root.api.RpcGetInviteesResponseClientState.verify(message.client_state);
                if (error)
                    return "client_state." + error;
            }
            if (message.created_at != null && message.hasOwnProperty("created_at"))
                if (!$util.isString(message.created_at))
                    return "created_at: string expected";
            if (message.firstname != null && message.hasOwnProperty("firstname"))
                if (!$util.isString(message.firstname))
                    return "firstname: string expected";
            if (message.group != null && message.hasOwnProperty("group")) {
                let error = $root.api.RpcGroupCreateResponseDataGroup.verify(message.group);
                if (error)
                    return "group." + error;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isInteger(message.id))
                    return "id: integer expected";
            if (message.lastname != null && message.hasOwnProperty("lastname"))
                if (!$util.isString(message.lastname))
                    return "lastname: string expected";
            if (message.ref_line1_count != null && message.hasOwnProperty("ref_line1_count"))
                if (!$util.isInteger(message.ref_line1_count))
                    return "ref_line1_count: integer expected";
            if (message.ref_line2_count != null && message.hasOwnProperty("ref_line2_count"))
                if (!$util.isInteger(message.ref_line2_count))
                    return "ref_line2_count: integer expected";
            if (message.ref_line3_count != null && message.hasOwnProperty("ref_line3_count"))
                if (!$util.isInteger(message.ref_line3_count))
                    return "ref_line3_count: integer expected";
            if (message.ref_link != null && message.hasOwnProperty("ref_link"))
                if (!$util.isString(message.ref_link))
                    return "ref_link: string expected";
            if (message.uid != null && message.hasOwnProperty("uid"))
                if (!$util.isString(message.uid))
                    return "uid: string expected";
            if (message.username != null && message.hasOwnProperty("username"))
                if (!$util.isString(message.username))
                    return "username: string expected";
            return null;
        };

        /**
         * Creates a RpcGetInviteesResponseInvitee message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcGetInviteesResponseInvitee
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcGetInviteesResponseInvitee} RpcGetInviteesResponseInvitee
         */
        RpcGetInviteesResponseInvitee.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcGetInviteesResponseInvitee)
                return object;
            let message = new $root.api.RpcGetInviteesResponseInvitee();
            if (object.avatar != null)
                message.avatar = String(object.avatar);
            if (object.client_state != null) {
                if (typeof object.client_state !== "object")
                    throw TypeError(".api.RpcGetInviteesResponseInvitee.client_state: object expected");
                message.client_state = $root.api.RpcGetInviteesResponseClientState.fromObject(object.client_state);
            }
            if (object.created_at != null)
                message.created_at = String(object.created_at);
            if (object.firstname != null)
                message.firstname = String(object.firstname);
            if (object.group != null) {
                if (typeof object.group !== "object")
                    throw TypeError(".api.RpcGetInviteesResponseInvitee.group: object expected");
                message.group = $root.api.RpcGroupCreateResponseDataGroup.fromObject(object.group);
            }
            if (object.id != null)
                message.id = object.id | 0;
            if (object.lastname != null)
                message.lastname = String(object.lastname);
            if (object.ref_line1_count != null)
                message.ref_line1_count = object.ref_line1_count | 0;
            if (object.ref_line2_count != null)
                message.ref_line2_count = object.ref_line2_count | 0;
            if (object.ref_line3_count != null)
                message.ref_line3_count = object.ref_line3_count | 0;
            if (object.ref_link != null)
                message.ref_link = String(object.ref_link);
            if (object.uid != null)
                message.uid = String(object.uid);
            if (object.username != null)
                message.username = String(object.username);
            return message;
        };

        /**
         * Creates a plain object from a RpcGetInviteesResponseInvitee message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcGetInviteesResponseInvitee
         * @static
         * @param {api.RpcGetInviteesResponseInvitee} message RpcGetInviteesResponseInvitee
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcGetInviteesResponseInvitee.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.avatar = "";
                object.client_state = null;
                object.created_at = "";
                object.firstname = "";
                object.group = null;
                object.id = 0;
                object.lastname = "";
                object.ref_line1_count = 0;
                object.ref_line2_count = 0;
                object.ref_line3_count = 0;
                object.ref_link = "";
                object.uid = "";
                object.username = "";
            }
            if (message.avatar != null && message.hasOwnProperty("avatar"))
                object.avatar = message.avatar;
            if (message.client_state != null && message.hasOwnProperty("client_state"))
                object.client_state = $root.api.RpcGetInviteesResponseClientState.toObject(message.client_state, options);
            if (message.created_at != null && message.hasOwnProperty("created_at"))
                object.created_at = message.created_at;
            if (message.firstname != null && message.hasOwnProperty("firstname"))
                object.firstname = message.firstname;
            if (message.group != null && message.hasOwnProperty("group"))
                object.group = $root.api.RpcGroupCreateResponseDataGroup.toObject(message.group, options);
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.lastname != null && message.hasOwnProperty("lastname"))
                object.lastname = message.lastname;
            if (message.ref_line1_count != null && message.hasOwnProperty("ref_line1_count"))
                object.ref_line1_count = message.ref_line1_count;
            if (message.ref_line2_count != null && message.hasOwnProperty("ref_line2_count"))
                object.ref_line2_count = message.ref_line2_count;
            if (message.ref_line3_count != null && message.hasOwnProperty("ref_line3_count"))
                object.ref_line3_count = message.ref_line3_count;
            if (message.ref_link != null && message.hasOwnProperty("ref_link"))
                object.ref_link = message.ref_link;
            if (message.uid != null && message.hasOwnProperty("uid"))
                object.uid = message.uid;
            if (message.username != null && message.hasOwnProperty("username"))
                object.username = message.username;
            return object;
        };

        /**
         * Converts this RpcGetInviteesResponseInvitee to JSON.
         * @function toJSON
         * @memberof api.RpcGetInviteesResponseInvitee
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcGetInviteesResponseInvitee.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcGetInviteesResponseInvitee
         * @function getTypeUrl
         * @memberof api.RpcGetInviteesResponseInvitee
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcGetInviteesResponseInvitee.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcGetInviteesResponseInvitee";
        };

        return RpcGetInviteesResponseInvitee;
    })();

    api.RpcGetInviteesResponseMeta = (function() {

        /**
         * Properties of a RpcGetInviteesResponseMeta.
         * @memberof api
         * @interface IRpcGetInviteesResponseMeta
         * @property {number|null} [count] RpcGetInviteesResponseMeta count
         * @property {number|null} [limit] RpcGetInviteesResponseMeta limit
         * @property {number|null} [offset] RpcGetInviteesResponseMeta offset
         * @property {string|null} [order] RpcGetInviteesResponseMeta order
         */

        /**
         * Constructs a new RpcGetInviteesResponseMeta.
         * @memberof api
         * @classdesc Represents a RpcGetInviteesResponseMeta.
         * @implements IRpcGetInviteesResponseMeta
         * @constructor
         * @param {api.IRpcGetInviteesResponseMeta=} [properties] Properties to set
         */
        function RpcGetInviteesResponseMeta(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcGetInviteesResponseMeta count.
         * @member {number} count
         * @memberof api.RpcGetInviteesResponseMeta
         * @instance
         */
        RpcGetInviteesResponseMeta.prototype.count = 0;

        /**
         * RpcGetInviteesResponseMeta limit.
         * @member {number} limit
         * @memberof api.RpcGetInviteesResponseMeta
         * @instance
         */
        RpcGetInviteesResponseMeta.prototype.limit = 0;

        /**
         * RpcGetInviteesResponseMeta offset.
         * @member {number} offset
         * @memberof api.RpcGetInviteesResponseMeta
         * @instance
         */
        RpcGetInviteesResponseMeta.prototype.offset = 0;

        /**
         * RpcGetInviteesResponseMeta order.
         * @member {string} order
         * @memberof api.RpcGetInviteesResponseMeta
         * @instance
         */
        RpcGetInviteesResponseMeta.prototype.order = "";

        /**
         * Creates a new RpcGetInviteesResponseMeta instance using the specified properties.
         * @function create
         * @memberof api.RpcGetInviteesResponseMeta
         * @static
         * @param {api.IRpcGetInviteesResponseMeta=} [properties] Properties to set
         * @returns {api.RpcGetInviteesResponseMeta} RpcGetInviteesResponseMeta instance
         */
        RpcGetInviteesResponseMeta.create = function create(properties) {
            return new RpcGetInviteesResponseMeta(properties);
        };

        /**
         * Encodes the specified RpcGetInviteesResponseMeta message. Does not implicitly {@link api.RpcGetInviteesResponseMeta.verify|verify} messages.
         * @function encode
         * @memberof api.RpcGetInviteesResponseMeta
         * @static
         * @param {api.RpcGetInviteesResponseMeta} message RpcGetInviteesResponseMeta message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGetInviteesResponseMeta.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.count);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.limit);
            if (message.offset != null && Object.hasOwnProperty.call(message, "offset"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.offset);
            if (message.order != null && Object.hasOwnProperty.call(message, "order"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.order);
            return writer;
        };

        /**
         * Encodes the specified RpcGetInviteesResponseMeta message, length delimited. Does not implicitly {@link api.RpcGetInviteesResponseMeta.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcGetInviteesResponseMeta
         * @static
         * @param {api.RpcGetInviteesResponseMeta} message RpcGetInviteesResponseMeta message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGetInviteesResponseMeta.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcGetInviteesResponseMeta message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcGetInviteesResponseMeta
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcGetInviteesResponseMeta} RpcGetInviteesResponseMeta
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGetInviteesResponseMeta.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcGetInviteesResponseMeta();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.count = reader.int32();
                        break;
                    }
                case 2: {
                        message.limit = reader.int32();
                        break;
                    }
                case 3: {
                        message.offset = reader.int32();
                        break;
                    }
                case 4: {
                        message.order = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcGetInviteesResponseMeta message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcGetInviteesResponseMeta
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcGetInviteesResponseMeta} RpcGetInviteesResponseMeta
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGetInviteesResponseMeta.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcGetInviteesResponseMeta message.
         * @function verify
         * @memberof api.RpcGetInviteesResponseMeta
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcGetInviteesResponseMeta.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.count != null && message.hasOwnProperty("count"))
                if (!$util.isInteger(message.count))
                    return "count: integer expected";
            if (message.limit != null && message.hasOwnProperty("limit"))
                if (!$util.isInteger(message.limit))
                    return "limit: integer expected";
            if (message.offset != null && message.hasOwnProperty("offset"))
                if (!$util.isInteger(message.offset))
                    return "offset: integer expected";
            if (message.order != null && message.hasOwnProperty("order"))
                if (!$util.isString(message.order))
                    return "order: string expected";
            return null;
        };

        /**
         * Creates a RpcGetInviteesResponseMeta message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcGetInviteesResponseMeta
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcGetInviteesResponseMeta} RpcGetInviteesResponseMeta
         */
        RpcGetInviteesResponseMeta.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcGetInviteesResponseMeta)
                return object;
            let message = new $root.api.RpcGetInviteesResponseMeta();
            if (object.count != null)
                message.count = object.count | 0;
            if (object.limit != null)
                message.limit = object.limit | 0;
            if (object.offset != null)
                message.offset = object.offset | 0;
            if (object.order != null)
                message.order = String(object.order);
            return message;
        };

        /**
         * Creates a plain object from a RpcGetInviteesResponseMeta message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcGetInviteesResponseMeta
         * @static
         * @param {api.RpcGetInviteesResponseMeta} message RpcGetInviteesResponseMeta
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcGetInviteesResponseMeta.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.count = 0;
                object.limit = 0;
                object.offset = 0;
                object.order = "";
            }
            if (message.count != null && message.hasOwnProperty("count"))
                object.count = message.count;
            if (message.limit != null && message.hasOwnProperty("limit"))
                object.limit = message.limit;
            if (message.offset != null && message.hasOwnProperty("offset"))
                object.offset = message.offset;
            if (message.order != null && message.hasOwnProperty("order"))
                object.order = message.order;
            return object;
        };

        /**
         * Converts this RpcGetInviteesResponseMeta to JSON.
         * @function toJSON
         * @memberof api.RpcGetInviteesResponseMeta
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcGetInviteesResponseMeta.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcGetInviteesResponseMeta
         * @function getTypeUrl
         * @memberof api.RpcGetInviteesResponseMeta
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcGetInviteesResponseMeta.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcGetInviteesResponseMeta";
        };

        return RpcGetInviteesResponseMeta;
    })();

    api.RpcGetInviteesResponseData = (function() {

        /**
         * Properties of a RpcGetInviteesResponseData.
         * @memberof api
         * @interface IRpcGetInviteesResponseData
         * @property {Array.<api.RpcGetInviteesResponseInvitee>|null} [invitees] RpcGetInviteesResponseData invitees
         * @property {api.RpcGetInviteesResponseMeta|null} [meta] RpcGetInviteesResponseData meta
         */

        /**
         * Constructs a new RpcGetInviteesResponseData.
         * @memberof api
         * @classdesc Represents a RpcGetInviteesResponseData.
         * @implements IRpcGetInviteesResponseData
         * @constructor
         * @param {api.IRpcGetInviteesResponseData=} [properties] Properties to set
         */
        function RpcGetInviteesResponseData(properties) {
            this.invitees = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcGetInviteesResponseData invitees.
         * @member {Array.<api.RpcGetInviteesResponseInvitee>} invitees
         * @memberof api.RpcGetInviteesResponseData
         * @instance
         */
        RpcGetInviteesResponseData.prototype.invitees = $util.emptyArray;

        /**
         * RpcGetInviteesResponseData meta.
         * @member {api.RpcGetInviteesResponseMeta|null|undefined} meta
         * @memberof api.RpcGetInviteesResponseData
         * @instance
         */
        RpcGetInviteesResponseData.prototype.meta = null;

        /**
         * Creates a new RpcGetInviteesResponseData instance using the specified properties.
         * @function create
         * @memberof api.RpcGetInviteesResponseData
         * @static
         * @param {api.IRpcGetInviteesResponseData=} [properties] Properties to set
         * @returns {api.RpcGetInviteesResponseData} RpcGetInviteesResponseData instance
         */
        RpcGetInviteesResponseData.create = function create(properties) {
            return new RpcGetInviteesResponseData(properties);
        };

        /**
         * Encodes the specified RpcGetInviteesResponseData message. Does not implicitly {@link api.RpcGetInviteesResponseData.verify|verify} messages.
         * @function encode
         * @memberof api.RpcGetInviteesResponseData
         * @static
         * @param {api.RpcGetInviteesResponseData} message RpcGetInviteesResponseData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGetInviteesResponseData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.invitees != null && message.invitees.length)
                for (let i = 0; i < message.invitees.length; ++i)
                    $root.api.RpcGetInviteesResponseInvitee.encode(message.invitees[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.meta != null && Object.hasOwnProperty.call(message, "meta"))
                $root.api.RpcGetInviteesResponseMeta.encode(message.meta, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified RpcGetInviteesResponseData message, length delimited. Does not implicitly {@link api.RpcGetInviteesResponseData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcGetInviteesResponseData
         * @static
         * @param {api.RpcGetInviteesResponseData} message RpcGetInviteesResponseData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGetInviteesResponseData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcGetInviteesResponseData message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcGetInviteesResponseData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcGetInviteesResponseData} RpcGetInviteesResponseData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGetInviteesResponseData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcGetInviteesResponseData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.invitees && message.invitees.length))
                            message.invitees = [];
                        message.invitees.push($root.api.RpcGetInviteesResponseInvitee.decode(reader, reader.uint32()));
                        break;
                    }
                case 2: {
                        message.meta = $root.api.RpcGetInviteesResponseMeta.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcGetInviteesResponseData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcGetInviteesResponseData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcGetInviteesResponseData} RpcGetInviteesResponseData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGetInviteesResponseData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcGetInviteesResponseData message.
         * @function verify
         * @memberof api.RpcGetInviteesResponseData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcGetInviteesResponseData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.invitees != null && message.hasOwnProperty("invitees")) {
                if (!Array.isArray(message.invitees))
                    return "invitees: array expected";
                for (let i = 0; i < message.invitees.length; ++i) {
                    let error = $root.api.RpcGetInviteesResponseInvitee.verify(message.invitees[i]);
                    if (error)
                        return "invitees." + error;
                }
            }
            if (message.meta != null && message.hasOwnProperty("meta")) {
                let error = $root.api.RpcGetInviteesResponseMeta.verify(message.meta);
                if (error)
                    return "meta." + error;
            }
            return null;
        };

        /**
         * Creates a RpcGetInviteesResponseData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcGetInviteesResponseData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcGetInviteesResponseData} RpcGetInviteesResponseData
         */
        RpcGetInviteesResponseData.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcGetInviteesResponseData)
                return object;
            let message = new $root.api.RpcGetInviteesResponseData();
            if (object.invitees) {
                if (!Array.isArray(object.invitees))
                    throw TypeError(".api.RpcGetInviteesResponseData.invitees: array expected");
                message.invitees = [];
                for (let i = 0; i < object.invitees.length; ++i) {
                    if (typeof object.invitees[i] !== "object")
                        throw TypeError(".api.RpcGetInviteesResponseData.invitees: object expected");
                    message.invitees[i] = $root.api.RpcGetInviteesResponseInvitee.fromObject(object.invitees[i]);
                }
            }
            if (object.meta != null) {
                if (typeof object.meta !== "object")
                    throw TypeError(".api.RpcGetInviteesResponseData.meta: object expected");
                message.meta = $root.api.RpcGetInviteesResponseMeta.fromObject(object.meta);
            }
            return message;
        };

        /**
         * Creates a plain object from a RpcGetInviteesResponseData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcGetInviteesResponseData
         * @static
         * @param {api.RpcGetInviteesResponseData} message RpcGetInviteesResponseData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcGetInviteesResponseData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.invitees = [];
            if (options.defaults)
                object.meta = null;
            if (message.invitees && message.invitees.length) {
                object.invitees = [];
                for (let j = 0; j < message.invitees.length; ++j)
                    object.invitees[j] = $root.api.RpcGetInviteesResponseInvitee.toObject(message.invitees[j], options);
            }
            if (message.meta != null && message.hasOwnProperty("meta"))
                object.meta = $root.api.RpcGetInviteesResponseMeta.toObject(message.meta, options);
            return object;
        };

        /**
         * Converts this RpcGetInviteesResponseData to JSON.
         * @function toJSON
         * @memberof api.RpcGetInviteesResponseData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcGetInviteesResponseData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcGetInviteesResponseData
         * @function getTypeUrl
         * @memberof api.RpcGetInviteesResponseData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcGetInviteesResponseData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcGetInviteesResponseData";
        };

        return RpcGetInviteesResponseData;
    })();

    api.RpcGetInviteesResponse = (function() {

        /**
         * Properties of a RpcGetInviteesResponse.
         * @memberof api
         * @interface IRpcGetInviteesResponse
         * @property {api.RpcGetInviteesResponseData|null} [data] RpcGetInviteesResponse data
         * @property {number|null} [code] RpcGetInviteesResponse code
         * @property {string|null} [message] RpcGetInviteesResponse message
         * @property {api.Error|null} [error] RpcGetInviteesResponse error
         */

        /**
         * Constructs a new RpcGetInviteesResponse.
         * @memberof api
         * @classdesc Represents a RpcGetInviteesResponse.
         * @implements IRpcGetInviteesResponse
         * @constructor
         * @param {api.IRpcGetInviteesResponse=} [properties] Properties to set
         */
        function RpcGetInviteesResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcGetInviteesResponse data.
         * @member {api.RpcGetInviteesResponseData|null|undefined} data
         * @memberof api.RpcGetInviteesResponse
         * @instance
         */
        RpcGetInviteesResponse.prototype.data = null;

        /**
         * RpcGetInviteesResponse code.
         * @member {number} code
         * @memberof api.RpcGetInviteesResponse
         * @instance
         */
        RpcGetInviteesResponse.prototype.code = 0;

        /**
         * RpcGetInviteesResponse message.
         * @member {string} message
         * @memberof api.RpcGetInviteesResponse
         * @instance
         */
        RpcGetInviteesResponse.prototype.message = "";

        /**
         * RpcGetInviteesResponse error.
         * @member {api.Error|null|undefined} error
         * @memberof api.RpcGetInviteesResponse
         * @instance
         */
        RpcGetInviteesResponse.prototype.error = null;

        /**
         * Creates a new RpcGetInviteesResponse instance using the specified properties.
         * @function create
         * @memberof api.RpcGetInviteesResponse
         * @static
         * @param {api.IRpcGetInviteesResponse=} [properties] Properties to set
         * @returns {api.RpcGetInviteesResponse} RpcGetInviteesResponse instance
         */
        RpcGetInviteesResponse.create = function create(properties) {
            return new RpcGetInviteesResponse(properties);
        };

        /**
         * Encodes the specified RpcGetInviteesResponse message. Does not implicitly {@link api.RpcGetInviteesResponse.verify|verify} messages.
         * @function encode
         * @memberof api.RpcGetInviteesResponse
         * @static
         * @param {api.RpcGetInviteesResponse} message RpcGetInviteesResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGetInviteesResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                $root.api.RpcGetInviteesResponseData.encode(message.data, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.code);
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.message);
            if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                $root.api.Error.encode(message.error, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified RpcGetInviteesResponse message, length delimited. Does not implicitly {@link api.RpcGetInviteesResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcGetInviteesResponse
         * @static
         * @param {api.RpcGetInviteesResponse} message RpcGetInviteesResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGetInviteesResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcGetInviteesResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcGetInviteesResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcGetInviteesResponse} RpcGetInviteesResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGetInviteesResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcGetInviteesResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.data = $root.api.RpcGetInviteesResponseData.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.code = reader.int32();
                        break;
                    }
                case 3: {
                        message.message = reader.string();
                        break;
                    }
                case 4: {
                        message.error = $root.api.Error.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcGetInviteesResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcGetInviteesResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcGetInviteesResponse} RpcGetInviteesResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGetInviteesResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcGetInviteesResponse message.
         * @function verify
         * @memberof api.RpcGetInviteesResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcGetInviteesResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.data != null && message.hasOwnProperty("data")) {
                let error = $root.api.RpcGetInviteesResponseData.verify(message.data);
                if (error)
                    return "data." + error;
            }
            if (message.code != null && message.hasOwnProperty("code"))
                if (!$util.isInteger(message.code))
                    return "code: integer expected";
            if (message.message != null && message.hasOwnProperty("message"))
                if (!$util.isString(message.message))
                    return "message: string expected";
            if (message.error != null && message.hasOwnProperty("error")) {
                let error = $root.api.Error.verify(message.error);
                if (error)
                    return "error." + error;
            }
            return null;
        };

        /**
         * Creates a RpcGetInviteesResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcGetInviteesResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcGetInviteesResponse} RpcGetInviteesResponse
         */
        RpcGetInviteesResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcGetInviteesResponse)
                return object;
            let message = new $root.api.RpcGetInviteesResponse();
            if (object.data != null) {
                if (typeof object.data !== "object")
                    throw TypeError(".api.RpcGetInviteesResponse.data: object expected");
                message.data = $root.api.RpcGetInviteesResponseData.fromObject(object.data);
            }
            if (object.code != null)
                message.code = object.code | 0;
            if (object.message != null)
                message.message = String(object.message);
            if (object.error != null) {
                if (typeof object.error !== "object")
                    throw TypeError(".api.RpcGetInviteesResponse.error: object expected");
                message.error = $root.api.Error.fromObject(object.error);
            }
            return message;
        };

        /**
         * Creates a plain object from a RpcGetInviteesResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcGetInviteesResponse
         * @static
         * @param {api.RpcGetInviteesResponse} message RpcGetInviteesResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcGetInviteesResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.data = null;
                object.code = 0;
                object.message = "";
                object.error = null;
            }
            if (message.data != null && message.hasOwnProperty("data"))
                object.data = $root.api.RpcGetInviteesResponseData.toObject(message.data, options);
            if (message.code != null && message.hasOwnProperty("code"))
                object.code = message.code;
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = message.message;
            if (message.error != null && message.hasOwnProperty("error"))
                object.error = $root.api.Error.toObject(message.error, options);
            return object;
        };

        /**
         * Converts this RpcGetInviteesResponse to JSON.
         * @function toJSON
         * @memberof api.RpcGetInviteesResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcGetInviteesResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcGetInviteesResponse
         * @function getTypeUrl
         * @memberof api.RpcGetInviteesResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcGetInviteesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcGetInviteesResponse";
        };

        return RpcGetInviteesResponse;
    })();

    api.RpcGetStateResponse = (function() {

        /**
         * Properties of a RpcGetStateResponse.
         * @memberof api
         * @interface IRpcGetStateResponse
         * @property {number|null} [code] RpcGetStateResponse code
         * @property {api.RpcGetStateResponseData|null} [data] RpcGetStateResponse data
         * @property {api.Error|null} [error] RpcGetStateResponse error
         * @property {string|null} [message] RpcGetStateResponse message
         */

        /**
         * Constructs a new RpcGetStateResponse.
         * @memberof api
         * @classdesc Represents a RpcGetStateResponse.
         * @implements IRpcGetStateResponse
         * @constructor
         * @param {api.IRpcGetStateResponse=} [properties] Properties to set
         */
        function RpcGetStateResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcGetStateResponse code.
         * @member {number} code
         * @memberof api.RpcGetStateResponse
         * @instance
         */
        RpcGetStateResponse.prototype.code = 0;

        /**
         * RpcGetStateResponse data.
         * @member {api.RpcGetStateResponseData|null|undefined} data
         * @memberof api.RpcGetStateResponse
         * @instance
         */
        RpcGetStateResponse.prototype.data = null;

        /**
         * RpcGetStateResponse error.
         * @member {api.Error|null|undefined} error
         * @memberof api.RpcGetStateResponse
         * @instance
         */
        RpcGetStateResponse.prototype.error = null;

        /**
         * RpcGetStateResponse message.
         * @member {string} message
         * @memberof api.RpcGetStateResponse
         * @instance
         */
        RpcGetStateResponse.prototype.message = "";

        /**
         * Creates a new RpcGetStateResponse instance using the specified properties.
         * @function create
         * @memberof api.RpcGetStateResponse
         * @static
         * @param {api.IRpcGetStateResponse=} [properties] Properties to set
         * @returns {api.RpcGetStateResponse} RpcGetStateResponse instance
         */
        RpcGetStateResponse.create = function create(properties) {
            return new RpcGetStateResponse(properties);
        };

        /**
         * Encodes the specified RpcGetStateResponse message. Does not implicitly {@link api.RpcGetStateResponse.verify|verify} messages.
         * @function encode
         * @memberof api.RpcGetStateResponse
         * @static
         * @param {api.RpcGetStateResponse} message RpcGetStateResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGetStateResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.code);
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                $root.api.RpcGetStateResponseData.encode(message.data, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                $root.api.Error.encode(message.error, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.message);
            return writer;
        };

        /**
         * Encodes the specified RpcGetStateResponse message, length delimited. Does not implicitly {@link api.RpcGetStateResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcGetStateResponse
         * @static
         * @param {api.RpcGetStateResponse} message RpcGetStateResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGetStateResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcGetStateResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcGetStateResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcGetStateResponse} RpcGetStateResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGetStateResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcGetStateResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.code = reader.int32();
                        break;
                    }
                case 2: {
                        message.data = $root.api.RpcGetStateResponseData.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.error = $root.api.Error.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.message = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcGetStateResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcGetStateResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcGetStateResponse} RpcGetStateResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGetStateResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcGetStateResponse message.
         * @function verify
         * @memberof api.RpcGetStateResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcGetStateResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.code != null && message.hasOwnProperty("code"))
                if (!$util.isInteger(message.code))
                    return "code: integer expected";
            if (message.data != null && message.hasOwnProperty("data")) {
                let error = $root.api.RpcGetStateResponseData.verify(message.data);
                if (error)
                    return "data." + error;
            }
            if (message.error != null && message.hasOwnProperty("error")) {
                let error = $root.api.Error.verify(message.error);
                if (error)
                    return "error." + error;
            }
            if (message.message != null && message.hasOwnProperty("message"))
                if (!$util.isString(message.message))
                    return "message: string expected";
            return null;
        };

        /**
         * Creates a RpcGetStateResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcGetStateResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcGetStateResponse} RpcGetStateResponse
         */
        RpcGetStateResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcGetStateResponse)
                return object;
            let message = new $root.api.RpcGetStateResponse();
            if (object.code != null)
                message.code = object.code | 0;
            if (object.data != null) {
                if (typeof object.data !== "object")
                    throw TypeError(".api.RpcGetStateResponse.data: object expected");
                message.data = $root.api.RpcGetStateResponseData.fromObject(object.data);
            }
            if (object.error != null) {
                if (typeof object.error !== "object")
                    throw TypeError(".api.RpcGetStateResponse.error: object expected");
                message.error = $root.api.Error.fromObject(object.error);
            }
            if (object.message != null)
                message.message = String(object.message);
            return message;
        };

        /**
         * Creates a plain object from a RpcGetStateResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcGetStateResponse
         * @static
         * @param {api.RpcGetStateResponse} message RpcGetStateResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcGetStateResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.code = 0;
                object.data = null;
                object.error = null;
                object.message = "";
            }
            if (message.code != null && message.hasOwnProperty("code"))
                object.code = message.code;
            if (message.data != null && message.hasOwnProperty("data"))
                object.data = $root.api.RpcGetStateResponseData.toObject(message.data, options);
            if (message.error != null && message.hasOwnProperty("error"))
                object.error = $root.api.Error.toObject(message.error, options);
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = message.message;
            return object;
        };

        /**
         * Converts this RpcGetStateResponse to JSON.
         * @function toJSON
         * @memberof api.RpcGetStateResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcGetStateResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcGetStateResponse
         * @function getTypeUrl
         * @memberof api.RpcGetStateResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcGetStateResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcGetStateResponse";
        };

        return RpcGetStateResponse;
    })();

    api.RpcGetStateResponseData = (function() {

        /**
         * Properties of a RpcGetStateResponseData.
         * @memberof api
         * @interface IRpcGetStateResponseData
         * @property {api.RpcClientState|null} [client_state] RpcGetStateResponseData client_state
         */

        /**
         * Constructs a new RpcGetStateResponseData.
         * @memberof api
         * @classdesc Represents a RpcGetStateResponseData.
         * @implements IRpcGetStateResponseData
         * @constructor
         * @param {api.IRpcGetStateResponseData=} [properties] Properties to set
         */
        function RpcGetStateResponseData(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcGetStateResponseData client_state.
         * @member {api.RpcClientState|null|undefined} client_state
         * @memberof api.RpcGetStateResponseData
         * @instance
         */
        RpcGetStateResponseData.prototype.client_state = null;

        /**
         * Creates a new RpcGetStateResponseData instance using the specified properties.
         * @function create
         * @memberof api.RpcGetStateResponseData
         * @static
         * @param {api.IRpcGetStateResponseData=} [properties] Properties to set
         * @returns {api.RpcGetStateResponseData} RpcGetStateResponseData instance
         */
        RpcGetStateResponseData.create = function create(properties) {
            return new RpcGetStateResponseData(properties);
        };

        /**
         * Encodes the specified RpcGetStateResponseData message. Does not implicitly {@link api.RpcGetStateResponseData.verify|verify} messages.
         * @function encode
         * @memberof api.RpcGetStateResponseData
         * @static
         * @param {api.RpcGetStateResponseData} message RpcGetStateResponseData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGetStateResponseData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.client_state != null && Object.hasOwnProperty.call(message, "client_state"))
                $root.api.RpcClientState.encode(message.client_state, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified RpcGetStateResponseData message, length delimited. Does not implicitly {@link api.RpcGetStateResponseData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcGetStateResponseData
         * @static
         * @param {api.RpcGetStateResponseData} message RpcGetStateResponseData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGetStateResponseData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcGetStateResponseData message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcGetStateResponseData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcGetStateResponseData} RpcGetStateResponseData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGetStateResponseData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcGetStateResponseData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.client_state = $root.api.RpcClientState.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcGetStateResponseData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcGetStateResponseData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcGetStateResponseData} RpcGetStateResponseData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGetStateResponseData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcGetStateResponseData message.
         * @function verify
         * @memberof api.RpcGetStateResponseData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcGetStateResponseData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.client_state != null && message.hasOwnProperty("client_state")) {
                let error = $root.api.RpcClientState.verify(message.client_state);
                if (error)
                    return "client_state." + error;
            }
            return null;
        };

        /**
         * Creates a RpcGetStateResponseData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcGetStateResponseData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcGetStateResponseData} RpcGetStateResponseData
         */
        RpcGetStateResponseData.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcGetStateResponseData)
                return object;
            let message = new $root.api.RpcGetStateResponseData();
            if (object.client_state != null) {
                if (typeof object.client_state !== "object")
                    throw TypeError(".api.RpcGetStateResponseData.client_state: object expected");
                message.client_state = $root.api.RpcClientState.fromObject(object.client_state);
            }
            return message;
        };

        /**
         * Creates a plain object from a RpcGetStateResponseData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcGetStateResponseData
         * @static
         * @param {api.RpcGetStateResponseData} message RpcGetStateResponseData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcGetStateResponseData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.client_state = null;
            if (message.client_state != null && message.hasOwnProperty("client_state"))
                object.client_state = $root.api.RpcClientState.toObject(message.client_state, options);
            return object;
        };

        /**
         * Converts this RpcGetStateResponseData to JSON.
         * @function toJSON
         * @memberof api.RpcGetStateResponseData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcGetStateResponseData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcGetStateResponseData
         * @function getTypeUrl
         * @memberof api.RpcGetStateResponseData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcGetStateResponseData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcGetStateResponseData";
        };

        return RpcGetStateResponseData;
    })();

    api.RpcClientState = (function() {

        /**
         * Properties of a RpcClientState.
         * @memberof api
         * @interface IRpcClientState
         * @property {number|null} [id] RpcClientState id
         * @property {number|null} [points] RpcClientState points
         */

        /**
         * Constructs a new RpcClientState.
         * @memberof api
         * @classdesc Represents a RpcClientState.
         * @implements IRpcClientState
         * @constructor
         * @param {api.IRpcClientState=} [properties] Properties to set
         */
        function RpcClientState(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcClientState id.
         * @member {number} id
         * @memberof api.RpcClientState
         * @instance
         */
        RpcClientState.prototype.id = 0;

        /**
         * RpcClientState points.
         * @member {number} points
         * @memberof api.RpcClientState
         * @instance
         */
        RpcClientState.prototype.points = 0;

        /**
         * Creates a new RpcClientState instance using the specified properties.
         * @function create
         * @memberof api.RpcClientState
         * @static
         * @param {api.IRpcClientState=} [properties] Properties to set
         * @returns {api.RpcClientState} RpcClientState instance
         */
        RpcClientState.create = function create(properties) {
            return new RpcClientState(properties);
        };

        /**
         * Encodes the specified RpcClientState message. Does not implicitly {@link api.RpcClientState.verify|verify} messages.
         * @function encode
         * @memberof api.RpcClientState
         * @static
         * @param {api.RpcClientState} message RpcClientState message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcClientState.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
            if (message.points != null && Object.hasOwnProperty.call(message, "points"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.points);
            return writer;
        };

        /**
         * Encodes the specified RpcClientState message, length delimited. Does not implicitly {@link api.RpcClientState.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcClientState
         * @static
         * @param {api.RpcClientState} message RpcClientState message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcClientState.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcClientState message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcClientState
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcClientState} RpcClientState
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcClientState.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcClientState();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.int32();
                        break;
                    }
                case 2: {
                        message.points = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcClientState message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcClientState
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcClientState} RpcClientState
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcClientState.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcClientState message.
         * @function verify
         * @memberof api.RpcClientState
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcClientState.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isInteger(message.id))
                    return "id: integer expected";
            if (message.points != null && message.hasOwnProperty("points"))
                if (!$util.isInteger(message.points))
                    return "points: integer expected";
            return null;
        };

        /**
         * Creates a RpcClientState message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcClientState
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcClientState} RpcClientState
         */
        RpcClientState.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcClientState)
                return object;
            let message = new $root.api.RpcClientState();
            if (object.id != null)
                message.id = object.id | 0;
            if (object.points != null)
                message.points = object.points | 0;
            return message;
        };

        /**
         * Creates a plain object from a RpcClientState message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcClientState
         * @static
         * @param {api.RpcClientState} message RpcClientState
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcClientState.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.id = 0;
                object.points = 0;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.points != null && message.hasOwnProperty("points"))
                object.points = message.points;
            return object;
        };

        /**
         * Converts this RpcClientState to JSON.
         * @function toJSON
         * @memberof api.RpcClientState
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcClientState.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcClientState
         * @function getTypeUrl
         * @memberof api.RpcClientState
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcClientState.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcClientState";
        };

        return RpcClientState;
    })();

    api.RpcGroupAddClientRequest = (function() {

        /**
         * Properties of a RpcGroupAddClientRequest.
         * @memberof api
         * @interface IRpcGroupAddClientRequest
         * @property {string|null} [group_uid] RpcGroupAddClientRequest group_uid
         */

        /**
         * Constructs a new RpcGroupAddClientRequest.
         * @memberof api
         * @classdesc Represents a RpcGroupAddClientRequest.
         * @implements IRpcGroupAddClientRequest
         * @constructor
         * @param {api.IRpcGroupAddClientRequest=} [properties] Properties to set
         */
        function RpcGroupAddClientRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcGroupAddClientRequest group_uid.
         * @member {string} group_uid
         * @memberof api.RpcGroupAddClientRequest
         * @instance
         */
        RpcGroupAddClientRequest.prototype.group_uid = "";

        /**
         * Creates a new RpcGroupAddClientRequest instance using the specified properties.
         * @function create
         * @memberof api.RpcGroupAddClientRequest
         * @static
         * @param {api.IRpcGroupAddClientRequest=} [properties] Properties to set
         * @returns {api.RpcGroupAddClientRequest} RpcGroupAddClientRequest instance
         */
        RpcGroupAddClientRequest.create = function create(properties) {
            return new RpcGroupAddClientRequest(properties);
        };

        /**
         * Encodes the specified RpcGroupAddClientRequest message. Does not implicitly {@link api.RpcGroupAddClientRequest.verify|verify} messages.
         * @function encode
         * @memberof api.RpcGroupAddClientRequest
         * @static
         * @param {api.RpcGroupAddClientRequest} message RpcGroupAddClientRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGroupAddClientRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.group_uid != null && Object.hasOwnProperty.call(message, "group_uid"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.group_uid);
            return writer;
        };

        /**
         * Encodes the specified RpcGroupAddClientRequest message, length delimited. Does not implicitly {@link api.RpcGroupAddClientRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcGroupAddClientRequest
         * @static
         * @param {api.RpcGroupAddClientRequest} message RpcGroupAddClientRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGroupAddClientRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcGroupAddClientRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcGroupAddClientRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcGroupAddClientRequest} RpcGroupAddClientRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGroupAddClientRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcGroupAddClientRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.group_uid = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcGroupAddClientRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcGroupAddClientRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcGroupAddClientRequest} RpcGroupAddClientRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGroupAddClientRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcGroupAddClientRequest message.
         * @function verify
         * @memberof api.RpcGroupAddClientRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcGroupAddClientRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.group_uid != null && message.hasOwnProperty("group_uid"))
                if (!$util.isString(message.group_uid))
                    return "group_uid: string expected";
            return null;
        };

        /**
         * Creates a RpcGroupAddClientRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcGroupAddClientRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcGroupAddClientRequest} RpcGroupAddClientRequest
         */
        RpcGroupAddClientRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcGroupAddClientRequest)
                return object;
            let message = new $root.api.RpcGroupAddClientRequest();
            if (object.group_uid != null)
                message.group_uid = String(object.group_uid);
            return message;
        };

        /**
         * Creates a plain object from a RpcGroupAddClientRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcGroupAddClientRequest
         * @static
         * @param {api.RpcGroupAddClientRequest} message RpcGroupAddClientRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcGroupAddClientRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.group_uid = "";
            if (message.group_uid != null && message.hasOwnProperty("group_uid"))
                object.group_uid = message.group_uid;
            return object;
        };

        /**
         * Converts this RpcGroupAddClientRequest to JSON.
         * @function toJSON
         * @memberof api.RpcGroupAddClientRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcGroupAddClientRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcGroupAddClientRequest
         * @function getTypeUrl
         * @memberof api.RpcGroupAddClientRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcGroupAddClientRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcGroupAddClientRequest";
        };

        return RpcGroupAddClientRequest;
    })();

    api.RpcGroupAddClientResponse = (function() {

        /**
         * Properties of a RpcGroupAddClientResponse.
         * @memberof api
         * @interface IRpcGroupAddClientResponse
         * @property {number|null} [code] RpcGroupAddClientResponse code
         * @property {api.RpcGroupAddClientResponseData|null} [data] RpcGroupAddClientResponse data
         * @property {api.Error|null} [error] RpcGroupAddClientResponse error
         * @property {string|null} [message] RpcGroupAddClientResponse message
         */

        /**
         * Constructs a new RpcGroupAddClientResponse.
         * @memberof api
         * @classdesc Represents a RpcGroupAddClientResponse.
         * @implements IRpcGroupAddClientResponse
         * @constructor
         * @param {api.IRpcGroupAddClientResponse=} [properties] Properties to set
         */
        function RpcGroupAddClientResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcGroupAddClientResponse code.
         * @member {number} code
         * @memberof api.RpcGroupAddClientResponse
         * @instance
         */
        RpcGroupAddClientResponse.prototype.code = 0;

        /**
         * RpcGroupAddClientResponse data.
         * @member {api.RpcGroupAddClientResponseData|null|undefined} data
         * @memberof api.RpcGroupAddClientResponse
         * @instance
         */
        RpcGroupAddClientResponse.prototype.data = null;

        /**
         * RpcGroupAddClientResponse error.
         * @member {api.Error|null|undefined} error
         * @memberof api.RpcGroupAddClientResponse
         * @instance
         */
        RpcGroupAddClientResponse.prototype.error = null;

        /**
         * RpcGroupAddClientResponse message.
         * @member {string} message
         * @memberof api.RpcGroupAddClientResponse
         * @instance
         */
        RpcGroupAddClientResponse.prototype.message = "";

        /**
         * Creates a new RpcGroupAddClientResponse instance using the specified properties.
         * @function create
         * @memberof api.RpcGroupAddClientResponse
         * @static
         * @param {api.IRpcGroupAddClientResponse=} [properties] Properties to set
         * @returns {api.RpcGroupAddClientResponse} RpcGroupAddClientResponse instance
         */
        RpcGroupAddClientResponse.create = function create(properties) {
            return new RpcGroupAddClientResponse(properties);
        };

        /**
         * Encodes the specified RpcGroupAddClientResponse message. Does not implicitly {@link api.RpcGroupAddClientResponse.verify|verify} messages.
         * @function encode
         * @memberof api.RpcGroupAddClientResponse
         * @static
         * @param {api.RpcGroupAddClientResponse} message RpcGroupAddClientResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGroupAddClientResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.code);
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                $root.api.RpcGroupAddClientResponseData.encode(message.data, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                $root.api.Error.encode(message.error, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.message);
            return writer;
        };

        /**
         * Encodes the specified RpcGroupAddClientResponse message, length delimited. Does not implicitly {@link api.RpcGroupAddClientResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcGroupAddClientResponse
         * @static
         * @param {api.RpcGroupAddClientResponse} message RpcGroupAddClientResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGroupAddClientResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcGroupAddClientResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcGroupAddClientResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcGroupAddClientResponse} RpcGroupAddClientResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGroupAddClientResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcGroupAddClientResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.code = reader.int32();
                        break;
                    }
                case 2: {
                        message.data = $root.api.RpcGroupAddClientResponseData.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.error = $root.api.Error.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.message = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcGroupAddClientResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcGroupAddClientResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcGroupAddClientResponse} RpcGroupAddClientResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGroupAddClientResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcGroupAddClientResponse message.
         * @function verify
         * @memberof api.RpcGroupAddClientResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcGroupAddClientResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.code != null && message.hasOwnProperty("code"))
                if (!$util.isInteger(message.code))
                    return "code: integer expected";
            if (message.data != null && message.hasOwnProperty("data")) {
                let error = $root.api.RpcGroupAddClientResponseData.verify(message.data);
                if (error)
                    return "data." + error;
            }
            if (message.error != null && message.hasOwnProperty("error")) {
                let error = $root.api.Error.verify(message.error);
                if (error)
                    return "error." + error;
            }
            if (message.message != null && message.hasOwnProperty("message"))
                if (!$util.isString(message.message))
                    return "message: string expected";
            return null;
        };

        /**
         * Creates a RpcGroupAddClientResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcGroupAddClientResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcGroupAddClientResponse} RpcGroupAddClientResponse
         */
        RpcGroupAddClientResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcGroupAddClientResponse)
                return object;
            let message = new $root.api.RpcGroupAddClientResponse();
            if (object.code != null)
                message.code = object.code | 0;
            if (object.data != null) {
                if (typeof object.data !== "object")
                    throw TypeError(".api.RpcGroupAddClientResponse.data: object expected");
                message.data = $root.api.RpcGroupAddClientResponseData.fromObject(object.data);
            }
            if (object.error != null) {
                if (typeof object.error !== "object")
                    throw TypeError(".api.RpcGroupAddClientResponse.error: object expected");
                message.error = $root.api.Error.fromObject(object.error);
            }
            if (object.message != null)
                message.message = String(object.message);
            return message;
        };

        /**
         * Creates a plain object from a RpcGroupAddClientResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcGroupAddClientResponse
         * @static
         * @param {api.RpcGroupAddClientResponse} message RpcGroupAddClientResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcGroupAddClientResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.code = 0;
                object.data = null;
                object.error = null;
                object.message = "";
            }
            if (message.code != null && message.hasOwnProperty("code"))
                object.code = message.code;
            if (message.data != null && message.hasOwnProperty("data"))
                object.data = $root.api.RpcGroupAddClientResponseData.toObject(message.data, options);
            if (message.error != null && message.hasOwnProperty("error"))
                object.error = $root.api.Error.toObject(message.error, options);
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = message.message;
            return object;
        };

        /**
         * Converts this RpcGroupAddClientResponse to JSON.
         * @function toJSON
         * @memberof api.RpcGroupAddClientResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcGroupAddClientResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcGroupAddClientResponse
         * @function getTypeUrl
         * @memberof api.RpcGroupAddClientResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcGroupAddClientResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcGroupAddClientResponse";
        };

        return RpcGroupAddClientResponse;
    })();

    api.RpcGroupAddClientResponseData = (function() {

        /**
         * Properties of a RpcGroupAddClientResponseData.
         * @memberof api
         * @interface IRpcGroupAddClientResponseData
         */

        /**
         * Constructs a new RpcGroupAddClientResponseData.
         * @memberof api
         * @classdesc Represents a RpcGroupAddClientResponseData.
         * @implements IRpcGroupAddClientResponseData
         * @constructor
         * @param {api.IRpcGroupAddClientResponseData=} [properties] Properties to set
         */
        function RpcGroupAddClientResponseData(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new RpcGroupAddClientResponseData instance using the specified properties.
         * @function create
         * @memberof api.RpcGroupAddClientResponseData
         * @static
         * @param {api.IRpcGroupAddClientResponseData=} [properties] Properties to set
         * @returns {api.RpcGroupAddClientResponseData} RpcGroupAddClientResponseData instance
         */
        RpcGroupAddClientResponseData.create = function create(properties) {
            return new RpcGroupAddClientResponseData(properties);
        };

        /**
         * Encodes the specified RpcGroupAddClientResponseData message. Does not implicitly {@link api.RpcGroupAddClientResponseData.verify|verify} messages.
         * @function encode
         * @memberof api.RpcGroupAddClientResponseData
         * @static
         * @param {api.RpcGroupAddClientResponseData} message RpcGroupAddClientResponseData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGroupAddClientResponseData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified RpcGroupAddClientResponseData message, length delimited. Does not implicitly {@link api.RpcGroupAddClientResponseData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcGroupAddClientResponseData
         * @static
         * @param {api.RpcGroupAddClientResponseData} message RpcGroupAddClientResponseData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGroupAddClientResponseData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcGroupAddClientResponseData message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcGroupAddClientResponseData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcGroupAddClientResponseData} RpcGroupAddClientResponseData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGroupAddClientResponseData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcGroupAddClientResponseData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcGroupAddClientResponseData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcGroupAddClientResponseData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcGroupAddClientResponseData} RpcGroupAddClientResponseData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGroupAddClientResponseData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcGroupAddClientResponseData message.
         * @function verify
         * @memberof api.RpcGroupAddClientResponseData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcGroupAddClientResponseData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a RpcGroupAddClientResponseData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcGroupAddClientResponseData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcGroupAddClientResponseData} RpcGroupAddClientResponseData
         */
        RpcGroupAddClientResponseData.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcGroupAddClientResponseData)
                return object;
            return new $root.api.RpcGroupAddClientResponseData();
        };

        /**
         * Creates a plain object from a RpcGroupAddClientResponseData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcGroupAddClientResponseData
         * @static
         * @param {api.RpcGroupAddClientResponseData} message RpcGroupAddClientResponseData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcGroupAddClientResponseData.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this RpcGroupAddClientResponseData to JSON.
         * @function toJSON
         * @memberof api.RpcGroupAddClientResponseData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcGroupAddClientResponseData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcGroupAddClientResponseData
         * @function getTypeUrl
         * @memberof api.RpcGroupAddClientResponseData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcGroupAddClientResponseData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcGroupAddClientResponseData";
        };

        return RpcGroupAddClientResponseData;
    })();

    api.RpcGroupCreateRequest = (function() {

        /**
         * Properties of a RpcGroupCreateRequest.
         * @memberof api
         * @interface IRpcGroupCreateRequest
         * @property {string|null} [name] RpcGroupCreateRequest name
         * @property {boolean|null} [force] RpcGroupCreateRequest force
         */

        /**
         * Constructs a new RpcGroupCreateRequest.
         * @memberof api
         * @classdesc Represents a RpcGroupCreateRequest.
         * @implements IRpcGroupCreateRequest
         * @constructor
         * @param {api.IRpcGroupCreateRequest=} [properties] Properties to set
         */
        function RpcGroupCreateRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcGroupCreateRequest name.
         * @member {string} name
         * @memberof api.RpcGroupCreateRequest
         * @instance
         */
        RpcGroupCreateRequest.prototype.name = "";

        /**
         * RpcGroupCreateRequest force.
         * @member {boolean} force
         * @memberof api.RpcGroupCreateRequest
         * @instance
         */
        RpcGroupCreateRequest.prototype.force = false;

        /**
         * Creates a new RpcGroupCreateRequest instance using the specified properties.
         * @function create
         * @memberof api.RpcGroupCreateRequest
         * @static
         * @param {api.IRpcGroupCreateRequest=} [properties] Properties to set
         * @returns {api.RpcGroupCreateRequest} RpcGroupCreateRequest instance
         */
        RpcGroupCreateRequest.create = function create(properties) {
            return new RpcGroupCreateRequest(properties);
        };

        /**
         * Encodes the specified RpcGroupCreateRequest message. Does not implicitly {@link api.RpcGroupCreateRequest.verify|verify} messages.
         * @function encode
         * @memberof api.RpcGroupCreateRequest
         * @static
         * @param {api.RpcGroupCreateRequest} message RpcGroupCreateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGroupCreateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.force != null && Object.hasOwnProperty.call(message, "force"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.force);
            return writer;
        };

        /**
         * Encodes the specified RpcGroupCreateRequest message, length delimited. Does not implicitly {@link api.RpcGroupCreateRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcGroupCreateRequest
         * @static
         * @param {api.RpcGroupCreateRequest} message RpcGroupCreateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGroupCreateRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcGroupCreateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcGroupCreateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcGroupCreateRequest} RpcGroupCreateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGroupCreateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcGroupCreateRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                case 2: {
                        message.force = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcGroupCreateRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcGroupCreateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcGroupCreateRequest} RpcGroupCreateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGroupCreateRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcGroupCreateRequest message.
         * @function verify
         * @memberof api.RpcGroupCreateRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcGroupCreateRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.force != null && message.hasOwnProperty("force"))
                if (typeof message.force !== "boolean")
                    return "force: boolean expected";
            return null;
        };

        /**
         * Creates a RpcGroupCreateRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcGroupCreateRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcGroupCreateRequest} RpcGroupCreateRequest
         */
        RpcGroupCreateRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcGroupCreateRequest)
                return object;
            let message = new $root.api.RpcGroupCreateRequest();
            if (object.name != null)
                message.name = String(object.name);
            if (object.force != null)
                message.force = Boolean(object.force);
            return message;
        };

        /**
         * Creates a plain object from a RpcGroupCreateRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcGroupCreateRequest
         * @static
         * @param {api.RpcGroupCreateRequest} message RpcGroupCreateRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcGroupCreateRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.name = "";
                object.force = false;
            }
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.force != null && message.hasOwnProperty("force"))
                object.force = message.force;
            return object;
        };

        /**
         * Converts this RpcGroupCreateRequest to JSON.
         * @function toJSON
         * @memberof api.RpcGroupCreateRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcGroupCreateRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcGroupCreateRequest
         * @function getTypeUrl
         * @memberof api.RpcGroupCreateRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcGroupCreateRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcGroupCreateRequest";
        };

        return RpcGroupCreateRequest;
    })();

    api.RpcGroupCreateResponse = (function() {

        /**
         * Properties of a RpcGroupCreateResponse.
         * @memberof api
         * @interface IRpcGroupCreateResponse
         * @property {number|null} [code] RpcGroupCreateResponse code
         * @property {api.RpcGroupCreateResponseData|null} [data] RpcGroupCreateResponse data
         * @property {api.Error|null} [error] RpcGroupCreateResponse error
         * @property {string|null} [message] RpcGroupCreateResponse message
         */

        /**
         * Constructs a new RpcGroupCreateResponse.
         * @memberof api
         * @classdesc Represents a RpcGroupCreateResponse.
         * @implements IRpcGroupCreateResponse
         * @constructor
         * @param {api.IRpcGroupCreateResponse=} [properties] Properties to set
         */
        function RpcGroupCreateResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcGroupCreateResponse code.
         * @member {number} code
         * @memberof api.RpcGroupCreateResponse
         * @instance
         */
        RpcGroupCreateResponse.prototype.code = 0;

        /**
         * RpcGroupCreateResponse data.
         * @member {api.RpcGroupCreateResponseData|null|undefined} data
         * @memberof api.RpcGroupCreateResponse
         * @instance
         */
        RpcGroupCreateResponse.prototype.data = null;

        /**
         * RpcGroupCreateResponse error.
         * @member {api.Error|null|undefined} error
         * @memberof api.RpcGroupCreateResponse
         * @instance
         */
        RpcGroupCreateResponse.prototype.error = null;

        /**
         * RpcGroupCreateResponse message.
         * @member {string} message
         * @memberof api.RpcGroupCreateResponse
         * @instance
         */
        RpcGroupCreateResponse.prototype.message = "";

        /**
         * Creates a new RpcGroupCreateResponse instance using the specified properties.
         * @function create
         * @memberof api.RpcGroupCreateResponse
         * @static
         * @param {api.IRpcGroupCreateResponse=} [properties] Properties to set
         * @returns {api.RpcGroupCreateResponse} RpcGroupCreateResponse instance
         */
        RpcGroupCreateResponse.create = function create(properties) {
            return new RpcGroupCreateResponse(properties);
        };

        /**
         * Encodes the specified RpcGroupCreateResponse message. Does not implicitly {@link api.RpcGroupCreateResponse.verify|verify} messages.
         * @function encode
         * @memberof api.RpcGroupCreateResponse
         * @static
         * @param {api.RpcGroupCreateResponse} message RpcGroupCreateResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGroupCreateResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.code);
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                $root.api.RpcGroupCreateResponseData.encode(message.data, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                $root.api.Error.encode(message.error, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.message);
            return writer;
        };

        /**
         * Encodes the specified RpcGroupCreateResponse message, length delimited. Does not implicitly {@link api.RpcGroupCreateResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcGroupCreateResponse
         * @static
         * @param {api.RpcGroupCreateResponse} message RpcGroupCreateResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGroupCreateResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcGroupCreateResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcGroupCreateResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcGroupCreateResponse} RpcGroupCreateResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGroupCreateResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcGroupCreateResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.code = reader.int32();
                        break;
                    }
                case 2: {
                        message.data = $root.api.RpcGroupCreateResponseData.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.error = $root.api.Error.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.message = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcGroupCreateResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcGroupCreateResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcGroupCreateResponse} RpcGroupCreateResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGroupCreateResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcGroupCreateResponse message.
         * @function verify
         * @memberof api.RpcGroupCreateResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcGroupCreateResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.code != null && message.hasOwnProperty("code"))
                if (!$util.isInteger(message.code))
                    return "code: integer expected";
            if (message.data != null && message.hasOwnProperty("data")) {
                let error = $root.api.RpcGroupCreateResponseData.verify(message.data);
                if (error)
                    return "data." + error;
            }
            if (message.error != null && message.hasOwnProperty("error")) {
                let error = $root.api.Error.verify(message.error);
                if (error)
                    return "error." + error;
            }
            if (message.message != null && message.hasOwnProperty("message"))
                if (!$util.isString(message.message))
                    return "message: string expected";
            return null;
        };

        /**
         * Creates a RpcGroupCreateResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcGroupCreateResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcGroupCreateResponse} RpcGroupCreateResponse
         */
        RpcGroupCreateResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcGroupCreateResponse)
                return object;
            let message = new $root.api.RpcGroupCreateResponse();
            if (object.code != null)
                message.code = object.code | 0;
            if (object.data != null) {
                if (typeof object.data !== "object")
                    throw TypeError(".api.RpcGroupCreateResponse.data: object expected");
                message.data = $root.api.RpcGroupCreateResponseData.fromObject(object.data);
            }
            if (object.error != null) {
                if (typeof object.error !== "object")
                    throw TypeError(".api.RpcGroupCreateResponse.error: object expected");
                message.error = $root.api.Error.fromObject(object.error);
            }
            if (object.message != null)
                message.message = String(object.message);
            return message;
        };

        /**
         * Creates a plain object from a RpcGroupCreateResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcGroupCreateResponse
         * @static
         * @param {api.RpcGroupCreateResponse} message RpcGroupCreateResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcGroupCreateResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.code = 0;
                object.data = null;
                object.error = null;
                object.message = "";
            }
            if (message.code != null && message.hasOwnProperty("code"))
                object.code = message.code;
            if (message.data != null && message.hasOwnProperty("data"))
                object.data = $root.api.RpcGroupCreateResponseData.toObject(message.data, options);
            if (message.error != null && message.hasOwnProperty("error"))
                object.error = $root.api.Error.toObject(message.error, options);
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = message.message;
            return object;
        };

        /**
         * Converts this RpcGroupCreateResponse to JSON.
         * @function toJSON
         * @memberof api.RpcGroupCreateResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcGroupCreateResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcGroupCreateResponse
         * @function getTypeUrl
         * @memberof api.RpcGroupCreateResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcGroupCreateResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcGroupCreateResponse";
        };

        return RpcGroupCreateResponse;
    })();

    api.RpcGroupCreateResponseData = (function() {

        /**
         * Properties of a RpcGroupCreateResponseData.
         * @memberof api
         * @interface IRpcGroupCreateResponseData
         * @property {api.RpcGroupCreateResponseDataGroup|null} [group] RpcGroupCreateResponseData group
         */

        /**
         * Constructs a new RpcGroupCreateResponseData.
         * @memberof api
         * @classdesc Represents a RpcGroupCreateResponseData.
         * @implements IRpcGroupCreateResponseData
         * @constructor
         * @param {api.IRpcGroupCreateResponseData=} [properties] Properties to set
         */
        function RpcGroupCreateResponseData(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcGroupCreateResponseData group.
         * @member {api.RpcGroupCreateResponseDataGroup|null|undefined} group
         * @memberof api.RpcGroupCreateResponseData
         * @instance
         */
        RpcGroupCreateResponseData.prototype.group = null;

        /**
         * Creates a new RpcGroupCreateResponseData instance using the specified properties.
         * @function create
         * @memberof api.RpcGroupCreateResponseData
         * @static
         * @param {api.IRpcGroupCreateResponseData=} [properties] Properties to set
         * @returns {api.RpcGroupCreateResponseData} RpcGroupCreateResponseData instance
         */
        RpcGroupCreateResponseData.create = function create(properties) {
            return new RpcGroupCreateResponseData(properties);
        };

        /**
         * Encodes the specified RpcGroupCreateResponseData message. Does not implicitly {@link api.RpcGroupCreateResponseData.verify|verify} messages.
         * @function encode
         * @memberof api.RpcGroupCreateResponseData
         * @static
         * @param {api.RpcGroupCreateResponseData} message RpcGroupCreateResponseData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGroupCreateResponseData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.group != null && Object.hasOwnProperty.call(message, "group"))
                $root.api.RpcGroupCreateResponseDataGroup.encode(message.group, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified RpcGroupCreateResponseData message, length delimited. Does not implicitly {@link api.RpcGroupCreateResponseData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcGroupCreateResponseData
         * @static
         * @param {api.RpcGroupCreateResponseData} message RpcGroupCreateResponseData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGroupCreateResponseData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcGroupCreateResponseData message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcGroupCreateResponseData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcGroupCreateResponseData} RpcGroupCreateResponseData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGroupCreateResponseData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcGroupCreateResponseData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.group = $root.api.RpcGroupCreateResponseDataGroup.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcGroupCreateResponseData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcGroupCreateResponseData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcGroupCreateResponseData} RpcGroupCreateResponseData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGroupCreateResponseData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcGroupCreateResponseData message.
         * @function verify
         * @memberof api.RpcGroupCreateResponseData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcGroupCreateResponseData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.group != null && message.hasOwnProperty("group")) {
                let error = $root.api.RpcGroupCreateResponseDataGroup.verify(message.group);
                if (error)
                    return "group." + error;
            }
            return null;
        };

        /**
         * Creates a RpcGroupCreateResponseData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcGroupCreateResponseData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcGroupCreateResponseData} RpcGroupCreateResponseData
         */
        RpcGroupCreateResponseData.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcGroupCreateResponseData)
                return object;
            let message = new $root.api.RpcGroupCreateResponseData();
            if (object.group != null) {
                if (typeof object.group !== "object")
                    throw TypeError(".api.RpcGroupCreateResponseData.group: object expected");
                message.group = $root.api.RpcGroupCreateResponseDataGroup.fromObject(object.group);
            }
            return message;
        };

        /**
         * Creates a plain object from a RpcGroupCreateResponseData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcGroupCreateResponseData
         * @static
         * @param {api.RpcGroupCreateResponseData} message RpcGroupCreateResponseData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcGroupCreateResponseData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.group = null;
            if (message.group != null && message.hasOwnProperty("group"))
                object.group = $root.api.RpcGroupCreateResponseDataGroup.toObject(message.group, options);
            return object;
        };

        /**
         * Converts this RpcGroupCreateResponseData to JSON.
         * @function toJSON
         * @memberof api.RpcGroupCreateResponseData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcGroupCreateResponseData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcGroupCreateResponseData
         * @function getTypeUrl
         * @memberof api.RpcGroupCreateResponseData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcGroupCreateResponseData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcGroupCreateResponseData";
        };

        return RpcGroupCreateResponseData;
    })();

    api.RpcGroupCreateResponseDataGroup = (function() {

        /**
         * Properties of a RpcGroupCreateResponseDataGroup.
         * @memberof api
         * @interface IRpcGroupCreateResponseDataGroup
         * @property {string|null} [admin_client_uid] RpcGroupCreateResponseDataGroup admin_client_uid
         * @property {string|null} [avatar] RpcGroupCreateResponseDataGroup avatar
         * @property {Object.<string,string>|null} [balances] RpcGroupCreateResponseDataGroup balances
         * @property {number|null} [clients_count] RpcGroupCreateResponseDataGroup clients_count
         * @property {string|null} [created_at] RpcGroupCreateResponseDataGroup created_at
         * @property {number|null} [id] RpcGroupCreateResponseDataGroup id
         * @property {string|null} [link] RpcGroupCreateResponseDataGroup link
         * @property {string|null} [name] RpcGroupCreateResponseDataGroup name
         * @property {number|null} [rank] RpcGroupCreateResponseDataGroup rank
         * @property {string|null} [ref_link] RpcGroupCreateResponseDataGroup ref_link
         * @property {string|null} [tg] RpcGroupCreateResponseDataGroup tg
         * @property {string|null} [uid] RpcGroupCreateResponseDataGroup uid
         */

        /**
         * Constructs a new RpcGroupCreateResponseDataGroup.
         * @memberof api
         * @classdesc Represents a RpcGroupCreateResponseDataGroup.
         * @implements IRpcGroupCreateResponseDataGroup
         * @constructor
         * @param {api.IRpcGroupCreateResponseDataGroup=} [properties] Properties to set
         */
        function RpcGroupCreateResponseDataGroup(properties) {
            this.balances = {};
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcGroupCreateResponseDataGroup admin_client_uid.
         * @member {string} admin_client_uid
         * @memberof api.RpcGroupCreateResponseDataGroup
         * @instance
         */
        RpcGroupCreateResponseDataGroup.prototype.admin_client_uid = "";

        /**
         * RpcGroupCreateResponseDataGroup avatar.
         * @member {string} avatar
         * @memberof api.RpcGroupCreateResponseDataGroup
         * @instance
         */
        RpcGroupCreateResponseDataGroup.prototype.avatar = "";

        /**
         * RpcGroupCreateResponseDataGroup balances.
         * @member {Object.<string,string>} balances
         * @memberof api.RpcGroupCreateResponseDataGroup
         * @instance
         */
        RpcGroupCreateResponseDataGroup.prototype.balances = $util.emptyObject;

        /**
         * RpcGroupCreateResponseDataGroup clients_count.
         * @member {number} clients_count
         * @memberof api.RpcGroupCreateResponseDataGroup
         * @instance
         */
        RpcGroupCreateResponseDataGroup.prototype.clients_count = 0;

        /**
         * RpcGroupCreateResponseDataGroup created_at.
         * @member {string} created_at
         * @memberof api.RpcGroupCreateResponseDataGroup
         * @instance
         */
        RpcGroupCreateResponseDataGroup.prototype.created_at = "";

        /**
         * RpcGroupCreateResponseDataGroup id.
         * @member {number} id
         * @memberof api.RpcGroupCreateResponseDataGroup
         * @instance
         */
        RpcGroupCreateResponseDataGroup.prototype.id = 0;

        /**
         * RpcGroupCreateResponseDataGroup link.
         * @member {string} link
         * @memberof api.RpcGroupCreateResponseDataGroup
         * @instance
         */
        RpcGroupCreateResponseDataGroup.prototype.link = "";

        /**
         * RpcGroupCreateResponseDataGroup name.
         * @member {string} name
         * @memberof api.RpcGroupCreateResponseDataGroup
         * @instance
         */
        RpcGroupCreateResponseDataGroup.prototype.name = "";

        /**
         * RpcGroupCreateResponseDataGroup rank.
         * @member {number} rank
         * @memberof api.RpcGroupCreateResponseDataGroup
         * @instance
         */
        RpcGroupCreateResponseDataGroup.prototype.rank = 0;

        /**
         * RpcGroupCreateResponseDataGroup ref_link.
         * @member {string} ref_link
         * @memberof api.RpcGroupCreateResponseDataGroup
         * @instance
         */
        RpcGroupCreateResponseDataGroup.prototype.ref_link = "";

        /**
         * RpcGroupCreateResponseDataGroup tg.
         * @member {string} tg
         * @memberof api.RpcGroupCreateResponseDataGroup
         * @instance
         */
        RpcGroupCreateResponseDataGroup.prototype.tg = "";

        /**
         * RpcGroupCreateResponseDataGroup uid.
         * @member {string} uid
         * @memberof api.RpcGroupCreateResponseDataGroup
         * @instance
         */
        RpcGroupCreateResponseDataGroup.prototype.uid = "";

        /**
         * Creates a new RpcGroupCreateResponseDataGroup instance using the specified properties.
         * @function create
         * @memberof api.RpcGroupCreateResponseDataGroup
         * @static
         * @param {api.IRpcGroupCreateResponseDataGroup=} [properties] Properties to set
         * @returns {api.RpcGroupCreateResponseDataGroup} RpcGroupCreateResponseDataGroup instance
         */
        RpcGroupCreateResponseDataGroup.create = function create(properties) {
            return new RpcGroupCreateResponseDataGroup(properties);
        };

        /**
         * Encodes the specified RpcGroupCreateResponseDataGroup message. Does not implicitly {@link api.RpcGroupCreateResponseDataGroup.verify|verify} messages.
         * @function encode
         * @memberof api.RpcGroupCreateResponseDataGroup
         * @static
         * @param {api.RpcGroupCreateResponseDataGroup} message RpcGroupCreateResponseDataGroup message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGroupCreateResponseDataGroup.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.admin_client_uid != null && Object.hasOwnProperty.call(message, "admin_client_uid"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.admin_client_uid);
            if (message.avatar != null && Object.hasOwnProperty.call(message, "avatar"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.avatar);
            if (message.balances != null && Object.hasOwnProperty.call(message, "balances"))
                for (let keys = Object.keys(message.balances), i = 0; i < keys.length; ++i)
                    writer.uint32(/* id 3, wireType 2 =*/26).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.balances[keys[i]]).ldelim();
            if (message.clients_count != null && Object.hasOwnProperty.call(message, "clients_count"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.clients_count);
            if (message.created_at != null && Object.hasOwnProperty.call(message, "created_at"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.created_at);
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.id);
            if (message.link != null && Object.hasOwnProperty.call(message, "link"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.link);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.name);
            if (message.rank != null && Object.hasOwnProperty.call(message, "rank"))
                writer.uint32(/* id 9, wireType 0 =*/72).int32(message.rank);
            if (message.ref_link != null && Object.hasOwnProperty.call(message, "ref_link"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.ref_link);
            if (message.tg != null && Object.hasOwnProperty.call(message, "tg"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.tg);
            if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
                writer.uint32(/* id 12, wireType 2 =*/98).string(message.uid);
            return writer;
        };

        /**
         * Encodes the specified RpcGroupCreateResponseDataGroup message, length delimited. Does not implicitly {@link api.RpcGroupCreateResponseDataGroup.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcGroupCreateResponseDataGroup
         * @static
         * @param {api.RpcGroupCreateResponseDataGroup} message RpcGroupCreateResponseDataGroup message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGroupCreateResponseDataGroup.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcGroupCreateResponseDataGroup message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcGroupCreateResponseDataGroup
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcGroupCreateResponseDataGroup} RpcGroupCreateResponseDataGroup
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGroupCreateResponseDataGroup.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcGroupCreateResponseDataGroup(), key, value;
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.admin_client_uid = reader.string();
                        break;
                    }
                case 2: {
                        message.avatar = reader.string();
                        break;
                    }
                case 3: {
                        if (message.balances === $util.emptyObject)
                            message.balances = {};
                        let end2 = reader.uint32() + reader.pos;
                        key = "";
                        value = "";
                        while (reader.pos < end2) {
                            let tag2 = reader.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                key = reader.string();
                                break;
                            case 2:
                                value = reader.string();
                                break;
                            default:
                                reader.skipType(tag2 & 7);
                                break;
                            }
                        }
                        message.balances[key] = value;
                        break;
                    }
                case 4: {
                        message.clients_count = reader.int32();
                        break;
                    }
                case 5: {
                        message.created_at = reader.string();
                        break;
                    }
                case 6: {
                        message.id = reader.int32();
                        break;
                    }
                case 7: {
                        message.link = reader.string();
                        break;
                    }
                case 8: {
                        message.name = reader.string();
                        break;
                    }
                case 9: {
                        message.rank = reader.int32();
                        break;
                    }
                case 10: {
                        message.ref_link = reader.string();
                        break;
                    }
                case 11: {
                        message.tg = reader.string();
                        break;
                    }
                case 12: {
                        message.uid = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcGroupCreateResponseDataGroup message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcGroupCreateResponseDataGroup
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcGroupCreateResponseDataGroup} RpcGroupCreateResponseDataGroup
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGroupCreateResponseDataGroup.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcGroupCreateResponseDataGroup message.
         * @function verify
         * @memberof api.RpcGroupCreateResponseDataGroup
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcGroupCreateResponseDataGroup.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.admin_client_uid != null && message.hasOwnProperty("admin_client_uid"))
                if (!$util.isString(message.admin_client_uid))
                    return "admin_client_uid: string expected";
            if (message.avatar != null && message.hasOwnProperty("avatar"))
                if (!$util.isString(message.avatar))
                    return "avatar: string expected";
            if (message.balances != null && message.hasOwnProperty("balances")) {
                if (!$util.isObject(message.balances))
                    return "balances: object expected";
                let key = Object.keys(message.balances);
                for (let i = 0; i < key.length; ++i)
                    if (!$util.isString(message.balances[key[i]]))
                        return "balances: string{k:string} expected";
            }
            if (message.clients_count != null && message.hasOwnProperty("clients_count"))
                if (!$util.isInteger(message.clients_count))
                    return "clients_count: integer expected";
            if (message.created_at != null && message.hasOwnProperty("created_at"))
                if (!$util.isString(message.created_at))
                    return "created_at: string expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isInteger(message.id))
                    return "id: integer expected";
            if (message.link != null && message.hasOwnProperty("link"))
                if (!$util.isString(message.link))
                    return "link: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.rank != null && message.hasOwnProperty("rank"))
                if (!$util.isInteger(message.rank))
                    return "rank: integer expected";
            if (message.ref_link != null && message.hasOwnProperty("ref_link"))
                if (!$util.isString(message.ref_link))
                    return "ref_link: string expected";
            if (message.tg != null && message.hasOwnProperty("tg"))
                if (!$util.isString(message.tg))
                    return "tg: string expected";
            if (message.uid != null && message.hasOwnProperty("uid"))
                if (!$util.isString(message.uid))
                    return "uid: string expected";
            return null;
        };

        /**
         * Creates a RpcGroupCreateResponseDataGroup message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcGroupCreateResponseDataGroup
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcGroupCreateResponseDataGroup} RpcGroupCreateResponseDataGroup
         */
        RpcGroupCreateResponseDataGroup.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcGroupCreateResponseDataGroup)
                return object;
            let message = new $root.api.RpcGroupCreateResponseDataGroup();
            if (object.admin_client_uid != null)
                message.admin_client_uid = String(object.admin_client_uid);
            if (object.avatar != null)
                message.avatar = String(object.avatar);
            if (object.balances) {
                if (typeof object.balances !== "object")
                    throw TypeError(".api.RpcGroupCreateResponseDataGroup.balances: object expected");
                message.balances = {};
                for (let keys = Object.keys(object.balances), i = 0; i < keys.length; ++i)
                    message.balances[keys[i]] = String(object.balances[keys[i]]);
            }
            if (object.clients_count != null)
                message.clients_count = object.clients_count | 0;
            if (object.created_at != null)
                message.created_at = String(object.created_at);
            if (object.id != null)
                message.id = object.id | 0;
            if (object.link != null)
                message.link = String(object.link);
            if (object.name != null)
                message.name = String(object.name);
            if (object.rank != null)
                message.rank = object.rank | 0;
            if (object.ref_link != null)
                message.ref_link = String(object.ref_link);
            if (object.tg != null)
                message.tg = String(object.tg);
            if (object.uid != null)
                message.uid = String(object.uid);
            return message;
        };

        /**
         * Creates a plain object from a RpcGroupCreateResponseDataGroup message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcGroupCreateResponseDataGroup
         * @static
         * @param {api.RpcGroupCreateResponseDataGroup} message RpcGroupCreateResponseDataGroup
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcGroupCreateResponseDataGroup.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.objects || options.defaults)
                object.balances = {};
            if (options.defaults) {
                object.admin_client_uid = "";
                object.avatar = "";
                object.clients_count = 0;
                object.created_at = "";
                object.id = 0;
                object.link = "";
                object.name = "";
                object.rank = 0;
                object.ref_link = "";
                object.tg = "";
                object.uid = "";
            }
            if (message.admin_client_uid != null && message.hasOwnProperty("admin_client_uid"))
                object.admin_client_uid = message.admin_client_uid;
            if (message.avatar != null && message.hasOwnProperty("avatar"))
                object.avatar = message.avatar;
            let keys2;
            if (message.balances && (keys2 = Object.keys(message.balances)).length) {
                object.balances = {};
                for (let j = 0; j < keys2.length; ++j)
                    object.balances[keys2[j]] = message.balances[keys2[j]];
            }
            if (message.clients_count != null && message.hasOwnProperty("clients_count"))
                object.clients_count = message.clients_count;
            if (message.created_at != null && message.hasOwnProperty("created_at"))
                object.created_at = message.created_at;
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.link != null && message.hasOwnProperty("link"))
                object.link = message.link;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.rank != null && message.hasOwnProperty("rank"))
                object.rank = message.rank;
            if (message.ref_link != null && message.hasOwnProperty("ref_link"))
                object.ref_link = message.ref_link;
            if (message.tg != null && message.hasOwnProperty("tg"))
                object.tg = message.tg;
            if (message.uid != null && message.hasOwnProperty("uid"))
                object.uid = message.uid;
            return object;
        };

        /**
         * Converts this RpcGroupCreateResponseDataGroup to JSON.
         * @function toJSON
         * @memberof api.RpcGroupCreateResponseDataGroup
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcGroupCreateResponseDataGroup.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcGroupCreateResponseDataGroup
         * @function getTypeUrl
         * @memberof api.RpcGroupCreateResponseDataGroup
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcGroupCreateResponseDataGroup.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcGroupCreateResponseDataGroup";
        };

        return RpcGroupCreateResponseDataGroup;
    })();

    api.RpcGroupDeleteRequest = (function() {

        /**
         * Properties of a RpcGroupDeleteRequest.
         * @memberof api
         * @interface IRpcGroupDeleteRequest
         * @property {string|null} [group_uid] RpcGroupDeleteRequest group_uid
         */

        /**
         * Constructs a new RpcGroupDeleteRequest.
         * @memberof api
         * @classdesc Represents a RpcGroupDeleteRequest.
         * @implements IRpcGroupDeleteRequest
         * @constructor
         * @param {api.IRpcGroupDeleteRequest=} [properties] Properties to set
         */
        function RpcGroupDeleteRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcGroupDeleteRequest group_uid.
         * @member {string} group_uid
         * @memberof api.RpcGroupDeleteRequest
         * @instance
         */
        RpcGroupDeleteRequest.prototype.group_uid = "";

        /**
         * Creates a new RpcGroupDeleteRequest instance using the specified properties.
         * @function create
         * @memberof api.RpcGroupDeleteRequest
         * @static
         * @param {api.IRpcGroupDeleteRequest=} [properties] Properties to set
         * @returns {api.RpcGroupDeleteRequest} RpcGroupDeleteRequest instance
         */
        RpcGroupDeleteRequest.create = function create(properties) {
            return new RpcGroupDeleteRequest(properties);
        };

        /**
         * Encodes the specified RpcGroupDeleteRequest message. Does not implicitly {@link api.RpcGroupDeleteRequest.verify|verify} messages.
         * @function encode
         * @memberof api.RpcGroupDeleteRequest
         * @static
         * @param {api.RpcGroupDeleteRequest} message RpcGroupDeleteRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGroupDeleteRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.group_uid != null && Object.hasOwnProperty.call(message, "group_uid"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.group_uid);
            return writer;
        };

        /**
         * Encodes the specified RpcGroupDeleteRequest message, length delimited. Does not implicitly {@link api.RpcGroupDeleteRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcGroupDeleteRequest
         * @static
         * @param {api.RpcGroupDeleteRequest} message RpcGroupDeleteRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGroupDeleteRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcGroupDeleteRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcGroupDeleteRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcGroupDeleteRequest} RpcGroupDeleteRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGroupDeleteRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcGroupDeleteRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.group_uid = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcGroupDeleteRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcGroupDeleteRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcGroupDeleteRequest} RpcGroupDeleteRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGroupDeleteRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcGroupDeleteRequest message.
         * @function verify
         * @memberof api.RpcGroupDeleteRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcGroupDeleteRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.group_uid != null && message.hasOwnProperty("group_uid"))
                if (!$util.isString(message.group_uid))
                    return "group_uid: string expected";
            return null;
        };

        /**
         * Creates a RpcGroupDeleteRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcGroupDeleteRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcGroupDeleteRequest} RpcGroupDeleteRequest
         */
        RpcGroupDeleteRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcGroupDeleteRequest)
                return object;
            let message = new $root.api.RpcGroupDeleteRequest();
            if (object.group_uid != null)
                message.group_uid = String(object.group_uid);
            return message;
        };

        /**
         * Creates a plain object from a RpcGroupDeleteRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcGroupDeleteRequest
         * @static
         * @param {api.RpcGroupDeleteRequest} message RpcGroupDeleteRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcGroupDeleteRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.group_uid = "";
            if (message.group_uid != null && message.hasOwnProperty("group_uid"))
                object.group_uid = message.group_uid;
            return object;
        };

        /**
         * Converts this RpcGroupDeleteRequest to JSON.
         * @function toJSON
         * @memberof api.RpcGroupDeleteRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcGroupDeleteRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcGroupDeleteRequest
         * @function getTypeUrl
         * @memberof api.RpcGroupDeleteRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcGroupDeleteRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcGroupDeleteRequest";
        };

        return RpcGroupDeleteRequest;
    })();

    api.RpcGroupDeleteResponse = (function() {

        /**
         * Properties of a RpcGroupDeleteResponse.
         * @memberof api
         * @interface IRpcGroupDeleteResponse
         * @property {number|null} [code] RpcGroupDeleteResponse code
         * @property {api.RpcGroupDeleteResponseData|null} [data] RpcGroupDeleteResponse data
         * @property {api.Error|null} [error] RpcGroupDeleteResponse error
         * @property {string|null} [message] RpcGroupDeleteResponse message
         */

        /**
         * Constructs a new RpcGroupDeleteResponse.
         * @memberof api
         * @classdesc Represents a RpcGroupDeleteResponse.
         * @implements IRpcGroupDeleteResponse
         * @constructor
         * @param {api.IRpcGroupDeleteResponse=} [properties] Properties to set
         */
        function RpcGroupDeleteResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcGroupDeleteResponse code.
         * @member {number} code
         * @memberof api.RpcGroupDeleteResponse
         * @instance
         */
        RpcGroupDeleteResponse.prototype.code = 0;

        /**
         * RpcGroupDeleteResponse data.
         * @member {api.RpcGroupDeleteResponseData|null|undefined} data
         * @memberof api.RpcGroupDeleteResponse
         * @instance
         */
        RpcGroupDeleteResponse.prototype.data = null;

        /**
         * RpcGroupDeleteResponse error.
         * @member {api.Error|null|undefined} error
         * @memberof api.RpcGroupDeleteResponse
         * @instance
         */
        RpcGroupDeleteResponse.prototype.error = null;

        /**
         * RpcGroupDeleteResponse message.
         * @member {string} message
         * @memberof api.RpcGroupDeleteResponse
         * @instance
         */
        RpcGroupDeleteResponse.prototype.message = "";

        /**
         * Creates a new RpcGroupDeleteResponse instance using the specified properties.
         * @function create
         * @memberof api.RpcGroupDeleteResponse
         * @static
         * @param {api.IRpcGroupDeleteResponse=} [properties] Properties to set
         * @returns {api.RpcGroupDeleteResponse} RpcGroupDeleteResponse instance
         */
        RpcGroupDeleteResponse.create = function create(properties) {
            return new RpcGroupDeleteResponse(properties);
        };

        /**
         * Encodes the specified RpcGroupDeleteResponse message. Does not implicitly {@link api.RpcGroupDeleteResponse.verify|verify} messages.
         * @function encode
         * @memberof api.RpcGroupDeleteResponse
         * @static
         * @param {api.RpcGroupDeleteResponse} message RpcGroupDeleteResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGroupDeleteResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.code);
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                $root.api.RpcGroupDeleteResponseData.encode(message.data, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                $root.api.Error.encode(message.error, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.message);
            return writer;
        };

        /**
         * Encodes the specified RpcGroupDeleteResponse message, length delimited. Does not implicitly {@link api.RpcGroupDeleteResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcGroupDeleteResponse
         * @static
         * @param {api.RpcGroupDeleteResponse} message RpcGroupDeleteResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGroupDeleteResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcGroupDeleteResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcGroupDeleteResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcGroupDeleteResponse} RpcGroupDeleteResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGroupDeleteResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcGroupDeleteResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.code = reader.int32();
                        break;
                    }
                case 2: {
                        message.data = $root.api.RpcGroupDeleteResponseData.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.error = $root.api.Error.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.message = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcGroupDeleteResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcGroupDeleteResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcGroupDeleteResponse} RpcGroupDeleteResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGroupDeleteResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcGroupDeleteResponse message.
         * @function verify
         * @memberof api.RpcGroupDeleteResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcGroupDeleteResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.code != null && message.hasOwnProperty("code"))
                if (!$util.isInteger(message.code))
                    return "code: integer expected";
            if (message.data != null && message.hasOwnProperty("data")) {
                let error = $root.api.RpcGroupDeleteResponseData.verify(message.data);
                if (error)
                    return "data." + error;
            }
            if (message.error != null && message.hasOwnProperty("error")) {
                let error = $root.api.Error.verify(message.error);
                if (error)
                    return "error." + error;
            }
            if (message.message != null && message.hasOwnProperty("message"))
                if (!$util.isString(message.message))
                    return "message: string expected";
            return null;
        };

        /**
         * Creates a RpcGroupDeleteResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcGroupDeleteResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcGroupDeleteResponse} RpcGroupDeleteResponse
         */
        RpcGroupDeleteResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcGroupDeleteResponse)
                return object;
            let message = new $root.api.RpcGroupDeleteResponse();
            if (object.code != null)
                message.code = object.code | 0;
            if (object.data != null) {
                if (typeof object.data !== "object")
                    throw TypeError(".api.RpcGroupDeleteResponse.data: object expected");
                message.data = $root.api.RpcGroupDeleteResponseData.fromObject(object.data);
            }
            if (object.error != null) {
                if (typeof object.error !== "object")
                    throw TypeError(".api.RpcGroupDeleteResponse.error: object expected");
                message.error = $root.api.Error.fromObject(object.error);
            }
            if (object.message != null)
                message.message = String(object.message);
            return message;
        };

        /**
         * Creates a plain object from a RpcGroupDeleteResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcGroupDeleteResponse
         * @static
         * @param {api.RpcGroupDeleteResponse} message RpcGroupDeleteResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcGroupDeleteResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.code = 0;
                object.data = null;
                object.error = null;
                object.message = "";
            }
            if (message.code != null && message.hasOwnProperty("code"))
                object.code = message.code;
            if (message.data != null && message.hasOwnProperty("data"))
                object.data = $root.api.RpcGroupDeleteResponseData.toObject(message.data, options);
            if (message.error != null && message.hasOwnProperty("error"))
                object.error = $root.api.Error.toObject(message.error, options);
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = message.message;
            return object;
        };

        /**
         * Converts this RpcGroupDeleteResponse to JSON.
         * @function toJSON
         * @memberof api.RpcGroupDeleteResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcGroupDeleteResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcGroupDeleteResponse
         * @function getTypeUrl
         * @memberof api.RpcGroupDeleteResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcGroupDeleteResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcGroupDeleteResponse";
        };

        return RpcGroupDeleteResponse;
    })();

    api.RpcGroupDeleteResponseData = (function() {

        /**
         * Properties of a RpcGroupDeleteResponseData.
         * @memberof api
         * @interface IRpcGroupDeleteResponseData
         */

        /**
         * Constructs a new RpcGroupDeleteResponseData.
         * @memberof api
         * @classdesc Represents a RpcGroupDeleteResponseData.
         * @implements IRpcGroupDeleteResponseData
         * @constructor
         * @param {api.IRpcGroupDeleteResponseData=} [properties] Properties to set
         */
        function RpcGroupDeleteResponseData(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new RpcGroupDeleteResponseData instance using the specified properties.
         * @function create
         * @memberof api.RpcGroupDeleteResponseData
         * @static
         * @param {api.IRpcGroupDeleteResponseData=} [properties] Properties to set
         * @returns {api.RpcGroupDeleteResponseData} RpcGroupDeleteResponseData instance
         */
        RpcGroupDeleteResponseData.create = function create(properties) {
            return new RpcGroupDeleteResponseData(properties);
        };

        /**
         * Encodes the specified RpcGroupDeleteResponseData message. Does not implicitly {@link api.RpcGroupDeleteResponseData.verify|verify} messages.
         * @function encode
         * @memberof api.RpcGroupDeleteResponseData
         * @static
         * @param {api.RpcGroupDeleteResponseData} message RpcGroupDeleteResponseData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGroupDeleteResponseData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified RpcGroupDeleteResponseData message, length delimited. Does not implicitly {@link api.RpcGroupDeleteResponseData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcGroupDeleteResponseData
         * @static
         * @param {api.RpcGroupDeleteResponseData} message RpcGroupDeleteResponseData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGroupDeleteResponseData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcGroupDeleteResponseData message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcGroupDeleteResponseData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcGroupDeleteResponseData} RpcGroupDeleteResponseData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGroupDeleteResponseData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcGroupDeleteResponseData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcGroupDeleteResponseData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcGroupDeleteResponseData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcGroupDeleteResponseData} RpcGroupDeleteResponseData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGroupDeleteResponseData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcGroupDeleteResponseData message.
         * @function verify
         * @memberof api.RpcGroupDeleteResponseData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcGroupDeleteResponseData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a RpcGroupDeleteResponseData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcGroupDeleteResponseData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcGroupDeleteResponseData} RpcGroupDeleteResponseData
         */
        RpcGroupDeleteResponseData.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcGroupDeleteResponseData)
                return object;
            return new $root.api.RpcGroupDeleteResponseData();
        };

        /**
         * Creates a plain object from a RpcGroupDeleteResponseData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcGroupDeleteResponseData
         * @static
         * @param {api.RpcGroupDeleteResponseData} message RpcGroupDeleteResponseData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcGroupDeleteResponseData.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this RpcGroupDeleteResponseData to JSON.
         * @function toJSON
         * @memberof api.RpcGroupDeleteResponseData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcGroupDeleteResponseData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcGroupDeleteResponseData
         * @function getTypeUrl
         * @memberof api.RpcGroupDeleteResponseData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcGroupDeleteResponseData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcGroupDeleteResponseData";
        };

        return RpcGroupDeleteResponseData;
    })();

    api.RpcGroupExcludeRequest = (function() {

        /**
         * Properties of a RpcGroupExcludeRequest.
         * @memberof api
         * @interface IRpcGroupExcludeRequest
         * @property {string|null} [group_uid] RpcGroupExcludeRequest group_uid
         */

        /**
         * Constructs a new RpcGroupExcludeRequest.
         * @memberof api
         * @classdesc Represents a RpcGroupExcludeRequest.
         * @implements IRpcGroupExcludeRequest
         * @constructor
         * @param {api.IRpcGroupExcludeRequest=} [properties] Properties to set
         */
        function RpcGroupExcludeRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcGroupExcludeRequest group_uid.
         * @member {string} group_uid
         * @memberof api.RpcGroupExcludeRequest
         * @instance
         */
        RpcGroupExcludeRequest.prototype.group_uid = "";

        /**
         * Creates a new RpcGroupExcludeRequest instance using the specified properties.
         * @function create
         * @memberof api.RpcGroupExcludeRequest
         * @static
         * @param {api.IRpcGroupExcludeRequest=} [properties] Properties to set
         * @returns {api.RpcGroupExcludeRequest} RpcGroupExcludeRequest instance
         */
        RpcGroupExcludeRequest.create = function create(properties) {
            return new RpcGroupExcludeRequest(properties);
        };

        /**
         * Encodes the specified RpcGroupExcludeRequest message. Does not implicitly {@link api.RpcGroupExcludeRequest.verify|verify} messages.
         * @function encode
         * @memberof api.RpcGroupExcludeRequest
         * @static
         * @param {api.RpcGroupExcludeRequest} message RpcGroupExcludeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGroupExcludeRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.group_uid != null && Object.hasOwnProperty.call(message, "group_uid"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.group_uid);
            return writer;
        };

        /**
         * Encodes the specified RpcGroupExcludeRequest message, length delimited. Does not implicitly {@link api.RpcGroupExcludeRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcGroupExcludeRequest
         * @static
         * @param {api.RpcGroupExcludeRequest} message RpcGroupExcludeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGroupExcludeRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcGroupExcludeRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcGroupExcludeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcGroupExcludeRequest} RpcGroupExcludeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGroupExcludeRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcGroupExcludeRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.group_uid = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcGroupExcludeRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcGroupExcludeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcGroupExcludeRequest} RpcGroupExcludeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGroupExcludeRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcGroupExcludeRequest message.
         * @function verify
         * @memberof api.RpcGroupExcludeRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcGroupExcludeRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.group_uid != null && message.hasOwnProperty("group_uid"))
                if (!$util.isString(message.group_uid))
                    return "group_uid: string expected";
            return null;
        };

        /**
         * Creates a RpcGroupExcludeRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcGroupExcludeRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcGroupExcludeRequest} RpcGroupExcludeRequest
         */
        RpcGroupExcludeRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcGroupExcludeRequest)
                return object;
            let message = new $root.api.RpcGroupExcludeRequest();
            if (object.group_uid != null)
                message.group_uid = String(object.group_uid);
            return message;
        };

        /**
         * Creates a plain object from a RpcGroupExcludeRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcGroupExcludeRequest
         * @static
         * @param {api.RpcGroupExcludeRequest} message RpcGroupExcludeRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcGroupExcludeRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.group_uid = "";
            if (message.group_uid != null && message.hasOwnProperty("group_uid"))
                object.group_uid = message.group_uid;
            return object;
        };

        /**
         * Converts this RpcGroupExcludeRequest to JSON.
         * @function toJSON
         * @memberof api.RpcGroupExcludeRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcGroupExcludeRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcGroupExcludeRequest
         * @function getTypeUrl
         * @memberof api.RpcGroupExcludeRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcGroupExcludeRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcGroupExcludeRequest";
        };

        return RpcGroupExcludeRequest;
    })();

    api.RpcGroupExcludeResponse = (function() {

        /**
         * Properties of a RpcGroupExcludeResponse.
         * @memberof api
         * @interface IRpcGroupExcludeResponse
         * @property {number|null} [code] RpcGroupExcludeResponse code
         * @property {api.RpcGroupExcludeResponseData|null} [data] RpcGroupExcludeResponse data
         * @property {api.Error|null} [error] RpcGroupExcludeResponse error
         * @property {string|null} [message] RpcGroupExcludeResponse message
         */

        /**
         * Constructs a new RpcGroupExcludeResponse.
         * @memberof api
         * @classdesc Represents a RpcGroupExcludeResponse.
         * @implements IRpcGroupExcludeResponse
         * @constructor
         * @param {api.IRpcGroupExcludeResponse=} [properties] Properties to set
         */
        function RpcGroupExcludeResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcGroupExcludeResponse code.
         * @member {number} code
         * @memberof api.RpcGroupExcludeResponse
         * @instance
         */
        RpcGroupExcludeResponse.prototype.code = 0;

        /**
         * RpcGroupExcludeResponse data.
         * @member {api.RpcGroupExcludeResponseData|null|undefined} data
         * @memberof api.RpcGroupExcludeResponse
         * @instance
         */
        RpcGroupExcludeResponse.prototype.data = null;

        /**
         * RpcGroupExcludeResponse error.
         * @member {api.Error|null|undefined} error
         * @memberof api.RpcGroupExcludeResponse
         * @instance
         */
        RpcGroupExcludeResponse.prototype.error = null;

        /**
         * RpcGroupExcludeResponse message.
         * @member {string} message
         * @memberof api.RpcGroupExcludeResponse
         * @instance
         */
        RpcGroupExcludeResponse.prototype.message = "";

        /**
         * Creates a new RpcGroupExcludeResponse instance using the specified properties.
         * @function create
         * @memberof api.RpcGroupExcludeResponse
         * @static
         * @param {api.IRpcGroupExcludeResponse=} [properties] Properties to set
         * @returns {api.RpcGroupExcludeResponse} RpcGroupExcludeResponse instance
         */
        RpcGroupExcludeResponse.create = function create(properties) {
            return new RpcGroupExcludeResponse(properties);
        };

        /**
         * Encodes the specified RpcGroupExcludeResponse message. Does not implicitly {@link api.RpcGroupExcludeResponse.verify|verify} messages.
         * @function encode
         * @memberof api.RpcGroupExcludeResponse
         * @static
         * @param {api.RpcGroupExcludeResponse} message RpcGroupExcludeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGroupExcludeResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.code);
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                $root.api.RpcGroupExcludeResponseData.encode(message.data, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                $root.api.Error.encode(message.error, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.message);
            return writer;
        };

        /**
         * Encodes the specified RpcGroupExcludeResponse message, length delimited. Does not implicitly {@link api.RpcGroupExcludeResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcGroupExcludeResponse
         * @static
         * @param {api.RpcGroupExcludeResponse} message RpcGroupExcludeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGroupExcludeResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcGroupExcludeResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcGroupExcludeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcGroupExcludeResponse} RpcGroupExcludeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGroupExcludeResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcGroupExcludeResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.code = reader.int32();
                        break;
                    }
                case 2: {
                        message.data = $root.api.RpcGroupExcludeResponseData.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.error = $root.api.Error.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.message = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcGroupExcludeResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcGroupExcludeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcGroupExcludeResponse} RpcGroupExcludeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGroupExcludeResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcGroupExcludeResponse message.
         * @function verify
         * @memberof api.RpcGroupExcludeResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcGroupExcludeResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.code != null && message.hasOwnProperty("code"))
                if (!$util.isInteger(message.code))
                    return "code: integer expected";
            if (message.data != null && message.hasOwnProperty("data")) {
                let error = $root.api.RpcGroupExcludeResponseData.verify(message.data);
                if (error)
                    return "data." + error;
            }
            if (message.error != null && message.hasOwnProperty("error")) {
                let error = $root.api.Error.verify(message.error);
                if (error)
                    return "error." + error;
            }
            if (message.message != null && message.hasOwnProperty("message"))
                if (!$util.isString(message.message))
                    return "message: string expected";
            return null;
        };

        /**
         * Creates a RpcGroupExcludeResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcGroupExcludeResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcGroupExcludeResponse} RpcGroupExcludeResponse
         */
        RpcGroupExcludeResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcGroupExcludeResponse)
                return object;
            let message = new $root.api.RpcGroupExcludeResponse();
            if (object.code != null)
                message.code = object.code | 0;
            if (object.data != null) {
                if (typeof object.data !== "object")
                    throw TypeError(".api.RpcGroupExcludeResponse.data: object expected");
                message.data = $root.api.RpcGroupExcludeResponseData.fromObject(object.data);
            }
            if (object.error != null) {
                if (typeof object.error !== "object")
                    throw TypeError(".api.RpcGroupExcludeResponse.error: object expected");
                message.error = $root.api.Error.fromObject(object.error);
            }
            if (object.message != null)
                message.message = String(object.message);
            return message;
        };

        /**
         * Creates a plain object from a RpcGroupExcludeResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcGroupExcludeResponse
         * @static
         * @param {api.RpcGroupExcludeResponse} message RpcGroupExcludeResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcGroupExcludeResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.code = 0;
                object.data = null;
                object.error = null;
                object.message = "";
            }
            if (message.code != null && message.hasOwnProperty("code"))
                object.code = message.code;
            if (message.data != null && message.hasOwnProperty("data"))
                object.data = $root.api.RpcGroupExcludeResponseData.toObject(message.data, options);
            if (message.error != null && message.hasOwnProperty("error"))
                object.error = $root.api.Error.toObject(message.error, options);
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = message.message;
            return object;
        };

        /**
         * Converts this RpcGroupExcludeResponse to JSON.
         * @function toJSON
         * @memberof api.RpcGroupExcludeResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcGroupExcludeResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcGroupExcludeResponse
         * @function getTypeUrl
         * @memberof api.RpcGroupExcludeResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcGroupExcludeResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcGroupExcludeResponse";
        };

        return RpcGroupExcludeResponse;
    })();

    api.RpcGroupExcludeResponseData = (function() {

        /**
         * Properties of a RpcGroupExcludeResponseData.
         * @memberof api
         * @interface IRpcGroupExcludeResponseData
         * @property {Object.<string,string>|null} [error] RpcGroupExcludeResponseData error
         */

        /**
         * Constructs a new RpcGroupExcludeResponseData.
         * @memberof api
         * @classdesc Represents a RpcGroupExcludeResponseData.
         * @implements IRpcGroupExcludeResponseData
         * @constructor
         * @param {api.IRpcGroupExcludeResponseData=} [properties] Properties to set
         */
        function RpcGroupExcludeResponseData(properties) {
            this.error = {};
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcGroupExcludeResponseData error.
         * @member {Object.<string,string>} error
         * @memberof api.RpcGroupExcludeResponseData
         * @instance
         */
        RpcGroupExcludeResponseData.prototype.error = $util.emptyObject;

        /**
         * Creates a new RpcGroupExcludeResponseData instance using the specified properties.
         * @function create
         * @memberof api.RpcGroupExcludeResponseData
         * @static
         * @param {api.IRpcGroupExcludeResponseData=} [properties] Properties to set
         * @returns {api.RpcGroupExcludeResponseData} RpcGroupExcludeResponseData instance
         */
        RpcGroupExcludeResponseData.create = function create(properties) {
            return new RpcGroupExcludeResponseData(properties);
        };

        /**
         * Encodes the specified RpcGroupExcludeResponseData message. Does not implicitly {@link api.RpcGroupExcludeResponseData.verify|verify} messages.
         * @function encode
         * @memberof api.RpcGroupExcludeResponseData
         * @static
         * @param {api.RpcGroupExcludeResponseData} message RpcGroupExcludeResponseData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGroupExcludeResponseData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                for (let keys = Object.keys(message.error), i = 0; i < keys.length; ++i)
                    writer.uint32(/* id 1, wireType 2 =*/10).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.error[keys[i]]).ldelim();
            return writer;
        };

        /**
         * Encodes the specified RpcGroupExcludeResponseData message, length delimited. Does not implicitly {@link api.RpcGroupExcludeResponseData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcGroupExcludeResponseData
         * @static
         * @param {api.RpcGroupExcludeResponseData} message RpcGroupExcludeResponseData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGroupExcludeResponseData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcGroupExcludeResponseData message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcGroupExcludeResponseData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcGroupExcludeResponseData} RpcGroupExcludeResponseData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGroupExcludeResponseData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcGroupExcludeResponseData(), key, value;
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (message.error === $util.emptyObject)
                            message.error = {};
                        let end2 = reader.uint32() + reader.pos;
                        key = "";
                        value = "";
                        while (reader.pos < end2) {
                            let tag2 = reader.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                key = reader.string();
                                break;
                            case 2:
                                value = reader.string();
                                break;
                            default:
                                reader.skipType(tag2 & 7);
                                break;
                            }
                        }
                        message.error[key] = value;
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcGroupExcludeResponseData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcGroupExcludeResponseData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcGroupExcludeResponseData} RpcGroupExcludeResponseData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGroupExcludeResponseData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcGroupExcludeResponseData message.
         * @function verify
         * @memberof api.RpcGroupExcludeResponseData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcGroupExcludeResponseData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.error != null && message.hasOwnProperty("error")) {
                if (!$util.isObject(message.error))
                    return "error: object expected";
                let key = Object.keys(message.error);
                for (let i = 0; i < key.length; ++i)
                    if (!$util.isString(message.error[key[i]]))
                        return "error: string{k:string} expected";
            }
            return null;
        };

        /**
         * Creates a RpcGroupExcludeResponseData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcGroupExcludeResponseData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcGroupExcludeResponseData} RpcGroupExcludeResponseData
         */
        RpcGroupExcludeResponseData.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcGroupExcludeResponseData)
                return object;
            let message = new $root.api.RpcGroupExcludeResponseData();
            if (object.error) {
                if (typeof object.error !== "object")
                    throw TypeError(".api.RpcGroupExcludeResponseData.error: object expected");
                message.error = {};
                for (let keys = Object.keys(object.error), i = 0; i < keys.length; ++i)
                    message.error[keys[i]] = String(object.error[keys[i]]);
            }
            return message;
        };

        /**
         * Creates a plain object from a RpcGroupExcludeResponseData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcGroupExcludeResponseData
         * @static
         * @param {api.RpcGroupExcludeResponseData} message RpcGroupExcludeResponseData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcGroupExcludeResponseData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.objects || options.defaults)
                object.error = {};
            let keys2;
            if (message.error && (keys2 = Object.keys(message.error)).length) {
                object.error = {};
                for (let j = 0; j < keys2.length; ++j)
                    object.error[keys2[j]] = message.error[keys2[j]];
            }
            return object;
        };

        /**
         * Converts this RpcGroupExcludeResponseData to JSON.
         * @function toJSON
         * @memberof api.RpcGroupExcludeResponseData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcGroupExcludeResponseData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcGroupExcludeResponseData
         * @function getTypeUrl
         * @memberof api.RpcGroupExcludeResponseData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcGroupExcludeResponseData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcGroupExcludeResponseData";
        };

        return RpcGroupExcludeResponseData;
    })();

    api.RpcGroupGetRequest = (function() {

        /**
         * Properties of a RpcGroupGetRequest.
         * @memberof api
         * @interface IRpcGroupGetRequest
         * @property {string|null} [group_uid] RpcGroupGetRequest group_uid
         */

        /**
         * Constructs a new RpcGroupGetRequest.
         * @memberof api
         * @classdesc Represents a RpcGroupGetRequest.
         * @implements IRpcGroupGetRequest
         * @constructor
         * @param {api.IRpcGroupGetRequest=} [properties] Properties to set
         */
        function RpcGroupGetRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcGroupGetRequest group_uid.
         * @member {string} group_uid
         * @memberof api.RpcGroupGetRequest
         * @instance
         */
        RpcGroupGetRequest.prototype.group_uid = "";

        /**
         * Creates a new RpcGroupGetRequest instance using the specified properties.
         * @function create
         * @memberof api.RpcGroupGetRequest
         * @static
         * @param {api.IRpcGroupGetRequest=} [properties] Properties to set
         * @returns {api.RpcGroupGetRequest} RpcGroupGetRequest instance
         */
        RpcGroupGetRequest.create = function create(properties) {
            return new RpcGroupGetRequest(properties);
        };

        /**
         * Encodes the specified RpcGroupGetRequest message. Does not implicitly {@link api.RpcGroupGetRequest.verify|verify} messages.
         * @function encode
         * @memberof api.RpcGroupGetRequest
         * @static
         * @param {api.RpcGroupGetRequest} message RpcGroupGetRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGroupGetRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.group_uid != null && Object.hasOwnProperty.call(message, "group_uid"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.group_uid);
            return writer;
        };

        /**
         * Encodes the specified RpcGroupGetRequest message, length delimited. Does not implicitly {@link api.RpcGroupGetRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcGroupGetRequest
         * @static
         * @param {api.RpcGroupGetRequest} message RpcGroupGetRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGroupGetRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcGroupGetRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcGroupGetRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcGroupGetRequest} RpcGroupGetRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGroupGetRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcGroupGetRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.group_uid = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcGroupGetRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcGroupGetRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcGroupGetRequest} RpcGroupGetRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGroupGetRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcGroupGetRequest message.
         * @function verify
         * @memberof api.RpcGroupGetRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcGroupGetRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.group_uid != null && message.hasOwnProperty("group_uid"))
                if (!$util.isString(message.group_uid))
                    return "group_uid: string expected";
            return null;
        };

        /**
         * Creates a RpcGroupGetRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcGroupGetRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcGroupGetRequest} RpcGroupGetRequest
         */
        RpcGroupGetRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcGroupGetRequest)
                return object;
            let message = new $root.api.RpcGroupGetRequest();
            if (object.group_uid != null)
                message.group_uid = String(object.group_uid);
            return message;
        };

        /**
         * Creates a plain object from a RpcGroupGetRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcGroupGetRequest
         * @static
         * @param {api.RpcGroupGetRequest} message RpcGroupGetRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcGroupGetRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.group_uid = "";
            if (message.group_uid != null && message.hasOwnProperty("group_uid"))
                object.group_uid = message.group_uid;
            return object;
        };

        /**
         * Converts this RpcGroupGetRequest to JSON.
         * @function toJSON
         * @memberof api.RpcGroupGetRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcGroupGetRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcGroupGetRequest
         * @function getTypeUrl
         * @memberof api.RpcGroupGetRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcGroupGetRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcGroupGetRequest";
        };

        return RpcGroupGetRequest;
    })();

    api.RpcGroupGetResponse = (function() {

        /**
         * Properties of a RpcGroupGetResponse.
         * @memberof api
         * @interface IRpcGroupGetResponse
         * @property {number|null} [code] RpcGroupGetResponse code
         * @property {api.RpcGroupGetResponseData|null} [data] RpcGroupGetResponse data
         * @property {api.Error|null} [error] RpcGroupGetResponse error
         * @property {string|null} [message] RpcGroupGetResponse message
         */

        /**
         * Constructs a new RpcGroupGetResponse.
         * @memberof api
         * @classdesc Represents a RpcGroupGetResponse.
         * @implements IRpcGroupGetResponse
         * @constructor
         * @param {api.IRpcGroupGetResponse=} [properties] Properties to set
         */
        function RpcGroupGetResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcGroupGetResponse code.
         * @member {number} code
         * @memberof api.RpcGroupGetResponse
         * @instance
         */
        RpcGroupGetResponse.prototype.code = 0;

        /**
         * RpcGroupGetResponse data.
         * @member {api.RpcGroupGetResponseData|null|undefined} data
         * @memberof api.RpcGroupGetResponse
         * @instance
         */
        RpcGroupGetResponse.prototype.data = null;

        /**
         * RpcGroupGetResponse error.
         * @member {api.Error|null|undefined} error
         * @memberof api.RpcGroupGetResponse
         * @instance
         */
        RpcGroupGetResponse.prototype.error = null;

        /**
         * RpcGroupGetResponse message.
         * @member {string} message
         * @memberof api.RpcGroupGetResponse
         * @instance
         */
        RpcGroupGetResponse.prototype.message = "";

        /**
         * Creates a new RpcGroupGetResponse instance using the specified properties.
         * @function create
         * @memberof api.RpcGroupGetResponse
         * @static
         * @param {api.IRpcGroupGetResponse=} [properties] Properties to set
         * @returns {api.RpcGroupGetResponse} RpcGroupGetResponse instance
         */
        RpcGroupGetResponse.create = function create(properties) {
            return new RpcGroupGetResponse(properties);
        };

        /**
         * Encodes the specified RpcGroupGetResponse message. Does not implicitly {@link api.RpcGroupGetResponse.verify|verify} messages.
         * @function encode
         * @memberof api.RpcGroupGetResponse
         * @static
         * @param {api.RpcGroupGetResponse} message RpcGroupGetResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGroupGetResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.code);
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                $root.api.RpcGroupGetResponseData.encode(message.data, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                $root.api.Error.encode(message.error, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.message);
            return writer;
        };

        /**
         * Encodes the specified RpcGroupGetResponse message, length delimited. Does not implicitly {@link api.RpcGroupGetResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcGroupGetResponse
         * @static
         * @param {api.RpcGroupGetResponse} message RpcGroupGetResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGroupGetResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcGroupGetResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcGroupGetResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcGroupGetResponse} RpcGroupGetResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGroupGetResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcGroupGetResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.code = reader.int32();
                        break;
                    }
                case 2: {
                        message.data = $root.api.RpcGroupGetResponseData.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.error = $root.api.Error.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.message = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcGroupGetResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcGroupGetResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcGroupGetResponse} RpcGroupGetResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGroupGetResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcGroupGetResponse message.
         * @function verify
         * @memberof api.RpcGroupGetResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcGroupGetResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.code != null && message.hasOwnProperty("code"))
                if (!$util.isInteger(message.code))
                    return "code: integer expected";
            if (message.data != null && message.hasOwnProperty("data")) {
                let error = $root.api.RpcGroupGetResponseData.verify(message.data);
                if (error)
                    return "data." + error;
            }
            if (message.error != null && message.hasOwnProperty("error")) {
                let error = $root.api.Error.verify(message.error);
                if (error)
                    return "error." + error;
            }
            if (message.message != null && message.hasOwnProperty("message"))
                if (!$util.isString(message.message))
                    return "message: string expected";
            return null;
        };

        /**
         * Creates a RpcGroupGetResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcGroupGetResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcGroupGetResponse} RpcGroupGetResponse
         */
        RpcGroupGetResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcGroupGetResponse)
                return object;
            let message = new $root.api.RpcGroupGetResponse();
            if (object.code != null)
                message.code = object.code | 0;
            if (object.data != null) {
                if (typeof object.data !== "object")
                    throw TypeError(".api.RpcGroupGetResponse.data: object expected");
                message.data = $root.api.RpcGroupGetResponseData.fromObject(object.data);
            }
            if (object.error != null) {
                if (typeof object.error !== "object")
                    throw TypeError(".api.RpcGroupGetResponse.error: object expected");
                message.error = $root.api.Error.fromObject(object.error);
            }
            if (object.message != null)
                message.message = String(object.message);
            return message;
        };

        /**
         * Creates a plain object from a RpcGroupGetResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcGroupGetResponse
         * @static
         * @param {api.RpcGroupGetResponse} message RpcGroupGetResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcGroupGetResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.code = 0;
                object.data = null;
                object.error = null;
                object.message = "";
            }
            if (message.code != null && message.hasOwnProperty("code"))
                object.code = message.code;
            if (message.data != null && message.hasOwnProperty("data"))
                object.data = $root.api.RpcGroupGetResponseData.toObject(message.data, options);
            if (message.error != null && message.hasOwnProperty("error"))
                object.error = $root.api.Error.toObject(message.error, options);
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = message.message;
            return object;
        };

        /**
         * Converts this RpcGroupGetResponse to JSON.
         * @function toJSON
         * @memberof api.RpcGroupGetResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcGroupGetResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcGroupGetResponse
         * @function getTypeUrl
         * @memberof api.RpcGroupGetResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcGroupGetResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcGroupGetResponse";
        };

        return RpcGroupGetResponse;
    })();

    api.RpcGroupGetResponseData = (function() {

        /**
         * Properties of a RpcGroupGetResponseData.
         * @memberof api
         * @interface IRpcGroupGetResponseData
         * @property {api.RpcGroupCreateResponseDataGroup|null} [group] RpcGroupGetResponseData group
         */

        /**
         * Constructs a new RpcGroupGetResponseData.
         * @memberof api
         * @classdesc Represents a RpcGroupGetResponseData.
         * @implements IRpcGroupGetResponseData
         * @constructor
         * @param {api.IRpcGroupGetResponseData=} [properties] Properties to set
         */
        function RpcGroupGetResponseData(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcGroupGetResponseData group.
         * @member {api.RpcGroupCreateResponseDataGroup|null|undefined} group
         * @memberof api.RpcGroupGetResponseData
         * @instance
         */
        RpcGroupGetResponseData.prototype.group = null;

        /**
         * Creates a new RpcGroupGetResponseData instance using the specified properties.
         * @function create
         * @memberof api.RpcGroupGetResponseData
         * @static
         * @param {api.IRpcGroupGetResponseData=} [properties] Properties to set
         * @returns {api.RpcGroupGetResponseData} RpcGroupGetResponseData instance
         */
        RpcGroupGetResponseData.create = function create(properties) {
            return new RpcGroupGetResponseData(properties);
        };

        /**
         * Encodes the specified RpcGroupGetResponseData message. Does not implicitly {@link api.RpcGroupGetResponseData.verify|verify} messages.
         * @function encode
         * @memberof api.RpcGroupGetResponseData
         * @static
         * @param {api.RpcGroupGetResponseData} message RpcGroupGetResponseData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGroupGetResponseData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.group != null && Object.hasOwnProperty.call(message, "group"))
                $root.api.RpcGroupCreateResponseDataGroup.encode(message.group, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified RpcGroupGetResponseData message, length delimited. Does not implicitly {@link api.RpcGroupGetResponseData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcGroupGetResponseData
         * @static
         * @param {api.RpcGroupGetResponseData} message RpcGroupGetResponseData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGroupGetResponseData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcGroupGetResponseData message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcGroupGetResponseData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcGroupGetResponseData} RpcGroupGetResponseData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGroupGetResponseData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcGroupGetResponseData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.group = $root.api.RpcGroupCreateResponseDataGroup.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcGroupGetResponseData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcGroupGetResponseData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcGroupGetResponseData} RpcGroupGetResponseData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGroupGetResponseData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcGroupGetResponseData message.
         * @function verify
         * @memberof api.RpcGroupGetResponseData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcGroupGetResponseData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.group != null && message.hasOwnProperty("group")) {
                let error = $root.api.RpcGroupCreateResponseDataGroup.verify(message.group);
                if (error)
                    return "group." + error;
            }
            return null;
        };

        /**
         * Creates a RpcGroupGetResponseData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcGroupGetResponseData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcGroupGetResponseData} RpcGroupGetResponseData
         */
        RpcGroupGetResponseData.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcGroupGetResponseData)
                return object;
            let message = new $root.api.RpcGroupGetResponseData();
            if (object.group != null) {
                if (typeof object.group !== "object")
                    throw TypeError(".api.RpcGroupGetResponseData.group: object expected");
                message.group = $root.api.RpcGroupCreateResponseDataGroup.fromObject(object.group);
            }
            return message;
        };

        /**
         * Creates a plain object from a RpcGroupGetResponseData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcGroupGetResponseData
         * @static
         * @param {api.RpcGroupGetResponseData} message RpcGroupGetResponseData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcGroupGetResponseData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.group = null;
            if (message.group != null && message.hasOwnProperty("group"))
                object.group = $root.api.RpcGroupCreateResponseDataGroup.toObject(message.group, options);
            return object;
        };

        /**
         * Converts this RpcGroupGetResponseData to JSON.
         * @function toJSON
         * @memberof api.RpcGroupGetResponseData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcGroupGetResponseData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcGroupGetResponseData
         * @function getTypeUrl
         * @memberof api.RpcGroupGetResponseData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcGroupGetResponseData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcGroupGetResponseData";
        };

        return RpcGroupGetResponseData;
    })();

    api.RpcGroupListRequest = (function() {

        /**
         * Properties of a RpcGroupListRequest.
         * @memberof api
         * @interface IRpcGroupListRequest
         * @property {string|null} [query] RpcGroupListRequest query
         */

        /**
         * Constructs a new RpcGroupListRequest.
         * @memberof api
         * @classdesc Represents a RpcGroupListRequest.
         * @implements IRpcGroupListRequest
         * @constructor
         * @param {api.IRpcGroupListRequest=} [properties] Properties to set
         */
        function RpcGroupListRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcGroupListRequest query.
         * @member {string} query
         * @memberof api.RpcGroupListRequest
         * @instance
         */
        RpcGroupListRequest.prototype.query = "";

        /**
         * Creates a new RpcGroupListRequest instance using the specified properties.
         * @function create
         * @memberof api.RpcGroupListRequest
         * @static
         * @param {api.IRpcGroupListRequest=} [properties] Properties to set
         * @returns {api.RpcGroupListRequest} RpcGroupListRequest instance
         */
        RpcGroupListRequest.create = function create(properties) {
            return new RpcGroupListRequest(properties);
        };

        /**
         * Encodes the specified RpcGroupListRequest message. Does not implicitly {@link api.RpcGroupListRequest.verify|verify} messages.
         * @function encode
         * @memberof api.RpcGroupListRequest
         * @static
         * @param {api.RpcGroupListRequest} message RpcGroupListRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGroupListRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.query != null && Object.hasOwnProperty.call(message, "query"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.query);
            return writer;
        };

        /**
         * Encodes the specified RpcGroupListRequest message, length delimited. Does not implicitly {@link api.RpcGroupListRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcGroupListRequest
         * @static
         * @param {api.RpcGroupListRequest} message RpcGroupListRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGroupListRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcGroupListRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcGroupListRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcGroupListRequest} RpcGroupListRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGroupListRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcGroupListRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.query = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcGroupListRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcGroupListRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcGroupListRequest} RpcGroupListRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGroupListRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcGroupListRequest message.
         * @function verify
         * @memberof api.RpcGroupListRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcGroupListRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.query != null && message.hasOwnProperty("query"))
                if (!$util.isString(message.query))
                    return "query: string expected";
            return null;
        };

        /**
         * Creates a RpcGroupListRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcGroupListRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcGroupListRequest} RpcGroupListRequest
         */
        RpcGroupListRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcGroupListRequest)
                return object;
            let message = new $root.api.RpcGroupListRequest();
            if (object.query != null)
                message.query = String(object.query);
            return message;
        };

        /**
         * Creates a plain object from a RpcGroupListRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcGroupListRequest
         * @static
         * @param {api.RpcGroupListRequest} message RpcGroupListRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcGroupListRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.query = "";
            if (message.query != null && message.hasOwnProperty("query"))
                object.query = message.query;
            return object;
        };

        /**
         * Converts this RpcGroupListRequest to JSON.
         * @function toJSON
         * @memberof api.RpcGroupListRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcGroupListRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcGroupListRequest
         * @function getTypeUrl
         * @memberof api.RpcGroupListRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcGroupListRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcGroupListRequest";
        };

        return RpcGroupListRequest;
    })();

    api.RpcGroupListResponse = (function() {

        /**
         * Properties of a RpcGroupListResponse.
         * @memberof api
         * @interface IRpcGroupListResponse
         * @property {number|null} [code] RpcGroupListResponse code
         * @property {api.RpcGroupListResponseData|null} [data] RpcGroupListResponse data
         * @property {api.Error|null} [error] RpcGroupListResponse error
         * @property {string|null} [message] RpcGroupListResponse message
         */

        /**
         * Constructs a new RpcGroupListResponse.
         * @memberof api
         * @classdesc Represents a RpcGroupListResponse.
         * @implements IRpcGroupListResponse
         * @constructor
         * @param {api.IRpcGroupListResponse=} [properties] Properties to set
         */
        function RpcGroupListResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcGroupListResponse code.
         * @member {number} code
         * @memberof api.RpcGroupListResponse
         * @instance
         */
        RpcGroupListResponse.prototype.code = 0;

        /**
         * RpcGroupListResponse data.
         * @member {api.RpcGroupListResponseData|null|undefined} data
         * @memberof api.RpcGroupListResponse
         * @instance
         */
        RpcGroupListResponse.prototype.data = null;

        /**
         * RpcGroupListResponse error.
         * @member {api.Error|null|undefined} error
         * @memberof api.RpcGroupListResponse
         * @instance
         */
        RpcGroupListResponse.prototype.error = null;

        /**
         * RpcGroupListResponse message.
         * @member {string} message
         * @memberof api.RpcGroupListResponse
         * @instance
         */
        RpcGroupListResponse.prototype.message = "";

        /**
         * Creates a new RpcGroupListResponse instance using the specified properties.
         * @function create
         * @memberof api.RpcGroupListResponse
         * @static
         * @param {api.IRpcGroupListResponse=} [properties] Properties to set
         * @returns {api.RpcGroupListResponse} RpcGroupListResponse instance
         */
        RpcGroupListResponse.create = function create(properties) {
            return new RpcGroupListResponse(properties);
        };

        /**
         * Encodes the specified RpcGroupListResponse message. Does not implicitly {@link api.RpcGroupListResponse.verify|verify} messages.
         * @function encode
         * @memberof api.RpcGroupListResponse
         * @static
         * @param {api.RpcGroupListResponse} message RpcGroupListResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGroupListResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.code);
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                $root.api.RpcGroupListResponseData.encode(message.data, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                $root.api.Error.encode(message.error, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.message);
            return writer;
        };

        /**
         * Encodes the specified RpcGroupListResponse message, length delimited. Does not implicitly {@link api.RpcGroupListResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcGroupListResponse
         * @static
         * @param {api.RpcGroupListResponse} message RpcGroupListResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGroupListResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcGroupListResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcGroupListResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcGroupListResponse} RpcGroupListResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGroupListResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcGroupListResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.code = reader.int32();
                        break;
                    }
                case 2: {
                        message.data = $root.api.RpcGroupListResponseData.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.error = $root.api.Error.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.message = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcGroupListResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcGroupListResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcGroupListResponse} RpcGroupListResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGroupListResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcGroupListResponse message.
         * @function verify
         * @memberof api.RpcGroupListResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcGroupListResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.code != null && message.hasOwnProperty("code"))
                if (!$util.isInteger(message.code))
                    return "code: integer expected";
            if (message.data != null && message.hasOwnProperty("data")) {
                let error = $root.api.RpcGroupListResponseData.verify(message.data);
                if (error)
                    return "data." + error;
            }
            if (message.error != null && message.hasOwnProperty("error")) {
                let error = $root.api.Error.verify(message.error);
                if (error)
                    return "error." + error;
            }
            if (message.message != null && message.hasOwnProperty("message"))
                if (!$util.isString(message.message))
                    return "message: string expected";
            return null;
        };

        /**
         * Creates a RpcGroupListResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcGroupListResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcGroupListResponse} RpcGroupListResponse
         */
        RpcGroupListResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcGroupListResponse)
                return object;
            let message = new $root.api.RpcGroupListResponse();
            if (object.code != null)
                message.code = object.code | 0;
            if (object.data != null) {
                if (typeof object.data !== "object")
                    throw TypeError(".api.RpcGroupListResponse.data: object expected");
                message.data = $root.api.RpcGroupListResponseData.fromObject(object.data);
            }
            if (object.error != null) {
                if (typeof object.error !== "object")
                    throw TypeError(".api.RpcGroupListResponse.error: object expected");
                message.error = $root.api.Error.fromObject(object.error);
            }
            if (object.message != null)
                message.message = String(object.message);
            return message;
        };

        /**
         * Creates a plain object from a RpcGroupListResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcGroupListResponse
         * @static
         * @param {api.RpcGroupListResponse} message RpcGroupListResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcGroupListResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.code = 0;
                object.data = null;
                object.error = null;
                object.message = "";
            }
            if (message.code != null && message.hasOwnProperty("code"))
                object.code = message.code;
            if (message.data != null && message.hasOwnProperty("data"))
                object.data = $root.api.RpcGroupListResponseData.toObject(message.data, options);
            if (message.error != null && message.hasOwnProperty("error"))
                object.error = $root.api.Error.toObject(message.error, options);
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = message.message;
            return object;
        };

        /**
         * Converts this RpcGroupListResponse to JSON.
         * @function toJSON
         * @memberof api.RpcGroupListResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcGroupListResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcGroupListResponse
         * @function getTypeUrl
         * @memberof api.RpcGroupListResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcGroupListResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcGroupListResponse";
        };

        return RpcGroupListResponse;
    })();

    api.RpcGroupListResponseData = (function() {

        /**
         * Properties of a RpcGroupListResponseData.
         * @memberof api
         * @interface IRpcGroupListResponseData
         * @property {Array.<api.RpcGroupCreateResponseDataGroup>|null} [groups] RpcGroupListResponseData groups
         * @property {api.RpcGroupListResponseMeta|null} [meta] RpcGroupListResponseData meta
         */

        /**
         * Constructs a new RpcGroupListResponseData.
         * @memberof api
         * @classdesc Represents a RpcGroupListResponseData.
         * @implements IRpcGroupListResponseData
         * @constructor
         * @param {api.IRpcGroupListResponseData=} [properties] Properties to set
         */
        function RpcGroupListResponseData(properties) {
            this.groups = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcGroupListResponseData groups.
         * @member {Array.<api.RpcGroupCreateResponseDataGroup>} groups
         * @memberof api.RpcGroupListResponseData
         * @instance
         */
        RpcGroupListResponseData.prototype.groups = $util.emptyArray;

        /**
         * RpcGroupListResponseData meta.
         * @member {api.RpcGroupListResponseMeta|null|undefined} meta
         * @memberof api.RpcGroupListResponseData
         * @instance
         */
        RpcGroupListResponseData.prototype.meta = null;

        /**
         * Creates a new RpcGroupListResponseData instance using the specified properties.
         * @function create
         * @memberof api.RpcGroupListResponseData
         * @static
         * @param {api.IRpcGroupListResponseData=} [properties] Properties to set
         * @returns {api.RpcGroupListResponseData} RpcGroupListResponseData instance
         */
        RpcGroupListResponseData.create = function create(properties) {
            return new RpcGroupListResponseData(properties);
        };

        /**
         * Encodes the specified RpcGroupListResponseData message. Does not implicitly {@link api.RpcGroupListResponseData.verify|verify} messages.
         * @function encode
         * @memberof api.RpcGroupListResponseData
         * @static
         * @param {api.RpcGroupListResponseData} message RpcGroupListResponseData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGroupListResponseData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.groups != null && message.groups.length)
                for (let i = 0; i < message.groups.length; ++i)
                    $root.api.RpcGroupCreateResponseDataGroup.encode(message.groups[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.meta != null && Object.hasOwnProperty.call(message, "meta"))
                $root.api.RpcGroupListResponseMeta.encode(message.meta, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified RpcGroupListResponseData message, length delimited. Does not implicitly {@link api.RpcGroupListResponseData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcGroupListResponseData
         * @static
         * @param {api.RpcGroupListResponseData} message RpcGroupListResponseData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGroupListResponseData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcGroupListResponseData message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcGroupListResponseData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcGroupListResponseData} RpcGroupListResponseData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGroupListResponseData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcGroupListResponseData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.groups && message.groups.length))
                            message.groups = [];
                        message.groups.push($root.api.RpcGroupCreateResponseDataGroup.decode(reader, reader.uint32()));
                        break;
                    }
                case 2: {
                        message.meta = $root.api.RpcGroupListResponseMeta.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcGroupListResponseData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcGroupListResponseData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcGroupListResponseData} RpcGroupListResponseData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGroupListResponseData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcGroupListResponseData message.
         * @function verify
         * @memberof api.RpcGroupListResponseData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcGroupListResponseData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.groups != null && message.hasOwnProperty("groups")) {
                if (!Array.isArray(message.groups))
                    return "groups: array expected";
                for (let i = 0; i < message.groups.length; ++i) {
                    let error = $root.api.RpcGroupCreateResponseDataGroup.verify(message.groups[i]);
                    if (error)
                        return "groups." + error;
                }
            }
            if (message.meta != null && message.hasOwnProperty("meta")) {
                let error = $root.api.RpcGroupListResponseMeta.verify(message.meta);
                if (error)
                    return "meta." + error;
            }
            return null;
        };

        /**
         * Creates a RpcGroupListResponseData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcGroupListResponseData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcGroupListResponseData} RpcGroupListResponseData
         */
        RpcGroupListResponseData.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcGroupListResponseData)
                return object;
            let message = new $root.api.RpcGroupListResponseData();
            if (object.groups) {
                if (!Array.isArray(object.groups))
                    throw TypeError(".api.RpcGroupListResponseData.groups: array expected");
                message.groups = [];
                for (let i = 0; i < object.groups.length; ++i) {
                    if (typeof object.groups[i] !== "object")
                        throw TypeError(".api.RpcGroupListResponseData.groups: object expected");
                    message.groups[i] = $root.api.RpcGroupCreateResponseDataGroup.fromObject(object.groups[i]);
                }
            }
            if (object.meta != null) {
                if (typeof object.meta !== "object")
                    throw TypeError(".api.RpcGroupListResponseData.meta: object expected");
                message.meta = $root.api.RpcGroupListResponseMeta.fromObject(object.meta);
            }
            return message;
        };

        /**
         * Creates a plain object from a RpcGroupListResponseData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcGroupListResponseData
         * @static
         * @param {api.RpcGroupListResponseData} message RpcGroupListResponseData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcGroupListResponseData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.groups = [];
            if (options.defaults)
                object.meta = null;
            if (message.groups && message.groups.length) {
                object.groups = [];
                for (let j = 0; j < message.groups.length; ++j)
                    object.groups[j] = $root.api.RpcGroupCreateResponseDataGroup.toObject(message.groups[j], options);
            }
            if (message.meta != null && message.hasOwnProperty("meta"))
                object.meta = $root.api.RpcGroupListResponseMeta.toObject(message.meta, options);
            return object;
        };

        /**
         * Converts this RpcGroupListResponseData to JSON.
         * @function toJSON
         * @memberof api.RpcGroupListResponseData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcGroupListResponseData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcGroupListResponseData
         * @function getTypeUrl
         * @memberof api.RpcGroupListResponseData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcGroupListResponseData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcGroupListResponseData";
        };

        return RpcGroupListResponseData;
    })();

    api.RpcGroupListResponseMeta = (function() {

        /**
         * Properties of a RpcGroupListResponseMeta.
         * @memberof api
         * @interface IRpcGroupListResponseMeta
         * @property {number|null} [count] RpcGroupListResponseMeta count
         * @property {number|null} [limit] RpcGroupListResponseMeta limit
         * @property {number|null} [offset] RpcGroupListResponseMeta offset
         * @property {string|null} [order] RpcGroupListResponseMeta order
         */

        /**
         * Constructs a new RpcGroupListResponseMeta.
         * @memberof api
         * @classdesc Represents a RpcGroupListResponseMeta.
         * @implements IRpcGroupListResponseMeta
         * @constructor
         * @param {api.IRpcGroupListResponseMeta=} [properties] Properties to set
         */
        function RpcGroupListResponseMeta(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcGroupListResponseMeta count.
         * @member {number} count
         * @memberof api.RpcGroupListResponseMeta
         * @instance
         */
        RpcGroupListResponseMeta.prototype.count = 0;

        /**
         * RpcGroupListResponseMeta limit.
         * @member {number} limit
         * @memberof api.RpcGroupListResponseMeta
         * @instance
         */
        RpcGroupListResponseMeta.prototype.limit = 0;

        /**
         * RpcGroupListResponseMeta offset.
         * @member {number} offset
         * @memberof api.RpcGroupListResponseMeta
         * @instance
         */
        RpcGroupListResponseMeta.prototype.offset = 0;

        /**
         * RpcGroupListResponseMeta order.
         * @member {string} order
         * @memberof api.RpcGroupListResponseMeta
         * @instance
         */
        RpcGroupListResponseMeta.prototype.order = "";

        /**
         * Creates a new RpcGroupListResponseMeta instance using the specified properties.
         * @function create
         * @memberof api.RpcGroupListResponseMeta
         * @static
         * @param {api.IRpcGroupListResponseMeta=} [properties] Properties to set
         * @returns {api.RpcGroupListResponseMeta} RpcGroupListResponseMeta instance
         */
        RpcGroupListResponseMeta.create = function create(properties) {
            return new RpcGroupListResponseMeta(properties);
        };

        /**
         * Encodes the specified RpcGroupListResponseMeta message. Does not implicitly {@link api.RpcGroupListResponseMeta.verify|verify} messages.
         * @function encode
         * @memberof api.RpcGroupListResponseMeta
         * @static
         * @param {api.RpcGroupListResponseMeta} message RpcGroupListResponseMeta message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGroupListResponseMeta.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.count);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.limit);
            if (message.offset != null && Object.hasOwnProperty.call(message, "offset"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.offset);
            if (message.order != null && Object.hasOwnProperty.call(message, "order"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.order);
            return writer;
        };

        /**
         * Encodes the specified RpcGroupListResponseMeta message, length delimited. Does not implicitly {@link api.RpcGroupListResponseMeta.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcGroupListResponseMeta
         * @static
         * @param {api.RpcGroupListResponseMeta} message RpcGroupListResponseMeta message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGroupListResponseMeta.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcGroupListResponseMeta message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcGroupListResponseMeta
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcGroupListResponseMeta} RpcGroupListResponseMeta
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGroupListResponseMeta.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcGroupListResponseMeta();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.count = reader.int32();
                        break;
                    }
                case 2: {
                        message.limit = reader.int32();
                        break;
                    }
                case 3: {
                        message.offset = reader.int32();
                        break;
                    }
                case 4: {
                        message.order = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcGroupListResponseMeta message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcGroupListResponseMeta
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcGroupListResponseMeta} RpcGroupListResponseMeta
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGroupListResponseMeta.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcGroupListResponseMeta message.
         * @function verify
         * @memberof api.RpcGroupListResponseMeta
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcGroupListResponseMeta.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.count != null && message.hasOwnProperty("count"))
                if (!$util.isInteger(message.count))
                    return "count: integer expected";
            if (message.limit != null && message.hasOwnProperty("limit"))
                if (!$util.isInteger(message.limit))
                    return "limit: integer expected";
            if (message.offset != null && message.hasOwnProperty("offset"))
                if (!$util.isInteger(message.offset))
                    return "offset: integer expected";
            if (message.order != null && message.hasOwnProperty("order"))
                if (!$util.isString(message.order))
                    return "order: string expected";
            return null;
        };

        /**
         * Creates a RpcGroupListResponseMeta message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcGroupListResponseMeta
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcGroupListResponseMeta} RpcGroupListResponseMeta
         */
        RpcGroupListResponseMeta.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcGroupListResponseMeta)
                return object;
            let message = new $root.api.RpcGroupListResponseMeta();
            if (object.count != null)
                message.count = object.count | 0;
            if (object.limit != null)
                message.limit = object.limit | 0;
            if (object.offset != null)
                message.offset = object.offset | 0;
            if (object.order != null)
                message.order = String(object.order);
            return message;
        };

        /**
         * Creates a plain object from a RpcGroupListResponseMeta message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcGroupListResponseMeta
         * @static
         * @param {api.RpcGroupListResponseMeta} message RpcGroupListResponseMeta
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcGroupListResponseMeta.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.count = 0;
                object.limit = 0;
                object.offset = 0;
                object.order = "";
            }
            if (message.count != null && message.hasOwnProperty("count"))
                object.count = message.count;
            if (message.limit != null && message.hasOwnProperty("limit"))
                object.limit = message.limit;
            if (message.offset != null && message.hasOwnProperty("offset"))
                object.offset = message.offset;
            if (message.order != null && message.hasOwnProperty("order"))
                object.order = message.order;
            return object;
        };

        /**
         * Converts this RpcGroupListResponseMeta to JSON.
         * @function toJSON
         * @memberof api.RpcGroupListResponseMeta
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcGroupListResponseMeta.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcGroupListResponseMeta
         * @function getTypeUrl
         * @memberof api.RpcGroupListResponseMeta
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcGroupListResponseMeta.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcGroupListResponseMeta";
        };

        return RpcGroupListResponseMeta;
    })();

    api.RpcGroupUpdateRequest = (function() {

        /**
         * Properties of a RpcGroupUpdateRequest.
         * @memberof api
         * @interface IRpcGroupUpdateRequest
         * @property {string|null} [group_uid] RpcGroupUpdateRequest group_uid
         * @property {string|null} [tg] RpcGroupUpdateRequest tg
         * @property {string|null} [name] RpcGroupUpdateRequest name
         */

        /**
         * Constructs a new RpcGroupUpdateRequest.
         * @memberof api
         * @classdesc Represents a RpcGroupUpdateRequest.
         * @implements IRpcGroupUpdateRequest
         * @constructor
         * @param {api.IRpcGroupUpdateRequest=} [properties] Properties to set
         */
        function RpcGroupUpdateRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcGroupUpdateRequest group_uid.
         * @member {string} group_uid
         * @memberof api.RpcGroupUpdateRequest
         * @instance
         */
        RpcGroupUpdateRequest.prototype.group_uid = "";

        /**
         * RpcGroupUpdateRequest tg.
         * @member {string} tg
         * @memberof api.RpcGroupUpdateRequest
         * @instance
         */
        RpcGroupUpdateRequest.prototype.tg = "";

        /**
         * RpcGroupUpdateRequest name.
         * @member {string} name
         * @memberof api.RpcGroupUpdateRequest
         * @instance
         */
        RpcGroupUpdateRequest.prototype.name = "";

        /**
         * Creates a new RpcGroupUpdateRequest instance using the specified properties.
         * @function create
         * @memberof api.RpcGroupUpdateRequest
         * @static
         * @param {api.IRpcGroupUpdateRequest=} [properties] Properties to set
         * @returns {api.RpcGroupUpdateRequest} RpcGroupUpdateRequest instance
         */
        RpcGroupUpdateRequest.create = function create(properties) {
            return new RpcGroupUpdateRequest(properties);
        };

        /**
         * Encodes the specified RpcGroupUpdateRequest message. Does not implicitly {@link api.RpcGroupUpdateRequest.verify|verify} messages.
         * @function encode
         * @memberof api.RpcGroupUpdateRequest
         * @static
         * @param {api.RpcGroupUpdateRequest} message RpcGroupUpdateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGroupUpdateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.group_uid != null && Object.hasOwnProperty.call(message, "group_uid"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.group_uid);
            if (message.tg != null && Object.hasOwnProperty.call(message, "tg"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.tg);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            return writer;
        };

        /**
         * Encodes the specified RpcGroupUpdateRequest message, length delimited. Does not implicitly {@link api.RpcGroupUpdateRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcGroupUpdateRequest
         * @static
         * @param {api.RpcGroupUpdateRequest} message RpcGroupUpdateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGroupUpdateRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcGroupUpdateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcGroupUpdateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcGroupUpdateRequest} RpcGroupUpdateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGroupUpdateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcGroupUpdateRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.group_uid = reader.string();
                        break;
                    }
                case 2: {
                        message.tg = reader.string();
                        break;
                    }
                case 3: {
                        message.name = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcGroupUpdateRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcGroupUpdateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcGroupUpdateRequest} RpcGroupUpdateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGroupUpdateRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcGroupUpdateRequest message.
         * @function verify
         * @memberof api.RpcGroupUpdateRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcGroupUpdateRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.group_uid != null && message.hasOwnProperty("group_uid"))
                if (!$util.isString(message.group_uid))
                    return "group_uid: string expected";
            if (message.tg != null && message.hasOwnProperty("tg"))
                if (!$util.isString(message.tg))
                    return "tg: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            return null;
        };

        /**
         * Creates a RpcGroupUpdateRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcGroupUpdateRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcGroupUpdateRequest} RpcGroupUpdateRequest
         */
        RpcGroupUpdateRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcGroupUpdateRequest)
                return object;
            let message = new $root.api.RpcGroupUpdateRequest();
            if (object.group_uid != null)
                message.group_uid = String(object.group_uid);
            if (object.tg != null)
                message.tg = String(object.tg);
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };

        /**
         * Creates a plain object from a RpcGroupUpdateRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcGroupUpdateRequest
         * @static
         * @param {api.RpcGroupUpdateRequest} message RpcGroupUpdateRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcGroupUpdateRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.group_uid = "";
                object.tg = "";
                object.name = "";
            }
            if (message.group_uid != null && message.hasOwnProperty("group_uid"))
                object.group_uid = message.group_uid;
            if (message.tg != null && message.hasOwnProperty("tg"))
                object.tg = message.tg;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            return object;
        };

        /**
         * Converts this RpcGroupUpdateRequest to JSON.
         * @function toJSON
         * @memberof api.RpcGroupUpdateRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcGroupUpdateRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcGroupUpdateRequest
         * @function getTypeUrl
         * @memberof api.RpcGroupUpdateRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcGroupUpdateRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcGroupUpdateRequest";
        };

        return RpcGroupUpdateRequest;
    })();

    api.RpcGroupUpdateResponse = (function() {

        /**
         * Properties of a RpcGroupUpdateResponse.
         * @memberof api
         * @interface IRpcGroupUpdateResponse
         * @property {number|null} [code] RpcGroupUpdateResponse code
         * @property {api.RpcGroupUpdateResponseData|null} [data] RpcGroupUpdateResponse data
         * @property {api.Error|null} [error] RpcGroupUpdateResponse error
         * @property {string|null} [message] RpcGroupUpdateResponse message
         */

        /**
         * Constructs a new RpcGroupUpdateResponse.
         * @memberof api
         * @classdesc Represents a RpcGroupUpdateResponse.
         * @implements IRpcGroupUpdateResponse
         * @constructor
         * @param {api.IRpcGroupUpdateResponse=} [properties] Properties to set
         */
        function RpcGroupUpdateResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcGroupUpdateResponse code.
         * @member {number} code
         * @memberof api.RpcGroupUpdateResponse
         * @instance
         */
        RpcGroupUpdateResponse.prototype.code = 0;

        /**
         * RpcGroupUpdateResponse data.
         * @member {api.RpcGroupUpdateResponseData|null|undefined} data
         * @memberof api.RpcGroupUpdateResponse
         * @instance
         */
        RpcGroupUpdateResponse.prototype.data = null;

        /**
         * RpcGroupUpdateResponse error.
         * @member {api.Error|null|undefined} error
         * @memberof api.RpcGroupUpdateResponse
         * @instance
         */
        RpcGroupUpdateResponse.prototype.error = null;

        /**
         * RpcGroupUpdateResponse message.
         * @member {string} message
         * @memberof api.RpcGroupUpdateResponse
         * @instance
         */
        RpcGroupUpdateResponse.prototype.message = "";

        /**
         * Creates a new RpcGroupUpdateResponse instance using the specified properties.
         * @function create
         * @memberof api.RpcGroupUpdateResponse
         * @static
         * @param {api.IRpcGroupUpdateResponse=} [properties] Properties to set
         * @returns {api.RpcGroupUpdateResponse} RpcGroupUpdateResponse instance
         */
        RpcGroupUpdateResponse.create = function create(properties) {
            return new RpcGroupUpdateResponse(properties);
        };

        /**
         * Encodes the specified RpcGroupUpdateResponse message. Does not implicitly {@link api.RpcGroupUpdateResponse.verify|verify} messages.
         * @function encode
         * @memberof api.RpcGroupUpdateResponse
         * @static
         * @param {api.RpcGroupUpdateResponse} message RpcGroupUpdateResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGroupUpdateResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.code);
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                $root.api.RpcGroupUpdateResponseData.encode(message.data, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                $root.api.Error.encode(message.error, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.message);
            return writer;
        };

        /**
         * Encodes the specified RpcGroupUpdateResponse message, length delimited. Does not implicitly {@link api.RpcGroupUpdateResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcGroupUpdateResponse
         * @static
         * @param {api.RpcGroupUpdateResponse} message RpcGroupUpdateResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGroupUpdateResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcGroupUpdateResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcGroupUpdateResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcGroupUpdateResponse} RpcGroupUpdateResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGroupUpdateResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcGroupUpdateResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.code = reader.int32();
                        break;
                    }
                case 2: {
                        message.data = $root.api.RpcGroupUpdateResponseData.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.error = $root.api.Error.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.message = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcGroupUpdateResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcGroupUpdateResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcGroupUpdateResponse} RpcGroupUpdateResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGroupUpdateResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcGroupUpdateResponse message.
         * @function verify
         * @memberof api.RpcGroupUpdateResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcGroupUpdateResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.code != null && message.hasOwnProperty("code"))
                if (!$util.isInteger(message.code))
                    return "code: integer expected";
            if (message.data != null && message.hasOwnProperty("data")) {
                let error = $root.api.RpcGroupUpdateResponseData.verify(message.data);
                if (error)
                    return "data." + error;
            }
            if (message.error != null && message.hasOwnProperty("error")) {
                let error = $root.api.Error.verify(message.error);
                if (error)
                    return "error." + error;
            }
            if (message.message != null && message.hasOwnProperty("message"))
                if (!$util.isString(message.message))
                    return "message: string expected";
            return null;
        };

        /**
         * Creates a RpcGroupUpdateResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcGroupUpdateResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcGroupUpdateResponse} RpcGroupUpdateResponse
         */
        RpcGroupUpdateResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcGroupUpdateResponse)
                return object;
            let message = new $root.api.RpcGroupUpdateResponse();
            if (object.code != null)
                message.code = object.code | 0;
            if (object.data != null) {
                if (typeof object.data !== "object")
                    throw TypeError(".api.RpcGroupUpdateResponse.data: object expected");
                message.data = $root.api.RpcGroupUpdateResponseData.fromObject(object.data);
            }
            if (object.error != null) {
                if (typeof object.error !== "object")
                    throw TypeError(".api.RpcGroupUpdateResponse.error: object expected");
                message.error = $root.api.Error.fromObject(object.error);
            }
            if (object.message != null)
                message.message = String(object.message);
            return message;
        };

        /**
         * Creates a plain object from a RpcGroupUpdateResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcGroupUpdateResponse
         * @static
         * @param {api.RpcGroupUpdateResponse} message RpcGroupUpdateResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcGroupUpdateResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.code = 0;
                object.data = null;
                object.error = null;
                object.message = "";
            }
            if (message.code != null && message.hasOwnProperty("code"))
                object.code = message.code;
            if (message.data != null && message.hasOwnProperty("data"))
                object.data = $root.api.RpcGroupUpdateResponseData.toObject(message.data, options);
            if (message.error != null && message.hasOwnProperty("error"))
                object.error = $root.api.Error.toObject(message.error, options);
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = message.message;
            return object;
        };

        /**
         * Converts this RpcGroupUpdateResponse to JSON.
         * @function toJSON
         * @memberof api.RpcGroupUpdateResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcGroupUpdateResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcGroupUpdateResponse
         * @function getTypeUrl
         * @memberof api.RpcGroupUpdateResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcGroupUpdateResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcGroupUpdateResponse";
        };

        return RpcGroupUpdateResponse;
    })();

    api.RpcGroupUpdateResponseData = (function() {

        /**
         * Properties of a RpcGroupUpdateResponseData.
         * @memberof api
         * @interface IRpcGroupUpdateResponseData
         * @property {api.RpcGroupCreateResponseDataGroup|null} [group] RpcGroupUpdateResponseData group
         */

        /**
         * Constructs a new RpcGroupUpdateResponseData.
         * @memberof api
         * @classdesc Represents a RpcGroupUpdateResponseData.
         * @implements IRpcGroupUpdateResponseData
         * @constructor
         * @param {api.IRpcGroupUpdateResponseData=} [properties] Properties to set
         */
        function RpcGroupUpdateResponseData(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcGroupUpdateResponseData group.
         * @member {api.RpcGroupCreateResponseDataGroup|null|undefined} group
         * @memberof api.RpcGroupUpdateResponseData
         * @instance
         */
        RpcGroupUpdateResponseData.prototype.group = null;

        /**
         * Creates a new RpcGroupUpdateResponseData instance using the specified properties.
         * @function create
         * @memberof api.RpcGroupUpdateResponseData
         * @static
         * @param {api.IRpcGroupUpdateResponseData=} [properties] Properties to set
         * @returns {api.RpcGroupUpdateResponseData} RpcGroupUpdateResponseData instance
         */
        RpcGroupUpdateResponseData.create = function create(properties) {
            return new RpcGroupUpdateResponseData(properties);
        };

        /**
         * Encodes the specified RpcGroupUpdateResponseData message. Does not implicitly {@link api.RpcGroupUpdateResponseData.verify|verify} messages.
         * @function encode
         * @memberof api.RpcGroupUpdateResponseData
         * @static
         * @param {api.RpcGroupUpdateResponseData} message RpcGroupUpdateResponseData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGroupUpdateResponseData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.group != null && Object.hasOwnProperty.call(message, "group"))
                $root.api.RpcGroupCreateResponseDataGroup.encode(message.group, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified RpcGroupUpdateResponseData message, length delimited. Does not implicitly {@link api.RpcGroupUpdateResponseData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcGroupUpdateResponseData
         * @static
         * @param {api.RpcGroupUpdateResponseData} message RpcGroupUpdateResponseData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGroupUpdateResponseData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcGroupUpdateResponseData message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcGroupUpdateResponseData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcGroupUpdateResponseData} RpcGroupUpdateResponseData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGroupUpdateResponseData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcGroupUpdateResponseData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.group = $root.api.RpcGroupCreateResponseDataGroup.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcGroupUpdateResponseData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcGroupUpdateResponseData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcGroupUpdateResponseData} RpcGroupUpdateResponseData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGroupUpdateResponseData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcGroupUpdateResponseData message.
         * @function verify
         * @memberof api.RpcGroupUpdateResponseData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcGroupUpdateResponseData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.group != null && message.hasOwnProperty("group")) {
                let error = $root.api.RpcGroupCreateResponseDataGroup.verify(message.group);
                if (error)
                    return "group." + error;
            }
            return null;
        };

        /**
         * Creates a RpcGroupUpdateResponseData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcGroupUpdateResponseData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcGroupUpdateResponseData} RpcGroupUpdateResponseData
         */
        RpcGroupUpdateResponseData.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcGroupUpdateResponseData)
                return object;
            let message = new $root.api.RpcGroupUpdateResponseData();
            if (object.group != null) {
                if (typeof object.group !== "object")
                    throw TypeError(".api.RpcGroupUpdateResponseData.group: object expected");
                message.group = $root.api.RpcGroupCreateResponseDataGroup.fromObject(object.group);
            }
            return message;
        };

        /**
         * Creates a plain object from a RpcGroupUpdateResponseData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcGroupUpdateResponseData
         * @static
         * @param {api.RpcGroupUpdateResponseData} message RpcGroupUpdateResponseData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcGroupUpdateResponseData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.group = null;
            if (message.group != null && message.hasOwnProperty("group"))
                object.group = $root.api.RpcGroupCreateResponseDataGroup.toObject(message.group, options);
            return object;
        };

        /**
         * Converts this RpcGroupUpdateResponseData to JSON.
         * @function toJSON
         * @memberof api.RpcGroupUpdateResponseData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcGroupUpdateResponseData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcGroupUpdateResponseData
         * @function getTypeUrl
         * @memberof api.RpcGroupUpdateResponseData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcGroupUpdateResponseData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcGroupUpdateResponseData";
        };

        return RpcGroupUpdateResponseData;
    })();

    api.RpcGroupListClientsRequest = (function() {

        /**
         * Properties of a RpcGroupListClientsRequest.
         * @memberof api
         * @interface IRpcGroupListClientsRequest
         * @property {string|null} [group_uid] RpcGroupListClientsRequest group_uid
         * @property {string|null} [query] RpcGroupListClientsRequest query
         */

        /**
         * Constructs a new RpcGroupListClientsRequest.
         * @memberof api
         * @classdesc Represents a RpcGroupListClientsRequest.
         * @implements IRpcGroupListClientsRequest
         * @constructor
         * @param {api.IRpcGroupListClientsRequest=} [properties] Properties to set
         */
        function RpcGroupListClientsRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcGroupListClientsRequest group_uid.
         * @member {string} group_uid
         * @memberof api.RpcGroupListClientsRequest
         * @instance
         */
        RpcGroupListClientsRequest.prototype.group_uid = "";

        /**
         * RpcGroupListClientsRequest query.
         * @member {string} query
         * @memberof api.RpcGroupListClientsRequest
         * @instance
         */
        RpcGroupListClientsRequest.prototype.query = "";

        /**
         * Creates a new RpcGroupListClientsRequest instance using the specified properties.
         * @function create
         * @memberof api.RpcGroupListClientsRequest
         * @static
         * @param {api.IRpcGroupListClientsRequest=} [properties] Properties to set
         * @returns {api.RpcGroupListClientsRequest} RpcGroupListClientsRequest instance
         */
        RpcGroupListClientsRequest.create = function create(properties) {
            return new RpcGroupListClientsRequest(properties);
        };

        /**
         * Encodes the specified RpcGroupListClientsRequest message. Does not implicitly {@link api.RpcGroupListClientsRequest.verify|verify} messages.
         * @function encode
         * @memberof api.RpcGroupListClientsRequest
         * @static
         * @param {api.RpcGroupListClientsRequest} message RpcGroupListClientsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGroupListClientsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.group_uid != null && Object.hasOwnProperty.call(message, "group_uid"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.group_uid);
            if (message.query != null && Object.hasOwnProperty.call(message, "query"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.query);
            return writer;
        };

        /**
         * Encodes the specified RpcGroupListClientsRequest message, length delimited. Does not implicitly {@link api.RpcGroupListClientsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcGroupListClientsRequest
         * @static
         * @param {api.RpcGroupListClientsRequest} message RpcGroupListClientsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGroupListClientsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcGroupListClientsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcGroupListClientsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcGroupListClientsRequest} RpcGroupListClientsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGroupListClientsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcGroupListClientsRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.group_uid = reader.string();
                        break;
                    }
                case 2: {
                        message.query = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcGroupListClientsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcGroupListClientsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcGroupListClientsRequest} RpcGroupListClientsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGroupListClientsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcGroupListClientsRequest message.
         * @function verify
         * @memberof api.RpcGroupListClientsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcGroupListClientsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.group_uid != null && message.hasOwnProperty("group_uid"))
                if (!$util.isString(message.group_uid))
                    return "group_uid: string expected";
            if (message.query != null && message.hasOwnProperty("query"))
                if (!$util.isString(message.query))
                    return "query: string expected";
            return null;
        };

        /**
         * Creates a RpcGroupListClientsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcGroupListClientsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcGroupListClientsRequest} RpcGroupListClientsRequest
         */
        RpcGroupListClientsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcGroupListClientsRequest)
                return object;
            let message = new $root.api.RpcGroupListClientsRequest();
            if (object.group_uid != null)
                message.group_uid = String(object.group_uid);
            if (object.query != null)
                message.query = String(object.query);
            return message;
        };

        /**
         * Creates a plain object from a RpcGroupListClientsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcGroupListClientsRequest
         * @static
         * @param {api.RpcGroupListClientsRequest} message RpcGroupListClientsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcGroupListClientsRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.group_uid = "";
                object.query = "";
            }
            if (message.group_uid != null && message.hasOwnProperty("group_uid"))
                object.group_uid = message.group_uid;
            if (message.query != null && message.hasOwnProperty("query"))
                object.query = message.query;
            return object;
        };

        /**
         * Converts this RpcGroupListClientsRequest to JSON.
         * @function toJSON
         * @memberof api.RpcGroupListClientsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcGroupListClientsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcGroupListClientsRequest
         * @function getTypeUrl
         * @memberof api.RpcGroupListClientsRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcGroupListClientsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcGroupListClientsRequest";
        };

        return RpcGroupListClientsRequest;
    })();

    api.RpcGroupListClient = (function() {

        /**
         * Properties of a RpcGroupListClient.
         * @memberof api
         * @interface IRpcGroupListClient
         * @property {Array.<api.ClientAccount>|null} [accounts] RpcGroupListClient accounts
         * @property {string|null} [avatar] RpcGroupListClient avatar
         * @property {string|null} [created_at] RpcGroupListClient created_at
         * @property {string|null} [firstname] RpcGroupListClient firstname
         * @property {number|null} [id] RpcGroupListClient id
         * @property {string|null} [lastname] RpcGroupListClient lastname
         * @property {number|null} [ref_count] RpcGroupListClient ref_count
         * @property {number|null} [ref_line1_count] RpcGroupListClient ref_line1_count
         * @property {number|null} [ref_line2_count] RpcGroupListClient ref_line2_count
         * @property {number|null} [ref_line3_count] RpcGroupListClient ref_line3_count
         * @property {string|null} [ref_link] RpcGroupListClient ref_link
         * @property {Array.<api.ClientRewardAccount>|null} [reward_accounts] RpcGroupListClient reward_accounts
         * @property {api.RpcGetInviteesResponseClientState|null} [state] RpcGroupListClient state
         * @property {string|null} [uid] RpcGroupListClient uid
         * @property {string|null} [username] RpcGroupListClient username
         */

        /**
         * Constructs a new RpcGroupListClient.
         * @memberof api
         * @classdesc Represents a RpcGroupListClient.
         * @implements IRpcGroupListClient
         * @constructor
         * @param {api.IRpcGroupListClient=} [properties] Properties to set
         */
        function RpcGroupListClient(properties) {
            this.accounts = [];
            this.reward_accounts = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcGroupListClient accounts.
         * @member {Array.<api.ClientAccount>} accounts
         * @memberof api.RpcGroupListClient
         * @instance
         */
        RpcGroupListClient.prototype.accounts = $util.emptyArray;

        /**
         * RpcGroupListClient avatar.
         * @member {string} avatar
         * @memberof api.RpcGroupListClient
         * @instance
         */
        RpcGroupListClient.prototype.avatar = "";

        /**
         * RpcGroupListClient created_at.
         * @member {string} created_at
         * @memberof api.RpcGroupListClient
         * @instance
         */
        RpcGroupListClient.prototype.created_at = "";

        /**
         * RpcGroupListClient firstname.
         * @member {string} firstname
         * @memberof api.RpcGroupListClient
         * @instance
         */
        RpcGroupListClient.prototype.firstname = "";

        /**
         * RpcGroupListClient id.
         * @member {number} id
         * @memberof api.RpcGroupListClient
         * @instance
         */
        RpcGroupListClient.prototype.id = 0;

        /**
         * RpcGroupListClient lastname.
         * @member {string} lastname
         * @memberof api.RpcGroupListClient
         * @instance
         */
        RpcGroupListClient.prototype.lastname = "";

        /**
         * RpcGroupListClient ref_count.
         * @member {number} ref_count
         * @memberof api.RpcGroupListClient
         * @instance
         */
        RpcGroupListClient.prototype.ref_count = 0;

        /**
         * RpcGroupListClient ref_line1_count.
         * @member {number} ref_line1_count
         * @memberof api.RpcGroupListClient
         * @instance
         */
        RpcGroupListClient.prototype.ref_line1_count = 0;

        /**
         * RpcGroupListClient ref_line2_count.
         * @member {number} ref_line2_count
         * @memberof api.RpcGroupListClient
         * @instance
         */
        RpcGroupListClient.prototype.ref_line2_count = 0;

        /**
         * RpcGroupListClient ref_line3_count.
         * @member {number} ref_line3_count
         * @memberof api.RpcGroupListClient
         * @instance
         */
        RpcGroupListClient.prototype.ref_line3_count = 0;

        /**
         * RpcGroupListClient ref_link.
         * @member {string} ref_link
         * @memberof api.RpcGroupListClient
         * @instance
         */
        RpcGroupListClient.prototype.ref_link = "";

        /**
         * RpcGroupListClient reward_accounts.
         * @member {Array.<api.ClientRewardAccount>} reward_accounts
         * @memberof api.RpcGroupListClient
         * @instance
         */
        RpcGroupListClient.prototype.reward_accounts = $util.emptyArray;

        /**
         * RpcGroupListClient state.
         * @member {api.RpcGetInviteesResponseClientState|null|undefined} state
         * @memberof api.RpcGroupListClient
         * @instance
         */
        RpcGroupListClient.prototype.state = null;

        /**
         * RpcGroupListClient uid.
         * @member {string} uid
         * @memberof api.RpcGroupListClient
         * @instance
         */
        RpcGroupListClient.prototype.uid = "";

        /**
         * RpcGroupListClient username.
         * @member {string} username
         * @memberof api.RpcGroupListClient
         * @instance
         */
        RpcGroupListClient.prototype.username = "";

        /**
         * Creates a new RpcGroupListClient instance using the specified properties.
         * @function create
         * @memberof api.RpcGroupListClient
         * @static
         * @param {api.IRpcGroupListClient=} [properties] Properties to set
         * @returns {api.RpcGroupListClient} RpcGroupListClient instance
         */
        RpcGroupListClient.create = function create(properties) {
            return new RpcGroupListClient(properties);
        };

        /**
         * Encodes the specified RpcGroupListClient message. Does not implicitly {@link api.RpcGroupListClient.verify|verify} messages.
         * @function encode
         * @memberof api.RpcGroupListClient
         * @static
         * @param {api.RpcGroupListClient} message RpcGroupListClient message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGroupListClient.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.accounts != null && message.accounts.length)
                for (let i = 0; i < message.accounts.length; ++i)
                    $root.api.ClientAccount.encode(message.accounts[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.avatar != null && Object.hasOwnProperty.call(message, "avatar"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.avatar);
            if (message.created_at != null && Object.hasOwnProperty.call(message, "created_at"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.created_at);
            if (message.firstname != null && Object.hasOwnProperty.call(message, "firstname"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.firstname);
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.id);
            if (message.lastname != null && Object.hasOwnProperty.call(message, "lastname"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.lastname);
            if (message.ref_count != null && Object.hasOwnProperty.call(message, "ref_count"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.ref_count);
            if (message.ref_line1_count != null && Object.hasOwnProperty.call(message, "ref_line1_count"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.ref_line1_count);
            if (message.ref_line2_count != null && Object.hasOwnProperty.call(message, "ref_line2_count"))
                writer.uint32(/* id 9, wireType 0 =*/72).int32(message.ref_line2_count);
            if (message.ref_line3_count != null && Object.hasOwnProperty.call(message, "ref_line3_count"))
                writer.uint32(/* id 10, wireType 0 =*/80).int32(message.ref_line3_count);
            if (message.ref_link != null && Object.hasOwnProperty.call(message, "ref_link"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.ref_link);
            if (message.reward_accounts != null && message.reward_accounts.length)
                for (let i = 0; i < message.reward_accounts.length; ++i)
                    $root.api.ClientRewardAccount.encode(message.reward_accounts[i], writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                $root.api.RpcGetInviteesResponseClientState.encode(message.state, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
                writer.uint32(/* id 14, wireType 2 =*/114).string(message.uid);
            if (message.username != null && Object.hasOwnProperty.call(message, "username"))
                writer.uint32(/* id 15, wireType 2 =*/122).string(message.username);
            return writer;
        };

        /**
         * Encodes the specified RpcGroupListClient message, length delimited. Does not implicitly {@link api.RpcGroupListClient.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcGroupListClient
         * @static
         * @param {api.RpcGroupListClient} message RpcGroupListClient message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGroupListClient.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcGroupListClient message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcGroupListClient
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcGroupListClient} RpcGroupListClient
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGroupListClient.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcGroupListClient();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.accounts && message.accounts.length))
                            message.accounts = [];
                        message.accounts.push($root.api.ClientAccount.decode(reader, reader.uint32()));
                        break;
                    }
                case 2: {
                        message.avatar = reader.string();
                        break;
                    }
                case 3: {
                        message.created_at = reader.string();
                        break;
                    }
                case 4: {
                        message.firstname = reader.string();
                        break;
                    }
                case 5: {
                        message.id = reader.int32();
                        break;
                    }
                case 6: {
                        message.lastname = reader.string();
                        break;
                    }
                case 7: {
                        message.ref_count = reader.int32();
                        break;
                    }
                case 8: {
                        message.ref_line1_count = reader.int32();
                        break;
                    }
                case 9: {
                        message.ref_line2_count = reader.int32();
                        break;
                    }
                case 10: {
                        message.ref_line3_count = reader.int32();
                        break;
                    }
                case 11: {
                        message.ref_link = reader.string();
                        break;
                    }
                case 12: {
                        if (!(message.reward_accounts && message.reward_accounts.length))
                            message.reward_accounts = [];
                        message.reward_accounts.push($root.api.ClientRewardAccount.decode(reader, reader.uint32()));
                        break;
                    }
                case 13: {
                        message.state = $root.api.RpcGetInviteesResponseClientState.decode(reader, reader.uint32());
                        break;
                    }
                case 14: {
                        message.uid = reader.string();
                        break;
                    }
                case 15: {
                        message.username = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcGroupListClient message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcGroupListClient
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcGroupListClient} RpcGroupListClient
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGroupListClient.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcGroupListClient message.
         * @function verify
         * @memberof api.RpcGroupListClient
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcGroupListClient.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.accounts != null && message.hasOwnProperty("accounts")) {
                if (!Array.isArray(message.accounts))
                    return "accounts: array expected";
                for (let i = 0; i < message.accounts.length; ++i) {
                    let error = $root.api.ClientAccount.verify(message.accounts[i]);
                    if (error)
                        return "accounts." + error;
                }
            }
            if (message.avatar != null && message.hasOwnProperty("avatar"))
                if (!$util.isString(message.avatar))
                    return "avatar: string expected";
            if (message.created_at != null && message.hasOwnProperty("created_at"))
                if (!$util.isString(message.created_at))
                    return "created_at: string expected";
            if (message.firstname != null && message.hasOwnProperty("firstname"))
                if (!$util.isString(message.firstname))
                    return "firstname: string expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isInteger(message.id))
                    return "id: integer expected";
            if (message.lastname != null && message.hasOwnProperty("lastname"))
                if (!$util.isString(message.lastname))
                    return "lastname: string expected";
            if (message.ref_count != null && message.hasOwnProperty("ref_count"))
                if (!$util.isInteger(message.ref_count))
                    return "ref_count: integer expected";
            if (message.ref_line1_count != null && message.hasOwnProperty("ref_line1_count"))
                if (!$util.isInteger(message.ref_line1_count))
                    return "ref_line1_count: integer expected";
            if (message.ref_line2_count != null && message.hasOwnProperty("ref_line2_count"))
                if (!$util.isInteger(message.ref_line2_count))
                    return "ref_line2_count: integer expected";
            if (message.ref_line3_count != null && message.hasOwnProperty("ref_line3_count"))
                if (!$util.isInteger(message.ref_line3_count))
                    return "ref_line3_count: integer expected";
            if (message.ref_link != null && message.hasOwnProperty("ref_link"))
                if (!$util.isString(message.ref_link))
                    return "ref_link: string expected";
            if (message.reward_accounts != null && message.hasOwnProperty("reward_accounts")) {
                if (!Array.isArray(message.reward_accounts))
                    return "reward_accounts: array expected";
                for (let i = 0; i < message.reward_accounts.length; ++i) {
                    let error = $root.api.ClientRewardAccount.verify(message.reward_accounts[i]);
                    if (error)
                        return "reward_accounts." + error;
                }
            }
            if (message.state != null && message.hasOwnProperty("state")) {
                let error = $root.api.RpcGetInviteesResponseClientState.verify(message.state);
                if (error)
                    return "state." + error;
            }
            if (message.uid != null && message.hasOwnProperty("uid"))
                if (!$util.isString(message.uid))
                    return "uid: string expected";
            if (message.username != null && message.hasOwnProperty("username"))
                if (!$util.isString(message.username))
                    return "username: string expected";
            return null;
        };

        /**
         * Creates a RpcGroupListClient message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcGroupListClient
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcGroupListClient} RpcGroupListClient
         */
        RpcGroupListClient.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcGroupListClient)
                return object;
            let message = new $root.api.RpcGroupListClient();
            if (object.accounts) {
                if (!Array.isArray(object.accounts))
                    throw TypeError(".api.RpcGroupListClient.accounts: array expected");
                message.accounts = [];
                for (let i = 0; i < object.accounts.length; ++i) {
                    if (typeof object.accounts[i] !== "object")
                        throw TypeError(".api.RpcGroupListClient.accounts: object expected");
                    message.accounts[i] = $root.api.ClientAccount.fromObject(object.accounts[i]);
                }
            }
            if (object.avatar != null)
                message.avatar = String(object.avatar);
            if (object.created_at != null)
                message.created_at = String(object.created_at);
            if (object.firstname != null)
                message.firstname = String(object.firstname);
            if (object.id != null)
                message.id = object.id | 0;
            if (object.lastname != null)
                message.lastname = String(object.lastname);
            if (object.ref_count != null)
                message.ref_count = object.ref_count | 0;
            if (object.ref_line1_count != null)
                message.ref_line1_count = object.ref_line1_count | 0;
            if (object.ref_line2_count != null)
                message.ref_line2_count = object.ref_line2_count | 0;
            if (object.ref_line3_count != null)
                message.ref_line3_count = object.ref_line3_count | 0;
            if (object.ref_link != null)
                message.ref_link = String(object.ref_link);
            if (object.reward_accounts) {
                if (!Array.isArray(object.reward_accounts))
                    throw TypeError(".api.RpcGroupListClient.reward_accounts: array expected");
                message.reward_accounts = [];
                for (let i = 0; i < object.reward_accounts.length; ++i) {
                    if (typeof object.reward_accounts[i] !== "object")
                        throw TypeError(".api.RpcGroupListClient.reward_accounts: object expected");
                    message.reward_accounts[i] = $root.api.ClientRewardAccount.fromObject(object.reward_accounts[i]);
                }
            }
            if (object.state != null) {
                if (typeof object.state !== "object")
                    throw TypeError(".api.RpcGroupListClient.state: object expected");
                message.state = $root.api.RpcGetInviteesResponseClientState.fromObject(object.state);
            }
            if (object.uid != null)
                message.uid = String(object.uid);
            if (object.username != null)
                message.username = String(object.username);
            return message;
        };

        /**
         * Creates a plain object from a RpcGroupListClient message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcGroupListClient
         * @static
         * @param {api.RpcGroupListClient} message RpcGroupListClient
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcGroupListClient.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.accounts = [];
                object.reward_accounts = [];
            }
            if (options.defaults) {
                object.avatar = "";
                object.created_at = "";
                object.firstname = "";
                object.id = 0;
                object.lastname = "";
                object.ref_count = 0;
                object.ref_line1_count = 0;
                object.ref_line2_count = 0;
                object.ref_line3_count = 0;
                object.ref_link = "";
                object.state = null;
                object.uid = "";
                object.username = "";
            }
            if (message.accounts && message.accounts.length) {
                object.accounts = [];
                for (let j = 0; j < message.accounts.length; ++j)
                    object.accounts[j] = $root.api.ClientAccount.toObject(message.accounts[j], options);
            }
            if (message.avatar != null && message.hasOwnProperty("avatar"))
                object.avatar = message.avatar;
            if (message.created_at != null && message.hasOwnProperty("created_at"))
                object.created_at = message.created_at;
            if (message.firstname != null && message.hasOwnProperty("firstname"))
                object.firstname = message.firstname;
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.lastname != null && message.hasOwnProperty("lastname"))
                object.lastname = message.lastname;
            if (message.ref_count != null && message.hasOwnProperty("ref_count"))
                object.ref_count = message.ref_count;
            if (message.ref_line1_count != null && message.hasOwnProperty("ref_line1_count"))
                object.ref_line1_count = message.ref_line1_count;
            if (message.ref_line2_count != null && message.hasOwnProperty("ref_line2_count"))
                object.ref_line2_count = message.ref_line2_count;
            if (message.ref_line3_count != null && message.hasOwnProperty("ref_line3_count"))
                object.ref_line3_count = message.ref_line3_count;
            if (message.ref_link != null && message.hasOwnProperty("ref_link"))
                object.ref_link = message.ref_link;
            if (message.reward_accounts && message.reward_accounts.length) {
                object.reward_accounts = [];
                for (let j = 0; j < message.reward_accounts.length; ++j)
                    object.reward_accounts[j] = $root.api.ClientRewardAccount.toObject(message.reward_accounts[j], options);
            }
            if (message.state != null && message.hasOwnProperty("state"))
                object.state = $root.api.RpcGetInviteesResponseClientState.toObject(message.state, options);
            if (message.uid != null && message.hasOwnProperty("uid"))
                object.uid = message.uid;
            if (message.username != null && message.hasOwnProperty("username"))
                object.username = message.username;
            return object;
        };

        /**
         * Converts this RpcGroupListClient to JSON.
         * @function toJSON
         * @memberof api.RpcGroupListClient
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcGroupListClient.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcGroupListClient
         * @function getTypeUrl
         * @memberof api.RpcGroupListClient
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcGroupListClient.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcGroupListClient";
        };

        return RpcGroupListClient;
    })();

    api.RpcGroupListClientsResponseMeta = (function() {

        /**
         * Properties of a RpcGroupListClientsResponseMeta.
         * @memberof api
         * @interface IRpcGroupListClientsResponseMeta
         * @property {number|null} [count] RpcGroupListClientsResponseMeta count
         * @property {number|null} [limit] RpcGroupListClientsResponseMeta limit
         * @property {number|null} [offset] RpcGroupListClientsResponseMeta offset
         * @property {string|null} [order] RpcGroupListClientsResponseMeta order
         */

        /**
         * Constructs a new RpcGroupListClientsResponseMeta.
         * @memberof api
         * @classdesc Represents a RpcGroupListClientsResponseMeta.
         * @implements IRpcGroupListClientsResponseMeta
         * @constructor
         * @param {api.IRpcGroupListClientsResponseMeta=} [properties] Properties to set
         */
        function RpcGroupListClientsResponseMeta(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcGroupListClientsResponseMeta count.
         * @member {number} count
         * @memberof api.RpcGroupListClientsResponseMeta
         * @instance
         */
        RpcGroupListClientsResponseMeta.prototype.count = 0;

        /**
         * RpcGroupListClientsResponseMeta limit.
         * @member {number} limit
         * @memberof api.RpcGroupListClientsResponseMeta
         * @instance
         */
        RpcGroupListClientsResponseMeta.prototype.limit = 0;

        /**
         * RpcGroupListClientsResponseMeta offset.
         * @member {number} offset
         * @memberof api.RpcGroupListClientsResponseMeta
         * @instance
         */
        RpcGroupListClientsResponseMeta.prototype.offset = 0;

        /**
         * RpcGroupListClientsResponseMeta order.
         * @member {string} order
         * @memberof api.RpcGroupListClientsResponseMeta
         * @instance
         */
        RpcGroupListClientsResponseMeta.prototype.order = "";

        /**
         * Creates a new RpcGroupListClientsResponseMeta instance using the specified properties.
         * @function create
         * @memberof api.RpcGroupListClientsResponseMeta
         * @static
         * @param {api.IRpcGroupListClientsResponseMeta=} [properties] Properties to set
         * @returns {api.RpcGroupListClientsResponseMeta} RpcGroupListClientsResponseMeta instance
         */
        RpcGroupListClientsResponseMeta.create = function create(properties) {
            return new RpcGroupListClientsResponseMeta(properties);
        };

        /**
         * Encodes the specified RpcGroupListClientsResponseMeta message. Does not implicitly {@link api.RpcGroupListClientsResponseMeta.verify|verify} messages.
         * @function encode
         * @memberof api.RpcGroupListClientsResponseMeta
         * @static
         * @param {api.RpcGroupListClientsResponseMeta} message RpcGroupListClientsResponseMeta message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGroupListClientsResponseMeta.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.count);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.limit);
            if (message.offset != null && Object.hasOwnProperty.call(message, "offset"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.offset);
            if (message.order != null && Object.hasOwnProperty.call(message, "order"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.order);
            return writer;
        };

        /**
         * Encodes the specified RpcGroupListClientsResponseMeta message, length delimited. Does not implicitly {@link api.RpcGroupListClientsResponseMeta.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcGroupListClientsResponseMeta
         * @static
         * @param {api.RpcGroupListClientsResponseMeta} message RpcGroupListClientsResponseMeta message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGroupListClientsResponseMeta.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcGroupListClientsResponseMeta message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcGroupListClientsResponseMeta
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcGroupListClientsResponseMeta} RpcGroupListClientsResponseMeta
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGroupListClientsResponseMeta.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcGroupListClientsResponseMeta();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.count = reader.int32();
                        break;
                    }
                case 2: {
                        message.limit = reader.int32();
                        break;
                    }
                case 3: {
                        message.offset = reader.int32();
                        break;
                    }
                case 4: {
                        message.order = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcGroupListClientsResponseMeta message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcGroupListClientsResponseMeta
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcGroupListClientsResponseMeta} RpcGroupListClientsResponseMeta
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGroupListClientsResponseMeta.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcGroupListClientsResponseMeta message.
         * @function verify
         * @memberof api.RpcGroupListClientsResponseMeta
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcGroupListClientsResponseMeta.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.count != null && message.hasOwnProperty("count"))
                if (!$util.isInteger(message.count))
                    return "count: integer expected";
            if (message.limit != null && message.hasOwnProperty("limit"))
                if (!$util.isInteger(message.limit))
                    return "limit: integer expected";
            if (message.offset != null && message.hasOwnProperty("offset"))
                if (!$util.isInteger(message.offset))
                    return "offset: integer expected";
            if (message.order != null && message.hasOwnProperty("order"))
                if (!$util.isString(message.order))
                    return "order: string expected";
            return null;
        };

        /**
         * Creates a RpcGroupListClientsResponseMeta message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcGroupListClientsResponseMeta
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcGroupListClientsResponseMeta} RpcGroupListClientsResponseMeta
         */
        RpcGroupListClientsResponseMeta.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcGroupListClientsResponseMeta)
                return object;
            let message = new $root.api.RpcGroupListClientsResponseMeta();
            if (object.count != null)
                message.count = object.count | 0;
            if (object.limit != null)
                message.limit = object.limit | 0;
            if (object.offset != null)
                message.offset = object.offset | 0;
            if (object.order != null)
                message.order = String(object.order);
            return message;
        };

        /**
         * Creates a plain object from a RpcGroupListClientsResponseMeta message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcGroupListClientsResponseMeta
         * @static
         * @param {api.RpcGroupListClientsResponseMeta} message RpcGroupListClientsResponseMeta
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcGroupListClientsResponseMeta.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.count = 0;
                object.limit = 0;
                object.offset = 0;
                object.order = "";
            }
            if (message.count != null && message.hasOwnProperty("count"))
                object.count = message.count;
            if (message.limit != null && message.hasOwnProperty("limit"))
                object.limit = message.limit;
            if (message.offset != null && message.hasOwnProperty("offset"))
                object.offset = message.offset;
            if (message.order != null && message.hasOwnProperty("order"))
                object.order = message.order;
            return object;
        };

        /**
         * Converts this RpcGroupListClientsResponseMeta to JSON.
         * @function toJSON
         * @memberof api.RpcGroupListClientsResponseMeta
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcGroupListClientsResponseMeta.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcGroupListClientsResponseMeta
         * @function getTypeUrl
         * @memberof api.RpcGroupListClientsResponseMeta
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcGroupListClientsResponseMeta.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcGroupListClientsResponseMeta";
        };

        return RpcGroupListClientsResponseMeta;
    })();

    api.RpcGroupListClientsResponseData = (function() {

        /**
         * Properties of a RpcGroupListClientsResponseData.
         * @memberof api
         * @interface IRpcGroupListClientsResponseData
         * @property {Array.<api.RpcGroupListClient>|null} [clients] RpcGroupListClientsResponseData clients
         * @property {api.RpcGroupListClientsResponseMeta|null} [meta] RpcGroupListClientsResponseData meta
         */

        /**
         * Constructs a new RpcGroupListClientsResponseData.
         * @memberof api
         * @classdesc Represents a RpcGroupListClientsResponseData.
         * @implements IRpcGroupListClientsResponseData
         * @constructor
         * @param {api.IRpcGroupListClientsResponseData=} [properties] Properties to set
         */
        function RpcGroupListClientsResponseData(properties) {
            this.clients = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcGroupListClientsResponseData clients.
         * @member {Array.<api.RpcGroupListClient>} clients
         * @memberof api.RpcGroupListClientsResponseData
         * @instance
         */
        RpcGroupListClientsResponseData.prototype.clients = $util.emptyArray;

        /**
         * RpcGroupListClientsResponseData meta.
         * @member {api.RpcGroupListClientsResponseMeta|null|undefined} meta
         * @memberof api.RpcGroupListClientsResponseData
         * @instance
         */
        RpcGroupListClientsResponseData.prototype.meta = null;

        /**
         * Creates a new RpcGroupListClientsResponseData instance using the specified properties.
         * @function create
         * @memberof api.RpcGroupListClientsResponseData
         * @static
         * @param {api.IRpcGroupListClientsResponseData=} [properties] Properties to set
         * @returns {api.RpcGroupListClientsResponseData} RpcGroupListClientsResponseData instance
         */
        RpcGroupListClientsResponseData.create = function create(properties) {
            return new RpcGroupListClientsResponseData(properties);
        };

        /**
         * Encodes the specified RpcGroupListClientsResponseData message. Does not implicitly {@link api.RpcGroupListClientsResponseData.verify|verify} messages.
         * @function encode
         * @memberof api.RpcGroupListClientsResponseData
         * @static
         * @param {api.RpcGroupListClientsResponseData} message RpcGroupListClientsResponseData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGroupListClientsResponseData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.clients != null && message.clients.length)
                for (let i = 0; i < message.clients.length; ++i)
                    $root.api.RpcGroupListClient.encode(message.clients[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.meta != null && Object.hasOwnProperty.call(message, "meta"))
                $root.api.RpcGroupListClientsResponseMeta.encode(message.meta, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified RpcGroupListClientsResponseData message, length delimited. Does not implicitly {@link api.RpcGroupListClientsResponseData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcGroupListClientsResponseData
         * @static
         * @param {api.RpcGroupListClientsResponseData} message RpcGroupListClientsResponseData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGroupListClientsResponseData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcGroupListClientsResponseData message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcGroupListClientsResponseData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcGroupListClientsResponseData} RpcGroupListClientsResponseData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGroupListClientsResponseData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcGroupListClientsResponseData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.clients && message.clients.length))
                            message.clients = [];
                        message.clients.push($root.api.RpcGroupListClient.decode(reader, reader.uint32()));
                        break;
                    }
                case 2: {
                        message.meta = $root.api.RpcGroupListClientsResponseMeta.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcGroupListClientsResponseData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcGroupListClientsResponseData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcGroupListClientsResponseData} RpcGroupListClientsResponseData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGroupListClientsResponseData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcGroupListClientsResponseData message.
         * @function verify
         * @memberof api.RpcGroupListClientsResponseData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcGroupListClientsResponseData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.clients != null && message.hasOwnProperty("clients")) {
                if (!Array.isArray(message.clients))
                    return "clients: array expected";
                for (let i = 0; i < message.clients.length; ++i) {
                    let error = $root.api.RpcGroupListClient.verify(message.clients[i]);
                    if (error)
                        return "clients." + error;
                }
            }
            if (message.meta != null && message.hasOwnProperty("meta")) {
                let error = $root.api.RpcGroupListClientsResponseMeta.verify(message.meta);
                if (error)
                    return "meta." + error;
            }
            return null;
        };

        /**
         * Creates a RpcGroupListClientsResponseData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcGroupListClientsResponseData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcGroupListClientsResponseData} RpcGroupListClientsResponseData
         */
        RpcGroupListClientsResponseData.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcGroupListClientsResponseData)
                return object;
            let message = new $root.api.RpcGroupListClientsResponseData();
            if (object.clients) {
                if (!Array.isArray(object.clients))
                    throw TypeError(".api.RpcGroupListClientsResponseData.clients: array expected");
                message.clients = [];
                for (let i = 0; i < object.clients.length; ++i) {
                    if (typeof object.clients[i] !== "object")
                        throw TypeError(".api.RpcGroupListClientsResponseData.clients: object expected");
                    message.clients[i] = $root.api.RpcGroupListClient.fromObject(object.clients[i]);
                }
            }
            if (object.meta != null) {
                if (typeof object.meta !== "object")
                    throw TypeError(".api.RpcGroupListClientsResponseData.meta: object expected");
                message.meta = $root.api.RpcGroupListClientsResponseMeta.fromObject(object.meta);
            }
            return message;
        };

        /**
         * Creates a plain object from a RpcGroupListClientsResponseData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcGroupListClientsResponseData
         * @static
         * @param {api.RpcGroupListClientsResponseData} message RpcGroupListClientsResponseData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcGroupListClientsResponseData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.clients = [];
            if (options.defaults)
                object.meta = null;
            if (message.clients && message.clients.length) {
                object.clients = [];
                for (let j = 0; j < message.clients.length; ++j)
                    object.clients[j] = $root.api.RpcGroupListClient.toObject(message.clients[j], options);
            }
            if (message.meta != null && message.hasOwnProperty("meta"))
                object.meta = $root.api.RpcGroupListClientsResponseMeta.toObject(message.meta, options);
            return object;
        };

        /**
         * Converts this RpcGroupListClientsResponseData to JSON.
         * @function toJSON
         * @memberof api.RpcGroupListClientsResponseData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcGroupListClientsResponseData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcGroupListClientsResponseData
         * @function getTypeUrl
         * @memberof api.RpcGroupListClientsResponseData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcGroupListClientsResponseData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcGroupListClientsResponseData";
        };

        return RpcGroupListClientsResponseData;
    })();

    api.RpcGroupListClientsResponse = (function() {

        /**
         * Properties of a RpcGroupListClientsResponse.
         * @memberof api
         * @interface IRpcGroupListClientsResponse
         * @property {number|null} [code] RpcGroupListClientsResponse code
         * @property {api.RpcGroupListClientsResponseData|null} [data] RpcGroupListClientsResponse data
         * @property {api.Error|null} [error] RpcGroupListClientsResponse error
         * @property {string|null} [message] RpcGroupListClientsResponse message
         */

        /**
         * Constructs a new RpcGroupListClientsResponse.
         * @memberof api
         * @classdesc Represents a RpcGroupListClientsResponse.
         * @implements IRpcGroupListClientsResponse
         * @constructor
         * @param {api.IRpcGroupListClientsResponse=} [properties] Properties to set
         */
        function RpcGroupListClientsResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcGroupListClientsResponse code.
         * @member {number} code
         * @memberof api.RpcGroupListClientsResponse
         * @instance
         */
        RpcGroupListClientsResponse.prototype.code = 0;

        /**
         * RpcGroupListClientsResponse data.
         * @member {api.RpcGroupListClientsResponseData|null|undefined} data
         * @memberof api.RpcGroupListClientsResponse
         * @instance
         */
        RpcGroupListClientsResponse.prototype.data = null;

        /**
         * RpcGroupListClientsResponse error.
         * @member {api.Error|null|undefined} error
         * @memberof api.RpcGroupListClientsResponse
         * @instance
         */
        RpcGroupListClientsResponse.prototype.error = null;

        /**
         * RpcGroupListClientsResponse message.
         * @member {string} message
         * @memberof api.RpcGroupListClientsResponse
         * @instance
         */
        RpcGroupListClientsResponse.prototype.message = "";

        /**
         * Creates a new RpcGroupListClientsResponse instance using the specified properties.
         * @function create
         * @memberof api.RpcGroupListClientsResponse
         * @static
         * @param {api.IRpcGroupListClientsResponse=} [properties] Properties to set
         * @returns {api.RpcGroupListClientsResponse} RpcGroupListClientsResponse instance
         */
        RpcGroupListClientsResponse.create = function create(properties) {
            return new RpcGroupListClientsResponse(properties);
        };

        /**
         * Encodes the specified RpcGroupListClientsResponse message. Does not implicitly {@link api.RpcGroupListClientsResponse.verify|verify} messages.
         * @function encode
         * @memberof api.RpcGroupListClientsResponse
         * @static
         * @param {api.RpcGroupListClientsResponse} message RpcGroupListClientsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGroupListClientsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.code);
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                $root.api.RpcGroupListClientsResponseData.encode(message.data, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                $root.api.Error.encode(message.error, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.message);
            return writer;
        };

        /**
         * Encodes the specified RpcGroupListClientsResponse message, length delimited. Does not implicitly {@link api.RpcGroupListClientsResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcGroupListClientsResponse
         * @static
         * @param {api.RpcGroupListClientsResponse} message RpcGroupListClientsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcGroupListClientsResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcGroupListClientsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcGroupListClientsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcGroupListClientsResponse} RpcGroupListClientsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGroupListClientsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcGroupListClientsResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.code = reader.int32();
                        break;
                    }
                case 2: {
                        message.data = $root.api.RpcGroupListClientsResponseData.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.error = $root.api.Error.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.message = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcGroupListClientsResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcGroupListClientsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcGroupListClientsResponse} RpcGroupListClientsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcGroupListClientsResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcGroupListClientsResponse message.
         * @function verify
         * @memberof api.RpcGroupListClientsResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcGroupListClientsResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.code != null && message.hasOwnProperty("code"))
                if (!$util.isInteger(message.code))
                    return "code: integer expected";
            if (message.data != null && message.hasOwnProperty("data")) {
                let error = $root.api.RpcGroupListClientsResponseData.verify(message.data);
                if (error)
                    return "data." + error;
            }
            if (message.error != null && message.hasOwnProperty("error")) {
                let error = $root.api.Error.verify(message.error);
                if (error)
                    return "error." + error;
            }
            if (message.message != null && message.hasOwnProperty("message"))
                if (!$util.isString(message.message))
                    return "message: string expected";
            return null;
        };

        /**
         * Creates a RpcGroupListClientsResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcGroupListClientsResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcGroupListClientsResponse} RpcGroupListClientsResponse
         */
        RpcGroupListClientsResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcGroupListClientsResponse)
                return object;
            let message = new $root.api.RpcGroupListClientsResponse();
            if (object.code != null)
                message.code = object.code | 0;
            if (object.data != null) {
                if (typeof object.data !== "object")
                    throw TypeError(".api.RpcGroupListClientsResponse.data: object expected");
                message.data = $root.api.RpcGroupListClientsResponseData.fromObject(object.data);
            }
            if (object.error != null) {
                if (typeof object.error !== "object")
                    throw TypeError(".api.RpcGroupListClientsResponse.error: object expected");
                message.error = $root.api.Error.fromObject(object.error);
            }
            if (object.message != null)
                message.message = String(object.message);
            return message;
        };

        /**
         * Creates a plain object from a RpcGroupListClientsResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcGroupListClientsResponse
         * @static
         * @param {api.RpcGroupListClientsResponse} message RpcGroupListClientsResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcGroupListClientsResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.code = 0;
                object.data = null;
                object.error = null;
                object.message = "";
            }
            if (message.code != null && message.hasOwnProperty("code"))
                object.code = message.code;
            if (message.data != null && message.hasOwnProperty("data"))
                object.data = $root.api.RpcGroupListClientsResponseData.toObject(message.data, options);
            if (message.error != null && message.hasOwnProperty("error"))
                object.error = $root.api.Error.toObject(message.error, options);
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = message.message;
            return object;
        };

        /**
         * Converts this RpcGroupListClientsResponse to JSON.
         * @function toJSON
         * @memberof api.RpcGroupListClientsResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcGroupListClientsResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcGroupListClientsResponse
         * @function getTypeUrl
         * @memberof api.RpcGroupListClientsResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcGroupListClientsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcGroupListClientsResponse";
        };

        return RpcGroupListClientsResponse;
    })();

    api.RpcCustomIdsToUserIdsRequest = (function() {

        /**
         * Properties of a RpcCustomIdsToUserIdsRequest.
         * @memberof api
         * @interface IRpcCustomIdsToUserIdsRequest
         * @property {Array.<string>|null} [ids] RpcCustomIdsToUserIdsRequest ids
         */

        /**
         * Constructs a new RpcCustomIdsToUserIdsRequest.
         * @memberof api
         * @classdesc Represents a RpcCustomIdsToUserIdsRequest.
         * @implements IRpcCustomIdsToUserIdsRequest
         * @constructor
         * @param {api.IRpcCustomIdsToUserIdsRequest=} [properties] Properties to set
         */
        function RpcCustomIdsToUserIdsRequest(properties) {
            this.ids = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcCustomIdsToUserIdsRequest ids.
         * @member {Array.<string>} ids
         * @memberof api.RpcCustomIdsToUserIdsRequest
         * @instance
         */
        RpcCustomIdsToUserIdsRequest.prototype.ids = $util.emptyArray;

        /**
         * Creates a new RpcCustomIdsToUserIdsRequest instance using the specified properties.
         * @function create
         * @memberof api.RpcCustomIdsToUserIdsRequest
         * @static
         * @param {api.IRpcCustomIdsToUserIdsRequest=} [properties] Properties to set
         * @returns {api.RpcCustomIdsToUserIdsRequest} RpcCustomIdsToUserIdsRequest instance
         */
        RpcCustomIdsToUserIdsRequest.create = function create(properties) {
            return new RpcCustomIdsToUserIdsRequest(properties);
        };

        /**
         * Encodes the specified RpcCustomIdsToUserIdsRequest message. Does not implicitly {@link api.RpcCustomIdsToUserIdsRequest.verify|verify} messages.
         * @function encode
         * @memberof api.RpcCustomIdsToUserIdsRequest
         * @static
         * @param {api.RpcCustomIdsToUserIdsRequest} message RpcCustomIdsToUserIdsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcCustomIdsToUserIdsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.ids != null && message.ids.length)
                for (let i = 0; i < message.ids.length; ++i)
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.ids[i]);
            return writer;
        };

        /**
         * Encodes the specified RpcCustomIdsToUserIdsRequest message, length delimited. Does not implicitly {@link api.RpcCustomIdsToUserIdsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcCustomIdsToUserIdsRequest
         * @static
         * @param {api.RpcCustomIdsToUserIdsRequest} message RpcCustomIdsToUserIdsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcCustomIdsToUserIdsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcCustomIdsToUserIdsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcCustomIdsToUserIdsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcCustomIdsToUserIdsRequest} RpcCustomIdsToUserIdsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcCustomIdsToUserIdsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcCustomIdsToUserIdsRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.ids && message.ids.length))
                            message.ids = [];
                        message.ids.push(reader.string());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcCustomIdsToUserIdsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcCustomIdsToUserIdsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcCustomIdsToUserIdsRequest} RpcCustomIdsToUserIdsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcCustomIdsToUserIdsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcCustomIdsToUserIdsRequest message.
         * @function verify
         * @memberof api.RpcCustomIdsToUserIdsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcCustomIdsToUserIdsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.ids != null && message.hasOwnProperty("ids")) {
                if (!Array.isArray(message.ids))
                    return "ids: array expected";
                for (let i = 0; i < message.ids.length; ++i)
                    if (!$util.isString(message.ids[i]))
                        return "ids: string[] expected";
            }
            return null;
        };

        /**
         * Creates a RpcCustomIdsToUserIdsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcCustomIdsToUserIdsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcCustomIdsToUserIdsRequest} RpcCustomIdsToUserIdsRequest
         */
        RpcCustomIdsToUserIdsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcCustomIdsToUserIdsRequest)
                return object;
            let message = new $root.api.RpcCustomIdsToUserIdsRequest();
            if (object.ids) {
                if (!Array.isArray(object.ids))
                    throw TypeError(".api.RpcCustomIdsToUserIdsRequest.ids: array expected");
                message.ids = [];
                for (let i = 0; i < object.ids.length; ++i)
                    message.ids[i] = String(object.ids[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from a RpcCustomIdsToUserIdsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcCustomIdsToUserIdsRequest
         * @static
         * @param {api.RpcCustomIdsToUserIdsRequest} message RpcCustomIdsToUserIdsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcCustomIdsToUserIdsRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.ids = [];
            if (message.ids && message.ids.length) {
                object.ids = [];
                for (let j = 0; j < message.ids.length; ++j)
                    object.ids[j] = message.ids[j];
            }
            return object;
        };

        /**
         * Converts this RpcCustomIdsToUserIdsRequest to JSON.
         * @function toJSON
         * @memberof api.RpcCustomIdsToUserIdsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcCustomIdsToUserIdsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcCustomIdsToUserIdsRequest
         * @function getTypeUrl
         * @memberof api.RpcCustomIdsToUserIdsRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcCustomIdsToUserIdsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcCustomIdsToUserIdsRequest";
        };

        return RpcCustomIdsToUserIdsRequest;
    })();

    api.RpcCustomIdsToUserIdsResponse = (function() {

        /**
         * Properties of a RpcCustomIdsToUserIdsResponse.
         * @memberof api
         * @interface IRpcCustomIdsToUserIdsResponse
         * @property {Array.<string>|null} [ids] RpcCustomIdsToUserIdsResponse ids
         */

        /**
         * Constructs a new RpcCustomIdsToUserIdsResponse.
         * @memberof api
         * @classdesc Represents a RpcCustomIdsToUserIdsResponse.
         * @implements IRpcCustomIdsToUserIdsResponse
         * @constructor
         * @param {api.IRpcCustomIdsToUserIdsResponse=} [properties] Properties to set
         */
        function RpcCustomIdsToUserIdsResponse(properties) {
            this.ids = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcCustomIdsToUserIdsResponse ids.
         * @member {Array.<string>} ids
         * @memberof api.RpcCustomIdsToUserIdsResponse
         * @instance
         */
        RpcCustomIdsToUserIdsResponse.prototype.ids = $util.emptyArray;

        /**
         * Creates a new RpcCustomIdsToUserIdsResponse instance using the specified properties.
         * @function create
         * @memberof api.RpcCustomIdsToUserIdsResponse
         * @static
         * @param {api.IRpcCustomIdsToUserIdsResponse=} [properties] Properties to set
         * @returns {api.RpcCustomIdsToUserIdsResponse} RpcCustomIdsToUserIdsResponse instance
         */
        RpcCustomIdsToUserIdsResponse.create = function create(properties) {
            return new RpcCustomIdsToUserIdsResponse(properties);
        };

        /**
         * Encodes the specified RpcCustomIdsToUserIdsResponse message. Does not implicitly {@link api.RpcCustomIdsToUserIdsResponse.verify|verify} messages.
         * @function encode
         * @memberof api.RpcCustomIdsToUserIdsResponse
         * @static
         * @param {api.RpcCustomIdsToUserIdsResponse} message RpcCustomIdsToUserIdsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcCustomIdsToUserIdsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.ids != null && message.ids.length)
                for (let i = 0; i < message.ids.length; ++i)
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.ids[i]);
            return writer;
        };

        /**
         * Encodes the specified RpcCustomIdsToUserIdsResponse message, length delimited. Does not implicitly {@link api.RpcCustomIdsToUserIdsResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcCustomIdsToUserIdsResponse
         * @static
         * @param {api.RpcCustomIdsToUserIdsResponse} message RpcCustomIdsToUserIdsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcCustomIdsToUserIdsResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcCustomIdsToUserIdsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcCustomIdsToUserIdsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcCustomIdsToUserIdsResponse} RpcCustomIdsToUserIdsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcCustomIdsToUserIdsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcCustomIdsToUserIdsResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.ids && message.ids.length))
                            message.ids = [];
                        message.ids.push(reader.string());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcCustomIdsToUserIdsResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcCustomIdsToUserIdsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcCustomIdsToUserIdsResponse} RpcCustomIdsToUserIdsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcCustomIdsToUserIdsResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcCustomIdsToUserIdsResponse message.
         * @function verify
         * @memberof api.RpcCustomIdsToUserIdsResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcCustomIdsToUserIdsResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.ids != null && message.hasOwnProperty("ids")) {
                if (!Array.isArray(message.ids))
                    return "ids: array expected";
                for (let i = 0; i < message.ids.length; ++i)
                    if (!$util.isString(message.ids[i]))
                        return "ids: string[] expected";
            }
            return null;
        };

        /**
         * Creates a RpcCustomIdsToUserIdsResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcCustomIdsToUserIdsResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcCustomIdsToUserIdsResponse} RpcCustomIdsToUserIdsResponse
         */
        RpcCustomIdsToUserIdsResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcCustomIdsToUserIdsResponse)
                return object;
            let message = new $root.api.RpcCustomIdsToUserIdsResponse();
            if (object.ids) {
                if (!Array.isArray(object.ids))
                    throw TypeError(".api.RpcCustomIdsToUserIdsResponse.ids: array expected");
                message.ids = [];
                for (let i = 0; i < object.ids.length; ++i)
                    message.ids[i] = String(object.ids[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from a RpcCustomIdsToUserIdsResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcCustomIdsToUserIdsResponse
         * @static
         * @param {api.RpcCustomIdsToUserIdsResponse} message RpcCustomIdsToUserIdsResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcCustomIdsToUserIdsResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.ids = [];
            if (message.ids && message.ids.length) {
                object.ids = [];
                for (let j = 0; j < message.ids.length; ++j)
                    object.ids[j] = message.ids[j];
            }
            return object;
        };

        /**
         * Converts this RpcCustomIdsToUserIdsResponse to JSON.
         * @function toJSON
         * @memberof api.RpcCustomIdsToUserIdsResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcCustomIdsToUserIdsResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcCustomIdsToUserIdsResponse
         * @function getTypeUrl
         * @memberof api.RpcCustomIdsToUserIdsResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcCustomIdsToUserIdsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcCustomIdsToUserIdsResponse";
        };

        return RpcCustomIdsToUserIdsResponse;
    })();

    api.RpcInitBetRequest = (function() {

        /**
         * Properties of a RpcInitBetRequest.
         * @memberof api
         * @interface IRpcInitBetRequest
         * @property {string|null} [currency] RpcInitBetRequest currency
         * @property {string|null} [currencyOut] RpcInitBetRequest currencyOut
         * @property {string|null} [amount] RpcInitBetRequest amount
         */

        /**
         * Constructs a new RpcInitBetRequest.
         * @memberof api
         * @classdesc Represents a RpcInitBetRequest.
         * @implements IRpcInitBetRequest
         * @constructor
         * @param {api.IRpcInitBetRequest=} [properties] Properties to set
         */
        function RpcInitBetRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcInitBetRequest currency.
         * @member {string} currency
         * @memberof api.RpcInitBetRequest
         * @instance
         */
        RpcInitBetRequest.prototype.currency = "";

        /**
         * RpcInitBetRequest currencyOut.
         * @member {string} currencyOut
         * @memberof api.RpcInitBetRequest
         * @instance
         */
        RpcInitBetRequest.prototype.currencyOut = "";

        /**
         * RpcInitBetRequest amount.
         * @member {string} amount
         * @memberof api.RpcInitBetRequest
         * @instance
         */
        RpcInitBetRequest.prototype.amount = "";

        /**
         * Creates a new RpcInitBetRequest instance using the specified properties.
         * @function create
         * @memberof api.RpcInitBetRequest
         * @static
         * @param {api.IRpcInitBetRequest=} [properties] Properties to set
         * @returns {api.RpcInitBetRequest} RpcInitBetRequest instance
         */
        RpcInitBetRequest.create = function create(properties) {
            return new RpcInitBetRequest(properties);
        };

        /**
         * Encodes the specified RpcInitBetRequest message. Does not implicitly {@link api.RpcInitBetRequest.verify|verify} messages.
         * @function encode
         * @memberof api.RpcInitBetRequest
         * @static
         * @param {api.RpcInitBetRequest} message RpcInitBetRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcInitBetRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.currency);
            if (message.currencyOut != null && Object.hasOwnProperty.call(message, "currencyOut"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.currencyOut);
            if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.amount);
            return writer;
        };

        /**
         * Encodes the specified RpcInitBetRequest message, length delimited. Does not implicitly {@link api.RpcInitBetRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcInitBetRequest
         * @static
         * @param {api.RpcInitBetRequest} message RpcInitBetRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcInitBetRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcInitBetRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcInitBetRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcInitBetRequest} RpcInitBetRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcInitBetRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcInitBetRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.currency = reader.string();
                        break;
                    }
                case 2: {
                        message.currencyOut = reader.string();
                        break;
                    }
                case 3: {
                        message.amount = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcInitBetRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcInitBetRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcInitBetRequest} RpcInitBetRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcInitBetRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcInitBetRequest message.
         * @function verify
         * @memberof api.RpcInitBetRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcInitBetRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.currency != null && message.hasOwnProperty("currency"))
                if (!$util.isString(message.currency))
                    return "currency: string expected";
            if (message.currencyOut != null && message.hasOwnProperty("currencyOut"))
                if (!$util.isString(message.currencyOut))
                    return "currencyOut: string expected";
            if (message.amount != null && message.hasOwnProperty("amount"))
                if (!$util.isString(message.amount))
                    return "amount: string expected";
            return null;
        };

        /**
         * Creates a RpcInitBetRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcInitBetRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcInitBetRequest} RpcInitBetRequest
         */
        RpcInitBetRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcInitBetRequest)
                return object;
            let message = new $root.api.RpcInitBetRequest();
            if (object.currency != null)
                message.currency = String(object.currency);
            if (object.currencyOut != null)
                message.currencyOut = String(object.currencyOut);
            if (object.amount != null)
                message.amount = String(object.amount);
            return message;
        };

        /**
         * Creates a plain object from a RpcInitBetRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcInitBetRequest
         * @static
         * @param {api.RpcInitBetRequest} message RpcInitBetRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcInitBetRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.currency = "";
                object.currencyOut = "";
                object.amount = "";
            }
            if (message.currency != null && message.hasOwnProperty("currency"))
                object.currency = message.currency;
            if (message.currencyOut != null && message.hasOwnProperty("currencyOut"))
                object.currencyOut = message.currencyOut;
            if (message.amount != null && message.hasOwnProperty("amount"))
                object.amount = message.amount;
            return object;
        };

        /**
         * Converts this RpcInitBetRequest to JSON.
         * @function toJSON
         * @memberof api.RpcInitBetRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcInitBetRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcInitBetRequest
         * @function getTypeUrl
         * @memberof api.RpcInitBetRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcInitBetRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcInitBetRequest";
        };

        return RpcInitBetRequest;
    })();

    api.RpcInitBetResponse = (function() {

        /**
         * Properties of a RpcInitBetResponse.
         * @memberof api
         * @interface IRpcInitBetResponse
         * @property {number|null} [code] RpcInitBetResponse code
         * @property {string|null} [operationUid] RpcInitBetResponse operationUid
         * @property {string|null} [refLink] RpcInitBetResponse refLink
         */

        /**
         * Constructs a new RpcInitBetResponse.
         * @memberof api
         * @classdesc Represents a RpcInitBetResponse.
         * @implements IRpcInitBetResponse
         * @constructor
         * @param {api.IRpcInitBetResponse=} [properties] Properties to set
         */
        function RpcInitBetResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcInitBetResponse code.
         * @member {number} code
         * @memberof api.RpcInitBetResponse
         * @instance
         */
        RpcInitBetResponse.prototype.code = 0;

        /**
         * RpcInitBetResponse operationUid.
         * @member {string} operationUid
         * @memberof api.RpcInitBetResponse
         * @instance
         */
        RpcInitBetResponse.prototype.operationUid = "";

        /**
         * RpcInitBetResponse refLink.
         * @member {string} refLink
         * @memberof api.RpcInitBetResponse
         * @instance
         */
        RpcInitBetResponse.prototype.refLink = "";

        /**
         * Creates a new RpcInitBetResponse instance using the specified properties.
         * @function create
         * @memberof api.RpcInitBetResponse
         * @static
         * @param {api.IRpcInitBetResponse=} [properties] Properties to set
         * @returns {api.RpcInitBetResponse} RpcInitBetResponse instance
         */
        RpcInitBetResponse.create = function create(properties) {
            return new RpcInitBetResponse(properties);
        };

        /**
         * Encodes the specified RpcInitBetResponse message. Does not implicitly {@link api.RpcInitBetResponse.verify|verify} messages.
         * @function encode
         * @memberof api.RpcInitBetResponse
         * @static
         * @param {api.RpcInitBetResponse} message RpcInitBetResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcInitBetResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.code);
            if (message.operationUid != null && Object.hasOwnProperty.call(message, "operationUid"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.operationUid);
            if (message.refLink != null && Object.hasOwnProperty.call(message, "refLink"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.refLink);
            return writer;
        };

        /**
         * Encodes the specified RpcInitBetResponse message, length delimited. Does not implicitly {@link api.RpcInitBetResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcInitBetResponse
         * @static
         * @param {api.RpcInitBetResponse} message RpcInitBetResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcInitBetResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcInitBetResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcInitBetResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcInitBetResponse} RpcInitBetResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcInitBetResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcInitBetResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.code = reader.int32();
                        break;
                    }
                case 2: {
                        message.operationUid = reader.string();
                        break;
                    }
                case 3: {
                        message.refLink = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcInitBetResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcInitBetResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcInitBetResponse} RpcInitBetResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcInitBetResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcInitBetResponse message.
         * @function verify
         * @memberof api.RpcInitBetResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcInitBetResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.code != null && message.hasOwnProperty("code"))
                if (!$util.isInteger(message.code))
                    return "code: integer expected";
            if (message.operationUid != null && message.hasOwnProperty("operationUid"))
                if (!$util.isString(message.operationUid))
                    return "operationUid: string expected";
            if (message.refLink != null && message.hasOwnProperty("refLink"))
                if (!$util.isString(message.refLink))
                    return "refLink: string expected";
            return null;
        };

        /**
         * Creates a RpcInitBetResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcInitBetResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcInitBetResponse} RpcInitBetResponse
         */
        RpcInitBetResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcInitBetResponse)
                return object;
            let message = new $root.api.RpcInitBetResponse();
            if (object.code != null)
                message.code = object.code | 0;
            if (object.operationUid != null)
                message.operationUid = String(object.operationUid);
            if (object.refLink != null)
                message.refLink = String(object.refLink);
            return message;
        };

        /**
         * Creates a plain object from a RpcInitBetResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcInitBetResponse
         * @static
         * @param {api.RpcInitBetResponse} message RpcInitBetResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcInitBetResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.code = 0;
                object.operationUid = "";
                object.refLink = "";
            }
            if (message.code != null && message.hasOwnProperty("code"))
                object.code = message.code;
            if (message.operationUid != null && message.hasOwnProperty("operationUid"))
                object.operationUid = message.operationUid;
            if (message.refLink != null && message.hasOwnProperty("refLink"))
                object.refLink = message.refLink;
            return object;
        };

        /**
         * Converts this RpcInitBetResponse to JSON.
         * @function toJSON
         * @memberof api.RpcInitBetResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcInitBetResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcInitBetResponse
         * @function getTypeUrl
         * @memberof api.RpcInitBetResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcInitBetResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcInitBetResponse";
        };

        return RpcInitBetResponse;
    })();

    api.RpcAddBetRequest = (function() {

        /**
         * Properties of a RpcAddBetRequest.
         * @memberof api
         * @interface IRpcAddBetRequest
         * @property {string|null} [operationUid] RpcAddBetRequest operationUid
         * @property {string|null} [currency] RpcAddBetRequest currency
         */

        /**
         * Constructs a new RpcAddBetRequest.
         * @memberof api
         * @classdesc Represents a RpcAddBetRequest.
         * @implements IRpcAddBetRequest
         * @constructor
         * @param {api.IRpcAddBetRequest=} [properties] Properties to set
         */
        function RpcAddBetRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcAddBetRequest operationUid.
         * @member {string} operationUid
         * @memberof api.RpcAddBetRequest
         * @instance
         */
        RpcAddBetRequest.prototype.operationUid = "";

        /**
         * RpcAddBetRequest currency.
         * @member {string} currency
         * @memberof api.RpcAddBetRequest
         * @instance
         */
        RpcAddBetRequest.prototype.currency = "";

        /**
         * Creates a new RpcAddBetRequest instance using the specified properties.
         * @function create
         * @memberof api.RpcAddBetRequest
         * @static
         * @param {api.IRpcAddBetRequest=} [properties] Properties to set
         * @returns {api.RpcAddBetRequest} RpcAddBetRequest instance
         */
        RpcAddBetRequest.create = function create(properties) {
            return new RpcAddBetRequest(properties);
        };

        /**
         * Encodes the specified RpcAddBetRequest message. Does not implicitly {@link api.RpcAddBetRequest.verify|verify} messages.
         * @function encode
         * @memberof api.RpcAddBetRequest
         * @static
         * @param {api.RpcAddBetRequest} message RpcAddBetRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcAddBetRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.operationUid != null && Object.hasOwnProperty.call(message, "operationUid"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.operationUid);
            if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.currency);
            return writer;
        };

        /**
         * Encodes the specified RpcAddBetRequest message, length delimited. Does not implicitly {@link api.RpcAddBetRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcAddBetRequest
         * @static
         * @param {api.RpcAddBetRequest} message RpcAddBetRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcAddBetRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcAddBetRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcAddBetRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcAddBetRequest} RpcAddBetRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcAddBetRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcAddBetRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.operationUid = reader.string();
                        break;
                    }
                case 2: {
                        message.currency = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcAddBetRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcAddBetRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcAddBetRequest} RpcAddBetRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcAddBetRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcAddBetRequest message.
         * @function verify
         * @memberof api.RpcAddBetRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcAddBetRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.operationUid != null && message.hasOwnProperty("operationUid"))
                if (!$util.isString(message.operationUid))
                    return "operationUid: string expected";
            if (message.currency != null && message.hasOwnProperty("currency"))
                if (!$util.isString(message.currency))
                    return "currency: string expected";
            return null;
        };

        /**
         * Creates a RpcAddBetRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcAddBetRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcAddBetRequest} RpcAddBetRequest
         */
        RpcAddBetRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcAddBetRequest)
                return object;
            let message = new $root.api.RpcAddBetRequest();
            if (object.operationUid != null)
                message.operationUid = String(object.operationUid);
            if (object.currency != null)
                message.currency = String(object.currency);
            return message;
        };

        /**
         * Creates a plain object from a RpcAddBetRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcAddBetRequest
         * @static
         * @param {api.RpcAddBetRequest} message RpcAddBetRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcAddBetRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.operationUid = "";
                object.currency = "";
            }
            if (message.operationUid != null && message.hasOwnProperty("operationUid"))
                object.operationUid = message.operationUid;
            if (message.currency != null && message.hasOwnProperty("currency"))
                object.currency = message.currency;
            return object;
        };

        /**
         * Converts this RpcAddBetRequest to JSON.
         * @function toJSON
         * @memberof api.RpcAddBetRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcAddBetRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcAddBetRequest
         * @function getTypeUrl
         * @memberof api.RpcAddBetRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcAddBetRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcAddBetRequest";
        };

        return RpcAddBetRequest;
    })();

    api.RpcAddBetResponse = (function() {

        /**
         * Properties of a RpcAddBetResponse.
         * @memberof api
         * @interface IRpcAddBetResponse
         * @property {number|null} [code] RpcAddBetResponse code
         * @property {string|null} [operationUid] RpcAddBetResponse operationUid
         * @property {string|null} [refLink] RpcAddBetResponse refLink
         */

        /**
         * Constructs a new RpcAddBetResponse.
         * @memberof api
         * @classdesc Represents a RpcAddBetResponse.
         * @implements IRpcAddBetResponse
         * @constructor
         * @param {api.IRpcAddBetResponse=} [properties] Properties to set
         */
        function RpcAddBetResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RpcAddBetResponse code.
         * @member {number} code
         * @memberof api.RpcAddBetResponse
         * @instance
         */
        RpcAddBetResponse.prototype.code = 0;

        /**
         * RpcAddBetResponse operationUid.
         * @member {string} operationUid
         * @memberof api.RpcAddBetResponse
         * @instance
         */
        RpcAddBetResponse.prototype.operationUid = "";

        /**
         * RpcAddBetResponse refLink.
         * @member {string} refLink
         * @memberof api.RpcAddBetResponse
         * @instance
         */
        RpcAddBetResponse.prototype.refLink = "";

        /**
         * Creates a new RpcAddBetResponse instance using the specified properties.
         * @function create
         * @memberof api.RpcAddBetResponse
         * @static
         * @param {api.IRpcAddBetResponse=} [properties] Properties to set
         * @returns {api.RpcAddBetResponse} RpcAddBetResponse instance
         */
        RpcAddBetResponse.create = function create(properties) {
            return new RpcAddBetResponse(properties);
        };

        /**
         * Encodes the specified RpcAddBetResponse message. Does not implicitly {@link api.RpcAddBetResponse.verify|verify} messages.
         * @function encode
         * @memberof api.RpcAddBetResponse
         * @static
         * @param {api.RpcAddBetResponse} message RpcAddBetResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcAddBetResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.code);
            if (message.operationUid != null && Object.hasOwnProperty.call(message, "operationUid"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.operationUid);
            if (message.refLink != null && Object.hasOwnProperty.call(message, "refLink"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.refLink);
            return writer;
        };

        /**
         * Encodes the specified RpcAddBetResponse message, length delimited. Does not implicitly {@link api.RpcAddBetResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RpcAddBetResponse
         * @static
         * @param {api.RpcAddBetResponse} message RpcAddBetResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RpcAddBetResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RpcAddBetResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.RpcAddBetResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RpcAddBetResponse} RpcAddBetResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcAddBetResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RpcAddBetResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.code = reader.int32();
                        break;
                    }
                case 2: {
                        message.operationUid = reader.string();
                        break;
                    }
                case 3: {
                        message.refLink = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RpcAddBetResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RpcAddBetResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RpcAddBetResponse} RpcAddBetResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RpcAddBetResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RpcAddBetResponse message.
         * @function verify
         * @memberof api.RpcAddBetResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RpcAddBetResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.code != null && message.hasOwnProperty("code"))
                if (!$util.isInteger(message.code))
                    return "code: integer expected";
            if (message.operationUid != null && message.hasOwnProperty("operationUid"))
                if (!$util.isString(message.operationUid))
                    return "operationUid: string expected";
            if (message.refLink != null && message.hasOwnProperty("refLink"))
                if (!$util.isString(message.refLink))
                    return "refLink: string expected";
            return null;
        };

        /**
         * Creates a RpcAddBetResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RpcAddBetResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RpcAddBetResponse} RpcAddBetResponse
         */
        RpcAddBetResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RpcAddBetResponse)
                return object;
            let message = new $root.api.RpcAddBetResponse();
            if (object.code != null)
                message.code = object.code | 0;
            if (object.operationUid != null)
                message.operationUid = String(object.operationUid);
            if (object.refLink != null)
                message.refLink = String(object.refLink);
            return message;
        };

        /**
         * Creates a plain object from a RpcAddBetResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RpcAddBetResponse
         * @static
         * @param {api.RpcAddBetResponse} message RpcAddBetResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RpcAddBetResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.code = 0;
                object.operationUid = "";
                object.refLink = "";
            }
            if (message.code != null && message.hasOwnProperty("code"))
                object.code = message.code;
            if (message.operationUid != null && message.hasOwnProperty("operationUid"))
                object.operationUid = message.operationUid;
            if (message.refLink != null && message.hasOwnProperty("refLink"))
                object.refLink = message.refLink;
            return object;
        };

        /**
         * Converts this RpcAddBetResponse to JSON.
         * @function toJSON
         * @memberof api.RpcAddBetResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RpcAddBetResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RpcAddBetResponse
         * @function getTypeUrl
         * @memberof api.RpcAddBetResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RpcAddBetResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.RpcAddBetResponse";
        };

        return RpcAddBetResponse;
    })();

    api.PlayerDiceResponse = (function() {

        /**
         * Properties of a PlayerDiceResponse.
         * @memberof api
         * @interface IPlayerDiceResponse
         * @property {api.PlayerDiceResult|null} [result] PlayerDiceResponse result
         * @property {string|null} [uId] PlayerDiceResponse uId
         * @property {number|null} [turnTimeS] PlayerDiceResponse turnTimeS
         * @property {Array.<number>|null} [rIdTiles] PlayerDiceResponse rIdTiles
         */

        /**
         * Constructs a new PlayerDiceResponse.
         * @memberof api
         * @classdesc Represents a PlayerDiceResponse.
         * @implements IPlayerDiceResponse
         * @constructor
         * @param {api.IPlayerDiceResponse=} [properties] Properties to set
         */
        function PlayerDiceResponse(properties) {
            this.rIdTiles = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PlayerDiceResponse result.
         * @member {api.PlayerDiceResult} result
         * @memberof api.PlayerDiceResponse
         * @instance
         */
        PlayerDiceResponse.prototype.result = 0;

        /**
         * PlayerDiceResponse uId.
         * @member {string} uId
         * @memberof api.PlayerDiceResponse
         * @instance
         */
        PlayerDiceResponse.prototype.uId = "";

        /**
         * PlayerDiceResponse turnTimeS.
         * @member {number} turnTimeS
         * @memberof api.PlayerDiceResponse
         * @instance
         */
        PlayerDiceResponse.prototype.turnTimeS = 0;

        /**
         * PlayerDiceResponse rIdTiles.
         * @member {Array.<number>} rIdTiles
         * @memberof api.PlayerDiceResponse
         * @instance
         */
        PlayerDiceResponse.prototype.rIdTiles = $util.emptyArray;

        /**
         * Creates a new PlayerDiceResponse instance using the specified properties.
         * @function create
         * @memberof api.PlayerDiceResponse
         * @static
         * @param {api.IPlayerDiceResponse=} [properties] Properties to set
         * @returns {api.PlayerDiceResponse} PlayerDiceResponse instance
         */
        PlayerDiceResponse.create = function create(properties) {
            return new PlayerDiceResponse(properties);
        };

        /**
         * Encodes the specified PlayerDiceResponse message. Does not implicitly {@link api.PlayerDiceResponse.verify|verify} messages.
         * @function encode
         * @memberof api.PlayerDiceResponse
         * @static
         * @param {api.PlayerDiceResponse} message PlayerDiceResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlayerDiceResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
            if (message.uId != null && Object.hasOwnProperty.call(message, "uId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.uId);
            if (message.turnTimeS != null && Object.hasOwnProperty.call(message, "turnTimeS"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.turnTimeS);
            if (message.rIdTiles != null && message.rIdTiles.length) {
                writer.uint32(/* id 4, wireType 2 =*/34).fork();
                for (let i = 0; i < message.rIdTiles.length; ++i)
                    writer.int32(message.rIdTiles[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Encodes the specified PlayerDiceResponse message, length delimited. Does not implicitly {@link api.PlayerDiceResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.PlayerDiceResponse
         * @static
         * @param {api.PlayerDiceResponse} message PlayerDiceResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlayerDiceResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PlayerDiceResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.PlayerDiceResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.PlayerDiceResponse} PlayerDiceResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlayerDiceResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.PlayerDiceResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.result = reader.int32();
                        break;
                    }
                case 2: {
                        message.uId = reader.string();
                        break;
                    }
                case 3: {
                        message.turnTimeS = reader.int32();
                        break;
                    }
                case 4: {
                        if (!(message.rIdTiles && message.rIdTiles.length))
                            message.rIdTiles = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.rIdTiles.push(reader.int32());
                        } else
                            message.rIdTiles.push(reader.int32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PlayerDiceResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.PlayerDiceResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.PlayerDiceResponse} PlayerDiceResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlayerDiceResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PlayerDiceResponse message.
         * @function verify
         * @memberof api.PlayerDiceResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PlayerDiceResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.result != null && message.hasOwnProperty("result"))
                switch (message.result) {
                default:
                    return "result: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            if (message.uId != null && message.hasOwnProperty("uId"))
                if (!$util.isString(message.uId))
                    return "uId: string expected";
            if (message.turnTimeS != null && message.hasOwnProperty("turnTimeS"))
                if (!$util.isInteger(message.turnTimeS))
                    return "turnTimeS: integer expected";
            if (message.rIdTiles != null && message.hasOwnProperty("rIdTiles")) {
                if (!Array.isArray(message.rIdTiles))
                    return "rIdTiles: array expected";
                for (let i = 0; i < message.rIdTiles.length; ++i)
                    if (!$util.isInteger(message.rIdTiles[i]))
                        return "rIdTiles: integer[] expected";
            }
            return null;
        };

        /**
         * Creates a PlayerDiceResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.PlayerDiceResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.PlayerDiceResponse} PlayerDiceResponse
         */
        PlayerDiceResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.PlayerDiceResponse)
                return object;
            let message = new $root.api.PlayerDiceResponse();
            switch (object.result) {
            default:
                if (typeof object.result === "number") {
                    message.result = object.result;
                    break;
                }
                break;
            case "DICE_UNSPECIFIED":
            case 0:
                message.result = 0;
                break;
            case "DICE_SUCCESS":
            case 1:
                message.result = 1;
                break;
            case "DICE_FAIL_BAD_DATA":
            case 2:
                message.result = 2;
                break;
            case "DICE_FAIL_NOT_ACTION_YET":
            case 3:
                message.result = 3;
                break;
            }
            if (object.uId != null)
                message.uId = String(object.uId);
            if (object.turnTimeS != null)
                message.turnTimeS = object.turnTimeS | 0;
            if (object.rIdTiles) {
                if (!Array.isArray(object.rIdTiles))
                    throw TypeError(".api.PlayerDiceResponse.rIdTiles: array expected");
                message.rIdTiles = [];
                for (let i = 0; i < object.rIdTiles.length; ++i)
                    message.rIdTiles[i] = object.rIdTiles[i] | 0;
            }
            return message;
        };

        /**
         * Creates a plain object from a PlayerDiceResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.PlayerDiceResponse
         * @static
         * @param {api.PlayerDiceResponse} message PlayerDiceResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PlayerDiceResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.rIdTiles = [];
            if (options.defaults) {
                object.result = options.enums === String ? "DICE_UNSPECIFIED" : 0;
                object.uId = "";
                object.turnTimeS = 0;
            }
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = options.enums === String ? $root.api.PlayerDiceResult[message.result] === undefined ? message.result : $root.api.PlayerDiceResult[message.result] : message.result;
            if (message.uId != null && message.hasOwnProperty("uId"))
                object.uId = message.uId;
            if (message.turnTimeS != null && message.hasOwnProperty("turnTimeS"))
                object.turnTimeS = message.turnTimeS;
            if (message.rIdTiles && message.rIdTiles.length) {
                object.rIdTiles = [];
                for (let j = 0; j < message.rIdTiles.length; ++j)
                    object.rIdTiles[j] = message.rIdTiles[j];
            }
            return object;
        };

        /**
         * Converts this PlayerDiceResponse to JSON.
         * @function toJSON
         * @memberof api.PlayerDiceResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PlayerDiceResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PlayerDiceResponse
         * @function getTypeUrl
         * @memberof api.PlayerDiceResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PlayerDiceResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.PlayerDiceResponse";
        };

        return PlayerDiceResponse;
    })();

    api.MoveOnTileRequest = (function() {

        /**
         * Properties of a MoveOnTileRequest.
         * @memberof api
         * @interface IMoveOnTileRequest
         * @property {number|null} [index] MoveOnTileRequest index
         */

        /**
         * Constructs a new MoveOnTileRequest.
         * @memberof api
         * @classdesc Represents a MoveOnTileRequest.
         * @implements IMoveOnTileRequest
         * @constructor
         * @param {api.IMoveOnTileRequest=} [properties] Properties to set
         */
        function MoveOnTileRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MoveOnTileRequest index.
         * @member {number} index
         * @memberof api.MoveOnTileRequest
         * @instance
         */
        MoveOnTileRequest.prototype.index = 0;

        /**
         * Creates a new MoveOnTileRequest instance using the specified properties.
         * @function create
         * @memberof api.MoveOnTileRequest
         * @static
         * @param {api.IMoveOnTileRequest=} [properties] Properties to set
         * @returns {api.MoveOnTileRequest} MoveOnTileRequest instance
         */
        MoveOnTileRequest.create = function create(properties) {
            return new MoveOnTileRequest(properties);
        };

        /**
         * Encodes the specified MoveOnTileRequest message. Does not implicitly {@link api.MoveOnTileRequest.verify|verify} messages.
         * @function encode
         * @memberof api.MoveOnTileRequest
         * @static
         * @param {api.MoveOnTileRequest} message MoveOnTileRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MoveOnTileRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.index != null && Object.hasOwnProperty.call(message, "index"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.index);
            return writer;
        };

        /**
         * Encodes the specified MoveOnTileRequest message, length delimited. Does not implicitly {@link api.MoveOnTileRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.MoveOnTileRequest
         * @static
         * @param {api.MoveOnTileRequest} message MoveOnTileRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MoveOnTileRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MoveOnTileRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.MoveOnTileRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.MoveOnTileRequest} MoveOnTileRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MoveOnTileRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.MoveOnTileRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.index = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MoveOnTileRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.MoveOnTileRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.MoveOnTileRequest} MoveOnTileRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MoveOnTileRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MoveOnTileRequest message.
         * @function verify
         * @memberof api.MoveOnTileRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MoveOnTileRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.index != null && message.hasOwnProperty("index"))
                if (!$util.isInteger(message.index))
                    return "index: integer expected";
            return null;
        };

        /**
         * Creates a MoveOnTileRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.MoveOnTileRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.MoveOnTileRequest} MoveOnTileRequest
         */
        MoveOnTileRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.MoveOnTileRequest)
                return object;
            let message = new $root.api.MoveOnTileRequest();
            if (object.index != null)
                message.index = object.index | 0;
            return message;
        };

        /**
         * Creates a plain object from a MoveOnTileRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.MoveOnTileRequest
         * @static
         * @param {api.MoveOnTileRequest} message MoveOnTileRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MoveOnTileRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.index = 0;
            if (message.index != null && message.hasOwnProperty("index"))
                object.index = message.index;
            return object;
        };

        /**
         * Converts this MoveOnTileRequest to JSON.
         * @function toJSON
         * @memberof api.MoveOnTileRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MoveOnTileRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MoveOnTileRequest
         * @function getTypeUrl
         * @memberof api.MoveOnTileRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MoveOnTileRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.MoveOnTileRequest";
        };

        return MoveOnTileRequest;
    })();

    api.MoveOnTileResponce = (function() {

        /**
         * Properties of a MoveOnTileResponce.
         * @memberof api
         * @interface IMoveOnTileResponce
         * @property {api.MoveOnTileResult|null} [result] MoveOnTileResponce result
         * @property {number|null} [index] MoveOnTileResponce index
         */

        /**
         * Constructs a new MoveOnTileResponce.
         * @memberof api
         * @classdesc Represents a MoveOnTileResponce.
         * @implements IMoveOnTileResponce
         * @constructor
         * @param {api.IMoveOnTileResponce=} [properties] Properties to set
         */
        function MoveOnTileResponce(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MoveOnTileResponce result.
         * @member {api.MoveOnTileResult} result
         * @memberof api.MoveOnTileResponce
         * @instance
         */
        MoveOnTileResponce.prototype.result = 0;

        /**
         * MoveOnTileResponce index.
         * @member {number} index
         * @memberof api.MoveOnTileResponce
         * @instance
         */
        MoveOnTileResponce.prototype.index = 0;

        /**
         * Creates a new MoveOnTileResponce instance using the specified properties.
         * @function create
         * @memberof api.MoveOnTileResponce
         * @static
         * @param {api.IMoveOnTileResponce=} [properties] Properties to set
         * @returns {api.MoveOnTileResponce} MoveOnTileResponce instance
         */
        MoveOnTileResponce.create = function create(properties) {
            return new MoveOnTileResponce(properties);
        };

        /**
         * Encodes the specified MoveOnTileResponce message. Does not implicitly {@link api.MoveOnTileResponce.verify|verify} messages.
         * @function encode
         * @memberof api.MoveOnTileResponce
         * @static
         * @param {api.MoveOnTileResponce} message MoveOnTileResponce message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MoveOnTileResponce.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
            if (message.index != null && Object.hasOwnProperty.call(message, "index"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.index);
            return writer;
        };

        /**
         * Encodes the specified MoveOnTileResponce message, length delimited. Does not implicitly {@link api.MoveOnTileResponce.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.MoveOnTileResponce
         * @static
         * @param {api.MoveOnTileResponce} message MoveOnTileResponce message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MoveOnTileResponce.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MoveOnTileResponce message from the specified reader or buffer.
         * @function decode
         * @memberof api.MoveOnTileResponce
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.MoveOnTileResponce} MoveOnTileResponce
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MoveOnTileResponce.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.MoveOnTileResponce();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.result = reader.int32();
                        break;
                    }
                case 2: {
                        message.index = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MoveOnTileResponce message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.MoveOnTileResponce
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.MoveOnTileResponce} MoveOnTileResponce
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MoveOnTileResponce.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MoveOnTileResponce message.
         * @function verify
         * @memberof api.MoveOnTileResponce
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MoveOnTileResponce.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.result != null && message.hasOwnProperty("result"))
                switch (message.result) {
                default:
                    return "result: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                    break;
                }
            if (message.index != null && message.hasOwnProperty("index"))
                if (!$util.isInteger(message.index))
                    return "index: integer expected";
            return null;
        };

        /**
         * Creates a MoveOnTileResponce message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.MoveOnTileResponce
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.MoveOnTileResponce} MoveOnTileResponce
         */
        MoveOnTileResponce.fromObject = function fromObject(object) {
            if (object instanceof $root.api.MoveOnTileResponce)
                return object;
            let message = new $root.api.MoveOnTileResponce();
            switch (object.result) {
            default:
                if (typeof object.result === "number") {
                    message.result = object.result;
                    break;
                }
                break;
            case "MOVE_UNSPECIFIED":
            case 0:
                message.result = 0;
                break;
            case "MOVE_SUCCESS":
            case 1:
                message.result = 1;
                break;
            case "MOVE_FAIL_BAD_DATA":
            case 2:
                message.result = 2;
                break;
            case "MOVE_FAIL_UNAVAILABLE_TILE_TYPE":
            case 3:
                message.result = 3;
                break;
            case "MOVE_FAIL_CURRENT_TILE_POSITION":
            case 4:
                message.result = 4;
                break;
            case "MOVE_FAIL_INCORRECT_MATCH_STATE":
            case 5:
                message.result = 5;
                break;
            }
            if (object.index != null)
                message.index = object.index | 0;
            return message;
        };

        /**
         * Creates a plain object from a MoveOnTileResponce message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.MoveOnTileResponce
         * @static
         * @param {api.MoveOnTileResponce} message MoveOnTileResponce
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MoveOnTileResponce.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.result = options.enums === String ? "MOVE_UNSPECIFIED" : 0;
                object.index = 0;
            }
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = options.enums === String ? $root.api.MoveOnTileResult[message.result] === undefined ? message.result : $root.api.MoveOnTileResult[message.result] : message.result;
            if (message.index != null && message.hasOwnProperty("index"))
                object.index = message.index;
            return object;
        };

        /**
         * Converts this MoveOnTileResponce to JSON.
         * @function toJSON
         * @memberof api.MoveOnTileResponce
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MoveOnTileResponce.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MoveOnTileResponce
         * @function getTypeUrl
         * @memberof api.MoveOnTileResponce
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MoveOnTileResponce.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.MoveOnTileResponce";
        };

        return MoveOnTileResponce;
    })();

    api.EventResponce = (function() {

        /**
         * Properties of an EventResponce.
         * @memberof api
         * @interface IEventResponce
         * @property {string|null} [uId] EventResponce uId
         */

        /**
         * Constructs a new EventResponce.
         * @memberof api
         * @classdesc Represents an EventResponce.
         * @implements IEventResponce
         * @constructor
         * @param {api.IEventResponce=} [properties] Properties to set
         */
        function EventResponce(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EventResponce uId.
         * @member {string|null|undefined} uId
         * @memberof api.EventResponce
         * @instance
         */
        EventResponce.prototype.uId = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(EventResponce.prototype, "_uId", {
            get: $util.oneOfGetter($oneOfFields = ["uId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new EventResponce instance using the specified properties.
         * @function create
         * @memberof api.EventResponce
         * @static
         * @param {api.IEventResponce=} [properties] Properties to set
         * @returns {api.EventResponce} EventResponce instance
         */
        EventResponce.create = function create(properties) {
            return new EventResponce(properties);
        };

        /**
         * Encodes the specified EventResponce message. Does not implicitly {@link api.EventResponce.verify|verify} messages.
         * @function encode
         * @memberof api.EventResponce
         * @static
         * @param {api.EventResponce} message EventResponce message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EventResponce.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.uId != null && Object.hasOwnProperty.call(message, "uId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.uId);
            return writer;
        };

        /**
         * Encodes the specified EventResponce message, length delimited. Does not implicitly {@link api.EventResponce.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.EventResponce
         * @static
         * @param {api.EventResponce} message EventResponce message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EventResponce.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an EventResponce message from the specified reader or buffer.
         * @function decode
         * @memberof api.EventResponce
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.EventResponce} EventResponce
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EventResponce.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.EventResponce();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.uId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an EventResponce message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.EventResponce
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.EventResponce} EventResponce
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EventResponce.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an EventResponce message.
         * @function verify
         * @memberof api.EventResponce
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EventResponce.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.uId != null && message.hasOwnProperty("uId")) {
                properties._uId = 1;
                if (!$util.isString(message.uId))
                    return "uId: string expected";
            }
            return null;
        };

        /**
         * Creates an EventResponce message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.EventResponce
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.EventResponce} EventResponce
         */
        EventResponce.fromObject = function fromObject(object) {
            if (object instanceof $root.api.EventResponce)
                return object;
            let message = new $root.api.EventResponce();
            if (object.uId != null)
                message.uId = String(object.uId);
            return message;
        };

        /**
         * Creates a plain object from an EventResponce message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.EventResponce
         * @static
         * @param {api.EventResponce} message EventResponce
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EventResponce.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.uId != null && message.hasOwnProperty("uId")) {
                object.uId = message.uId;
                if (options.oneofs)
                    object._uId = "uId";
            }
            return object;
        };

        /**
         * Converts this EventResponce to JSON.
         * @function toJSON
         * @memberof api.EventResponce
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EventResponce.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for EventResponce
         * @function getTypeUrl
         * @memberof api.EventResponce
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        EventResponce.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.EventResponce";
        };

        return EventResponce;
    })();

    api.BuyTileRequest = (function() {

        /**
         * Properties of a BuyTileRequest.
         * @memberof api
         * @interface IBuyTileRequest
         * @property {number|null} [index] BuyTileRequest index
         */

        /**
         * Constructs a new BuyTileRequest.
         * @memberof api
         * @classdesc Represents a BuyTileRequest.
         * @implements IBuyTileRequest
         * @constructor
         * @param {api.IBuyTileRequest=} [properties] Properties to set
         */
        function BuyTileRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BuyTileRequest index.
         * @member {number} index
         * @memberof api.BuyTileRequest
         * @instance
         */
        BuyTileRequest.prototype.index = 0;

        /**
         * Creates a new BuyTileRequest instance using the specified properties.
         * @function create
         * @memberof api.BuyTileRequest
         * @static
         * @param {api.IBuyTileRequest=} [properties] Properties to set
         * @returns {api.BuyTileRequest} BuyTileRequest instance
         */
        BuyTileRequest.create = function create(properties) {
            return new BuyTileRequest(properties);
        };

        /**
         * Encodes the specified BuyTileRequest message. Does not implicitly {@link api.BuyTileRequest.verify|verify} messages.
         * @function encode
         * @memberof api.BuyTileRequest
         * @static
         * @param {api.BuyTileRequest} message BuyTileRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BuyTileRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.index != null && Object.hasOwnProperty.call(message, "index"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.index);
            return writer;
        };

        /**
         * Encodes the specified BuyTileRequest message, length delimited. Does not implicitly {@link api.BuyTileRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.BuyTileRequest
         * @static
         * @param {api.BuyTileRequest} message BuyTileRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BuyTileRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a BuyTileRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.BuyTileRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.BuyTileRequest} BuyTileRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BuyTileRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.BuyTileRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.index = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a BuyTileRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.BuyTileRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.BuyTileRequest} BuyTileRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BuyTileRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a BuyTileRequest message.
         * @function verify
         * @memberof api.BuyTileRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        BuyTileRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.index != null && message.hasOwnProperty("index"))
                if (!$util.isInteger(message.index))
                    return "index: integer expected";
            return null;
        };

        /**
         * Creates a BuyTileRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.BuyTileRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.BuyTileRequest} BuyTileRequest
         */
        BuyTileRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.BuyTileRequest)
                return object;
            let message = new $root.api.BuyTileRequest();
            if (object.index != null)
                message.index = object.index | 0;
            return message;
        };

        /**
         * Creates a plain object from a BuyTileRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.BuyTileRequest
         * @static
         * @param {api.BuyTileRequest} message BuyTileRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        BuyTileRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.index = 0;
            if (message.index != null && message.hasOwnProperty("index"))
                object.index = message.index;
            return object;
        };

        /**
         * Converts this BuyTileRequest to JSON.
         * @function toJSON
         * @memberof api.BuyTileRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        BuyTileRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for BuyTileRequest
         * @function getTypeUrl
         * @memberof api.BuyTileRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        BuyTileRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.BuyTileRequest";
        };

        return BuyTileRequest;
    })();

    api.BuyTileResponce = (function() {

        /**
         * Properties of a BuyTileResponce.
         * @memberof api
         * @interface IBuyTileResponce
         * @property {api.BuyTileResult|null} [result] BuyTileResponce result
         * @property {number|null} [index] BuyTileResponce index
         */

        /**
         * Constructs a new BuyTileResponce.
         * @memberof api
         * @classdesc Represents a BuyTileResponce.
         * @implements IBuyTileResponce
         * @constructor
         * @param {api.IBuyTileResponce=} [properties] Properties to set
         */
        function BuyTileResponce(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BuyTileResponce result.
         * @member {api.BuyTileResult} result
         * @memberof api.BuyTileResponce
         * @instance
         */
        BuyTileResponce.prototype.result = 0;

        /**
         * BuyTileResponce index.
         * @member {number} index
         * @memberof api.BuyTileResponce
         * @instance
         */
        BuyTileResponce.prototype.index = 0;

        /**
         * Creates a new BuyTileResponce instance using the specified properties.
         * @function create
         * @memberof api.BuyTileResponce
         * @static
         * @param {api.IBuyTileResponce=} [properties] Properties to set
         * @returns {api.BuyTileResponce} BuyTileResponce instance
         */
        BuyTileResponce.create = function create(properties) {
            return new BuyTileResponce(properties);
        };

        /**
         * Encodes the specified BuyTileResponce message. Does not implicitly {@link api.BuyTileResponce.verify|verify} messages.
         * @function encode
         * @memberof api.BuyTileResponce
         * @static
         * @param {api.BuyTileResponce} message BuyTileResponce message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BuyTileResponce.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
            if (message.index != null && Object.hasOwnProperty.call(message, "index"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.index);
            return writer;
        };

        /**
         * Encodes the specified BuyTileResponce message, length delimited. Does not implicitly {@link api.BuyTileResponce.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.BuyTileResponce
         * @static
         * @param {api.BuyTileResponce} message BuyTileResponce message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BuyTileResponce.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a BuyTileResponce message from the specified reader or buffer.
         * @function decode
         * @memberof api.BuyTileResponce
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.BuyTileResponce} BuyTileResponce
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BuyTileResponce.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.BuyTileResponce();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.result = reader.int32();
                        break;
                    }
                case 2: {
                        message.index = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a BuyTileResponce message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.BuyTileResponce
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.BuyTileResponce} BuyTileResponce
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BuyTileResponce.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a BuyTileResponce message.
         * @function verify
         * @memberof api.BuyTileResponce
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        BuyTileResponce.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.result != null && message.hasOwnProperty("result"))
                switch (message.result) {
                default:
                    return "result: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                    break;
                }
            if (message.index != null && message.hasOwnProperty("index"))
                if (!$util.isInteger(message.index))
                    return "index: integer expected";
            return null;
        };

        /**
         * Creates a BuyTileResponce message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.BuyTileResponce
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.BuyTileResponce} BuyTileResponce
         */
        BuyTileResponce.fromObject = function fromObject(object) {
            if (object instanceof $root.api.BuyTileResponce)
                return object;
            let message = new $root.api.BuyTileResponce();
            switch (object.result) {
            default:
                if (typeof object.result === "number") {
                    message.result = object.result;
                    break;
                }
                break;
            case "BUY_UNSPECIFIED":
            case 0:
                message.result = 0;
                break;
            case "BUY_SUCCESS":
            case 1:
                message.result = 1;
                break;
            case "BUY_FAIL_BAD_DATA":
            case 2:
                message.result = 2;
                break;
            case "BUY_FAIL_UNAVAILABLE_TILE_TYPE":
            case 3:
                message.result = 3;
                break;
            case "BUY_FAIL_NOT_ENOUGH_COINS":
            case 4:
                message.result = 4;
                break;
            case "BUY_FAIL_INCORRECT_MATCH_STATE":
            case 5:
                message.result = 5;
                break;
            case "BUY_FAIL_MISMATCH_TILE_INDEX":
            case 6:
                message.result = 6;
                break;
            case "BUY_FAIL_INCORRECT_OWNER":
            case 7:
                message.result = 7;
                break;
            case "BUY_FAIL_BUY_TURN_LIMIT":
            case 8:
                message.result = 8;
                break;
            }
            if (object.index != null)
                message.index = object.index | 0;
            return message;
        };

        /**
         * Creates a plain object from a BuyTileResponce message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.BuyTileResponce
         * @static
         * @param {api.BuyTileResponce} message BuyTileResponce
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        BuyTileResponce.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.result = options.enums === String ? "BUY_UNSPECIFIED" : 0;
                object.index = 0;
            }
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = options.enums === String ? $root.api.BuyTileResult[message.result] === undefined ? message.result : $root.api.BuyTileResult[message.result] : message.result;
            if (message.index != null && message.hasOwnProperty("index"))
                object.index = message.index;
            return object;
        };

        /**
         * Converts this BuyTileResponce to JSON.
         * @function toJSON
         * @memberof api.BuyTileResponce
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        BuyTileResponce.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for BuyTileResponce
         * @function getTypeUrl
         * @memberof api.BuyTileResponce
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        BuyTileResponce.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.BuyTileResponce";
        };

        return BuyTileResponce;
    })();

    api.SellTileRequest = (function() {

        /**
         * Properties of a SellTileRequest.
         * @memberof api
         * @interface ISellTileRequest
         * @property {number|null} [index] SellTileRequest index
         */

        /**
         * Constructs a new SellTileRequest.
         * @memberof api
         * @classdesc Represents a SellTileRequest.
         * @implements ISellTileRequest
         * @constructor
         * @param {api.ISellTileRequest=} [properties] Properties to set
         */
        function SellTileRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SellTileRequest index.
         * @member {number} index
         * @memberof api.SellTileRequest
         * @instance
         */
        SellTileRequest.prototype.index = 0;

        /**
         * Creates a new SellTileRequest instance using the specified properties.
         * @function create
         * @memberof api.SellTileRequest
         * @static
         * @param {api.ISellTileRequest=} [properties] Properties to set
         * @returns {api.SellTileRequest} SellTileRequest instance
         */
        SellTileRequest.create = function create(properties) {
            return new SellTileRequest(properties);
        };

        /**
         * Encodes the specified SellTileRequest message. Does not implicitly {@link api.SellTileRequest.verify|verify} messages.
         * @function encode
         * @memberof api.SellTileRequest
         * @static
         * @param {api.SellTileRequest} message SellTileRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SellTileRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.index != null && Object.hasOwnProperty.call(message, "index"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.index);
            return writer;
        };

        /**
         * Encodes the specified SellTileRequest message, length delimited. Does not implicitly {@link api.SellTileRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.SellTileRequest
         * @static
         * @param {api.SellTileRequest} message SellTileRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SellTileRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SellTileRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.SellTileRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.SellTileRequest} SellTileRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SellTileRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.SellTileRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.index = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SellTileRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.SellTileRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.SellTileRequest} SellTileRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SellTileRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SellTileRequest message.
         * @function verify
         * @memberof api.SellTileRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SellTileRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.index != null && message.hasOwnProperty("index"))
                if (!$util.isInteger(message.index))
                    return "index: integer expected";
            return null;
        };

        /**
         * Creates a SellTileRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.SellTileRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.SellTileRequest} SellTileRequest
         */
        SellTileRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.SellTileRequest)
                return object;
            let message = new $root.api.SellTileRequest();
            if (object.index != null)
                message.index = object.index | 0;
            return message;
        };

        /**
         * Creates a plain object from a SellTileRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.SellTileRequest
         * @static
         * @param {api.SellTileRequest} message SellTileRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SellTileRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.index = 0;
            if (message.index != null && message.hasOwnProperty("index"))
                object.index = message.index;
            return object;
        };

        /**
         * Converts this SellTileRequest to JSON.
         * @function toJSON
         * @memberof api.SellTileRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SellTileRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SellTileRequest
         * @function getTypeUrl
         * @memberof api.SellTileRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SellTileRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.SellTileRequest";
        };

        return SellTileRequest;
    })();

    api.SellTileResponce = (function() {

        /**
         * Properties of a SellTileResponce.
         * @memberof api
         * @interface ISellTileResponce
         * @property {api.SellTileResult|null} [result] SellTileResponce result
         */

        /**
         * Constructs a new SellTileResponce.
         * @memberof api
         * @classdesc Represents a SellTileResponce.
         * @implements ISellTileResponce
         * @constructor
         * @param {api.ISellTileResponce=} [properties] Properties to set
         */
        function SellTileResponce(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SellTileResponce result.
         * @member {api.SellTileResult} result
         * @memberof api.SellTileResponce
         * @instance
         */
        SellTileResponce.prototype.result = 0;

        /**
         * Creates a new SellTileResponce instance using the specified properties.
         * @function create
         * @memberof api.SellTileResponce
         * @static
         * @param {api.ISellTileResponce=} [properties] Properties to set
         * @returns {api.SellTileResponce} SellTileResponce instance
         */
        SellTileResponce.create = function create(properties) {
            return new SellTileResponce(properties);
        };

        /**
         * Encodes the specified SellTileResponce message. Does not implicitly {@link api.SellTileResponce.verify|verify} messages.
         * @function encode
         * @memberof api.SellTileResponce
         * @static
         * @param {api.SellTileResponce} message SellTileResponce message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SellTileResponce.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
            return writer;
        };

        /**
         * Encodes the specified SellTileResponce message, length delimited. Does not implicitly {@link api.SellTileResponce.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.SellTileResponce
         * @static
         * @param {api.SellTileResponce} message SellTileResponce message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SellTileResponce.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SellTileResponce message from the specified reader or buffer.
         * @function decode
         * @memberof api.SellTileResponce
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.SellTileResponce} SellTileResponce
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SellTileResponce.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.SellTileResponce();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.result = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SellTileResponce message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.SellTileResponce
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.SellTileResponce} SellTileResponce
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SellTileResponce.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SellTileResponce message.
         * @function verify
         * @memberof api.SellTileResponce
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SellTileResponce.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.result != null && message.hasOwnProperty("result"))
                switch (message.result) {
                default:
                    return "result: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            return null;
        };

        /**
         * Creates a SellTileResponce message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.SellTileResponce
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.SellTileResponce} SellTileResponce
         */
        SellTileResponce.fromObject = function fromObject(object) {
            if (object instanceof $root.api.SellTileResponce)
                return object;
            let message = new $root.api.SellTileResponce();
            switch (object.result) {
            default:
                if (typeof object.result === "number") {
                    message.result = object.result;
                    break;
                }
                break;
            case "SELL_UNSPECIFIED":
            case 0:
                message.result = 0;
                break;
            case "SELL_SUCCESS":
            case 1:
                message.result = 1;
                break;
            case "SELL_FAIL_BAD_DATA":
            case 2:
                message.result = 2;
                break;
            case "SELL_FAIL_INCORRECT_MATCH_STATE":
            case 3:
                message.result = 3;
                break;
            case "SELL_FAIL_INCORRECT_OWNER":
            case 4:
                message.result = 4;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a SellTileResponce message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.SellTileResponce
         * @static
         * @param {api.SellTileResponce} message SellTileResponce
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SellTileResponce.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.result = options.enums === String ? "SELL_UNSPECIFIED" : 0;
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = options.enums === String ? $root.api.SellTileResult[message.result] === undefined ? message.result : $root.api.SellTileResult[message.result] : message.result;
            return object;
        };

        /**
         * Converts this SellTileResponce to JSON.
         * @function toJSON
         * @memberof api.SellTileResponce
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SellTileResponce.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SellTileResponce
         * @function getTypeUrl
         * @memberof api.SellTileResponce
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SellTileResponce.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.SellTileResponce";
        };

        return SellTileResponce;
    })();

    api.PlayerBuyAvailabilityResponce = (function() {

        /**
         * Properties of a PlayerBuyAvailabilityResponce.
         * @memberof api
         * @interface IPlayerBuyAvailabilityResponce
         * @property {boolean|null} [available] PlayerBuyAvailabilityResponce available
         */

        /**
         * Constructs a new PlayerBuyAvailabilityResponce.
         * @memberof api
         * @classdesc Represents a PlayerBuyAvailabilityResponce.
         * @implements IPlayerBuyAvailabilityResponce
         * @constructor
         * @param {api.IPlayerBuyAvailabilityResponce=} [properties] Properties to set
         */
        function PlayerBuyAvailabilityResponce(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PlayerBuyAvailabilityResponce available.
         * @member {boolean} available
         * @memberof api.PlayerBuyAvailabilityResponce
         * @instance
         */
        PlayerBuyAvailabilityResponce.prototype.available = false;

        /**
         * Creates a new PlayerBuyAvailabilityResponce instance using the specified properties.
         * @function create
         * @memberof api.PlayerBuyAvailabilityResponce
         * @static
         * @param {api.IPlayerBuyAvailabilityResponce=} [properties] Properties to set
         * @returns {api.PlayerBuyAvailabilityResponce} PlayerBuyAvailabilityResponce instance
         */
        PlayerBuyAvailabilityResponce.create = function create(properties) {
            return new PlayerBuyAvailabilityResponce(properties);
        };

        /**
         * Encodes the specified PlayerBuyAvailabilityResponce message. Does not implicitly {@link api.PlayerBuyAvailabilityResponce.verify|verify} messages.
         * @function encode
         * @memberof api.PlayerBuyAvailabilityResponce
         * @static
         * @param {api.PlayerBuyAvailabilityResponce} message PlayerBuyAvailabilityResponce message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlayerBuyAvailabilityResponce.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.available != null && Object.hasOwnProperty.call(message, "available"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.available);
            return writer;
        };

        /**
         * Encodes the specified PlayerBuyAvailabilityResponce message, length delimited. Does not implicitly {@link api.PlayerBuyAvailabilityResponce.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.PlayerBuyAvailabilityResponce
         * @static
         * @param {api.PlayerBuyAvailabilityResponce} message PlayerBuyAvailabilityResponce message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlayerBuyAvailabilityResponce.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PlayerBuyAvailabilityResponce message from the specified reader or buffer.
         * @function decode
         * @memberof api.PlayerBuyAvailabilityResponce
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.PlayerBuyAvailabilityResponce} PlayerBuyAvailabilityResponce
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlayerBuyAvailabilityResponce.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.PlayerBuyAvailabilityResponce();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.available = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PlayerBuyAvailabilityResponce message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.PlayerBuyAvailabilityResponce
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.PlayerBuyAvailabilityResponce} PlayerBuyAvailabilityResponce
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlayerBuyAvailabilityResponce.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PlayerBuyAvailabilityResponce message.
         * @function verify
         * @memberof api.PlayerBuyAvailabilityResponce
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PlayerBuyAvailabilityResponce.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.available != null && message.hasOwnProperty("available"))
                if (typeof message.available !== "boolean")
                    return "available: boolean expected";
            return null;
        };

        /**
         * Creates a PlayerBuyAvailabilityResponce message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.PlayerBuyAvailabilityResponce
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.PlayerBuyAvailabilityResponce} PlayerBuyAvailabilityResponce
         */
        PlayerBuyAvailabilityResponce.fromObject = function fromObject(object) {
            if (object instanceof $root.api.PlayerBuyAvailabilityResponce)
                return object;
            let message = new $root.api.PlayerBuyAvailabilityResponce();
            if (object.available != null)
                message.available = Boolean(object.available);
            return message;
        };

        /**
         * Creates a plain object from a PlayerBuyAvailabilityResponce message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.PlayerBuyAvailabilityResponce
         * @static
         * @param {api.PlayerBuyAvailabilityResponce} message PlayerBuyAvailabilityResponce
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PlayerBuyAvailabilityResponce.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.available = false;
            if (message.available != null && message.hasOwnProperty("available"))
                object.available = message.available;
            return object;
        };

        /**
         * Converts this PlayerBuyAvailabilityResponce to JSON.
         * @function toJSON
         * @memberof api.PlayerBuyAvailabilityResponce
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PlayerBuyAvailabilityResponce.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PlayerBuyAvailabilityResponce
         * @function getTypeUrl
         * @memberof api.PlayerBuyAvailabilityResponce
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PlayerBuyAvailabilityResponce.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.PlayerBuyAvailabilityResponce";
        };

        return PlayerBuyAvailabilityResponce;
    })();

    api.CoinsUpdateResponce = (function() {

        /**
         * Properties of a CoinsUpdateResponce.
         * @memberof api
         * @interface ICoinsUpdateResponce
         * @property {api.CoinsUpdateReason|null} [reason] CoinsUpdateResponce reason
         * @property {number|null} [value] CoinsUpdateResponce value
         * @property {number|null} [rid] CoinsUpdateResponce rid
         */

        /**
         * Constructs a new CoinsUpdateResponce.
         * @memberof api
         * @classdesc Represents a CoinsUpdateResponce.
         * @implements ICoinsUpdateResponce
         * @constructor
         * @param {api.ICoinsUpdateResponce=} [properties] Properties to set
         */
        function CoinsUpdateResponce(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CoinsUpdateResponce reason.
         * @member {api.CoinsUpdateReason} reason
         * @memberof api.CoinsUpdateResponce
         * @instance
         */
        CoinsUpdateResponce.prototype.reason = 0;

        /**
         * CoinsUpdateResponce value.
         * @member {number} value
         * @memberof api.CoinsUpdateResponce
         * @instance
         */
        CoinsUpdateResponce.prototype.value = 0;

        /**
         * CoinsUpdateResponce rid.
         * @member {number} rid
         * @memberof api.CoinsUpdateResponce
         * @instance
         */
        CoinsUpdateResponce.prototype.rid = 0;

        /**
         * Creates a new CoinsUpdateResponce instance using the specified properties.
         * @function create
         * @memberof api.CoinsUpdateResponce
         * @static
         * @param {api.ICoinsUpdateResponce=} [properties] Properties to set
         * @returns {api.CoinsUpdateResponce} CoinsUpdateResponce instance
         */
        CoinsUpdateResponce.create = function create(properties) {
            return new CoinsUpdateResponce(properties);
        };

        /**
         * Encodes the specified CoinsUpdateResponce message. Does not implicitly {@link api.CoinsUpdateResponce.verify|verify} messages.
         * @function encode
         * @memberof api.CoinsUpdateResponce
         * @static
         * @param {api.CoinsUpdateResponce} message CoinsUpdateResponce message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CoinsUpdateResponce.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.reason != null && Object.hasOwnProperty.call(message, "reason"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.reason);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.value);
            if (message.rid != null && Object.hasOwnProperty.call(message, "rid"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.rid);
            return writer;
        };

        /**
         * Encodes the specified CoinsUpdateResponce message, length delimited. Does not implicitly {@link api.CoinsUpdateResponce.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.CoinsUpdateResponce
         * @static
         * @param {api.CoinsUpdateResponce} message CoinsUpdateResponce message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CoinsUpdateResponce.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CoinsUpdateResponce message from the specified reader or buffer.
         * @function decode
         * @memberof api.CoinsUpdateResponce
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.CoinsUpdateResponce} CoinsUpdateResponce
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CoinsUpdateResponce.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.CoinsUpdateResponce();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.reason = reader.int32();
                        break;
                    }
                case 2: {
                        message.value = reader.int32();
                        break;
                    }
                case 3: {
                        message.rid = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CoinsUpdateResponce message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.CoinsUpdateResponce
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.CoinsUpdateResponce} CoinsUpdateResponce
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CoinsUpdateResponce.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CoinsUpdateResponce message.
         * @function verify
         * @memberof api.CoinsUpdateResponce
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CoinsUpdateResponce.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.reason != null && message.hasOwnProperty("reason"))
                switch (message.reason) {
                default:
                    return "reason: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                    break;
                }
            if (message.value != null && message.hasOwnProperty("value"))
                if (!$util.isInteger(message.value))
                    return "value: integer expected";
            if (message.rid != null && message.hasOwnProperty("rid"))
                if (!$util.isInteger(message.rid))
                    return "rid: integer expected";
            return null;
        };

        /**
         * Creates a CoinsUpdateResponce message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.CoinsUpdateResponce
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.CoinsUpdateResponce} CoinsUpdateResponce
         */
        CoinsUpdateResponce.fromObject = function fromObject(object) {
            if (object instanceof $root.api.CoinsUpdateResponce)
                return object;
            let message = new $root.api.CoinsUpdateResponce();
            switch (object.reason) {
            default:
                if (typeof object.reason === "number") {
                    message.reason = object.reason;
                    break;
                }
                break;
            case "COINS_UPDATE_REASON_UNSPECIFIED":
            case 0:
                message.reason = 0;
                break;
            case "INCOME_PENALTY":
            case 1:
                message.reason = 1;
                break;
            case "OUTCOME_PENALTY":
            case 2:
                message.reason = 2;
                break;
            case "COLLECT_TILE_COINS":
            case 3:
                message.reason = 3;
                break;
            case "PURCHASE_TILE":
            case 4:
                message.reason = 4;
                break;
            case "SELL_TILE":
            case 5:
                message.reason = 5;
                break;
            case "BANKRUPTY_PROCESS":
            case 6:
                message.reason = 6;
                break;
            case "CHANCE_TILE":
            case 7:
                message.reason = 7;
                break;
            case "DICE_BONUS":
            case 8:
                message.reason = 8;
                break;
            case "START_MATCH_COINS":
            case 9:
                message.reason = 9;
                break;
            case "SHAPE_BREAK_BONUS":
            case 10:
                message.reason = 10;
                break;
            }
            if (object.value != null)
                message.value = object.value | 0;
            if (object.rid != null)
                message.rid = object.rid | 0;
            return message;
        };

        /**
         * Creates a plain object from a CoinsUpdateResponce message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.CoinsUpdateResponce
         * @static
         * @param {api.CoinsUpdateResponce} message CoinsUpdateResponce
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CoinsUpdateResponce.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.reason = options.enums === String ? "COINS_UPDATE_REASON_UNSPECIFIED" : 0;
                object.value = 0;
                object.rid = 0;
            }
            if (message.reason != null && message.hasOwnProperty("reason"))
                object.reason = options.enums === String ? $root.api.CoinsUpdateReason[message.reason] === undefined ? message.reason : $root.api.CoinsUpdateReason[message.reason] : message.reason;
            if (message.value != null && message.hasOwnProperty("value"))
                object.value = message.value;
            if (message.rid != null && message.hasOwnProperty("rid"))
                object.rid = message.rid;
            return object;
        };

        /**
         * Converts this CoinsUpdateResponce to JSON.
         * @function toJSON
         * @memberof api.CoinsUpdateResponce
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CoinsUpdateResponce.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CoinsUpdateResponce
         * @function getTypeUrl
         * @memberof api.CoinsUpdateResponce
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CoinsUpdateResponce.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.CoinsUpdateResponce";
        };

        return CoinsUpdateResponce;
    })();

    api.TileOwnerUpdateResponce = (function() {

        /**
         * Properties of a TileOwnerUpdateResponce.
         * @memberof api
         * @interface ITileOwnerUpdateResponce
         * @property {number|null} [index] TileOwnerUpdateResponce index
         * @property {number|null} [rId] TileOwnerUpdateResponce rId
         */

        /**
         * Constructs a new TileOwnerUpdateResponce.
         * @memberof api
         * @classdesc Represents a TileOwnerUpdateResponce.
         * @implements ITileOwnerUpdateResponce
         * @constructor
         * @param {api.ITileOwnerUpdateResponce=} [properties] Properties to set
         */
        function TileOwnerUpdateResponce(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TileOwnerUpdateResponce index.
         * @member {number} index
         * @memberof api.TileOwnerUpdateResponce
         * @instance
         */
        TileOwnerUpdateResponce.prototype.index = 0;

        /**
         * TileOwnerUpdateResponce rId.
         * @member {number|null|undefined} rId
         * @memberof api.TileOwnerUpdateResponce
         * @instance
         */
        TileOwnerUpdateResponce.prototype.rId = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(TileOwnerUpdateResponce.prototype, "_rId", {
            get: $util.oneOfGetter($oneOfFields = ["rId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new TileOwnerUpdateResponce instance using the specified properties.
         * @function create
         * @memberof api.TileOwnerUpdateResponce
         * @static
         * @param {api.ITileOwnerUpdateResponce=} [properties] Properties to set
         * @returns {api.TileOwnerUpdateResponce} TileOwnerUpdateResponce instance
         */
        TileOwnerUpdateResponce.create = function create(properties) {
            return new TileOwnerUpdateResponce(properties);
        };

        /**
         * Encodes the specified TileOwnerUpdateResponce message. Does not implicitly {@link api.TileOwnerUpdateResponce.verify|verify} messages.
         * @function encode
         * @memberof api.TileOwnerUpdateResponce
         * @static
         * @param {api.TileOwnerUpdateResponce} message TileOwnerUpdateResponce message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TileOwnerUpdateResponce.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.index != null && Object.hasOwnProperty.call(message, "index"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.index);
            if (message.rId != null && Object.hasOwnProperty.call(message, "rId"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.rId);
            return writer;
        };

        /**
         * Encodes the specified TileOwnerUpdateResponce message, length delimited. Does not implicitly {@link api.TileOwnerUpdateResponce.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.TileOwnerUpdateResponce
         * @static
         * @param {api.TileOwnerUpdateResponce} message TileOwnerUpdateResponce message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TileOwnerUpdateResponce.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TileOwnerUpdateResponce message from the specified reader or buffer.
         * @function decode
         * @memberof api.TileOwnerUpdateResponce
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.TileOwnerUpdateResponce} TileOwnerUpdateResponce
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TileOwnerUpdateResponce.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.TileOwnerUpdateResponce();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.index = reader.int32();
                        break;
                    }
                case 2: {
                        message.rId = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TileOwnerUpdateResponce message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.TileOwnerUpdateResponce
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.TileOwnerUpdateResponce} TileOwnerUpdateResponce
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TileOwnerUpdateResponce.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TileOwnerUpdateResponce message.
         * @function verify
         * @memberof api.TileOwnerUpdateResponce
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TileOwnerUpdateResponce.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.index != null && message.hasOwnProperty("index"))
                if (!$util.isInteger(message.index))
                    return "index: integer expected";
            if (message.rId != null && message.hasOwnProperty("rId")) {
                properties._rId = 1;
                if (!$util.isInteger(message.rId))
                    return "rId: integer expected";
            }
            return null;
        };

        /**
         * Creates a TileOwnerUpdateResponce message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.TileOwnerUpdateResponce
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.TileOwnerUpdateResponce} TileOwnerUpdateResponce
         */
        TileOwnerUpdateResponce.fromObject = function fromObject(object) {
            if (object instanceof $root.api.TileOwnerUpdateResponce)
                return object;
            let message = new $root.api.TileOwnerUpdateResponce();
            if (object.index != null)
                message.index = object.index | 0;
            if (object.rId != null)
                message.rId = object.rId | 0;
            return message;
        };

        /**
         * Creates a plain object from a TileOwnerUpdateResponce message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.TileOwnerUpdateResponce
         * @static
         * @param {api.TileOwnerUpdateResponce} message TileOwnerUpdateResponce
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TileOwnerUpdateResponce.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.index = 0;
            if (message.index != null && message.hasOwnProperty("index"))
                object.index = message.index;
            if (message.rId != null && message.hasOwnProperty("rId")) {
                object.rId = message.rId;
                if (options.oneofs)
                    object._rId = "rId";
            }
            return object;
        };

        /**
         * Converts this TileOwnerUpdateResponce to JSON.
         * @function toJSON
         * @memberof api.TileOwnerUpdateResponce
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TileOwnerUpdateResponce.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TileOwnerUpdateResponce
         * @function getTypeUrl
         * @memberof api.TileOwnerUpdateResponce
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TileOwnerUpdateResponce.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.TileOwnerUpdateResponce";
        };

        return TileOwnerUpdateResponce;
    })();

    api.PlayerPositionUpdateResponce = (function() {

        /**
         * Properties of a PlayerPositionUpdateResponce.
         * @memberof api
         * @interface IPlayerPositionUpdateResponce
         * @property {number|null} [rId] PlayerPositionUpdateResponce rId
         * @property {number|null} [index] PlayerPositionUpdateResponce index
         */

        /**
         * Constructs a new PlayerPositionUpdateResponce.
         * @memberof api
         * @classdesc Represents a PlayerPositionUpdateResponce.
         * @implements IPlayerPositionUpdateResponce
         * @constructor
         * @param {api.IPlayerPositionUpdateResponce=} [properties] Properties to set
         */
        function PlayerPositionUpdateResponce(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PlayerPositionUpdateResponce rId.
         * @member {number} rId
         * @memberof api.PlayerPositionUpdateResponce
         * @instance
         */
        PlayerPositionUpdateResponce.prototype.rId = 0;

        /**
         * PlayerPositionUpdateResponce index.
         * @member {number|null|undefined} index
         * @memberof api.PlayerPositionUpdateResponce
         * @instance
         */
        PlayerPositionUpdateResponce.prototype.index = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(PlayerPositionUpdateResponce.prototype, "_index", {
            get: $util.oneOfGetter($oneOfFields = ["index"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new PlayerPositionUpdateResponce instance using the specified properties.
         * @function create
         * @memberof api.PlayerPositionUpdateResponce
         * @static
         * @param {api.IPlayerPositionUpdateResponce=} [properties] Properties to set
         * @returns {api.PlayerPositionUpdateResponce} PlayerPositionUpdateResponce instance
         */
        PlayerPositionUpdateResponce.create = function create(properties) {
            return new PlayerPositionUpdateResponce(properties);
        };

        /**
         * Encodes the specified PlayerPositionUpdateResponce message. Does not implicitly {@link api.PlayerPositionUpdateResponce.verify|verify} messages.
         * @function encode
         * @memberof api.PlayerPositionUpdateResponce
         * @static
         * @param {api.PlayerPositionUpdateResponce} message PlayerPositionUpdateResponce message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlayerPositionUpdateResponce.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rId != null && Object.hasOwnProperty.call(message, "rId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.rId);
            if (message.index != null && Object.hasOwnProperty.call(message, "index"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.index);
            return writer;
        };

        /**
         * Encodes the specified PlayerPositionUpdateResponce message, length delimited. Does not implicitly {@link api.PlayerPositionUpdateResponce.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.PlayerPositionUpdateResponce
         * @static
         * @param {api.PlayerPositionUpdateResponce} message PlayerPositionUpdateResponce message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlayerPositionUpdateResponce.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PlayerPositionUpdateResponce message from the specified reader or buffer.
         * @function decode
         * @memberof api.PlayerPositionUpdateResponce
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.PlayerPositionUpdateResponce} PlayerPositionUpdateResponce
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlayerPositionUpdateResponce.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.PlayerPositionUpdateResponce();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.rId = reader.int32();
                        break;
                    }
                case 2: {
                        message.index = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PlayerPositionUpdateResponce message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.PlayerPositionUpdateResponce
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.PlayerPositionUpdateResponce} PlayerPositionUpdateResponce
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlayerPositionUpdateResponce.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PlayerPositionUpdateResponce message.
         * @function verify
         * @memberof api.PlayerPositionUpdateResponce
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PlayerPositionUpdateResponce.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.rId != null && message.hasOwnProperty("rId"))
                if (!$util.isInteger(message.rId))
                    return "rId: integer expected";
            if (message.index != null && message.hasOwnProperty("index")) {
                properties._index = 1;
                if (!$util.isInteger(message.index))
                    return "index: integer expected";
            }
            return null;
        };

        /**
         * Creates a PlayerPositionUpdateResponce message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.PlayerPositionUpdateResponce
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.PlayerPositionUpdateResponce} PlayerPositionUpdateResponce
         */
        PlayerPositionUpdateResponce.fromObject = function fromObject(object) {
            if (object instanceof $root.api.PlayerPositionUpdateResponce)
                return object;
            let message = new $root.api.PlayerPositionUpdateResponce();
            if (object.rId != null)
                message.rId = object.rId | 0;
            if (object.index != null)
                message.index = object.index | 0;
            return message;
        };

        /**
         * Creates a plain object from a PlayerPositionUpdateResponce message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.PlayerPositionUpdateResponce
         * @static
         * @param {api.PlayerPositionUpdateResponce} message PlayerPositionUpdateResponce
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PlayerPositionUpdateResponce.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.rId = 0;
            if (message.rId != null && message.hasOwnProperty("rId"))
                object.rId = message.rId;
            if (message.index != null && message.hasOwnProperty("index")) {
                object.index = message.index;
                if (options.oneofs)
                    object._index = "index";
            }
            return object;
        };

        /**
         * Converts this PlayerPositionUpdateResponce to JSON.
         * @function toJSON
         * @memberof api.PlayerPositionUpdateResponce
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PlayerPositionUpdateResponce.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PlayerPositionUpdateResponce
         * @function getTypeUrl
         * @memberof api.PlayerPositionUpdateResponce
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PlayerPositionUpdateResponce.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.PlayerPositionUpdateResponce";
        };

        return PlayerPositionUpdateResponce;
    })();

    api.PlayerLeftGameResponce = (function() {

        /**
         * Properties of a PlayerLeftGameResponce.
         * @memberof api
         * @interface IPlayerLeftGameResponce
         * @property {api.PlayerLeaveReason|null} [reason] PlayerLeftGameResponce reason
         * @property {number|null} [rId] PlayerLeftGameResponce rId
         */

        /**
         * Constructs a new PlayerLeftGameResponce.
         * @memberof api
         * @classdesc Represents a PlayerLeftGameResponce.
         * @implements IPlayerLeftGameResponce
         * @constructor
         * @param {api.IPlayerLeftGameResponce=} [properties] Properties to set
         */
        function PlayerLeftGameResponce(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PlayerLeftGameResponce reason.
         * @member {api.PlayerLeaveReason} reason
         * @memberof api.PlayerLeftGameResponce
         * @instance
         */
        PlayerLeftGameResponce.prototype.reason = 0;

        /**
         * PlayerLeftGameResponce rId.
         * @member {number} rId
         * @memberof api.PlayerLeftGameResponce
         * @instance
         */
        PlayerLeftGameResponce.prototype.rId = 0;

        /**
         * Creates a new PlayerLeftGameResponce instance using the specified properties.
         * @function create
         * @memberof api.PlayerLeftGameResponce
         * @static
         * @param {api.IPlayerLeftGameResponce=} [properties] Properties to set
         * @returns {api.PlayerLeftGameResponce} PlayerLeftGameResponce instance
         */
        PlayerLeftGameResponce.create = function create(properties) {
            return new PlayerLeftGameResponce(properties);
        };

        /**
         * Encodes the specified PlayerLeftGameResponce message. Does not implicitly {@link api.PlayerLeftGameResponce.verify|verify} messages.
         * @function encode
         * @memberof api.PlayerLeftGameResponce
         * @static
         * @param {api.PlayerLeftGameResponce} message PlayerLeftGameResponce message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlayerLeftGameResponce.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.reason != null && Object.hasOwnProperty.call(message, "reason"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.reason);
            if (message.rId != null && Object.hasOwnProperty.call(message, "rId"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.rId);
            return writer;
        };

        /**
         * Encodes the specified PlayerLeftGameResponce message, length delimited. Does not implicitly {@link api.PlayerLeftGameResponce.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.PlayerLeftGameResponce
         * @static
         * @param {api.PlayerLeftGameResponce} message PlayerLeftGameResponce message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlayerLeftGameResponce.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PlayerLeftGameResponce message from the specified reader or buffer.
         * @function decode
         * @memberof api.PlayerLeftGameResponce
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.PlayerLeftGameResponce} PlayerLeftGameResponce
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlayerLeftGameResponce.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.PlayerLeftGameResponce();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.reason = reader.int32();
                        break;
                    }
                case 2: {
                        message.rId = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PlayerLeftGameResponce message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.PlayerLeftGameResponce
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.PlayerLeftGameResponce} PlayerLeftGameResponce
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlayerLeftGameResponce.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PlayerLeftGameResponce message.
         * @function verify
         * @memberof api.PlayerLeftGameResponce
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PlayerLeftGameResponce.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.reason != null && message.hasOwnProperty("reason"))
                switch (message.reason) {
                default:
                    return "reason: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.rId != null && message.hasOwnProperty("rId"))
                if (!$util.isInteger(message.rId))
                    return "rId: integer expected";
            return null;
        };

        /**
         * Creates a PlayerLeftGameResponce message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.PlayerLeftGameResponce
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.PlayerLeftGameResponce} PlayerLeftGameResponce
         */
        PlayerLeftGameResponce.fromObject = function fromObject(object) {
            if (object instanceof $root.api.PlayerLeftGameResponce)
                return object;
            let message = new $root.api.PlayerLeftGameResponce();
            switch (object.reason) {
            default:
                if (typeof object.reason === "number") {
                    message.reason = object.reason;
                    break;
                }
                break;
            case "PLAYER_LEAVE_REASON_UNSPECIFIED":
            case 0:
                message.reason = 0;
                break;
            case "DISCONNECTION":
            case 1:
                message.reason = 1;
                break;
            case "LOSS":
            case 2:
                message.reason = 2;
                break;
            }
            if (object.rId != null)
                message.rId = object.rId | 0;
            return message;
        };

        /**
         * Creates a plain object from a PlayerLeftGameResponce message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.PlayerLeftGameResponce
         * @static
         * @param {api.PlayerLeftGameResponce} message PlayerLeftGameResponce
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PlayerLeftGameResponce.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.reason = options.enums === String ? "PLAYER_LEAVE_REASON_UNSPECIFIED" : 0;
                object.rId = 0;
            }
            if (message.reason != null && message.hasOwnProperty("reason"))
                object.reason = options.enums === String ? $root.api.PlayerLeaveReason[message.reason] === undefined ? message.reason : $root.api.PlayerLeaveReason[message.reason] : message.reason;
            if (message.rId != null && message.hasOwnProperty("rId"))
                object.rId = message.rId;
            return object;
        };

        /**
         * Converts this PlayerLeftGameResponce to JSON.
         * @function toJSON
         * @memberof api.PlayerLeftGameResponce
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PlayerLeftGameResponce.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PlayerLeftGameResponce
         * @function getTypeUrl
         * @memberof api.PlayerLeftGameResponce
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PlayerLeftGameResponce.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.PlayerLeftGameResponce";
        };

        return PlayerLeftGameResponce;
    })();

    api.MatchTimeResponce = (function() {

        /**
         * Properties of a MatchTimeResponce.
         * @memberof api
         * @interface IMatchTimeResponce
         * @property {number|null} [value] MatchTimeResponce value
         */

        /**
         * Constructs a new MatchTimeResponce.
         * @memberof api
         * @classdesc Represents a MatchTimeResponce.
         * @implements IMatchTimeResponce
         * @constructor
         * @param {api.IMatchTimeResponce=} [properties] Properties to set
         */
        function MatchTimeResponce(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MatchTimeResponce value.
         * @member {number} value
         * @memberof api.MatchTimeResponce
         * @instance
         */
        MatchTimeResponce.prototype.value = 0;

        /**
         * Creates a new MatchTimeResponce instance using the specified properties.
         * @function create
         * @memberof api.MatchTimeResponce
         * @static
         * @param {api.IMatchTimeResponce=} [properties] Properties to set
         * @returns {api.MatchTimeResponce} MatchTimeResponce instance
         */
        MatchTimeResponce.create = function create(properties) {
            return new MatchTimeResponce(properties);
        };

        /**
         * Encodes the specified MatchTimeResponce message. Does not implicitly {@link api.MatchTimeResponce.verify|verify} messages.
         * @function encode
         * @memberof api.MatchTimeResponce
         * @static
         * @param {api.MatchTimeResponce} message MatchTimeResponce message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MatchTimeResponce.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.value);
            return writer;
        };

        /**
         * Encodes the specified MatchTimeResponce message, length delimited. Does not implicitly {@link api.MatchTimeResponce.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.MatchTimeResponce
         * @static
         * @param {api.MatchTimeResponce} message MatchTimeResponce message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MatchTimeResponce.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MatchTimeResponce message from the specified reader or buffer.
         * @function decode
         * @memberof api.MatchTimeResponce
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.MatchTimeResponce} MatchTimeResponce
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MatchTimeResponce.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.MatchTimeResponce();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.value = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MatchTimeResponce message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.MatchTimeResponce
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.MatchTimeResponce} MatchTimeResponce
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MatchTimeResponce.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MatchTimeResponce message.
         * @function verify
         * @memberof api.MatchTimeResponce
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MatchTimeResponce.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.value != null && message.hasOwnProperty("value"))
                if (!$util.isInteger(message.value))
                    return "value: integer expected";
            return null;
        };

        /**
         * Creates a MatchTimeResponce message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.MatchTimeResponce
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.MatchTimeResponce} MatchTimeResponce
         */
        MatchTimeResponce.fromObject = function fromObject(object) {
            if (object instanceof $root.api.MatchTimeResponce)
                return object;
            let message = new $root.api.MatchTimeResponce();
            if (object.value != null)
                message.value = object.value | 0;
            return message;
        };

        /**
         * Creates a plain object from a MatchTimeResponce message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.MatchTimeResponce
         * @static
         * @param {api.MatchTimeResponce} message MatchTimeResponce
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MatchTimeResponce.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.value = 0;
            if (message.value != null && message.hasOwnProperty("value"))
                object.value = message.value;
            return object;
        };

        /**
         * Converts this MatchTimeResponce to JSON.
         * @function toJSON
         * @memberof api.MatchTimeResponce
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MatchTimeResponce.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MatchTimeResponce
         * @function getTypeUrl
         * @memberof api.MatchTimeResponce
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MatchTimeResponce.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.MatchTimeResponce";
        };

        return MatchTimeResponce;
    })();

    api.MatchStartResponce = (function() {

        /**
         * Properties of a MatchStartResponce.
         * @memberof api
         * @interface IMatchStartResponce
         * @property {number|null} [coins] MatchStartResponce coins
         * @property {api.PizzaOperationResponseTransaction|null} [transaction] MatchStartResponce transaction
         * @property {number|null} [matchTimeS] MatchStartResponce matchTimeS
         */

        /**
         * Constructs a new MatchStartResponce.
         * @memberof api
         * @classdesc Represents a MatchStartResponce.
         * @implements IMatchStartResponce
         * @constructor
         * @param {api.IMatchStartResponce=} [properties] Properties to set
         */
        function MatchStartResponce(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MatchStartResponce coins.
         * @member {number} coins
         * @memberof api.MatchStartResponce
         * @instance
         */
        MatchStartResponce.prototype.coins = 0;

        /**
         * MatchStartResponce transaction.
         * @member {api.PizzaOperationResponseTransaction|null|undefined} transaction
         * @memberof api.MatchStartResponce
         * @instance
         */
        MatchStartResponce.prototype.transaction = null;

        /**
         * MatchStartResponce matchTimeS.
         * @member {number} matchTimeS
         * @memberof api.MatchStartResponce
         * @instance
         */
        MatchStartResponce.prototype.matchTimeS = 0;

        /**
         * Creates a new MatchStartResponce instance using the specified properties.
         * @function create
         * @memberof api.MatchStartResponce
         * @static
         * @param {api.IMatchStartResponce=} [properties] Properties to set
         * @returns {api.MatchStartResponce} MatchStartResponce instance
         */
        MatchStartResponce.create = function create(properties) {
            return new MatchStartResponce(properties);
        };

        /**
         * Encodes the specified MatchStartResponce message. Does not implicitly {@link api.MatchStartResponce.verify|verify} messages.
         * @function encode
         * @memberof api.MatchStartResponce
         * @static
         * @param {api.MatchStartResponce} message MatchStartResponce message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MatchStartResponce.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.coins != null && Object.hasOwnProperty.call(message, "coins"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.coins);
            if (message.transaction != null && Object.hasOwnProperty.call(message, "transaction"))
                $root.api.PizzaOperationResponseTransaction.encode(message.transaction, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.matchTimeS != null && Object.hasOwnProperty.call(message, "matchTimeS"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.matchTimeS);
            return writer;
        };

        /**
         * Encodes the specified MatchStartResponce message, length delimited. Does not implicitly {@link api.MatchStartResponce.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.MatchStartResponce
         * @static
         * @param {api.MatchStartResponce} message MatchStartResponce message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MatchStartResponce.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MatchStartResponce message from the specified reader or buffer.
         * @function decode
         * @memberof api.MatchStartResponce
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.MatchStartResponce} MatchStartResponce
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MatchStartResponce.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.MatchStartResponce();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.coins = reader.int32();
                        break;
                    }
                case 2: {
                        message.transaction = $root.api.PizzaOperationResponseTransaction.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.matchTimeS = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MatchStartResponce message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.MatchStartResponce
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.MatchStartResponce} MatchStartResponce
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MatchStartResponce.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MatchStartResponce message.
         * @function verify
         * @memberof api.MatchStartResponce
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MatchStartResponce.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.coins != null && message.hasOwnProperty("coins"))
                if (!$util.isInteger(message.coins))
                    return "coins: integer expected";
            if (message.transaction != null && message.hasOwnProperty("transaction")) {
                let error = $root.api.PizzaOperationResponseTransaction.verify(message.transaction);
                if (error)
                    return "transaction." + error;
            }
            if (message.matchTimeS != null && message.hasOwnProperty("matchTimeS"))
                if (!$util.isInteger(message.matchTimeS))
                    return "matchTimeS: integer expected";
            return null;
        };

        /**
         * Creates a MatchStartResponce message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.MatchStartResponce
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.MatchStartResponce} MatchStartResponce
         */
        MatchStartResponce.fromObject = function fromObject(object) {
            if (object instanceof $root.api.MatchStartResponce)
                return object;
            let message = new $root.api.MatchStartResponce();
            if (object.coins != null)
                message.coins = object.coins | 0;
            if (object.transaction != null) {
                if (typeof object.transaction !== "object")
                    throw TypeError(".api.MatchStartResponce.transaction: object expected");
                message.transaction = $root.api.PizzaOperationResponseTransaction.fromObject(object.transaction);
            }
            if (object.matchTimeS != null)
                message.matchTimeS = object.matchTimeS | 0;
            return message;
        };

        /**
         * Creates a plain object from a MatchStartResponce message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.MatchStartResponce
         * @static
         * @param {api.MatchStartResponce} message MatchStartResponce
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MatchStartResponce.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.coins = 0;
                object.transaction = null;
                object.matchTimeS = 0;
            }
            if (message.coins != null && message.hasOwnProperty("coins"))
                object.coins = message.coins;
            if (message.transaction != null && message.hasOwnProperty("transaction"))
                object.transaction = $root.api.PizzaOperationResponseTransaction.toObject(message.transaction, options);
            if (message.matchTimeS != null && message.hasOwnProperty("matchTimeS"))
                object.matchTimeS = message.matchTimeS;
            return object;
        };

        /**
         * Converts this MatchStartResponce to JSON.
         * @function toJSON
         * @memberof api.MatchStartResponce
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MatchStartResponce.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MatchStartResponce
         * @function getTypeUrl
         * @memberof api.MatchStartResponce
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MatchStartResponce.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.MatchStartResponce";
        };

        return MatchStartResponce;
    })();

    api.AddPlayerResponce = (function() {

        /**
         * Properties of an AddPlayerResponce.
         * @memberof api
         * @interface IAddPlayerResponce
         * @property {number|null} [rId] AddPlayerResponce rId
         * @property {string|null} [userId] AddPlayerResponce userId
         */

        /**
         * Constructs a new AddPlayerResponce.
         * @memberof api
         * @classdesc Represents an AddPlayerResponce.
         * @implements IAddPlayerResponce
         * @constructor
         * @param {api.IAddPlayerResponce=} [properties] Properties to set
         */
        function AddPlayerResponce(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AddPlayerResponce rId.
         * @member {number} rId
         * @memberof api.AddPlayerResponce
         * @instance
         */
        AddPlayerResponce.prototype.rId = 0;

        /**
         * AddPlayerResponce userId.
         * @member {string|null|undefined} userId
         * @memberof api.AddPlayerResponce
         * @instance
         */
        AddPlayerResponce.prototype.userId = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(AddPlayerResponce.prototype, "_userId", {
            get: $util.oneOfGetter($oneOfFields = ["userId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new AddPlayerResponce instance using the specified properties.
         * @function create
         * @memberof api.AddPlayerResponce
         * @static
         * @param {api.IAddPlayerResponce=} [properties] Properties to set
         * @returns {api.AddPlayerResponce} AddPlayerResponce instance
         */
        AddPlayerResponce.create = function create(properties) {
            return new AddPlayerResponce(properties);
        };

        /**
         * Encodes the specified AddPlayerResponce message. Does not implicitly {@link api.AddPlayerResponce.verify|verify} messages.
         * @function encode
         * @memberof api.AddPlayerResponce
         * @static
         * @param {api.AddPlayerResponce} message AddPlayerResponce message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddPlayerResponce.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rId != null && Object.hasOwnProperty.call(message, "rId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.rId);
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.userId);
            return writer;
        };

        /**
         * Encodes the specified AddPlayerResponce message, length delimited. Does not implicitly {@link api.AddPlayerResponce.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.AddPlayerResponce
         * @static
         * @param {api.AddPlayerResponce} message AddPlayerResponce message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddPlayerResponce.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AddPlayerResponce message from the specified reader or buffer.
         * @function decode
         * @memberof api.AddPlayerResponce
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.AddPlayerResponce} AddPlayerResponce
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddPlayerResponce.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.AddPlayerResponce();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.rId = reader.int32();
                        break;
                    }
                case 2: {
                        message.userId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AddPlayerResponce message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.AddPlayerResponce
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.AddPlayerResponce} AddPlayerResponce
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddPlayerResponce.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AddPlayerResponce message.
         * @function verify
         * @memberof api.AddPlayerResponce
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AddPlayerResponce.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.rId != null && message.hasOwnProperty("rId"))
                if (!$util.isInteger(message.rId))
                    return "rId: integer expected";
            if (message.userId != null && message.hasOwnProperty("userId")) {
                properties._userId = 1;
                if (!$util.isString(message.userId))
                    return "userId: string expected";
            }
            return null;
        };

        /**
         * Creates an AddPlayerResponce message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.AddPlayerResponce
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.AddPlayerResponce} AddPlayerResponce
         */
        AddPlayerResponce.fromObject = function fromObject(object) {
            if (object instanceof $root.api.AddPlayerResponce)
                return object;
            let message = new $root.api.AddPlayerResponce();
            if (object.rId != null)
                message.rId = object.rId | 0;
            if (object.userId != null)
                message.userId = String(object.userId);
            return message;
        };

        /**
         * Creates a plain object from an AddPlayerResponce message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.AddPlayerResponce
         * @static
         * @param {api.AddPlayerResponce} message AddPlayerResponce
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AddPlayerResponce.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.rId = 0;
            if (message.rId != null && message.hasOwnProperty("rId"))
                object.rId = message.rId;
            if (message.userId != null && message.hasOwnProperty("userId")) {
                object.userId = message.userId;
                if (options.oneofs)
                    object._userId = "userId";
            }
            return object;
        };

        /**
         * Converts this AddPlayerResponce to JSON.
         * @function toJSON
         * @memberof api.AddPlayerResponce
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AddPlayerResponce.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AddPlayerResponce
         * @function getTypeUrl
         * @memberof api.AddPlayerResponce
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AddPlayerResponce.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.AddPlayerResponce";
        };

        return AddPlayerResponce;
    })();

    api.PlayerMatchStateResponce = (function() {

        /**
         * Properties of a PlayerMatchStateResponce.
         * @memberof api
         * @interface IPlayerMatchStateResponce
         * @property {api.PlayerMatchState|null} [state] PlayerMatchStateResponce state
         */

        /**
         * Constructs a new PlayerMatchStateResponce.
         * @memberof api
         * @classdesc Represents a PlayerMatchStateResponce.
         * @implements IPlayerMatchStateResponce
         * @constructor
         * @param {api.IPlayerMatchStateResponce=} [properties] Properties to set
         */
        function PlayerMatchStateResponce(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PlayerMatchStateResponce state.
         * @member {api.PlayerMatchState} state
         * @memberof api.PlayerMatchStateResponce
         * @instance
         */
        PlayerMatchStateResponce.prototype.state = 0;

        /**
         * Creates a new PlayerMatchStateResponce instance using the specified properties.
         * @function create
         * @memberof api.PlayerMatchStateResponce
         * @static
         * @param {api.IPlayerMatchStateResponce=} [properties] Properties to set
         * @returns {api.PlayerMatchStateResponce} PlayerMatchStateResponce instance
         */
        PlayerMatchStateResponce.create = function create(properties) {
            return new PlayerMatchStateResponce(properties);
        };

        /**
         * Encodes the specified PlayerMatchStateResponce message. Does not implicitly {@link api.PlayerMatchStateResponce.verify|verify} messages.
         * @function encode
         * @memberof api.PlayerMatchStateResponce
         * @static
         * @param {api.PlayerMatchStateResponce} message PlayerMatchStateResponce message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlayerMatchStateResponce.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.state);
            return writer;
        };

        /**
         * Encodes the specified PlayerMatchStateResponce message, length delimited. Does not implicitly {@link api.PlayerMatchStateResponce.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.PlayerMatchStateResponce
         * @static
         * @param {api.PlayerMatchStateResponce} message PlayerMatchStateResponce message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlayerMatchStateResponce.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PlayerMatchStateResponce message from the specified reader or buffer.
         * @function decode
         * @memberof api.PlayerMatchStateResponce
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.PlayerMatchStateResponce} PlayerMatchStateResponce
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlayerMatchStateResponce.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.PlayerMatchStateResponce();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.state = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PlayerMatchStateResponce message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.PlayerMatchStateResponce
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.PlayerMatchStateResponce} PlayerMatchStateResponce
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlayerMatchStateResponce.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PlayerMatchStateResponce message.
         * @function verify
         * @memberof api.PlayerMatchStateResponce
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PlayerMatchStateResponce.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.state != null && message.hasOwnProperty("state"))
                switch (message.state) {
                default:
                    return "state: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                    break;
                }
            return null;
        };

        /**
         * Creates a PlayerMatchStateResponce message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.PlayerMatchStateResponce
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.PlayerMatchStateResponce} PlayerMatchStateResponce
         */
        PlayerMatchStateResponce.fromObject = function fromObject(object) {
            if (object instanceof $root.api.PlayerMatchStateResponce)
                return object;
            let message = new $root.api.PlayerMatchStateResponce();
            switch (object.state) {
            default:
                if (typeof object.state === "number") {
                    message.state = object.state;
                    break;
                }
                break;
            case "STATE_UNSPECIFIED":
            case 0:
                message.state = 0;
                break;
            case "DICE":
            case 1:
                message.state = 1;
                break;
            case "MOVE":
            case 2:
                message.state = 2;
                break;
            case "BANKRUPTY":
            case 3:
                message.state = 3;
                break;
            case "SPECTATOR":
            case 4:
                message.state = 4;
                break;
            case "WAIT":
            case 5:
                message.state = 5;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a PlayerMatchStateResponce message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.PlayerMatchStateResponce
         * @static
         * @param {api.PlayerMatchStateResponce} message PlayerMatchStateResponce
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PlayerMatchStateResponce.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.state = options.enums === String ? "STATE_UNSPECIFIED" : 0;
            if (message.state != null && message.hasOwnProperty("state"))
                object.state = options.enums === String ? $root.api.PlayerMatchState[message.state] === undefined ? message.state : $root.api.PlayerMatchState[message.state] : message.state;
            return object;
        };

        /**
         * Converts this PlayerMatchStateResponce to JSON.
         * @function toJSON
         * @memberof api.PlayerMatchStateResponce
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PlayerMatchStateResponce.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PlayerMatchStateResponce
         * @function getTypeUrl
         * @memberof api.PlayerMatchStateResponce
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PlayerMatchStateResponce.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.PlayerMatchStateResponce";
        };

        return PlayerMatchStateResponce;
    })();

    api.UserMetadata = (function() {

        /**
         * Properties of a UserMetadata.
         * @memberof api
         * @interface IUserMetadata
         * @property {string|null} [operationUid] UserMetadata operationUid
         * @property {string|null} [initialCampaign] UserMetadata initialCampaign
         * @property {string|null} [initialGroupUid] UserMetadata initialGroupUid
         * @property {string|null} [initialInvitedByUid] UserMetadata initialInvitedByUid
         */

        /**
         * Constructs a new UserMetadata.
         * @memberof api
         * @classdesc Represents a UserMetadata.
         * @implements IUserMetadata
         * @constructor
         * @param {api.IUserMetadata=} [properties] Properties to set
         */
        function UserMetadata(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserMetadata operationUid.
         * @member {string} operationUid
         * @memberof api.UserMetadata
         * @instance
         */
        UserMetadata.prototype.operationUid = "";

        /**
         * UserMetadata initialCampaign.
         * @member {string} initialCampaign
         * @memberof api.UserMetadata
         * @instance
         */
        UserMetadata.prototype.initialCampaign = "";

        /**
         * UserMetadata initialGroupUid.
         * @member {string} initialGroupUid
         * @memberof api.UserMetadata
         * @instance
         */
        UserMetadata.prototype.initialGroupUid = "";

        /**
         * UserMetadata initialInvitedByUid.
         * @member {string} initialInvitedByUid
         * @memberof api.UserMetadata
         * @instance
         */
        UserMetadata.prototype.initialInvitedByUid = "";

        /**
         * Creates a new UserMetadata instance using the specified properties.
         * @function create
         * @memberof api.UserMetadata
         * @static
         * @param {api.IUserMetadata=} [properties] Properties to set
         * @returns {api.UserMetadata} UserMetadata instance
         */
        UserMetadata.create = function create(properties) {
            return new UserMetadata(properties);
        };

        /**
         * Encodes the specified UserMetadata message. Does not implicitly {@link api.UserMetadata.verify|verify} messages.
         * @function encode
         * @memberof api.UserMetadata
         * @static
         * @param {api.UserMetadata} message UserMetadata message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserMetadata.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.operationUid != null && Object.hasOwnProperty.call(message, "operationUid"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.operationUid);
            if (message.initialCampaign != null && Object.hasOwnProperty.call(message, "initialCampaign"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.initialCampaign);
            if (message.initialGroupUid != null && Object.hasOwnProperty.call(message, "initialGroupUid"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.initialGroupUid);
            if (message.initialInvitedByUid != null && Object.hasOwnProperty.call(message, "initialInvitedByUid"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.initialInvitedByUid);
            return writer;
        };

        /**
         * Encodes the specified UserMetadata message, length delimited. Does not implicitly {@link api.UserMetadata.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.UserMetadata
         * @static
         * @param {api.UserMetadata} message UserMetadata message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserMetadata.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a UserMetadata message from the specified reader or buffer.
         * @function decode
         * @memberof api.UserMetadata
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.UserMetadata} UserMetadata
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserMetadata.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.UserMetadata();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.operationUid = reader.string();
                        break;
                    }
                case 2: {
                        message.initialCampaign = reader.string();
                        break;
                    }
                case 3: {
                        message.initialGroupUid = reader.string();
                        break;
                    }
                case 4: {
                        message.initialInvitedByUid = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a UserMetadata message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.UserMetadata
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.UserMetadata} UserMetadata
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserMetadata.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a UserMetadata message.
         * @function verify
         * @memberof api.UserMetadata
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UserMetadata.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.operationUid != null && message.hasOwnProperty("operationUid"))
                if (!$util.isString(message.operationUid))
                    return "operationUid: string expected";
            if (message.initialCampaign != null && message.hasOwnProperty("initialCampaign"))
                if (!$util.isString(message.initialCampaign))
                    return "initialCampaign: string expected";
            if (message.initialGroupUid != null && message.hasOwnProperty("initialGroupUid"))
                if (!$util.isString(message.initialGroupUid))
                    return "initialGroupUid: string expected";
            if (message.initialInvitedByUid != null && message.hasOwnProperty("initialInvitedByUid"))
                if (!$util.isString(message.initialInvitedByUid))
                    return "initialInvitedByUid: string expected";
            return null;
        };

        /**
         * Creates a UserMetadata message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.UserMetadata
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.UserMetadata} UserMetadata
         */
        UserMetadata.fromObject = function fromObject(object) {
            if (object instanceof $root.api.UserMetadata)
                return object;
            let message = new $root.api.UserMetadata();
            if (object.operationUid != null)
                message.operationUid = String(object.operationUid);
            if (object.initialCampaign != null)
                message.initialCampaign = String(object.initialCampaign);
            if (object.initialGroupUid != null)
                message.initialGroupUid = String(object.initialGroupUid);
            if (object.initialInvitedByUid != null)
                message.initialInvitedByUid = String(object.initialInvitedByUid);
            return message;
        };

        /**
         * Creates a plain object from a UserMetadata message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.UserMetadata
         * @static
         * @param {api.UserMetadata} message UserMetadata
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserMetadata.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.operationUid = "";
                object.initialCampaign = "";
                object.initialGroupUid = "";
                object.initialInvitedByUid = "";
            }
            if (message.operationUid != null && message.hasOwnProperty("operationUid"))
                object.operationUid = message.operationUid;
            if (message.initialCampaign != null && message.hasOwnProperty("initialCampaign"))
                object.initialCampaign = message.initialCampaign;
            if (message.initialGroupUid != null && message.hasOwnProperty("initialGroupUid"))
                object.initialGroupUid = message.initialGroupUid;
            if (message.initialInvitedByUid != null && message.hasOwnProperty("initialInvitedByUid"))
                object.initialInvitedByUid = message.initialInvitedByUid;
            return object;
        };

        /**
         * Converts this UserMetadata to JSON.
         * @function toJSON
         * @memberof api.UserMetadata
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserMetadata.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UserMetadata
         * @function getTypeUrl
         * @memberof api.UserMetadata
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UserMetadata.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.UserMetadata";
        };

        return UserMetadata;
    })();

    api.MatchLabel = (function() {

        /**
         * Properties of a MatchLabel.
         * @memberof api
         * @interface IMatchLabel
         * @property {number|null} [open] MatchLabel open
         * @property {Array.<api.Bet>|null} [bet] MatchLabel bet
         * @property {string|null} [mapId] MatchLabel mapId
         * @property {Array.<string>|null} [users] MatchLabel users
         * @property {string|null} [matchName] MatchLabel matchName
         * @property {string|null} [operationUid] MatchLabel operationUid
         */

        /**
         * Constructs a new MatchLabel.
         * @memberof api
         * @classdesc Represents a MatchLabel.
         * @implements IMatchLabel
         * @constructor
         * @param {api.IMatchLabel=} [properties] Properties to set
         */
        function MatchLabel(properties) {
            this.bet = [];
            this.users = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MatchLabel open.
         * @member {number} open
         * @memberof api.MatchLabel
         * @instance
         */
        MatchLabel.prototype.open = 0;

        /**
         * MatchLabel bet.
         * @member {Array.<api.Bet>} bet
         * @memberof api.MatchLabel
         * @instance
         */
        MatchLabel.prototype.bet = $util.emptyArray;

        /**
         * MatchLabel mapId.
         * @member {string} mapId
         * @memberof api.MatchLabel
         * @instance
         */
        MatchLabel.prototype.mapId = "";

        /**
         * MatchLabel users.
         * @member {Array.<string>} users
         * @memberof api.MatchLabel
         * @instance
         */
        MatchLabel.prototype.users = $util.emptyArray;

        /**
         * MatchLabel matchName.
         * @member {string} matchName
         * @memberof api.MatchLabel
         * @instance
         */
        MatchLabel.prototype.matchName = "";

        /**
         * MatchLabel operationUid.
         * @member {string} operationUid
         * @memberof api.MatchLabel
         * @instance
         */
        MatchLabel.prototype.operationUid = "";

        /**
         * Creates a new MatchLabel instance using the specified properties.
         * @function create
         * @memberof api.MatchLabel
         * @static
         * @param {api.IMatchLabel=} [properties] Properties to set
         * @returns {api.MatchLabel} MatchLabel instance
         */
        MatchLabel.create = function create(properties) {
            return new MatchLabel(properties);
        };

        /**
         * Encodes the specified MatchLabel message. Does not implicitly {@link api.MatchLabel.verify|verify} messages.
         * @function encode
         * @memberof api.MatchLabel
         * @static
         * @param {api.MatchLabel} message MatchLabel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MatchLabel.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.open != null && Object.hasOwnProperty.call(message, "open"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.open);
            if (message.bet != null && message.bet.length)
                for (let i = 0; i < message.bet.length; ++i)
                    $root.api.Bet.encode(message.bet[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.mapId != null && Object.hasOwnProperty.call(message, "mapId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.mapId);
            if (message.users != null && message.users.length)
                for (let i = 0; i < message.users.length; ++i)
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.users[i]);
            if (message.matchName != null && Object.hasOwnProperty.call(message, "matchName"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.matchName);
            if (message.operationUid != null && Object.hasOwnProperty.call(message, "operationUid"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.operationUid);
            return writer;
        };

        /**
         * Encodes the specified MatchLabel message, length delimited. Does not implicitly {@link api.MatchLabel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.MatchLabel
         * @static
         * @param {api.MatchLabel} message MatchLabel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MatchLabel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MatchLabel message from the specified reader or buffer.
         * @function decode
         * @memberof api.MatchLabel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.MatchLabel} MatchLabel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MatchLabel.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.MatchLabel();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.open = reader.int32();
                        break;
                    }
                case 2: {
                        if (!(message.bet && message.bet.length))
                            message.bet = [];
                        message.bet.push($root.api.Bet.decode(reader, reader.uint32()));
                        break;
                    }
                case 3: {
                        message.mapId = reader.string();
                        break;
                    }
                case 4: {
                        if (!(message.users && message.users.length))
                            message.users = [];
                        message.users.push(reader.string());
                        break;
                    }
                case 5: {
                        message.matchName = reader.string();
                        break;
                    }
                case 6: {
                        message.operationUid = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MatchLabel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.MatchLabel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.MatchLabel} MatchLabel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MatchLabel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MatchLabel message.
         * @function verify
         * @memberof api.MatchLabel
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MatchLabel.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.open != null && message.hasOwnProperty("open"))
                if (!$util.isInteger(message.open))
                    return "open: integer expected";
            if (message.bet != null && message.hasOwnProperty("bet")) {
                if (!Array.isArray(message.bet))
                    return "bet: array expected";
                for (let i = 0; i < message.bet.length; ++i) {
                    let error = $root.api.Bet.verify(message.bet[i]);
                    if (error)
                        return "bet." + error;
                }
            }
            if (message.mapId != null && message.hasOwnProperty("mapId"))
                if (!$util.isString(message.mapId))
                    return "mapId: string expected";
            if (message.users != null && message.hasOwnProperty("users")) {
                if (!Array.isArray(message.users))
                    return "users: array expected";
                for (let i = 0; i < message.users.length; ++i)
                    if (!$util.isString(message.users[i]))
                        return "users: string[] expected";
            }
            if (message.matchName != null && message.hasOwnProperty("matchName"))
                if (!$util.isString(message.matchName))
                    return "matchName: string expected";
            if (message.operationUid != null && message.hasOwnProperty("operationUid"))
                if (!$util.isString(message.operationUid))
                    return "operationUid: string expected";
            return null;
        };

        /**
         * Creates a MatchLabel message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.MatchLabel
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.MatchLabel} MatchLabel
         */
        MatchLabel.fromObject = function fromObject(object) {
            if (object instanceof $root.api.MatchLabel)
                return object;
            let message = new $root.api.MatchLabel();
            if (object.open != null)
                message.open = object.open | 0;
            if (object.bet) {
                if (!Array.isArray(object.bet))
                    throw TypeError(".api.MatchLabel.bet: array expected");
                message.bet = [];
                for (let i = 0; i < object.bet.length; ++i) {
                    if (typeof object.bet[i] !== "object")
                        throw TypeError(".api.MatchLabel.bet: object expected");
                    message.bet[i] = $root.api.Bet.fromObject(object.bet[i]);
                }
            }
            if (object.mapId != null)
                message.mapId = String(object.mapId);
            if (object.users) {
                if (!Array.isArray(object.users))
                    throw TypeError(".api.MatchLabel.users: array expected");
                message.users = [];
                for (let i = 0; i < object.users.length; ++i)
                    message.users[i] = String(object.users[i]);
            }
            if (object.matchName != null)
                message.matchName = String(object.matchName);
            if (object.operationUid != null)
                message.operationUid = String(object.operationUid);
            return message;
        };

        /**
         * Creates a plain object from a MatchLabel message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.MatchLabel
         * @static
         * @param {api.MatchLabel} message MatchLabel
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MatchLabel.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.bet = [];
                object.users = [];
            }
            if (options.defaults) {
                object.open = 0;
                object.mapId = "";
                object.matchName = "";
                object.operationUid = "";
            }
            if (message.open != null && message.hasOwnProperty("open"))
                object.open = message.open;
            if (message.bet && message.bet.length) {
                object.bet = [];
                for (let j = 0; j < message.bet.length; ++j)
                    object.bet[j] = $root.api.Bet.toObject(message.bet[j], options);
            }
            if (message.mapId != null && message.hasOwnProperty("mapId"))
                object.mapId = message.mapId;
            if (message.users && message.users.length) {
                object.users = [];
                for (let j = 0; j < message.users.length; ++j)
                    object.users[j] = message.users[j];
            }
            if (message.matchName != null && message.hasOwnProperty("matchName"))
                object.matchName = message.matchName;
            if (message.operationUid != null && message.hasOwnProperty("operationUid"))
                object.operationUid = message.operationUid;
            return object;
        };

        /**
         * Converts this MatchLabel to JSON.
         * @function toJSON
         * @memberof api.MatchLabel
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MatchLabel.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MatchLabel
         * @function getTypeUrl
         * @memberof api.MatchLabel
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MatchLabel.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.MatchLabel";
        };

        return MatchLabel;
    })();

    api.WelcomeRewardResponce = (function() {

        /**
         * Properties of a WelcomeRewardResponce.
         * @memberof api
         * @interface IWelcomeRewardResponce
         * @property {boolean|null} [available] WelcomeRewardResponce available
         * @property {number|null} [value] WelcomeRewardResponce value
         * @property {string|null} [currency] WelcomeRewardResponce currency
         */

        /**
         * Constructs a new WelcomeRewardResponce.
         * @memberof api
         * @classdesc Represents a WelcomeRewardResponce.
         * @implements IWelcomeRewardResponce
         * @constructor
         * @param {api.IWelcomeRewardResponce=} [properties] Properties to set
         */
        function WelcomeRewardResponce(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WelcomeRewardResponce available.
         * @member {boolean} available
         * @memberof api.WelcomeRewardResponce
         * @instance
         */
        WelcomeRewardResponce.prototype.available = false;

        /**
         * WelcomeRewardResponce value.
         * @member {number|null|undefined} value
         * @memberof api.WelcomeRewardResponce
         * @instance
         */
        WelcomeRewardResponce.prototype.value = null;

        /**
         * WelcomeRewardResponce currency.
         * @member {string|null|undefined} currency
         * @memberof api.WelcomeRewardResponce
         * @instance
         */
        WelcomeRewardResponce.prototype.currency = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(WelcomeRewardResponce.prototype, "_value", {
            get: $util.oneOfGetter($oneOfFields = ["value"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        // Virtual OneOf for proto3 optional field
        Object.defineProperty(WelcomeRewardResponce.prototype, "_currency", {
            get: $util.oneOfGetter($oneOfFields = ["currency"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new WelcomeRewardResponce instance using the specified properties.
         * @function create
         * @memberof api.WelcomeRewardResponce
         * @static
         * @param {api.IWelcomeRewardResponce=} [properties] Properties to set
         * @returns {api.WelcomeRewardResponce} WelcomeRewardResponce instance
         */
        WelcomeRewardResponce.create = function create(properties) {
            return new WelcomeRewardResponce(properties);
        };

        /**
         * Encodes the specified WelcomeRewardResponce message. Does not implicitly {@link api.WelcomeRewardResponce.verify|verify} messages.
         * @function encode
         * @memberof api.WelcomeRewardResponce
         * @static
         * @param {api.WelcomeRewardResponce} message WelcomeRewardResponce message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WelcomeRewardResponce.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.available != null && Object.hasOwnProperty.call(message, "available"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.available);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.value);
            if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.currency);
            return writer;
        };

        /**
         * Encodes the specified WelcomeRewardResponce message, length delimited. Does not implicitly {@link api.WelcomeRewardResponce.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.WelcomeRewardResponce
         * @static
         * @param {api.WelcomeRewardResponce} message WelcomeRewardResponce message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WelcomeRewardResponce.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WelcomeRewardResponce message from the specified reader or buffer.
         * @function decode
         * @memberof api.WelcomeRewardResponce
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.WelcomeRewardResponce} WelcomeRewardResponce
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WelcomeRewardResponce.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.WelcomeRewardResponce();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.available = reader.bool();
                        break;
                    }
                case 2: {
                        message.value = reader.int32();
                        break;
                    }
                case 3: {
                        message.currency = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WelcomeRewardResponce message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.WelcomeRewardResponce
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.WelcomeRewardResponce} WelcomeRewardResponce
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WelcomeRewardResponce.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WelcomeRewardResponce message.
         * @function verify
         * @memberof api.WelcomeRewardResponce
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WelcomeRewardResponce.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.available != null && message.hasOwnProperty("available"))
                if (typeof message.available !== "boolean")
                    return "available: boolean expected";
            if (message.value != null && message.hasOwnProperty("value")) {
                properties._value = 1;
                if (!$util.isInteger(message.value))
                    return "value: integer expected";
            }
            if (message.currency != null && message.hasOwnProperty("currency")) {
                properties._currency = 1;
                if (!$util.isString(message.currency))
                    return "currency: string expected";
            }
            return null;
        };

        /**
         * Creates a WelcomeRewardResponce message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.WelcomeRewardResponce
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.WelcomeRewardResponce} WelcomeRewardResponce
         */
        WelcomeRewardResponce.fromObject = function fromObject(object) {
            if (object instanceof $root.api.WelcomeRewardResponce)
                return object;
            let message = new $root.api.WelcomeRewardResponce();
            if (object.available != null)
                message.available = Boolean(object.available);
            if (object.value != null)
                message.value = object.value | 0;
            if (object.currency != null)
                message.currency = String(object.currency);
            return message;
        };

        /**
         * Creates a plain object from a WelcomeRewardResponce message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.WelcomeRewardResponce
         * @static
         * @param {api.WelcomeRewardResponce} message WelcomeRewardResponce
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WelcomeRewardResponce.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.available = false;
            if (message.available != null && message.hasOwnProperty("available"))
                object.available = message.available;
            if (message.value != null && message.hasOwnProperty("value")) {
                object.value = message.value;
                if (options.oneofs)
                    object._value = "value";
            }
            if (message.currency != null && message.hasOwnProperty("currency")) {
                object.currency = message.currency;
                if (options.oneofs)
                    object._currency = "currency";
            }
            return object;
        };

        /**
         * Converts this WelcomeRewardResponce to JSON.
         * @function toJSON
         * @memberof api.WelcomeRewardResponce
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WelcomeRewardResponce.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WelcomeRewardResponce
         * @function getTypeUrl
         * @memberof api.WelcomeRewardResponce
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WelcomeRewardResponce.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.WelcomeRewardResponce";
        };

        return WelcomeRewardResponce;
    })();

    api.Bet = (function() {

        /**
         * Properties of a Bet.
         * @memberof api
         * @interface IBet
         * @property {number|null} [value] Bet value
         * @property {string|null} [currency] Bet currency
         */

        /**
         * Constructs a new Bet.
         * @memberof api
         * @classdesc Represents a Bet.
         * @implements IBet
         * @constructor
         * @param {api.IBet=} [properties] Properties to set
         */
        function Bet(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Bet value.
         * @member {number} value
         * @memberof api.Bet
         * @instance
         */
        Bet.prototype.value = 0;

        /**
         * Bet currency.
         * @member {string} currency
         * @memberof api.Bet
         * @instance
         */
        Bet.prototype.currency = "";

        /**
         * Creates a new Bet instance using the specified properties.
         * @function create
         * @memberof api.Bet
         * @static
         * @param {api.IBet=} [properties] Properties to set
         * @returns {api.Bet} Bet instance
         */
        Bet.create = function create(properties) {
            return new Bet(properties);
        };

        /**
         * Encodes the specified Bet message. Does not implicitly {@link api.Bet.verify|verify} messages.
         * @function encode
         * @memberof api.Bet
         * @static
         * @param {api.Bet} message Bet message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Bet.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.value);
            if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.currency);
            return writer;
        };

        /**
         * Encodes the specified Bet message, length delimited. Does not implicitly {@link api.Bet.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.Bet
         * @static
         * @param {api.Bet} message Bet message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Bet.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Bet message from the specified reader or buffer.
         * @function decode
         * @memberof api.Bet
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.Bet} Bet
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Bet.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.Bet();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.value = reader.int32();
                        break;
                    }
                case 2: {
                        message.currency = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Bet message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.Bet
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.Bet} Bet
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Bet.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Bet message.
         * @function verify
         * @memberof api.Bet
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Bet.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.value != null && message.hasOwnProperty("value"))
                if (!$util.isInteger(message.value))
                    return "value: integer expected";
            if (message.currency != null && message.hasOwnProperty("currency"))
                if (!$util.isString(message.currency))
                    return "currency: string expected";
            return null;
        };

        /**
         * Creates a Bet message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.Bet
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.Bet} Bet
         */
        Bet.fromObject = function fromObject(object) {
            if (object instanceof $root.api.Bet)
                return object;
            let message = new $root.api.Bet();
            if (object.value != null)
                message.value = object.value | 0;
            if (object.currency != null)
                message.currency = String(object.currency);
            return message;
        };

        /**
         * Creates a plain object from a Bet message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.Bet
         * @static
         * @param {api.Bet} message Bet
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Bet.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.value = 0;
                object.currency = "";
            }
            if (message.value != null && message.hasOwnProperty("value"))
                object.value = message.value;
            if (message.currency != null && message.hasOwnProperty("currency"))
                object.currency = message.currency;
            return object;
        };

        /**
         * Converts this Bet to JSON.
         * @function toJSON
         * @memberof api.Bet
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Bet.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Bet
         * @function getTypeUrl
         * @memberof api.Bet
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Bet.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.Bet";
        };

        return Bet;
    })();

    api.PizzaOperationResponseTransaction = (function() {

        /**
         * Properties of a PizzaOperationResponseTransaction.
         * @memberof api
         * @interface IPizzaOperationResponseTransaction
         * @property {string|null} [amount] PizzaOperationResponseTransaction amount
         * @property {string|null} [clientUid] PizzaOperationResponseTransaction clientUid
         * @property {string|null} [currency] PizzaOperationResponseTransaction currency
         */

        /**
         * Constructs a new PizzaOperationResponseTransaction.
         * @memberof api
         * @classdesc Represents a PizzaOperationResponseTransaction.
         * @implements IPizzaOperationResponseTransaction
         * @constructor
         * @param {api.IPizzaOperationResponseTransaction=} [properties] Properties to set
         */
        function PizzaOperationResponseTransaction(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PizzaOperationResponseTransaction amount.
         * @member {string} amount
         * @memberof api.PizzaOperationResponseTransaction
         * @instance
         */
        PizzaOperationResponseTransaction.prototype.amount = "";

        /**
         * PizzaOperationResponseTransaction clientUid.
         * @member {string} clientUid
         * @memberof api.PizzaOperationResponseTransaction
         * @instance
         */
        PizzaOperationResponseTransaction.prototype.clientUid = "";

        /**
         * PizzaOperationResponseTransaction currency.
         * @member {string} currency
         * @memberof api.PizzaOperationResponseTransaction
         * @instance
         */
        PizzaOperationResponseTransaction.prototype.currency = "";

        /**
         * Creates a new PizzaOperationResponseTransaction instance using the specified properties.
         * @function create
         * @memberof api.PizzaOperationResponseTransaction
         * @static
         * @param {api.IPizzaOperationResponseTransaction=} [properties] Properties to set
         * @returns {api.PizzaOperationResponseTransaction} PizzaOperationResponseTransaction instance
         */
        PizzaOperationResponseTransaction.create = function create(properties) {
            return new PizzaOperationResponseTransaction(properties);
        };

        /**
         * Encodes the specified PizzaOperationResponseTransaction message. Does not implicitly {@link api.PizzaOperationResponseTransaction.verify|verify} messages.
         * @function encode
         * @memberof api.PizzaOperationResponseTransaction
         * @static
         * @param {api.PizzaOperationResponseTransaction} message PizzaOperationResponseTransaction message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PizzaOperationResponseTransaction.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.amount);
            if (message.clientUid != null && Object.hasOwnProperty.call(message, "clientUid"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.clientUid);
            if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.currency);
            return writer;
        };

        /**
         * Encodes the specified PizzaOperationResponseTransaction message, length delimited. Does not implicitly {@link api.PizzaOperationResponseTransaction.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.PizzaOperationResponseTransaction
         * @static
         * @param {api.PizzaOperationResponseTransaction} message PizzaOperationResponseTransaction message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PizzaOperationResponseTransaction.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PizzaOperationResponseTransaction message from the specified reader or buffer.
         * @function decode
         * @memberof api.PizzaOperationResponseTransaction
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.PizzaOperationResponseTransaction} PizzaOperationResponseTransaction
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PizzaOperationResponseTransaction.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.PizzaOperationResponseTransaction();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.amount = reader.string();
                        break;
                    }
                case 2: {
                        message.clientUid = reader.string();
                        break;
                    }
                case 3: {
                        message.currency = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PizzaOperationResponseTransaction message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.PizzaOperationResponseTransaction
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.PizzaOperationResponseTransaction} PizzaOperationResponseTransaction
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PizzaOperationResponseTransaction.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PizzaOperationResponseTransaction message.
         * @function verify
         * @memberof api.PizzaOperationResponseTransaction
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PizzaOperationResponseTransaction.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.amount != null && message.hasOwnProperty("amount"))
                if (!$util.isString(message.amount))
                    return "amount: string expected";
            if (message.clientUid != null && message.hasOwnProperty("clientUid"))
                if (!$util.isString(message.clientUid))
                    return "clientUid: string expected";
            if (message.currency != null && message.hasOwnProperty("currency"))
                if (!$util.isString(message.currency))
                    return "currency: string expected";
            return null;
        };

        /**
         * Creates a PizzaOperationResponseTransaction message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.PizzaOperationResponseTransaction
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.PizzaOperationResponseTransaction} PizzaOperationResponseTransaction
         */
        PizzaOperationResponseTransaction.fromObject = function fromObject(object) {
            if (object instanceof $root.api.PizzaOperationResponseTransaction)
                return object;
            let message = new $root.api.PizzaOperationResponseTransaction();
            if (object.amount != null)
                message.amount = String(object.amount);
            if (object.clientUid != null)
                message.clientUid = String(object.clientUid);
            if (object.currency != null)
                message.currency = String(object.currency);
            return message;
        };

        /**
         * Creates a plain object from a PizzaOperationResponseTransaction message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.PizzaOperationResponseTransaction
         * @static
         * @param {api.PizzaOperationResponseTransaction} message PizzaOperationResponseTransaction
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PizzaOperationResponseTransaction.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.amount = "";
                object.clientUid = "";
                object.currency = "";
            }
            if (message.amount != null && message.hasOwnProperty("amount"))
                object.amount = message.amount;
            if (message.clientUid != null && message.hasOwnProperty("clientUid"))
                object.clientUid = message.clientUid;
            if (message.currency != null && message.hasOwnProperty("currency"))
                object.currency = message.currency;
            return object;
        };

        /**
         * Converts this PizzaOperationResponseTransaction to JSON.
         * @function toJSON
         * @memberof api.PizzaOperationResponseTransaction
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PizzaOperationResponseTransaction.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PizzaOperationResponseTransaction
         * @function getTypeUrl
         * @memberof api.PizzaOperationResponseTransaction
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PizzaOperationResponseTransaction.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.PizzaOperationResponseTransaction";
        };

        return PizzaOperationResponseTransaction;
    })();

    api.MatchOverResponce = (function() {

        /**
         * Properties of a MatchOverResponce.
         * @memberof api
         * @interface IMatchOverResponce
         * @property {Array.<api.PizzaOperationResponseTransaction>|null} [transactionsOut] MatchOverResponce transactionsOut
         */

        /**
         * Constructs a new MatchOverResponce.
         * @memberof api
         * @classdesc Represents a MatchOverResponce.
         * @implements IMatchOverResponce
         * @constructor
         * @param {api.IMatchOverResponce=} [properties] Properties to set
         */
        function MatchOverResponce(properties) {
            this.transactionsOut = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MatchOverResponce transactionsOut.
         * @member {Array.<api.PizzaOperationResponseTransaction>} transactionsOut
         * @memberof api.MatchOverResponce
         * @instance
         */
        MatchOverResponce.prototype.transactionsOut = $util.emptyArray;

        /**
         * Creates a new MatchOverResponce instance using the specified properties.
         * @function create
         * @memberof api.MatchOverResponce
         * @static
         * @param {api.IMatchOverResponce=} [properties] Properties to set
         * @returns {api.MatchOverResponce} MatchOverResponce instance
         */
        MatchOverResponce.create = function create(properties) {
            return new MatchOverResponce(properties);
        };

        /**
         * Encodes the specified MatchOverResponce message. Does not implicitly {@link api.MatchOverResponce.verify|verify} messages.
         * @function encode
         * @memberof api.MatchOverResponce
         * @static
         * @param {api.MatchOverResponce} message MatchOverResponce message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MatchOverResponce.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.transactionsOut != null && message.transactionsOut.length)
                for (let i = 0; i < message.transactionsOut.length; ++i)
                    $root.api.PizzaOperationResponseTransaction.encode(message.transactionsOut[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified MatchOverResponce message, length delimited. Does not implicitly {@link api.MatchOverResponce.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.MatchOverResponce
         * @static
         * @param {api.MatchOverResponce} message MatchOverResponce message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MatchOverResponce.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MatchOverResponce message from the specified reader or buffer.
         * @function decode
         * @memberof api.MatchOverResponce
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.MatchOverResponce} MatchOverResponce
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MatchOverResponce.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.MatchOverResponce();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.transactionsOut && message.transactionsOut.length))
                            message.transactionsOut = [];
                        message.transactionsOut.push($root.api.PizzaOperationResponseTransaction.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MatchOverResponce message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.MatchOverResponce
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.MatchOverResponce} MatchOverResponce
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MatchOverResponce.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MatchOverResponce message.
         * @function verify
         * @memberof api.MatchOverResponce
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MatchOverResponce.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.transactionsOut != null && message.hasOwnProperty("transactionsOut")) {
                if (!Array.isArray(message.transactionsOut))
                    return "transactionsOut: array expected";
                for (let i = 0; i < message.transactionsOut.length; ++i) {
                    let error = $root.api.PizzaOperationResponseTransaction.verify(message.transactionsOut[i]);
                    if (error)
                        return "transactionsOut." + error;
                }
            }
            return null;
        };

        /**
         * Creates a MatchOverResponce message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.MatchOverResponce
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.MatchOverResponce} MatchOverResponce
         */
        MatchOverResponce.fromObject = function fromObject(object) {
            if (object instanceof $root.api.MatchOverResponce)
                return object;
            let message = new $root.api.MatchOverResponce();
            if (object.transactionsOut) {
                if (!Array.isArray(object.transactionsOut))
                    throw TypeError(".api.MatchOverResponce.transactionsOut: array expected");
                message.transactionsOut = [];
                for (let i = 0; i < object.transactionsOut.length; ++i) {
                    if (typeof object.transactionsOut[i] !== "object")
                        throw TypeError(".api.MatchOverResponce.transactionsOut: object expected");
                    message.transactionsOut[i] = $root.api.PizzaOperationResponseTransaction.fromObject(object.transactionsOut[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a MatchOverResponce message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.MatchOverResponce
         * @static
         * @param {api.MatchOverResponce} message MatchOverResponce
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MatchOverResponce.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.transactionsOut = [];
            if (message.transactionsOut && message.transactionsOut.length) {
                object.transactionsOut = [];
                for (let j = 0; j < message.transactionsOut.length; ++j)
                    object.transactionsOut[j] = $root.api.PizzaOperationResponseTransaction.toObject(message.transactionsOut[j], options);
            }
            return object;
        };

        /**
         * Converts this MatchOverResponce to JSON.
         * @function toJSON
         * @memberof api.MatchOverResponce
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MatchOverResponce.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MatchOverResponce
         * @function getTypeUrl
         * @memberof api.MatchOverResponce
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MatchOverResponce.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.MatchOverResponce";
        };

        return MatchOverResponce;
    })();

    return api;
})();

export { $root as default };
