import { type Entity, SystemService, type NodeList } from '@nord-beaver/core/ecs';
import { SceneObjectComponent } from '@nord-beaver/core/ecs/components/sceneObjectComponent';
import { type EngineService } from '@nord-beaver/core/ecs/engineService';
import { type EntityService } from '@nord-beaver/core/ecs/entityService';
import { type AnyAssetDesc, ASSET_TYPE, type AssetServicePhaser } from '@nord-beaver/core/services/assetService';
import { type StageService } from '@nord-beaver/core/services/stageService';
import { mainLogger } from '@nord-beaver/core/utils/logger';
import { api } from 'game/api/api';
import { Layers } from 'game/constants';
import { LoadingComponent } from 'game/ecs/components/loadingComponent';
import { DiceComponent } from 'game/ecs/components/match/diceComponent';
import { MatchComponent } from 'game/ecs/components/match/matchComponent';
import { MatchLinkComponent } from 'game/ecs/components/match/matchLinkComponent';
import { MatchRoomsComponent, type MatchRoomsDesc } from 'game/ecs/components/match/matchRoomsComponent';
import { type ClientNakamaComponent } from 'game/ecs/components/nakama/clientNakamaComponent';
import { MatchJoinNakamaComponent } from 'game/ecs/components/nakama/match/matchJoinNakamaComponent';
import { MatchLeaveNakamaComponent } from 'game/ecs/components/nakama/match/matchLeaveNakamaComponent';
import { MatchMapNakamaComponent } from 'game/ecs/components/nakama/match/matchMapNakamaComponent';
import { type SessionNakamaComponent } from 'game/ecs/components/nakama/sessionNakamaComponent';
import { MatchNode } from 'game/ecs/nodes/matchNode';
import { CoreNakamaNode } from 'game/ecs/nodes/nakama/coreNakamaNode';
import { type AmplitudeEventService, EventCategory } from 'game/services/analytics/amplitudeEventService';
import { type EntityMapService } from 'game/services/entityMapService';
import { type EventService } from 'game/services/eventService';
import { type SessionNakamaService } from 'game/services/nakama/session/sessionNakamaService';
import { type NakamaService } from 'game/services/nakamaService';
import { type TelegramService } from 'game/services/telegramService';
import { EnitityComponents } from 'game/types/entityComponents';
import { Currency } from 'game/types/entityDescs/currency';
import { type DiceComponentDesc } from 'game/types/entityDescs/dice';
import { type MatchComponentDesc } from 'game/types/entityDescs/match';
import { ApiRpc } from 'game/types/nakama/rpcData';
import { windowManager } from 'game/ui/services/windowManager';
import { type DependencyContainer } from 'game/utils/dependencyContainer';
import { getEntityComponentDesc } from 'game/utils/entityDesc';
import { parseMatchLabel } from 'game/utils/matchLablel';
import { getClientCurrency, getServerCurrency, ServerCurrency } from 'game/utils/resource';
import { getUserMetadata } from 'game/utils/userMetadata';

const logger = mainLogger.getLogger('MatchMakinng');

export class MatchmakingSystem extends SystemService {
    private unloadAssetIds: string[] = [];
    private matchDesc?: MatchComponentDesc;
    private diceDesc?: DiceComponentDesc;
    private matchNodeList?: NodeList<MatchNode>;

    constructor(
        _dependencyContainer: DependencyContainer,
        private readonly entityMapService: EntityMapService,
        private readonly assetService: AssetServicePhaser,
        private readonly engineService: EngineService,
        private readonly stageService: StageService,
        private readonly entityService: EntityService,
        private readonly eventService: EventService,
        private readonly nakamaService: NakamaService,
        private readonly sessionNakamaService: SessionNakamaService,
        private readonly telegramService: TelegramService,
        private readonly amplitudeEventService: AmplitudeEventService,
    ) {
        super();
    }

    async init() {
        this.eventService.game.once('entityLoaded', () => {
            const matchEntityDesc = getEntityComponentDesc<MatchComponentDesc>(this.entityService, '/match', EnitityComponents.Match);
            if (!matchEntityDesc) {
                logger.error('Match entity not found');

                return;
            }
            const diceEntityDesc = getEntityComponentDesc<DiceComponentDesc>(this.entityService, 'objects/dice', EnitityComponents.Dice);
            if (!diceEntityDesc) {
                logger.error('Dice entity not found');

                return;
            }

            this.matchDesc = matchEntityDesc;
            this.diceDesc = diceEntityDesc;
        }, this);

        this.setupNodeList({
            node: CoreNakamaNode,
            add: this.addCoreNakama,
            remove: this.removeCoreNakama,
        });

        this.matchNodeList = this.setupNodeList({
            node: MatchNode,
        });
    }

    override destroy() {
        this.eventService.game.offAll('entityLoaded', this);

        this.unloadAssetIds.forEach(assetId => {
            this.assetService.unload(assetId, true);
        });

        this.unloadAssetIds.length = 0;

        super.destroy();
    }

    private async addCoreNakama(node: CoreNakamaNode) {
        const { client, session, entity } = node;

        this.eventService.lobby.on('createMatch', async ({ bet, name }) => {
            const {
                operationUid: operationUid,
                refLink,
            } = await this.nakamaService.callRpc({
                rpcType: ApiRpc.initBet,
                payload: {
                    currency: getServerCurrency(bet.type),
                    amount: bet.value.toString(),
                },
            });

            if (!operationUid) {
                logger.error('OperationUid not found');

                return;
            }

            this.eventService.metaData.dispatch('request', 'clientAccounts');

            const {
                match,
            } = await this.nakamaService.callRpc({
                rpcType: ApiRpc.createMatch,
                payload: {
                    matchName: name,
                    operationUid,
                },
            });

            if (!match?.matchId || !match?.tilemapEntityId) {
                logger.error('Match not found');

                return;
            }

            switch (bet.type) {
                case Currency.Soft:
                    this.amplitudeEventService.sendEvent('Create a Room - Silver GG', {
                        category: EventCategory.Lobby,
                        betAmount: bet.value,
                        operationUid,
                        matchId: match.matchId,
                    });
                    break;
                case Currency.Hard:
                    this.amplitudeEventService.sendEvent('Create a Room - Gold GG', {
                        category: EventCategory.Lobby,
                        betAmount: bet.value,
                        operationUid,
                        matchId: match.matchId,
                    });
                    break;
            }

            this.joinMatch(entity, match.matchId, match.tilemapEntityId, operationUid, bet, refLink);
        }, entity);

        this.eventService.lobby.on('joinMatch', async ({ matchId, currencyType }) => {
            const matchRoomsComponent = entity.get(MatchRoomsComponent);
            if (!matchRoomsComponent) {
                logger.error('MatchRoomsComponent not found');

                return;
            }

            const matchRoom = matchRoomsComponent.rooms[matchId];
            if (!matchRoom) {
                logger.error('MatchRoom not found');

                return;
            }

            const bet = matchRoom.bet.find(bet => bet.type === currencyType);
            if (!bet) {
                logger.error('Bet not found');

                return;
            }

            const {
                operationUid,
                refLink,
            } = await this.nakamaService.callRpc({
                rpcType: ApiRpc.addBet,
                payload: {
                    operationUid: matchRoom.operationUid,
                    currency: getServerCurrency(bet.type),
                },
            });
            if (!operationUid) {
                logger.error('OperationUid not found');

                return;
            }

            this.eventService.metaData.dispatch('request', 'clientAccounts');

            this.joinMatch(entity, matchId, matchRoom.tilemapEntityId, operationUid, bet, refLink);
        }, entity);

        this.eventService.lobby.on('leaveMatch', () => {
            this.leaveMatch(entity);
        }, entity);

        this.eventService.windowManager.on('changeState', state => state.stateKey === 'matchRooms' && this.updateMatchRooms(entity, client, session), entity);
        this.eventService.lobby.on('updateRooms', () => this.updateMatchRooms(entity, client, session), entity);

        const isReconnecting = await this.reconnectMatch(entity, client, session);

        if (!isReconnecting) {
            await this.joinMatchThroughRefLink(entity, client, session);
        }

        await this.updateMatchRooms(entity, client, session);
    }

    private removeCoreNakama(node: CoreNakamaNode) {
        const { matchMap, entity } = node;

        matchMap.matchJoinSignal.offAll(entity);
        matchMap.matchLeaveSignal.offAll(entity);
        matchMap.matchJoinErrorSignal.offAll(entity);
        this.eventService.match.offAll('startMatch', entity);
        this.eventService.nakama.offAll('disconnect', entity);
        this.eventService.match.offAll('shareMatchInviteLink', entity);
        this.eventService.lobby.offAll('createMatch', entity);
        this.eventService.lobby.offAll('joinMatch', entity);
        this.eventService.lobby.offAll('leaveMatch', entity);
        this.eventService.lobby.offAll('updateRooms', entity);
        this.eventService.windowManager.offAll('changeState', entity);
    }

    private async joinMatchThroughRefLink(entity: Entity, client: ClientNakamaComponent, session: SessionNakamaComponent) {
        const operationUid = this.telegramService.getStartParams();
        if (!operationUid) {
            return;
        }

        logger.log('Join match through ref link', { operationUid });

        const matchList = await this.sessionNakamaService.getMatchList(client.client, session.session, {
            limit: 1,
            query: `+label.open:1 +label.operationUid:${operationUid}`,
        });
        if (!matchList) {
            logger.warn('Match for ref link not found', { operationUid });

            return;
        }

        const match = matchList.matches?.find(match => match.label?.includes(operationUid));
        if (!match || !match.match_id) {
            logger.warn('Match for ref link not found', { operationUid });

            return;
        }

        const matchParams = match.label ? parseMatchLabel(match.label) : undefined;
        if (!matchParams) {
            logger.error('Match params for ref link not found', { match });

            return;
        }

        if (matchParams.operationUid !== operationUid
            || !matchParams.open
            || !matchParams.mapId) {
            logger.warn('Match params for ref link not found', { matchParams });

            return;
        }

        logger.log('Match found by ref link', { match });

        entity.add(new MatchLinkComponent(match.match_id));
    }

    private async reconnectMatch(entity: Entity, client: ClientNakamaComponent, session: SessionNakamaComponent) {
        const playerMetadata = getUserMetadata(session.userData?.metadata);
        if (!playerMetadata) {
            logger.error('Player metadata not found', session.userData);

            return false;
        }

        const operationUid = playerMetadata.operationUid;
        if (!operationUid) {
            return false;
        }

        const matchList = await this.sessionNakamaService.getMatchList(client.client, session.session, {
            limit: 1,
            query: `+label.operationUid:${operationUid}`,
        });
        if (!matchList) {
            logger.warn('Match not found', { operationUid });

            return false;
        }

        const match = matchList.matches?.[0];
        if (!match || !match.match_id) {
            logger.warn('Match not found', { operationUid });

            return false;
        }

        const matchParams = match.label ? parseMatchLabel(match.label) : undefined;
        if (!matchParams || matchParams.operationUid !== operationUid) {
            logger.error('Match params not found', { match });

            return false;
        }

        // TODO: remove bet from here. bet should be taken from start match message
        const betCurrency = Currency.Soft;

        const serverBet = matchParams.bet?.find(bet => bet.currency === betCurrency);
        const bet = serverBet
            ? {
                type: getClientCurrency(serverBet.currency),
                value: serverBet.value,
            }
            : undefined;

        this.joinMatch(entity, match.match_id, matchParams.mapId, operationUid, bet);

        return true;
    }

    private async updateMatchRooms(entity: Entity, client: ClientNakamaComponent, session: SessionNakamaComponent) {
        let matchRoomsComponent = entity.get(MatchRoomsComponent);

        const matchList = await this.sessionNakamaService.getMatchList(client.client, session.session, {
            limit: 100,
            query: '+label.open:1',
        });
        if (!matchList) {
            logger.error('Match list not found');

            return;
        }

        const matchRooms: Record<string, MatchRoomsDesc> = {};

        if (matchList.matches) {
            for (const match of matchList.matches) {
                const matchId = match.match_id;
                const matchParams = match.label ? parseMatchLabel(match.label) : undefined;
                if (!matchId || !matchParams) {
                    continue;
                }

                const playerAvatars = await this.sessionNakamaService.getUserData(client.client, session.session, matchParams.users);

                matchRooms[matchId] = {
                    matchId: matchId,
                    tilemapEntityId: matchParams.mapId,
                    matchName: matchParams.matchName,
                    bet: matchParams.bet.map(bet => ({
                        type: getClientCurrency(bet.currency),
                        value: bet.currency === ServerCurrency.Hard
                            ? bet.value
                            : bet.value,
                    })),
                    playerAvatars: playerAvatars.map(user => user.avatar_url),
                    operationUid: matchParams.operationUid,
                };
            }
        }

        if (!matchRoomsComponent) {
            matchRoomsComponent = new MatchRoomsComponent(matchRooms);
            entity.add(matchRoomsComponent);
        } else {
            matchRoomsComponent.rooms = matchRooms;
            matchRoomsComponent.isDirty = true;
        }

        logger.log('Match rooms updated', { matchRooms });

        return matchRoomsComponent;
    }

    private async leaveMatch(entity: Entity, matchId?: string) {
        const matchMap = entity.get(MatchMapNakamaComponent);
        if (!matchMap) {
            logger.error('MatchMapNakamaComponent not found');

            return;
        }

        if (matchMap.matchMap.size === 0) {
            return;
        }

        if (!matchId) {
            matchId = matchMap.getMatch()?.match_id;
        }

        if (!matchId) {
            logger.error('MatchId not found');

            return;
        }

        entity.add(new MatchLeaveNakamaComponent(matchId));
    }

    private async joinMatch(
        entity: Entity,
        matchId: string,
        tilemapEntityId: string,
        operationUid: string,
        bet: { type: Currency; value: number } | undefined,
        refLink?: string,
    ) {
        const matchMap = entity.get(MatchMapNakamaComponent);
        if (!matchMap) {
            logger.error('MatchMapNakamaComponent not found');

            return;
        }

        if (!this.matchDesc) {
            logger.error('MatchDesc not found');

            return;
        }

        if (!this.diceDesc) {
            logger.error('DiceDesc not found');

            return;
        }

        if (!matchId || !tilemapEntityId) {
            logger.error('MatchId or tilemapEntityId not found');

            return;
        }

        if (this.unloadAssetIds.length) {
            this.assetService.unload(this.unloadAssetIds, true);

            this.unloadAssetIds.length = 0;
        }

        const loading = new LoadingComponent();
        entity.add(loading);

        const onProgress = (progress: number) => {
            loading.progress = progress;
        };

        this.assetService.progressSignal.on(onProgress);

        const mapEntity = await this.loadMap(tilemapEntityId);
        if (!mapEntity) {
            logger.error('Map entity not found');

            return;
        }

        loading.progress = 1;
        this.assetService.progressSignal.off(onProgress);

        const match = new MatchComponent(this.matchDesc, mapEntity, { refLink: refLink ?? '', matchId, bet, operationUid });
        const dice = new DiceComponent(this.diceDesc);

        entity
            .add(dice)
            .add(match)
            .add(new MatchJoinNakamaComponent(matchId));

        matchMap.matchJoinSignal.once((_, nakamaMatch) => {
            entity.remove(LoadingComponent);

            const matchLabel = nakamaMatch.label ? parseMatchLabel(nakamaMatch.label) : undefined;
            if (matchLabel) {
                match.name = matchLabel.matchName;
                match.refLink = refLink ?? '';
            } else {
                logger.error('Match label not found', nakamaMatch);
            }

            windowManager.setState('matchmaking');
        }, entity);
        matchMap.matchLeaveSignal.once(() => {
            entity.remove(LoadingComponent);
            entity.remove(MatchComponent);
            entity.remove(DiceComponent);

            windowManager.setState('matchRooms');

            this.eventService.match.offAll('shareMatchInviteLink', entity);
            this.eventService.nakama.offAll('disconnect', entity);
            this.eventService.match.offAll('startMatch', entity);
        });
        matchMap.matchJoinErrorSignal.once(() => {
            entity.remove(LoadingComponent);
            entity.remove(MatchComponent);
            entity.remove(DiceComponent);

            windowManager.setState('matchRooms');

            this.eventService.match.offAll('shareMatchInviteLink', entity);
            this.eventService.nakama.offAll('disconnect', entity);
            this.eventService.match.offAll('startMatch', entity);
        });

        this.eventService.match.once('startMatch', () => {
            this.nakamaService.sendMatchData({ opCode: api.OpCodeRequest.MATCH_START, message: {} });
        }, entity);
        this.eventService.nakama.once('disconnect', disconnectNakamaEntity => {
            if (disconnectNakamaEntity !== entity) {
                return;
            }

            confirm('You have been disconnected from the server. Please refresh game page to reconnect.');
        }, entity);
        this.eventService.match.on('shareMatchInviteLink', this.shareMatchInviteLink, entity);
    }

    private async loadMap(mapUid: string) {
        const mapEntity = this.entityMapService.createEntity(mapUid);
        if (!mapEntity) {
            logger.error('Map entity not found');

            return null;
        }

        if (mapEntity.entityId) {
            const mapPackAssetId = mapEntity.entityId.split('/').pop();
            const assetId = `assets/packs/maps/${mapPackAssetId}.json`;
            const packs: AnyAssetDesc[] = [{
                type: ASSET_TYPE.PACK,
                url: assetId,
            }];

            this.unloadAssetIds.push(assetId);

            await this.assetService.load(packs);
        }

        const sceneObject = mapEntity.get(SceneObjectComponent);
        if (sceneObject) {
            sceneObject.parent = this.stageService.getLayer(Layers.Game);
        }

        this.engineService.addEntity(mapEntity, true);

        return mapEntity;
    }

    private shareMatchInviteLink = () => {
        if (!this.matchNodeList) {
            logger.error('Match node list not found');

            return;
        }

        const { match } = this.matchNodeList.get(0) ?? {};
        if (!match) {
            logger.error('Match not found');

            return;
        }

        const refLink = match?.refLink;
        if (!refLink) {
            logger.error('Ref link not found');

            return;
        }

        this.telegramService.shareMessageDirectly('Join me in the game', refLink);

        logger.info('copyMatchInviteLink');
    };
}