import { type AmplitudeService } from 'game/services/analytics/amplitudeService';
import { type DependencyContainer } from 'game/utils/dependencyContainer';

export enum EventCategory {
    Start = 'Start',
    Menu = 'Menu',
    Friends = 'Friends',
    Clans = 'Clans',
    Tasks = 'Tasks',
    Tutorial = 'Tutorial',
    Lobby = 'Lobby',
}

// eslint-disable-next-line @typescript-eslint/ban-types
type EmptyObject = {};

type EventParamsMap = {
    'Login': { withCode: string; codeType: string; nakamaUserId: string };
    'Welcome Bonus': { currency: string; amount: number };
    'Claim Daily Reward': { day: number; currency: string; amount: number };
    'Claim Storage': EmptyObject;
    'Menu click Play': EmptyObject;
    'Menu click Home': EmptyObject;
    'Menu click Friends': EmptyObject;
    'Menu click Clans': EmptyObject;
    'Menu click Tasks': EmptyObject;
    'Invite a Friend': EmptyObject;
    'Invite friend via Telegram': EmptyObject;
    'Copy a friend Link': EmptyObject;
    'Close Invite Friend Window': EmptyObject;
    'Join the Clan': EmptyObject;
    'Create or Join Clan': { code: string };
    'Join to Clan': { clanId: string };
    'Copy Clan Link': { clanId: string };
    'Leave Clan': { clanId: string };
    'Task click Invite a Friend - Complete': EmptyObject;
    'Task click Invite a Friend - Claim': EmptyObject;
    'Task click Link a Wallet - Complete': EmptyObject;
    'Task click Link a Wallet - Claim': EmptyObject;
    'Task click Subscribe to X - Complete': EmptyObject;
    'Task click Subscribe to X - Claim': EmptyObject;
    'Task click Subscribe to Telegram - Complete': EmptyObject;
    'Task click Subscribe to Telegram - Claim': EmptyObject;
    'Task click Subscribe to YouTube - Complete': EmptyObject;
    'Task click Subscribe to YouTube - Claim': EmptyObject;
    'Tutorial': EmptyObject;
    'Tutorial - 1 - Next': EmptyObject;
    'Tutorial - 2 - Next': EmptyObject;
    'Tutorial - 3 - Next': EmptyObject;
    'Tutorial - 4 - Next': EmptyObject;
    'Tutorial - 5 - Next': EmptyObject;
    'Tutorial - 6 - Close': EmptyObject;
    'Go to a Room': { operationUid: string; matchId: string };
    'Create a Room - Silver GG': { operationUid: string; matchId: string; betAmount: number };
    'Create a Room - Gold GG': { operationUid: string; matchId: string; betAmount: number };
    'Invite to a Room': { operationUid: string; matchId: string; link: string };
    'Start With Bots': { operationUid: string; matchId: string };
    'Started a PvE Match': { operationUid: string; matchId: string };
    'Started a PvP Match': { operationUid: string; matchId: string };
    'Cancel the Match': { operationUid: string; matchId: string };
    'Match is Finished': { operationUid: string; matchId: string };
    'Match is Cancelled': { operationUid: string; matchId: string };
    'Take the Reward': { operationUid: string; matchId: string; rewardCurrency: string; rewardAmount: number };
};

export class AmplitudeEventService {
    constructor(
        _dependencyContainer: DependencyContainer,
        private readonly amplitudeService: AmplitudeService,
    ) {
    }

    sendEvent<T extends keyof EventParamsMap>(
        eventName: T,
        params: EventParamsMap[T] & { category: EventCategory },
    ) {
        this.amplitudeService.sendEvent(eventName, params);
    }
}
