import { type api } from 'game/api/api';

export class ClansComponent {
    isDirty = false;

    constructor(
        public list: api.RpcGroupCreateResponseDataGroup[] = [],
        public groupClients: Record<string, api.RpcGroupListClient[]> = {},
        public group: api.RpcGroupCreateResponseDataGroup | null = null, // TODO: replace with group index
    ) {
    }
}