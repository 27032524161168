<script setup lang="ts">
import { EventCategory } from 'game/services/analytics/amplitudeEventService';
import { Currency } from 'game/types/entityDescs/currency';
import MainButton from 'game/ui/commonInstances/MainButton.vue';
import Panel from 'game/ui/commonInstances/Panel.vue';
import ScrollPan from 'game/ui/commonInstances/ScrollPan.vue';
import FriendItem from 'game/ui/components/lobby/friends/FriendItem.vue';
import InfoBlock from 'game/ui/components/lobby/friends/InfoBlock.vue';
import InviteFriend from 'game/ui/components/lobby/friends/InviteFriend.vue';
import Resource from 'game/ui/components/lobby/Resource.vue';
import { useAmplitudeEventService } from 'game/ui/composables/amplitudeEventService';
import { useEventService } from 'game/ui/composables/eventService';
import { useModel } from 'game/ui/composables/model';
import { ContainerIds } from 'game/ui/config/containers';
import LobbyLayout from 'game/ui/layouts/LobbyLayout.vue';
import { formatNumber } from 'game/ui/utils/number';
import { ref } from 'vue';

const amplitudeEventService = useAmplitudeEventService();
const eventService = useEventService();
const friends = useModel(['friends', 'list']);
const refferalsCount = useModel(['friends', 'refferalsCount']);
const refferalsCount2ndLevel = useModel(['friends', 'refferalsCount2ndLevel']);
const refferalsCount3rdLevel = useModel(['friends', 'refferalsCount3rdLevel']);
const income = useModel(['friends', 'income']);
const incomeCoeficient = useModel(['friends', 'incomeCoeficient']);
const incomeFromRefferalsCoeficient = useModel(['friends', 'incomeFromRefferalsCoeficient']);

const lobbyLayout = ref<InstanceType<typeof LobbyLayout> | null>(null);

const pageState = ref<'friends' | 'joinFriend'>('friends');
const codeText = ref('');

const copyLink = () => {
    eventService.lobby.dispatch('copyFriendInviteLink');

    amplitudeEventService.sendEvent('Copy a friend Link', {
        category: EventCategory.Friends,
    });
};

const inviteFriend = () => {
    eventService.lobby.dispatch('inviteFriendViaTelegram');

    amplitudeEventService.sendEvent('Invite friend via Telegram', {
        category: EventCategory.Friends,
    });
};

const openJoinFriendWindow = () => {
    codeText.value = '';
    pageState.value = 'joinFriend';
    lobbyLayout.value?.scrollToTop();

    amplitudeEventService.sendEvent('Invite a Friend', {
        category: EventCategory.Friends,
    });
};
const closeWindows = () => {
    pageState.value = 'friends';
    lobbyLayout.value?.scrollToTop();

    amplitudeEventService.sendEvent('Close Invite Friend Window', {
        category: EventCategory.Friends,
    });
};
</script>

<template>
    <LobbyLayout ref="lobbyLayout" :container-id="ContainerIds.Friends" class="friends-container">
        <Transition :duration="1000" name="fade">
            <div v-show="pageState === 'friends'" class="friends friends-container__clans friends-container__page">
                <InfoBlock class="friends__info-block" />

                <div class="friends__mini-block">
                    <Panel padding-top-bottom="14px">
                        <div class="friends__mini-block-data">
                            <h3 class="friends__mini-block-title mini-block-title">
                                {{ formatNumber(incomeCoeficient) }}%
                            </h3>

                            <p class="friends__mini-block-text mini-block-text">
                                from your referrals income
                            </p>
                        </div>
                    </Panel>

                    <Panel padding-top-bottom="14px">
                        <div class="friends__mini-block-data">
                            <h3 class="friends__mini-block-title mini-block-title">
                                {{ formatNumber(incomeFromRefferalsCoeficient) }}%
                            </h3>

                            <p class="friends__mini-block-text mini-block-text">
                                referrals from your second lvl referrals
                            </p>
                        </div>
                    </Panel>
                </div>

                <h2 class="friends__title friends__title--margin-small section-title">
                    Statistics
                </h2>

                <div class="friends__mini-block-second">
                    <Panel padding-top-bottom="14px" padding-left-right="0px">
                        <div class="friends__mini-block-second__inner">
                            <div class="friends__mini-block-second__content">
                                <h3 class="friends__mini-block-title mini-block-title">
                                    {{ formatNumber(refferalsCount) }}
                                </h3>

                                <p class="friends__mini-block-text mini-block-text">
                                    first level referrals
                                </p>
                            </div>
                            <div class="friends__mini-block-second__content">
                                <h3 class="friends__mini-block-title mini-block-title">
                                    {{ formatNumber(refferalsCount2ndLevel) }}
                                </h3>

                                <p class="friends__mini-block-text mini-block-text">
                                    second level referrals
                                </p>
                            </div>
                            <div class="friends__mini-block-second__content">
                                <h3 class="friends__mini-block-title mini-block-title">
                                    {{ formatNumber(refferalsCount3rdLevel) }}
                                </h3>

                                <p class="friends__mini-block-text mini-block-text">
                                    third level referrals
                                </p>
                            </div>
                        </div>
                    </Panel>

                    <Panel padding-top-bottom="14px" class="friends__mini-block__left">
                        <div class="friends__mini-block-data">
                            <h3 class="friends__mini-block-title mini-block-title">
                                {{ formatNumber(income) }}
                            </h3>

                            <div class="friends__mini-block-data__income">
                                <p class="friends__mini-block-text mini-block-text">
                                    your income
                                </p>

                                <Resource
                                    class="friends__mini-block-data__icon"
                                    :resource-type="Currency.Soft"
                                />
                            </div>
                        </div>
                    </Panel>
                </div>

                <template v-if="friends.length">
                    <h2 class="friends__title section-title">
                        Friends list
                    </h2>

                    <Panel padding-right="0px" class="friends__clans-panel">
                        <ScrollPan>
                            <div class="friends__clans-block">
                                <template v-for="friend in friends" :key="friend.id">
                                    <FriendItem
                                        class="friends__clan-item"
                                        :friend="friend"
                                    />
                                </template>
                            </div>
                        </ScrollPan>
                    </Panel>
                </template>

                <MainButton class="friends__join-button" label="Invite a friend" @click="openJoinFriendWindow" />
            </div>
        </Transition>

        <Transition :duration="1000" name="fade">
            <div v-show="pageState === 'joinFriend'" class="friends friends-container__clans friends-container__page">
                <InviteFriend
                    v-model:code-text="codeText"
                    class="friends__join-clan"
                    @close="closeWindows"
                    @copy-link="copyLink"
                    @invite-friend="inviteFriend"
                />
            </div>
        </Transition>
    </LobbyLayout>
</template>

<style scoped lang="scss">
.friends-container {
    position: relative;

    &__page {
        position: absolute;
        top: 0;
        left: 0;

        display: flex;

        flex-direction: column;

        width: 100%;

        &::after {
            display: block;

            width: 100%;
            height: size(20px);

            content: '';
        }
    }
}

.friends {
    $parent: &;

    &__join-button {
        margin-bottom: 0;
    }

    &__clans-panel {
        margin-bottom: size(64px);
    }

    &__clans-block {
        display: flex;

        flex-direction: column;

        gap: size(21px);

        min-height: size(498px);
        max-height: size(988px);
        padding-right: size(60px);
    }

    &__buttons {
        display: grid;

        grid-template-columns: repeat(2, 1fr);

        gap: size(46px);

        justify-content: center;
    }

    &__mini-block {
        display: grid;

        grid-template-columns: 1fr 1fr;

        gap: size(33px);

        width: 100%;
        margin-bottom: size(48px);

        &__left {
            width: size(332px);
        }
    }

    &__mini-block-second {
        display: flex;

        justify-content: space-between;

        width: 100%;
        margin-bottom: size(48px);

        &__left {
            width: size(200px);
        }

        &__inner {
            display: flex;

            justify-content: space-between;

            width: size(684px);
        }

        &__content {
            width: size(241px);
        }
    }

    &__mini-block-data {
        display: flex;

        flex-direction: column;

        align-items: center;
        justify-content: center;

        &__income {
            display: flex;

            gap: size(16px);

            justify-content: center;

            & > p {
                text-align: right;;
            }
        }

        &__icon {
            width: size(73px);
            height: size(73px);
        }
    }

    &__mini-block-title {
        line-height: size(82px);
    }
}
</style>
