<script setup lang="ts">
import { EventCategory } from 'game/services/analytics/amplitudeEventService';
import MainButton from 'game/ui/commonInstances/MainButton.vue';
import { useAmplitudeEventService } from 'game/ui/composables/amplitudeEventService';
import { ContainerIds } from 'game/ui/config/containers';
import LobbyLayout from 'game/ui/layouts/LobbyLayout.vue';
import { windowManager } from 'game/ui/services/windowManager';
import { getAssetUrl } from 'game/ui/utils/assets';
import { computed, onBeforeMount, ref } from 'vue';

const amplitudeEventService = useAmplitudeEventService();

const imageUrls = ['1', '3', '5', '2', '4', '6'].map(src => getAssetUrl(`tutorial/${src}.webp`));

const currentIndex = ref(0);

const imageUrl = computed(() => imageUrls[currentIndex.value] ?? '');

const isLastImage = computed(() => currentIndex.value === imageUrls.length - 1);

const nextImage = () => {
    if (isLastImage.value) {
        windowManager.setState('matchRooms');

        amplitudeEventService.sendEvent('Tutorial - 6 - Close', {
            category: EventCategory.Tutorial,
        });

        return;
    }

    switch (currentIndex.value) {
        case 0:
            break;
        case 1:
            amplitudeEventService.sendEvent('Tutorial - 2 - Next', {
                category: EventCategory.Tutorial,
            });
            break;
        case 2:
            amplitudeEventService.sendEvent('Tutorial - 3 - Next', {
                category: EventCategory.Tutorial,
            });
            break;
        case 3:
            amplitudeEventService.sendEvent('Tutorial - 4 - Next', {
                category: EventCategory.Tutorial,
            });
            break;
        case 4:
            amplitudeEventService.sendEvent('Tutorial - 5 - Next', {
                category: EventCategory.Tutorial,
            });
            break;
    }

    currentIndex.value = (currentIndex.value + 1) % imageUrls.length;
};

onBeforeMount(() => {
    currentIndex.value = 0;
});
</script>

<template>
    <div class="tutorial-container">
        <LobbyLayout
            class="tutorial"
            :hide-header="true"
            :container-id="ContainerIds.Tutorial"
        >
            <div class="tutorial__inner">
                <div class="tutorial__page">
                    <div class="tutorial__image-container">
                        <img
                            class="tutorial__image"
                            :src="imageUrl"
                            alt="tutorial"
                        >
                    </div>

                    <MainButton
                        class="tutorial__button"
                        :label="isLastImage ? 'close' : 'next'"
                        @click="nextImage"
                    />
                </div>
            </div>
        </LobbyLayout>
    </div>
</template>

<style scoped lang="scss">
.tutorial-container {
    width: 100%;
    height: 100%;
}

.tutorial {
    &__inner {
        position: relative;
    }

    &__page {
        position: absolute;
        top: size(119px);
        left: 0;

        display: flex;

        flex-direction: column;

        gap: size(56px);

        align-items: center;
        justify-content: center;

        width: 100%;

        &::after {
            display: block;

            width: 100%;
            height: size(20px);

            content: '';
        }
    }

    &__image-container {
        flex-shrink: 0;

        width: size(973px);
        height: size(2110px);

        border: size(6px) solid #FFF;
    }

    &__image {
        width: 100%;
        height: 100%;

        object-fit: cover;
    }

    &__button {
        width: 100%;
        height: size(178.119px);
    }
}
</style>
