import { Entity } from '@nord-beaver/core/ecs';
import { EngineService, engineService } from '@nord-beaver/core/ecs/engineService';
import { EntityService, entityService } from '@nord-beaver/core/ecs/entityService';
import { motionDetectionSystem } from '@nord-beaver/core/ecs/systems/motionDetectionSystem';
import { pointerInputSystem } from '@nord-beaver/core/ecs/systems/pointerInputSystem';
import { sceneObjectSystem } from '@nord-beaver/core/ecs/systems/sceneObjectSystem';
import { soundSystem } from '@nord-beaver/core/ecs/systems/soundSystem';
import { spineSystem } from '@nord-beaver/core/ecs/systems/spineSystem';
import { spriteSystem } from '@nord-beaver/core/ecs/systems/spriteSystem';
import { tweenSystem } from '@nord-beaver/core/ecs/systems/tweenSystem';
import { viewportSystem } from '@nord-beaver/core/ecs/systems/viewportSystem';
import { type Model } from '@nord-beaver/core/mvc';
import { AnchorService, anchorService } from '@nord-beaver/core/services/anchorService';
import { ASSET_TYPE, assetService, AssetServicePhaser } from '@nord-beaver/core/services/assetService';
import { audioService, AudioServiceHowler } from '@nord-beaver/core/services/audioService';
import { BaseService } from '@nord-beaver/core/services/baseService';
import { CameraService, cameraService } from '@nord-beaver/core/services/cameraService';
import { DataService, dataService } from '@nord-beaver/core/services/dataService';
import { DebugPanelService, debugPanelService } from '@nord-beaver/core/services/debugPanelService';
import { ButtonElement, InputCheckboxElement } from '@nord-beaver/core/services/debugPanelService/debugElements';
import { DebugService, debugService } from '@nord-beaver/core/services/debugService';
import { EnvService, envService } from '@nord-beaver/core/services/envService/envService';
import { InputService, inputService } from '@nord-beaver/core/services/inputService';
import { StageService, stageService } from '@nord-beaver/core/services/stageService';
import { TweenService, tweenService } from '@nord-beaver/core/services/tweenService';
import { FLAG_DEBUG, FLAG_TEST } from '@nord-beaver/core/utils/debug';
import { keyboard } from '@nord-beaver/core/utils/input/devices/keyboard';
import { Mouse, mouse } from '@nord-beaver/core/utils/input/devices/mouse';
import { Touch, touch } from '@nord-beaver/core/utils/input/devices/touch';
import { mainLogger } from '@nord-beaver/core/utils/logger';
import * as Sentry from '@sentry/browser';
import { api } from 'game/api/api';
import { registerComponents } from 'game/componentsRegister';
import { CursorState, Layers, nakamaEntityId, fonts, APP_VERSION } from 'game/constants';
import { initModelDebugElements } from 'game/debug/modelDebugElements';
import { initWindowManagerDebugElements } from 'game/debug/windowManagerDebugElements';
import { LoadingComponent } from 'game/ecs/components/loadingComponent';
import { type NakamaCompositeConfig } from 'game/ecs/components/nakama/nakamaComponent';
import { CameraMoveSystem } from 'game/ecs/systems/cameraMoveSystem';
import { CursorSystem } from 'game/ecs/systems/cursorSystem';
import { GridTilemapInteractionSystem } from 'game/ecs/systems/gridTilemap/interactionSystem';
import { GridTilemapReplicantSystem } from 'game/ecs/systems/gridTilemap/replicantSystem';
import { GridTilemapSystem } from 'game/ecs/systems/gridTilemap/tilemapSystem';
import { GridTilemapTileOverlaySystem } from 'game/ecs/systems/gridTilemap/tileOverlaySystem';
import { GridTilemapTileSystem } from 'game/ecs/systems/gridTilemap/tileSystem';
import { LobbySystem } from 'game/ecs/systems/lobbySystem';
import { MatchmakingSystem } from 'game/ecs/systems/match/matchmakingSystem';
import { MatchSystem } from 'game/ecs/systems/match/matchSystem';
import { ClansSystem } from 'game/ecs/systems/meta/clansSystem';
import { DailyRewardsSystem } from 'game/ecs/systems/meta/dailyRewardsSystem';
import { FriendsSystem } from 'game/ecs/systems/meta/friendsSystem';
import { TasksSystem } from 'game/ecs/systems/meta/tasksSystem';
import { VaultSystem } from 'game/ecs/systems/meta/vaultSystem';
import { WelcomeRewardSystem } from 'game/ecs/systems/meta/welcomeRewardSystem';
import { MoveSystem } from 'game/ecs/systems/moveSystem';
import { FrameNakamaSystem } from 'game/ecs/systems/nakama/frameNakamaSystem';
import { MatchDataNakamaSystem } from 'game/ecs/systems/nakama/matchDataNakamaSystem';
import { MatchNakamaSystem } from 'game/ecs/systems/nakama/matchNakamaSystem';
import { MatchPresenceNakamaSystem } from 'game/ecs/systems/nakama/matchPresenceNakamaSystem';
import { NakamaSystem } from 'game/ecs/systems/nakama/nakamaSystem';
import { NotificationNakamaSystem } from 'game/ecs/systems/nakama/notificationNakamaSystem';
import { SessionNakamaSystem } from 'game/ecs/systems/nakama/sessionNakamaSystem';
import { TargetViewSizeSystem } from 'game/ecs/systems/targetViewSizeSystem';
import { GridTilemapViewSystem } from 'game/ecs/systems/ui/gridTilemapViewSystem';
import { LobbyViewSystem } from 'game/ecs/systems/ui/lobbyViewSystem';
import { MatchmakingViewSystem } from 'game/ecs/systems/ui/matchmakingViewSystem';
import { MatchPresenceViewSystem } from 'game/ecs/systems/ui/matchPresenceViewSystem';
import { MatchViewSystem } from 'game/ecs/systems/ui/matchViewSystem';
import { AmplitudeEventService } from 'game/services/analytics/amplitudeEventService';
import { AmplitudeService } from 'game/services/analytics/amplitudeService';
import { EntityMapService } from 'game/services/entityMapService';
import { EnvConfigService } from 'game/services/envConfigService';
import { EventService } from 'game/services/eventService';
import { ClientNakamaService } from 'game/services/nakama/clientNakamaService';
import { MatchDataNakamaService } from 'game/services/nakama/matchData/matchDataNakamaService';
import { MatchNakamaService } from 'game/services/nakama/matchNakamaService';
import { NotificationNakamaService } from 'game/services/nakama/notification/notificationNakamaService';
import { RpcNakamaService } from 'game/services/nakama/rpc/rpcNakamaService';
import { SessionNakamaService } from 'game/services/nakama/session/sessionNakamaService';
import { type AuthNakamaConfig, AuthNakamaType } from 'game/services/nakama/session/sessionNakamaUtils';
import { SocketNakamaService } from 'game/services/nakama/socketNakamaService';
import { NakamaService } from 'game/services/nakamaService';
import { TelegramService } from 'game/services/telegramService';
import { Currency } from 'game/types/entityDescs/currency';
import { type ViewModel } from 'game/types/model/viewModel';
import { ApiNotificationCode } from 'game/types/nakama/notificationData';
import { createUI } from 'game/ui';
import { windowManager } from 'game/ui/services/windowManager';
import { DependencyContainer, type InjectableServiceClass } from 'game/utils/dependencyContainer';
import { loadFonts } from 'game/utils/fontLoader';
import { getModel } from 'game/utils/model';
import { apiMatchDataHandlersDesc } from 'game/utils/nakama/matchDataUtils';
import { apiNotificationHandlersDesc } from 'game/utils/nakama/notificationUtils';
import { apiRpcHandlersDesc } from 'game/utils/nakama/rpcUtils';
import { testForDependencyCycles } from 'tests/diCycle.test';
import { v4 as uuidv4 } from 'uuid';

type ServiceRegistration = {
    instance?: unknown;
    dependencies?: InjectableServiceClass<unknown>[];
};

export async function boot() {
    const dependencyContainer = new DependencyContainer();

    const services = new Map<InjectableServiceClass<unknown>, ServiceRegistration | null>([
        [EngineService, { instance: engineService }],
        [AnchorService, { instance: anchorService }],
        [DataService, { instance: dataService }],
        [DebugPanelService, { instance: debugPanelService }],
        [InputService, { instance: inputService }],
        [StageService, { instance: stageService }],
        [TweenService, { instance: tweenService }],
        [CameraService, { instance: cameraService }],
        [EntityService, { instance: entityService }],
        [AssetServicePhaser, { instance: assetService }],
        [DebugService, { instance: debugService }],
        [EnvService, { instance: envService }],
        [AudioServiceHowler, { instance: audioService }],
        [EnvConfigService, null],
        [EventService, null],
        [ClientNakamaService, null],
        [SessionNakamaService, { dependencies: [EnvService] }],
        [SocketNakamaService, null],
        [MatchDataNakamaService, null],
        [MatchNakamaService, null],
        [NotificationNakamaService, null],
        [RpcNakamaService, null],
        [NakamaService, { dependencies: [EngineService, RpcNakamaService] }],
        [EntityMapService, { dependencies: [EntityService] }],
        [TelegramService, null],
        [Mouse, { instance: mouse }],
        [Touch, { instance: touch }],
        [AmplitudeService, null],
        [AmplitudeEventService, { dependencies: [AmplitudeService] }],
        // systems
        [NakamaSystem, { dependencies: [ClientNakamaService, SessionNakamaService, SocketNakamaService, RpcNakamaService, EventService] }],
        [LobbySystem, { dependencies: [EventService, NakamaService, TelegramService, AmplitudeService, AmplitudeEventService] }],
        [SessionNakamaSystem, { dependencies: [SessionNakamaService] }],
        [MatchNakamaSystem, { dependencies: [MatchNakamaService] }],
        [MatchPresenceNakamaSystem, { dependencies: [SocketNakamaService, SessionNakamaService] }],
        [MatchDataNakamaSystem, { dependencies: [SocketNakamaService, MatchDataNakamaService] }],
        [NotificationNakamaSystem, { dependencies: [SocketNakamaService, NotificationNakamaService] }],
        [FrameNakamaSystem, null],
        [GridTilemapSystem, { dependencies: [EngineService, EntityService] }],
        [GridTilemapInteractionSystem, { dependencies: [Mouse, Touch, EventService] }],
        [GridTilemapReplicantSystem, { dependencies: [EngineService, EntityService, NakamaService, EventService] }],
        [GridTilemapTileOverlaySystem, { dependencies: [EntityService, TweenService, EngineService] }],
        [GridTilemapTileSystem, { dependencies: [EntityService, NakamaService, EventService] }],
        [CameraMoveSystem, { dependencies: [CameraService, StageService, Mouse, Touch, TweenService] }],
        [MoveSystem, null],
        [MatchmakingSystem, { dependencies: [EntityMapService, AssetServicePhaser, EngineService, StageService, EntityService, EventService, NakamaService, SessionNakamaService, TelegramService, AmplitudeEventService] }],
        [MatchmakingViewSystem, { dependencies: [EventService] }],
        [MatchSystem, { dependencies: [EngineService, NakamaService, EventService, EntityMapService, SessionNakamaService, AmplitudeEventService] }],
        [LobbyViewSystem, null],
        [MatchViewSystem, null],
        [MatchPresenceViewSystem, null],
        [GridTilemapViewSystem, null],
        [CursorSystem, { dependencies: [StageService] }],
        [TargetViewSizeSystem, null],
        [DailyRewardsSystem, { dependencies: [EntityService, EventService, NakamaService, AmplitudeEventService] }],
        [FriendsSystem, { dependencies: [EventService, TelegramService] }],
        [TasksSystem, { dependencies: [EventService, NakamaService] }],
        [ClansSystem, { dependencies: [EventService, NakamaService, TelegramService] }],
        [VaultSystem, { dependencies: [EventService, NakamaService, EntityService, AmplitudeEventService] }],
        [WelcomeRewardSystem, { dependencies: [EventService, AmplitudeEventService] }],
    ]);

    const configService = dependencyContainer.get(EnvConfigService);
    if (configService.Config.SENTRY) {
        Sentry.init({
            dsn: configService.Config.SENTRY.DSN,
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration(),
            ],
            tracesSampleRate: 1.0,
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
        });
    }

    services.forEach((config, Service) => dependencyContainer.register(Service, config?.instance, config?.dependencies));

    const telegramService = dependencyContainer.get(TelegramService);

    if (FLAG_DEBUG || FLAG_TEST) {
        testForDependencyCycles(dependencyContainer);
    }

    entityService.applyRegisterComponents(registerComponents);

    // config services
    debugPanelService?.config({
        parentContainerId: 'debug-panel-container',
    });
    stageService.config({
        layers: [
            Layers.Game,
            Layers.View,
            Layers.InputWall,
        ],
        layerDefault: Layers.Game,
        customParams: {
            transparent: true,
        },
    });
    dataService.config<ViewModel>({
        core: {
            cursorState: CursorState.Normal,
            isMouseDisable: false,
            isMouseInputTriggered: {},
            isGameKeyboardEnabled: true,
        },
        lobby: {
            loadingProgress: 0,
            isMenuVisible: true,
        },
        match: {
            name: '',
            turnTimeLeft: 1,
            matchTimeLeft: 0,
            coins: 0,
            tiles: [],
            selectedTileIndex: null,
            playerTileIndex: null,
            leaderboard: [],
            matchPlayers: [],
            matchLink: '',
            matchId: '',
            operationUid: '',
            isBuyTileAvailable: false,
        },
        resources: {
            currencies: {
                [Currency.Soft]: 0,
                [Currency.Hard]: 0,
            },
            decimals: {},
            rates: {},
        },
        tasks: {
            tasks: [],
        },
        users: {
        },
        friends: {
            refferalsCount: 0,
            refferalsCount2ndLevel: 0,
            refferalsCount3rdLevel: 0,
            incomeCoeficient: 5,
            income: 0,
            incomeFromRefferalsCoeficient: 2.5,
            list: [],
        },
        clans: {
            list: [],
        },
        dailyReward: {
            rewards: [],
            activeRewardIndex: null,
            claimAvailable: false,
        },
        matchRooms: {
            rooms: [],
            selectedRoomMatchId: null,
        },
        vault: {
            isAvailable: false,
            isFetching: false,
            progress: 0,
            leftTimeMs: 0,
        },
        welcomeReward: {
            reward: {
                type: Currency.Soft,
                count: 0,
                isClaimed: false,
            },
        },
    });
    viewportSystem.config({
        margin: 150,
    });
    inputService.config({
        devices: [keyboard, mouse, touch],
    });
    pointerInputSystem.config({
        devices: [mouse, touch],
    });

    const entityMapService = dependencyContainer.get(EntityMapService);
    // systems. after config services
    const nakamaSystem = dependencyContainer.get(NakamaSystem);
    const sessionNakamaSystem = dependencyContainer.get(SessionNakamaSystem);
    const matchNakamaSystem = dependencyContainer.get(MatchNakamaSystem);
    const matchDataNakamaSystem = dependencyContainer.get(MatchDataNakamaSystem);
    const matchPresenceNakamaSystem = dependencyContainer.get(MatchPresenceNakamaSystem);
    const notificationNakamaSystem = dependencyContainer.get(NotificationNakamaSystem);
    const frameNakamaSystem = dependencyContainer.get(FrameNakamaSystem);
    const gridTilemapSystem = dependencyContainer.get(GridTilemapSystem);
    const gridTilemapInteractionSystem = dependencyContainer.get(GridTilemapInteractionSystem);
    const gridTilemapReplicantSystem = dependencyContainer.get(GridTilemapReplicantSystem);
    const gridTilemapTileOverlaySystem = dependencyContainer.get(GridTilemapTileOverlaySystem);
    const gridTilemapTileSystem = dependencyContainer.get(GridTilemapTileSystem);
    const cameraMoveSystem = dependencyContainer.get(CameraMoveSystem);
    const moveSystem = dependencyContainer.get(MoveSystem);
    const matchMakinngSystem = dependencyContainer.get(MatchmakingSystem);
    const matchSystem = dependencyContainer.get(MatchSystem);
    const lobbySystem = dependencyContainer.get(LobbySystem);
    const lobbyViewSystem = dependencyContainer.get(LobbyViewSystem);
    const matchViewSystem = dependencyContainer.get(MatchViewSystem);
    const matchPresenceViewSystem = dependencyContainer.get(MatchPresenceViewSystem);
    const gridTilemapViewSystem = dependencyContainer.get(GridTilemapViewSystem);
    const cursorSystem = dependencyContainer.get(CursorSystem);
    const targetViewSizeSystem = dependencyContainer.get(TargetViewSizeSystem);
    const matchmakingViewSystem = dependencyContainer.get(MatchmakingViewSystem);
    const dailyRewardSystem = dependencyContainer.get(DailyRewardsSystem);
    const friendsSystem = dependencyContainer.get(FriendsSystem);
    const tasksSystem = dependencyContainer.get(TasksSystem);
    const clansSystem = dependencyContainer.get(ClansSystem);
    const vaultSystem = dependencyContainer.get(VaultSystem);
    const welcomeRewardSystem = dependencyContainer.get(WelcomeRewardSystem);

    // setup priorities
    BaseService.setupInitPriority([
        envService,
        ...FLAG_DEBUG
            ? [
                debugPanelService,
            ]
            : [],

        stageService,
        inputService,
        pointerInputSystem,
        cameraService,
        audioService,

        targetViewSizeSystem,

        nakamaSystem,
        lobbySystem,
        sessionNakamaSystem,
        matchNakamaSystem,
        matchDataNakamaSystem,
        matchPresenceNakamaSystem,
        notificationNakamaSystem,
        frameNakamaSystem,

        gridTilemapReplicantSystem,
        matchMakinngSystem,
        matchSystem,
        gridTilemapSystem,
        gridTilemapTileSystem,
        gridTilemapTileOverlaySystem,
        gridTilemapInteractionSystem,
    ]);
    BaseService.setupUpdatePriority([
        inputService,
        pointerInputSystem,
        tweenSystem,
        motionDetectionSystem,
        spriteSystem,
        spineSystem,

        targetViewSizeSystem,

        nakamaSystem,
        sessionNakamaSystem,
        matchNakamaSystem,
        matchDataNakamaSystem,
        notificationNakamaSystem,

        dailyRewardSystem,
        friendsSystem,
        tasksSystem,
        clansSystem,
        vaultSystem,
        welcomeRewardSystem,
        lobbySystem,

        gridTilemapReplicantSystem,
        matchMakinngSystem,
        matchSystem,
        gridTilemapSystem,
        gridTilemapTileSystem,
        gridTilemapTileOverlaySystem,
        gridTilemapInteractionSystem,

        cameraMoveSystem,
        moveSystem,

        // anchorService update after cameraService, because need updated camera
        // position for some anchors
        cameraService,
        anchorService,
        sceneObjectSystem,
        debugService,
        audioService,
        soundSystem,

        // viewportSystem must be after camera and all system, that move objects (physics, etc.)
        viewportSystem,
        tweenService,

        matchViewSystem,
        gridTilemapViewSystem,
        matchmakingViewSystem,
        lobbyViewSystem,
        matchPresenceViewSystem,

        cursorSystem,

        // clean up all frame data (MatchData, Notifications, MatchPresence) after all systems. Should be last
        frameNakamaSystem,
    ]);
    EngineService.setupNodeAddActionPriority([
        spineSystem,
        spriteSystem,

        // sceneObjectSystem must be after all 'scene' systems (that create scene objects):
        // like tilemapSystem, spriteSystem, spineSystem
        sceneObjectSystem,
        pointerInputSystem,
    ]);
    EngineService.setupNodeRemoveActionPriority([
        spineSystem,
        sceneObjectSystem,
    ]);

    // init services
    await BaseService.init();
    BaseService.start();

    if (!FLAG_DEBUG
        || !FLAG_TEST
        || !debugPanelService.addElement(new InputCheckboxElement({
            id: 'Is context menu enabled',
            path: ['UI'],
            isReloadRequired: true,
            isStorable: true,
            initValue: false,
        })).value
    ) {
        document.addEventListener('contextmenu', event => event.preventDefault());
    }

    // load assets
    await loadFonts({
        fontsPath: 'assets/fonts',
        fonts: [...fonts],
    });
    await assetService.load([
        {
            type: ASSET_TYPE.PACK,
            url: 'assets/packs/entities.json',
        },
    ]);

    const amplitudeService = dependencyContainer.get(AmplitudeService);
    const eventService = dependencyContainer.get(EventService);
    eventService.game.dispatch('entityLoaded');

    createUI(dependencyContainer);

    windowManager.setState('loading');

    let loadingEntity: Entity | null = new Entity();
    let loading: LoadingComponent | null = new LoadingComponent();
    loadingEntity.add(loading);

    engineService.addEntity(loadingEntity);

    const onProgress = (progress: number) => {
        if (!loading) {
            return;
        }

        loading.progress = progress;
    };

    assetService.progressSignal.on(onProgress);

    await assetService.load([
        {
            type: ASSET_TYPE.PACK,
            url: 'assets/packs/common.json',
        },
        {
            type: ASSET_TYPE.PACK,
            url: 'assets/packs/ui.json',
        },
    ]);
    entityMapService.init(); // after load all entities

    loading.progress = 1;

    assetService.progressSignal.off(onProgress);

    const telegramData = telegramService.getData();
    let authConfig: AuthNakamaConfig | null = null;

    if (telegramData) {
        const username = telegramData.username.toString();

        authConfig = {
            type: AuthNakamaType.Custom,
            customId: username,
            username,
            vars: {
                password: telegramData.hash,
                seed: telegramData.seed,
                username,
            },
        };
    } else if (FLAG_DEBUG || FLAG_TEST) {
        authConfig = {
            type: AuthNakamaType.Device,
            deviceId: uuidv4(),
        };

        mainLogger.warn('Login with device id', authConfig.deviceId, 'because telegram data is not found');
    }

    if (!authConfig) {
        throw new Error('Auth config is not set use telegram bot for login');
    }

    if (configService.Config.AMPLITUDE && authConfig.type === AuthNakamaType.Custom && authConfig.customId) {
        const id = authConfig.customId;

        amplitudeService.init({
            apiKey: configService.Config.AMPLITUDE.API_KEY,
            id: id,
        });

        amplitudeService.updateUserProperties(user => {
            user.set('username', id);
            user.set('app_version', APP_VERSION);
        });
    }

    const config = dependencyContainer.get(EnvConfigService);
    const nakamaConfig: NakamaCompositeConfig = {
        ...config.Config.NAKAMA,
        authConfig,
        matchDataHandlersDesc: apiMatchDataHandlersDesc,
        notificationHandlersDesc: apiNotificationHandlersDesc,
        rpcHandlersDesc: apiRpcHandlersDesc,
        getMatchDataCodeName: (code, type) => (
            type === 'receive'
                ? api.OpCodeResponse[code]?.toString()
                : api.OpCodeRequest[code]?.toString()
        ) ?? code.toString(),
        getNotificationCodeName: code => ApiNotificationCode[code]?.toString() ?? code.toString(),
    };

    const nakamaService = dependencyContainer.get(NakamaService);
    let nakamaEntity = nakamaService.add(nakamaEntityId, nakamaConfig);

    eventService.lobby.on('lobbyReady', () => {
        if (!nakamaEntity) {
            nakamaEntity = nakamaService.get(nakamaEntityId);
        }

        if (loading) {
            loading.progress = 1;
        }
        if (loadingEntity) {
            engineService.removeEntity(loadingEntity);

            loadingEntity = null;
            loading = null;
        }

        if (FLAG_DEBUG || FLAG_TEST) {
            debugPanelService?.addElement(new ButtonElement({
                id: 'Reconnect',
                path: ['Nakama'],
                initValue: () => {
                    nakamaService.add(nakamaEntityId, nakamaConfig);
                },
            }));
        }
    });

    if (FLAG_DEBUG || FLAG_TEST) {
        initWindowManagerDebugElements?.(windowManager);
        initModelDebugElements?.(getModel(dependencyContainer) as unknown as Model<Record<string, unknown>>);

        // TODO: remove this
        mainLogger.info('-------------------------------------------');
        mainLogger.log('InitData', window.Telegram.WebApp.initData);
        mainLogger.info('-------------------------------------------');
        const params = telegramService.getStartParams();
        mainLogger.info('Start params',  structuredClone(params));
        mainLogger.info('-------------------------------------------');
    }
}