import { SystemService } from '@nord-beaver/core/ecs';
import { type Model } from '@nord-beaver/core/mvc/model';
import { mainLogger } from '@nord-beaver/core/utils/logger';
import { MatchRoomsComponent } from 'game/ecs/components/match/matchRoomsComponent';
import { NakamaNode } from 'game/ecs/nodes/nakama/nakamaNode';
import { type EventService } from 'game/services/eventService';
import { type MatchModel } from 'game/types/model/matchModel';
import { type MatchRoom, type MatchRoomsModel } from 'game/types/model/matchRoomsModel';
import { type DependencyContainer } from 'game/utils/dependencyContainer';
import { getModel } from 'game/utils/model';

const logger = mainLogger.getLogger('Matchmaking').getLogger('View');

export class MatchmakingViewSystem extends SystemService {
    private readonly matchModel: Model<MatchModel>;
    private readonly matchRoomsModel: Model<MatchRoomsModel>;

    constructor(
        dependencyContainer: DependencyContainer,
        private readonly eventService: EventService,
    ) {
        super();

        this.matchModel = getModel(dependencyContainer, ['match']);
        this.matchRoomsModel = getModel(dependencyContainer, ['matchRooms']);
    }

    async init() {
        this.setupNodeList({
            node: NakamaNode,
            update: this.updateNakama,
        });

        this.eventService.lobby.on('openJoinMatchPopup', (matchId: string) => {
            this.matchRoomsModel.data.selectedRoomMatchId = matchId;
        }, this);
    }

    override async destroy() {
        this.eventService.lobby.offAll('openJoinMatchPopup', this);

        super.destroy();
    }

    private updateNakama(node: NakamaNode) {
        const { matchPresence, entity } = node;

        matchPresence.matchPresenceMap.forEach(matchPresence => {
            if (!matchPresence.isDirty) {
                return;
            }

            for (const presence of matchPresence.presence) {
                if (!this.matchModel.data.matchPlayers.includes(presence.user_id)) {
                    this.matchModel.data.matchPlayers.push(presence.user_id);
                }
            }

            for (const userId of this.matchModel.data.matchPlayers) {
                if (!matchPresence.presence.find(presence => presence.user_id === userId)) {
                    const index = this.matchModel.data.matchPlayers.indexOf(userId);
                    if (index !== -1) {
                        this.matchModel.data.matchPlayers.splice(index, 1);
                    }
                }
            }
        });

        const matchRooms = entity.get(MatchRoomsComponent);
        if (matchRooms && matchRooms.isDirty) {
            this.updateMatchRooms(matchRooms);
        }
    }

    private updateMatchRooms(matchRooms: MatchRoomsComponent) {
        matchRooms.isDirty = false;

        const roomsView: MatchRoom[] = [];

        for (const matchId in matchRooms.rooms) {
            const matchDesc = matchRooms.rooms[matchId];
            if (!matchDesc) {
                logger.error('MatchRoomsComponent: matchDesc is not found', { matchId });

                continue;
            }

            roomsView.push({
                matchId,
                operationUid: matchDesc.operationUid,
                name: matchDesc.matchName,
                betCoins: matchDesc.bet.map(bet => ({
                    type: bet.type,
                    value: bet.value,
                })),
                playerAvatars: matchDesc.playerAvatars,
            });
        }

        this.matchRoomsModel.data.rooms = roomsView;
    }
}